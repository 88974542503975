export default (routingTableNode, path, scanRoute) => {
  if (routingTableNode.childRoutes && routingTableNode.childRoutes.length > 0) {
    let route = null;
    for (let i = 0; i < routingTableNode.childRoutes.length; i += 1) {
      route = scanRoute(routingTableNode.childRoutes[i], path);

      if (route) {
        return route;
      }
    }
  }

  return null;
};
