export const FETCH_BANK_ACCOUNTS_REQUEST = 'partner/partner/FETCH_BANK_ACCOUNTS_REQUEST';
export const FETCH_BANK_ACCOUNTS_SUCCESS = 'partner/partner/FETCH_BANK_ACCOUNTS_SUCCESS';
export const FETCH_BANK_ACCOUNTS_FAILURE = 'partner/partner/FETCH_BANK_ACCOUNTS_FAILURE';

export const CREATE_BANK_ACCOUNT_REQUEST = 'partner/partner/CREATE_BANK_ACCOUNT_REQUEST';
export const CREATE_BANK_ACCOUNT_SUCCESS = 'partner/partner/CREATE_BANK_ACCOUNT_SUCCESS';
export const CREATE_BANK_ACCOUNT_FAILURE = 'partner/partner/CREATE_BANK_ACCOUNT_FAILURE';

export const FETCH_DETAIL_REQUEST = 'partner/partner/FETCH_DETAIL_REQUEST';
export const FETCH_DETAIL_SUCCESS = 'partner/partner/FETCH_DETAIL_SUCCESS';
export const FETCH_DETAIL_FAILURE = 'partner/partner/FETCH_DETAIL_FAILURE';

export const FETCH_PARTNER_PAIRS_REQUEST = 'partner/partner/FETCH_PARTNER_PAIRS_REQUEST';
export const FETCH_PARTNER_PAIRS_SUCCESS = 'partner/partner/FETCH_PARTNER_PAIRS_SUCCESS';
export const FETCH_PARTNER_PAIRS_FAILURE = 'partner/partner/FETCH_PARTNER_PAIRS_FAILURE';

export const UPDATE_PARTNER_REQUEST = 'partner/partner/UPDATE_PARTNER_REQUEST';
export const UPDATE_PARTNER_SUCCESS = 'partner/partner/UPDATE_PARTNER_SUCCESS';
export const UPDATE_PARTNER_FAILURE = 'partner/partner/UPDATE_PARTNER_FAILURE';
