import PropTypes from 'prop-types';
import React from 'react';
import styles from './Aligner.scss';


const Aligner = (props) => (
  <div className={styles.root}>
    <div className={styles.item}>
      {props.children}
    </div>
  </div>
);

Aligner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Aligner;
