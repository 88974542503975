import Immutable from 'immutable';
import * as types from './actionTypes';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'branch']);
  }

  if (type === types.FETCH_FIRM_BRANCH_PAIRS_REQUEST) {
    return state
      .setIn(['branchPairs', 'isFetchingFailure'], false)
      .setIn(['branchPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_FIRM_BRANCH_PAIRS_SUCCESS) {
    const branches = payload.map((item) => ({
      label: item.c85_name,
      value: item.c85_branch_id,
      active: item.active,
    }));

    return state
      .setIn(['branchPairs', 'branchPairs'], Immutable.fromJS(branches))
      .setIn(['branchPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_FIRM_BRANCH_PAIRS_FAILURE) {
    return state
      .setIn(['branchPairs', 'isFetching'], false)
      .setIn(['branchPairs', 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
