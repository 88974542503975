import * as types from './actionTypes';

export const addFlashMessage = (type, text, group, links, details) => ({
  type: types.ADD_FLASH_MESSAGE,
  payload: {
    text, type, group, links, details,
  },
});

export const removeFlashMessage = (messageIndex) => ({
  type: types.REMOVE_FLASH_MESSAGE, payload: { messageIndex },
});

export const removeFlashMessageGroup = (group) => ({
  type: types.REMOVE_FLASH_MESSAGE_GROUP, payload: { group },
});

export { removeFlashMessage as default };
