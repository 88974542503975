import React, { lazy } from 'react';
import OrderRoutingTable from '../order/routingTable';

const FirmUserCreateContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/user/CreateContainer'));
const FirmUserDetailContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/user/DetailContainer'));
const FirmUserEditContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/user/EditContainer'));
const FirmUserListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/user/ListContainer'));
const FirmFirmBranchListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './firm/BranchListContainer'));
const FirmCardDetailContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/cardDetail/CardDetailContainer'));
const FirmDeliveryAddressCreateContainer = lazy(() => import(/* webpackChunkName: "firm" */ './firm/DeliveryAddressCreateContainer'));
const FirmDeliveryAddressDetailContainer = lazy(() => import(/* webpackChunkName: "firm" */ './firm/DeliveryAddressDetailContainer'));
const FirmDeliveryAddressListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './firm/DeliveryAddressListContainer'));
const FirmFirmDetailPage = lazy(() => import(/* webpackChunkName: "firm" */ './firm/FirmDetailContainer'));
const FirmFirmEditContainer = lazy(() => import(/* webpackChunkName: "firm" */ './firm/FirmEditContainer'));
const FirmSettingsContainer = lazy(() => import(/* webpackChunkName: "firm" */ './firm/SettingsContainer'));
const FirmTransactionListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/transactionList/TransactionListContainer'));
const FirmBenefiaOrderListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/benefiaOrderList/BenefiaOrderListContainer'));
const DeliveryListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/deliveryList/DeliveryListContainer'));
const FirmCardListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/cardList/CardListContainer'));
const InvoiceListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/invoiceList/InvoiceListContainer'));
const FirmInvoicesAndOrdersParentContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/invoiceList/FirmInvoicesAndOrdersParentContainer'));
const OrdersListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/invoiceList/OrdersListContainer'));
const OrderDetailContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/orderDetail/OrderDetailContainer'));
const AttendanceListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/attendance/AttendanceListContainer'));
const AttendanceEmployeeContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/attendance/AttendanceEmployeeContainer'));
const AttendanceRecordsContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/attendance/AttendanceRecordsContainer'));
const PositionListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './firm/PositionListContainer'));
const DepartmentListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './firm/DepartmentListContainer'));
const FirmUserGroupListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/userGroup/UserGroupListContainer'));
const FirmUserGroupUsersListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/userGroup/UserGroupUsersListContainer'));
const FirmBenefitsListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/benefits/BenefitsListContainer'));
const FirmBenefitEditContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/benefits/BenefitEditContainer'));
const FirmNewsListContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/news/NewsListContainer'));
const FirmNewsEditContainer = lazy(() => import(/* webpackChunkName: "firm" */ './pages/news/NewsEditContainer'));


export default {
  path: 'firma',
  childRoutes: [
    {
      name: 'firm',
      path: 'firma',
      key: 'C6',
      indexRoute: { component: FirmFirmDetailPage },
      childRoutes: [
        {
          name: 'edit_firm',
          path: 'upravit/:firmId',
          key: 'C32',
          component: FirmFirmEditContainer,
        },
        {
          name: 'firm_delivery_addresses',
          path: 'dorucovaci-adresy/:firmId',
          key: 'C33',
          component: FirmDeliveryAddressListContainer,
        },
        {
          name: 'firm_delivery_address',
          path: 'dorucovaci-adresa/:deliveryAddressId',
          key: 'C30',
          component: FirmDeliveryAddressDetailContainer,
        },
        {
          name: 'add_firm_delivery_addresses',
          path: 'nova-dorucovaci-adresa/:firmId',
          key: 'C11',
          component: FirmDeliveryAddressCreateContainer,
        },
        {
          name: 'firm_branches',
          path: 'pobocky/:firmId',
          key: 'C34',
          component: (props) => <FirmFirmBranchListContainer {...props} type="branch" />,
        },
        {
          name: 'firm_cost_centers',
          path: 'strediska/:firmId',
          key: 'C34',
          component: (props) => <FirmFirmBranchListContainer {...props} type="cost_center" />,
        },
        {
          name: 'firm_positions',
          path: 'pozice/:firmId',
          key: 'C34',
          component: PositionListContainer,
        },
        {
          name: 'firm_departments',
          path: 'oddeleni/:firmId',
          key: 'C34',
          component: DepartmentListContainer,
        },
        {
          name: 'firm_settings',
          path: 'nastaveni/:firmId',
          key: 'C35',
          component: FirmSettingsContainer,
        },
      ],
    },
    {
      name: 'firm',
      path: 'firma(/:firmId)',
      key: 'C6',
      component: FirmFirmDetailPage,
    },
    {
      name: 'firm_users',
      path: 'uzivatele',
      key: 'C1',
      indexRoute: { component: FirmUserListContainer },
      childRoutes: [
        {
          name: 'firm_user',
          path: 'uzivatel/:userId/:contractId',
          key: 'C12',
          component: FirmUserDetailContainer,
        },
        {
          name: 'edit_firm_user',
          path: 'uzivatel/:userId/:contractId/upravit',
          key: 'C37',
          component: FirmUserEditContainer,
        },
        {
          name: 'add_firm_user',
          path: 'novy-uzivatel',
          key: 'C8',
          component: FirmUserCreateContainer,
        },
      ],
    },
    {
      name: 'firm_cards',
      path: 'karty',
      key: 'C2',
      indexRoute: { component: FirmCardListContainer },
      childRoutes: [
        {
          name: 'firm_card',
          path: 'karta/:cardId',
          key: 'C36',
          component: FirmCardDetailContainer,
        },
      ],
    },
    {
      name: 'firm_transactions',
      path: 'transakce',
      key: 'C13',
      component: FirmTransactionListContainer,
    },
    {
      name: 'firm_benefia_orders',
      path: 'benefia-objednavky',
      key: 'BC01',
      component: FirmBenefiaOrderListContainer,
    },
    OrderRoutingTable,
    {
      name: 'firm_invoices',
      path: 'faktury',
      key: 'C4',
      component: FirmInvoicesAndOrdersParentContainer,
      indexRoute: { component: InvoiceListContainer },
      childRoutes: [
        {
          name: 'firm_orders',
          path: 'objednavky',
          key: 'C4',
          indexRoute: { component: OrdersListContainer },
        }],
    },
    {
      name: 'firm_orders_detail',
      path: 'faktury/objednavky/:orderId',
      key: 'C4',
      component: OrderDetailContainer,
    },
    {
      name: 'firm_deliveries',
      path: 'zasilky',
      key: 'C9',
      component: DeliveryListContainer,
    },
    {
      name: 'firm_attendance',
      path: 'dochazka',
      key: 'C40',
      indexRoute: { component: AttendanceListContainer },
      childRoutes: [
        {
          name: 'firm_attendance_employee',
          path: ':userId',
          key: 'C40',
          indexRoute: { component: AttendanceEmployeeContainer },
          childRoutes: [
            {
              name: 'firm_attendance_records',
              path: ':workShiftId',
              key: 'C40',
              indexRoute: { component: AttendanceRecordsContainer },
            },
          ],
        },
      ],
    },
    {
      name: 'firm_user_groups',
      path: 'skupiny',
      key: 'C41',
      indexRoute: { component: FirmUserGroupListContainer },
      childRoutes: [
        {
          name: 'firm_user_groups_detail',
          path: ':groupId',
          key: 'C41',
          component: FirmUserGroupUsersListContainer,
        },
      ],
    },
    {
      name: 'firm_benefits_admin',
      path: 'sprava-benefitu',
      key: 'C43',
      indexRoute: { component: FirmBenefitsListContainer },
      childRoutes: [
        {
          name: 'firm_benefits_admin_add',
          path: 'novy',
          key: 'C43',
          component: FirmBenefitEditContainer,
        },
        {
          name: 'firm_benefits_admin_detail',
          path: ':benefitId',
          key: 'C43',
          component: FirmBenefitEditContainer,
        },
      ],
    },
    {
      name: 'firm_news_admin',
      path: 'sprava-novinek',
      key: 'C42',
      indexRoute: { component: FirmNewsListContainer },
      childRoutes: [
        {
          name: 'firm_news_admin_add',
          path: 'nova',
          key: 'C42',
          component: FirmNewsEditContainer,
        },
        {
          name: 'firm_news_admin_detail',
          path: ':newsId',
          key: 'C42',
          component: FirmNewsEditContainer,
        },
      ],
    },
  ],
};
