export const FETCH_FIRM_COST_CENTER_PAIRS_REQUEST = 'firm/cost_center/FETCH_FIRM_COST_CENTER_PAIRS_REQUEST';
export const FETCH_FIRM_COST_CENTER_PAIRS_SUCCESS = 'firm/cost_center/FETCH_FIRM_COST_CENTER_PAIRS_SUCCESS';
export const FETCH_FIRM_COST_CENTER_PAIRS_FAILURE = 'firm/cost_center/FETCH_FIRM_COST_CENTER_PAIRS_FAILURE';

export const ADD_COST_CENTER_REQUEST = 'firm/cost_center/ADD_COST_CENTER_REQUEST';
export const ADD_COST_CENTER_SUCCESS = 'firm/cost_center/ADD_COST_CENTER_SUCCESS';
export const ADD_COST_CENTER_FAILURE = 'firm/cost_center/ADD_COST_CENTER_FAILURE';

export const UPDATE_COST_CENTER_REQUEST = 'firm/cost_center/UPDATE_COST_CENTER_REQUEST';
export const UPDATE_COST_CENTER_SUCCESS = 'firm/cost_center/UPDATE_COST_CENTER_SUCCESS';
export const UPDATE_COST_CENTER_FAILURE = 'firm/cost_center/UPDATE_COST_CENTER_FAILURE';

export const DELETE_COST_CENTER_REQUEST = 'firm/cost_center/DELETE_COST_CENTER_REQUEST';
export const DELETE_COST_CENTER_SUCCESS = 'firm/cost_center/DELETE_COST_CENTER_SUCCESS';
export const DELETE_COST_CENTER_FAILURE = 'firm/cost_center/DELETE_COST_CENTER_FAILURE';
