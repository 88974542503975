import PropTypes from 'prop-types';
import React from 'react';
import { Card } from '../../../../component1/Card';
import Fieldset from '../../../../component1/Form/Fieldset';
import { widgetMinHeight } from '../../../global/constants';
import FlatButton from '../../../../component1/FlatButton/FlatButton';
import Icon from '../../../../component1/Icon/Icon';
import TextField from '../../../../component1/TextField/TextField';
import { normalizePhone } from '../../../../service/number';

class SmsVerificationConfirmWidget extends React.Component {
  state = {
    pin: '',
  };

  nornalize() {
    return this.state.pin;
  }

  validate() {
    const pin = this.nornalize();

    if (pin.length === 4) {
      return true;
    }

    return null;
  }

  render() {
    const { t, phone } = this.props;
    const hasErrors = this.validate();

    return (
      <Card
        layout="fluid"
        title={t('SMS notifikace')}
        actions={[
          <FlatButton
            key={1}
            label={t('Potvrdit')}
            onClick={() => this.props.onConfirm(this.state.pin)}
            disabled={!hasErrors}
          />,
          <FlatButton
            key={2}
            label={t('Jiné číslo')}
            onClick={this.props.onReset}
          />,
        ]}
        minHeight={widgetMinHeight}
      >
        <Fieldset>
          <div style={{ textAlign: 'center' }}><Icon icon="textsms" size="xxlarge" /></div>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t('Pro ověření jsme vám na číslo {{phone}} poslali zprávu. Zadejte kód ze zprávy do tohoto pole a potvrďte tak, že zadané číslo je správné.', { phone: normalizePhone(phone) }) }}
          />
          <TextField
            type="text"
            label={t('Zadejte kód')}
            value={this.state.pin}
            onChange={(e, pin) => this.setState({ pin })}
            fieldId="tf-pin-field"
            bigText
          />
        </Fieldset>
      </Card>
    );
  }
}

SmsVerificationConfirmWidget.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  // isLoading: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default SmsVerificationConfirmWidget;
