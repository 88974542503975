import { INSTANCE } from '../../config/envspecific';

export const BRAND_O2 = 'O2';
export const widgetMinHeight = 359;

const INSTANCES = {
  sk: {
    currency: 'EUR',
    mapCenter: { lat: 48.149099, lng: 17.107760 },
    placesMapCenter: { lat: 48.808517, lng: 19.373595 },
    geosuggestBounds: {
      south: 47.610193, west: 16.713680, north: 49.626486, east: 22.932663,
    },
    faceValue: {
      min: 3.6,
      max: 15,
      def: 4,
      taxValue: false,
    },
    languagesSelection: ['sk', 'en'],
  },
  cz: {
    currency: 'CZK',
    mapCenter: { lat: 50.05163, lng: 14.442787 },
    placesMapCenter: { lat: 50.05163, lng: 14.442787 },
    geosuggestBounds: undefined,
    faceValue: {
      min: 1,
      max: 500,
      def: 100,
      taxValue: 131,
    },
    languagesSelection: ['cs', 'en', 'sk'],
  },
};

export const CURRENCY = INSTANCES[INSTANCE].currency;
export const DEFAULT_MAP_CENTER = INSTANCES[INSTANCE].mapCenter;
export const DEFAULT_MAP_CENTER_PLACES = INSTANCES[INSTANCE].placesMapCenter;
export const geosuggestBounds = INSTANCES[INSTANCE].geosuggestBounds;
export const faceValueSettings = INSTANCES[INSTANCE].faceValue;
export const languagesSelection = INSTANCES[INSTANCE].languagesSelection;

export const CARD_KIND_2 = 2;
export const CARD_KIND_3 = 3;
export const CARD_KINDS_ADD = [CARD_KIND_2, CARD_KIND_3];
