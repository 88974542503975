import PropTypes from 'prop-types';
import { allProductCodes } from '../../data/constants';

export const productCodeXPropType = (props, propName, componentName) => {
  if (!/\d{4}\/\d{2}\/\d{2}/.test(props[propName])) {
    return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`);
  }
  return undefined;
};

export const PropTypeProductCode = PropTypes.oneOfType([PropTypes.oneOf(allProductCodes), productCodeXPropType]);

export const PropTypeProductProperties = {
  s20_product_id: PropTypes.number.isRequired,
  s20_code: PropTypeProductCode,
  s20_name: PropTypes.string.isRequired,
  s20_desc: PropTypes.string,
  disabled: PropTypes.bool,
};

export const PropTypeProduct = PropTypes.shape(PropTypeProductProperties);

export const PropTypeProductMap = PropTypes.shape({
  GK: PropTypeProduct,
  GF: PropTypeProduct,
  RP: PropTypeProduct,
  GD: PropTypeProduct,
});

export const PropTypesProductsList = PropTypes.arrayOf(PropTypeProduct);

export const PropTypeProducts = PropTypes.shape({
  data: PropTypesProductsList,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
});

export const PropTypesCredits = PropTypes.arrayOf(
  PropTypes.shape({
    data: PropTypes.shape({
      o93_credit_temp_id: PropTypes.number,
      s20_code: PropTypeProductCode.isRequired,
      c30_personal_nb: PropTypes.string.isRequired,
      u01_name: PropTypes.string.isRequired,
      u01_surname: PropTypes.string.isRequired,
      u01_mail_work: PropTypes.string,
      c85_name: PropTypes.string,
      c87_name: PropTypes.string,
      address: PropTypes.string,
      o93_count: PropTypes.number.isRequired,
      o93_value: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired
);

export const PropTypeCardObsolete = PropTypes.shape({
  p20_card_id: PropTypes.number,
  fullname: PropTypes.string.isRequired,
  u01_nickname: PropTypes.string,
  p21_card_status_id: PropTypes.number,
  p21_card_status: PropTypes.string,
  p20_date_expire: PropTypes.string,
  card_balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const PropTypesCardProduct = PropTypes.shape({
  s20_product_id: PropTypes.number,
  s20_code: PropTypeProductCode,
  s20_name: PropTypes.string,
  balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  balance_pending: PropTypes.number,
});

export const PropTypesCardLimit = PropTypes.shape({
  s20_product_id: PropTypes.number,
  s20_code: PropTypeProductCode,
  limit: PropTypes.number,
  limit_till_date: PropTypes.string,
  limit_set: PropTypes.number,
  limit_pending: PropTypes.number,
  limit_temp_orig: PropTypes.number,
  max_standard_limit: PropTypes.number,
  max_temporary_limit: PropTypes.number,
  max_temporary_time: PropTypes.number,
});

export const PropTypesAutoCredit = PropTypes.shape({
  settings: PropTypes.arrayOf(PropTypes.shape({
    p60_recurrence_id: PropTypes.number,
    s20_product_id: PropTypes.number.isRequired,
    s20_code: PropTypes.string.isRequired,
    s20_name: PropTypes.string.isRequired,
    p65_min_balance: PropTypes.number,
    p65_credit_amount: PropTypes.number,
  })),
});

export const PropTypeCard = PropTypes.shape({
  c30_company_user_id: PropTypes.number.isRequired,
  p20_card_id: PropTypes.number.isRequired,
  fullname: PropTypes.string.isRequired,
  u01_nickname: PropTypes.string,
  p21_card_status: PropTypes.string,
  p21_card_status_pending: PropTypes.string,
  products: PropTypes.arrayOf(PropTypesCardProduct).isRequired,
  auto_credit: PropTypesAutoCredit,
  gopay_conditions: PropTypes.string,
});

export const PropTypeAssignedCard = PropTypes.shape({
  fullname: PropTypes.string.isRequired,
  p20_card_id: PropTypes.number.isRequired,
  p20_pan: PropTypes.string.isRequired,
  p60_card_expiration: PropTypes.string.isRequired,
  p60_card_number: PropTypes.string.isRequired,
  p60_recurrence_id: PropTypes.number.isRequired,
  p61_recurr_state_id: PropTypes.number.isRequired,
  p65_credit_amount: PropTypes.number.isRequired,
  p65_min_balance: PropTypes.number.isRequired,
  s20_code: PropTypes.string.isRequired,
  s20_name: PropTypes.string.isRequired,
  s20_product_id: PropTypes.number.isRequired,
  u01_nickname: PropTypes.string,
}).isRequired;

export const PropTypeAffiliateProductTypes = PropTypes.arrayOf(PropTypes.shape({
  is_enabled: PropTypes.oneOf([0, 1]).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    val: PropTypes.string.isRequired,
  })),
  s20_code: PropTypes.string.isRequired,
  s20_desc: PropTypes.string.isRequired,
  s20_name: PropTypes.string.isRequired,
  s20_product_id: PropTypes.number.isRequired,
}));


export const PropTypePairsList = PropTypes.arrayOf(PropTypes.oneOfType([
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })),
  }),
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
]));

export const PropTypeChildren = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
]);


export const PropTypeRouter = PropTypes.shape({
  push: PropTypes.func.isRequired,
}).isRequired;

export const PropTypeRouterLocation = PropTypes.shape({
  pathname: PropTypes.string.isRequired, // The pathname portion of the URL, without query string
  search: PropTypes.string.isRequired, // The query string portion of the URL, including the ?
  state: PropTypes.shape({}), // An object of data tied to this location
  action: PropTypes.string.isRequired, // One of PUSH, REPLACE, or POP
  key: PropTypes.string.isRequired, // A unique identifier for this location
}).isRequired;

export const PropTypeAddress = PropTypes.shape({
  orig_address: PropTypes.any,
  c20_label: PropTypes.string,
  g01_google_place_id: PropTypes.string,
  full_address: PropTypes.string,
  g50_street_name: PropTypes.string,
  full_number: PropTypes.string,
  g60_city_name: PropTypes.string,
  g30_postal_code: PropTypes.string,
});

export const PropTypeAddressInput = PropTypes.shape({
  g01_google_place_id: PropTypes.string,
  full_address: PropTypes.string,
  orig_address: PropTypes.shape({
    g50_street_name: PropTypes.string,
    full_number: PropTypes.string,
    g60_city_name: PropTypes.string,
    g30_postal_code: PropTypes.string,
  }),
  g01_gps_lat: PropTypes.number,
  g01_gps_lng: PropTypes.number,
  c20_gps_lat: PropTypes.number,
  c20_gps_lng: PropTypes.number,
});

export const PropTypeCoords = PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number });

export const PropTypePayGateTransaction = PropTypes.shape({
  c25_contract_address_id: PropTypes.number.isRequired,
  full_address: PropTypes.string.isRequired,
  n55_date_created: PropTypes.string.isRequired,
  n55_description: PropTypes.string,
  n55_ext_id: PropTypes.string,
  n55_gate_transaction_id: PropTypes.number.isRequired,
  n55_value: PropTypes.number.isRequired,
  n58_gate_trans_status_id: PropTypes.number.isRequired,
  n58_open_state: PropTypes.number.isRequired,
  n58_success_state: PropTypes.number.isRequired,
  n58_gate_trans_status: PropTypes.string,
  ac_error_message: PropTypes.string,
  p20_pan: PropTypes.string.isRequired,
});

export const PropTypePayGateTransactions = PropTypes.arrayOf(PropTypePayGateTransaction.isRequired);

export const PropTypeWidgetContribution = PropTypes.shape({
  m01_contribution_id: PropTypes.number,
  c30_company_user_id: PropTypes.number,
  m10_title: PropTypes.string,
  terms: PropTypes.string,
  m30_terms_confirmed: PropTypes.number,
  m30_rest_amount: PropTypes.number,
  amount_to_pay: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  participations: PropTypes.arrayOf(
    PropTypes.shape({
      m16_particip_role: PropTypes.string,
      m17_ratio: PropTypes.number,
      m17_label: PropTypes.string,
    })
  ),
  m20_ordered_amount: PropTypes.number,
  i04_payment_type_id: PropTypes.number,
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      i04_payment_type_id: PropTypes.number,
      fee: PropTypes.string,
      total: PropTypes.string,
    })
  ),
  invoice: PropTypes.shape({
    i01_invoice_id: PropTypes.number,
    i01_proforma_number: PropTypes.number,
    account: PropTypes.string,
  }),
  payment_required: PropTypes.oneOf([0, 1]).isRequired,
});

export const PropTypesUser = PropTypes.shape({
  c30_company_user_id: PropTypes.number,
  fullname: PropTypes.string,
  u01_name: PropTypes.string,
  u01_surname: PropTypes.string,
  c30_personal_nb: PropTypes.string,
  u01_login: PropTypes.string,
  can_edit_login: PropTypes.bool,
  u01_mail_work: PropTypes.string,
  u01_phone: PropTypes.string,
  c85_branch_id: PropTypes.number,
  c85_name: PropTypes.string,
  c87_cost_center_id: PropTypes.number,
  c87_name: PropTypes.string,
  comp_google_place_id: PropTypes.string,
  comp_address: PropTypes.string,
  home_google_place_id: PropTypes.string,
  home_address: PropTypes.string,
  home_is_primary: PropTypes.bool,
  u01_sex: PropTypes.number,
  u01_salutation: PropTypes.number,
  u01_birthdate: PropTypes.string,
  c30_date_employee_from: PropTypes.string,
  c30_date_employee_to: PropTypes.string,
  c32_position_id: PropTypes.number,
  c33_department_id: PropTypes.number,
  can_assign_card: PropTypes.bool,
  c30_active: PropTypes.number.isRequired,
  c30_export: PropTypes.number.isRequired,
});

export const PropTypesUserForm = PropTypes.shape({
  c30_company_user_id: PropTypes.number,
  u01_name: PropTypes.string,
  u01_surname: PropTypes.string,
  c30_personal_nb: PropTypes.string,
  u01_mail_work: PropTypes.string,
  home_address: PropTypes.string,
  u01_phone: PropTypes.string,
  c85_branch_id: PropTypes.number,
  c87_cost_center_id: PropTypes.number,
  comp_contract_address_id: PropTypes.number,
  home_google_place_id: PropTypes.string,
  home_orig_address: PropTypes.shape({
    g50_street_name: PropTypes.string,
    full_number: PropTypes.string,
    g60_city_name: PropTypes.string,
    g30_postal_code: PropTypes.string,
  }),
  u01_sex: PropTypes.number,
  u01_salutation: PropTypes.number,
  u01_birthdate: PropTypes.object,
  c30_date_employee_from: PropTypes.object,
  c30_date_employee_to: PropTypes.object,
  c32_position_id: PropTypes.number,
  c33_department_id: PropTypes.number,
  c30_active: PropTypes.number.isRequired,
  c30_export: PropTypes.number.isRequired,
  c30_contribution_explicit: PropTypes.number,
  limits: PropTypes.arrayOf(PropTypes.shape({
    s20_product_id: PropTypes.number.isRequired,
    s20_code: PropTypes.string.isRequired,
    s20_name: PropTypes.string.isRequired,
    max_limit: PropTypes.number,
    min_limit: PropTypes.number,
    limit: PropTypes.number,
    next_year_limit: PropTypes.number,
  })),
});

export const PropTypesUserEdit = PropTypes.shape({
  c30_company_user_id: PropTypes.number,
  u01_name: PropTypes.string,
  u01_surname: PropTypes.string,
  c30_personal_nb: PropTypes.string,
  u01_mail_work: PropTypes.string,
  home_address: PropTypes.string,
  u01_phone: PropTypes.string,
  c85_branch_id: PropTypes.number,
  c87_cost_center_id: PropTypes.number,
  comp_contract_address_id: PropTypes.number,
  home_google_place_id: PropTypes.string,
  home_orig_address: PropTypes.shape({
    g50_street_name: PropTypes.string,
    full_number: PropTypes.string,
    g60_city_name: PropTypes.string,
    g30_postal_code: PropTypes.string,
  }),
  c30_active: PropTypes.number.isRequired,
  c30_export: PropTypes.number.isRequired,
  c30_contribution_explicit: PropTypes.number,
  limits: PropTypes.arrayOf(PropTypes.shape({
    s20_product_id: PropTypes.number.isRequired,
    s20_code: PropTypes.string.isRequired,
    s20_name: PropTypes.string.isRequired,
    max_limit: PropTypes.number,
    min_limit: PropTypes.number,
    limit: PropTypes.number,
  })),
  fullname: PropTypes.string.isRequired,
  u01_phone_validated: PropTypes.bool.isRequired,
});

export const PropTypesFormElement = PropTypes.shape({
  errorText: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.func.isRequired),
});

export const PropTypeAffiliate = PropTypes.shape({
  c20_label: PropTypes.string,
  c23_branch_type: PropTypes.string,
  g80_region_name: PropTypes.string,
  full_address: PropTypes.string,
  c20_phone: PropTypes.string,
  c20_email: PropTypes.string,
  w01_url: PropTypes.string,
  c20_gps_lat: PropTypes.number,
  c20_gps_lng: PropTypes.number,
  c25_comment: PropTypes.string,
  pos_kinds: PropTypes.number,
  types: PropTypes.arrayOf(PropTypes.string.isRequired),
});

export const PropTypeErrorText = PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]);
