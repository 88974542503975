export const FETCH_BANK_PAIRS_REQUEST = 'global/FETCH_BANK_PAIRS_REQUEST';
export const FETCH_BANK_PAIRS_SUCCESS = 'global/FETCH_BANK_PAIRS_SUCCESS';
export const FETCH_BANK_PAIRS_FAILURE = 'global/FETCH_BANK_PAIRS_FAILURE';

export const FETCH_CASH_SYSTEM_PAIRS_REQUEST = 'global/FETCH_CASH_SYSTEM_PAIRS_REQUEST';
export const FETCH_CASH_SYSTEM_PAIRS_SUCCESS = 'global/FETCH_CASH_SYSTEM_PAIRS_SUCCESS';
export const FETCH_CASH_SYSTEM_PAIRS_FAILURE = 'global/FETCH_CASH_SYSTEM_PAIRS_FAILURE';

export const FETCH_DELIVERY_STATUS_PAIRS_REQUEST = 'global/FETCH_DELIVERY_STATUS_PAIRS_REQUEST';
export const FETCH_DELIVERY_STATUS_PAIRS_SUCCESS = 'global/FETCH_DELIVERY_STATUS_PAIRS_SUCCESS';
export const FETCH_DELIVERY_STATUS_PAIRS_FAILURE = 'global/FETCH_DELIVERY_STATUS_PAIRS_FAILURE';

export const FETCH_TRANSACTION_TYPE_PAIRS_REQUEST = 'global/FETCH_TRANSACTION_TYPE_PAIRS_REQUEST';
export const FETCH_TRANSACTION_TYPE_PAIRS_SUCCESS = 'global/FETCH_TRANSACTION_TYPE_PAIRS_SUCCESS';
export const FETCH_TRANSACTION_TYPE_PAIRS_FAILURE = 'global/FETCH_TRANSACTION_TYPE_PAIRS_FAILURE';

export const FETCH_AFFILIATE_TYPE_PAIRS_REQUEST = 'global/FETCH_AFFILIATE_TYPE_PAIRS_REQUEST';
export const FETCH_AFFILIATE_TYPE_PAIRS_SUCCESS = 'global/FETCH_AFFILIATE_TYPE_PAIRS_SUCCESS';
export const FETCH_AFFILIATE_TYPE_PAIRS_FAILURE = 'global/FETCH_AFFILIATE_TYPE_PAIRS_FAILURE';

export const FETCH_NAME_ON_CARD_PAIRS_REQUEST = 'global/FETCH_NAME_ON_CARD_PAIRS_REQUEST';
export const FETCH_NAME_ON_CARD_PAIRS_SUCCESS = 'global/FETCH_NAME_ON_CARD_PAIRS_SUCCESS';
export const FETCH_NAME_ON_CARD_PAIRS_FAILURE = 'global/FETCH_NAME_ON_CARD_PAIRS_FAILURE';

export const FETCH_DELIVERY_CARD_ORDER_PAIRS_REQUEST = 'global/FETCH_DELIVERY_CARD_ORDER_PAIRS_REQUEST';
export const FETCH_DELIVERY_CARD_ORDER_PAIRS_SUCCESS = 'global/FETCH_DELIVERY_CARD_ORDER_PAIRS_SUCCESS';
export const FETCH_DELIVERY_CARD_ORDER_PAIRS_FAILURE = 'global/FETCH_DELIVERY_CARD_ORDER_PAIRS_FAILURE';

export const FETCH_PRODUCT_PAIRS_REQUEST = 'global/FETCH_PRODUCT_PAIRS_REQUEST';
export const FETCH_PRODUCT_PAIRS_SUCCESS = 'global/FETCH_PRODUCT_PAIRS_SUCCESS';
export const FETCH_PRODUCT_PAIRS_FAILURE = 'global/FETCH_PRODUCT_PAIRS_FAILURE';

export const FETCH_PRODUCT_DESC_PAIRS_REQUEST = 'global/FETCH_PRODUCT_DESC_PAIRS_REQUEST';
export const FETCH_PRODUCT_DESC_PAIRS_SUCCESS = 'global/FETCH_PRODUCT_DESC_PAIRS_SUCCESS';
export const FETCH_PRODUCT_DESC_PAIRS_FAILURE = 'global/FETCH_PRODUCT_DESC_PAIRS_FAILURE';

export const FETCH_CARD_KINDS_PAIRS_REQUEST = 'global/FETCH_CARD_KINDS_PAIRS_REQUEST';
export const FETCH_CARD_KINDS_PAIRS_SUCCESS = 'global/FETCH_CARD_KINDS_PAIRS_SUCCESS';
export const FETCH_CARD_KINDS_PAIRS_FAILURE = 'global/FETCH_CARD_KINDS_PAIRS_FAILURE';

export const FETCH_CARD_TYPE_PAIRS_REQUEST = 'global/FETCH_CARD_TYPE_PAIRS_REQUEST';
export const FETCH_CARD_TYPE_PAIRS_SUCCESS = 'global/FETCH_CARD_TYPE_PAIRS_SUCCESS';
export const FETCH_CARD_TYPE_PAIRS_FAILURE = 'global/FETCH_CARD_KINDS_PAIRS_FAILURE';

export const FETCH_CARD_STATUS_PAIRS_REQUEST = 'global/FETCH_CARD_STATUS_PAIRS_REQUEST';
export const FETCH_CARD_STATUS_PAIRS_SUCCESS = 'global/FETCH_CARD_STATUS_PAIRS_SUCCESS';
export const FETCH_CARD_STATUS_PAIRS_FAILURE = 'global/FETCH_CARD_STATUS_PAIRS_FAILURE';

export const SEND_LOG_MESSAGE_REQUEST = 'global/SEND_LOG_MESSAGE_REQUEST';
export const SEND_LOG_MESSAGE_SUCCESS = 'global/SEND_LOG_MESSAGE_SUCCESS';
export const SEND_LOG_MESSAGE_FAILURE = 'global/SEND_LOG_MESSAGE_FAILURE';

export const FETCH_GATE_TRANS_STATUS_PAIRS_REQUEST = 'global/FETCH_GATE_TRANS_STATUS_PAIRS_REQUEST';
export const FETCH_GATE_TRANS_STATUS_PAIRS_SUCCESS = 'global/FETCH_GATE_TRANS_STATUS_PAIRS_SUCCESS';
export const FETCH_GATE_TRANS_STATUS_PAIRS_FAILURE = 'global/FETCH_GATE_TRANS_STATUS_PAIRS_FAILURE';

export const FETCH_PAYMENT_TYPE_PAIRS_REQUEST = 'global/FETCH_PAYMENT_TYPE_PAIRS_REQUEST';
export const FETCH_PAYMENT_TYPE_PAIRS_SUCCESS = 'global/FETCH_PAYMENT_TYPE_PAIRS_SUCCESS';
export const FETCH_PAYMENT_TYPE_PAIRS_FAILURE = 'global/FETCH_PAYMENT_TYPE_PAIRS_FAILURE';

export const FETCH_ATTENDANCE_TYPE_PAIRS_REQUEST = 'global/FETCH_ATTENDANCE_TYPE_PAIRS_REQUEST';
export const FETCH_ATTENDANCE_TYPE_PAIRS_SUCCESS = 'global/FETCH_ATTENDANCE_TYPE_PAIRS_SUCCESS';
export const FETCH_ATTENDANCE_TYPE_PAIRS_FAILURE = 'global/FETCH_ATTENDANCE_TYPE_PAIRS_FAILURE';

export const FETCH_GLOBAL_PAIRS_REQUEST = 'global/FETCH_GLOBAL_PAIRS_REQUEST';
export const FETCH_GLOBAL_PAIRS_SUCCESS = 'global/FETCH_GLOBAL_PAIRS_SUCCESS';
export const FETCH_GLOBAL_PAIRS_FAILURE = 'global/FETCH_GLOBAL_PAIRS_FAILURE';

export const SET_BRAND = 'global/SET_BRAND';
export const SET_OFFLINE = 'global/SET_OFFLINE';
export const SET_APP_ACTIVE = 'global/SET_APP_ACTIVE';

export const DOWNLOAD_EXPORT_LIST_REQUEST = 'global/DOWNLOAD_EXPORT_LIST_REQUEST';
export const DOWNLOAD_EXPORT_LIST_SUCCESS = 'global/DOWNLOAD_EXPORT_LIST_SUCCESS';
export const DOWNLOAD_EXPORT_LIST_FAILURE = 'global/DOWNLOAD_EXPORT_LIST_FAILURE';

export const UPLOAD_IMPORT_LIST_REQUEST = 'global/UPLOAD_IMPORT_LIST_REQUEST';
export const UPLOAD_IMPORT_LIST_SUCCESS = 'global/UPLOAD_IMPORT_LIST_SUCCESS';
export const UPLOAD_IMPORT_LIST_FAILURE = 'global/UPLOAD_IMPORT_LIST_FAILURE';
