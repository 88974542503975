import { lazy } from 'react';

const MyCardListContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/cardList/CardListContainer'));
const MyCardDetailContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/cardList/CardDetailContainer'));
const MyCardUpdateContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/cardList/CardUpdateContainer'));
const MyOrderListContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/orderList/OrderListContainer'));
const MyPlaceDetailContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/places/PlaceDetailContainer'));
const MyTransactionListContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/transactionList/TransactionListContainer'));
const MyBenefiaOrdersListContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/benefiaOrderList/BenefiaOrderListContainer'));
const MyPlacesPageContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/places/PlacesPageContainer'));
const CardsOrderCardsContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/cardsOrder/CardsOrderCardsContainer'));
const CardsOrderCreditsContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/cardsOrder/CardsOrderCreditsContainer'));
const CardsOrderOverviewContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/cardsOrder/CardsOrderOverviewContainer'));
const CardsOrderCompletionContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/cardsOrder/CardsOrderCompletionContainer'));
const CardsOrderReceivedContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/cardsOrder/CardsOrderReceivedContainer'));
const CardsOrderBankPaymentContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/cardsOrder/CardsOrderBankPaymentContainer'));
const PlaceNewContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/places/PlaceNewContainer'));
const CreditCardOverviewContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/creditCard/CreditCardOverviewContainer'));
const MySettlementPageContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/settlement/SettlementPageContainer'));
const AttendanceTodayContainer = lazy(() => import(/* webpackChunkName: "attendance" */ './pages/attendance/AttendanceTodayContainer'));
const AttendanceEmployeeContainer = lazy(() => import(/* webpackChunkName: "attendance" */ './pages/attendance/AttendanceEmployeeContainer'));
const AttendanceRecordsContainer = lazy(() => import(/* webpackChunkName: "attendance" */ '../firm/pages/attendance/AttendanceRecordsContainer'));
const MyBenefitsListContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/benefits/BenefitsListContainer'));
const MyBenefitDetailContainer = lazy(() => import(/* webpackChunkName: "my1" */ './pages/benefits/BenefitDetailContainer'));

export default {
  path: 'moje',
  childRoutes: [
    {
      name: 'my_cards',
      path: 'karty',
      key: 'U1',
      indexRoute: { component: MyCardListContainer },
      childRoutes: [
        {
          name: 'my_card',
          path: 'karta/:userId',
          key: 'U7',
          component: MyCardDetailContainer,
        },
        {
          name: 'edit_my_card',
          path: 'uprava-karty/:userId',
          key: 'U8',
          component: MyCardUpdateContainer,
        },
      ],
    },
    {
      name: 'bank_card_overview',
      path: 'propojena-karta',
      key: 'U17',
      component: CreditCardOverviewContainer,
    },
    {
      path: 'mista',
      childRoutes: [
        {
          name: 'my_places',
          path: 'moje-mista',
          key: 'U10',
          component: MyPlacesPageContainer,
        },
        {
          name: 'my_places_map',
          path: 'mapa-mist',
          key: 'U9',
          component: MyPlacesPageContainer,
        },
        {
          name: 'my_place',
          path: 'misto/:placeId',
          key: 'U6',
          component: MyPlaceDetailContainer,
        },
        {
          name: 'my_places_new',
          path: 'nove-misto',
          key: null,
          component: PlaceNewContainer,
        },
      ],
    },
    {
      name: 'my_transactions',
      path: 'transakce',
      key: 'U4',
      component: MyTransactionListContainer,
    },
    {
      name: 'my_benefia_orders',
      path: 'benefia-objednavky',
      key: 'BU01',
      component: MyBenefiaOrdersListContainer,
    },
    {
      name: 'my_orders',
      path: 'objednavky',
      key: 'U5',
      component: MyOrderListContainer,
    },
    {
      path: 'objednavka',
      childRoutes: [
        {
          name: 'my_order_cards',
          path: 'karty',
          key: 'U12',
          component: CardsOrderCardsContainer,
        },
        {
          name: 'my_order_credits',
          path: 'stravne',
          key: 'U13',
          component: CardsOrderCreditsContainer,
        },
        {
          name: 'my_order_overview',
          path: 'rekapitulace',
          key: 'U13',
          component: CardsOrderOverviewContainer,
        },
        {
          name: 'my_order_completion',
          path: 'dokoncena',
          key: 'U14',
          component: CardsOrderCompletionContainer,
        },
        {
          name: 'my_order_received',
          path: 'prijata',
          key: 'U14',
          component: CardsOrderReceivedContainer,
        },
        {
          name: 'my_order_payment',
          path: 'platba',
          key: 'U14',
          component: CardsOrderBankPaymentContainer,
        },
      ],
    },
    {
      name: 'my_settlement',
      path: 'vyporadani',
      key: null,
      isLanding: true,
      component: MySettlementPageContainer,
    },
    {
      name: 'my_attendance',
      path: 'dochazka',
      key: 'U18',
      indexRoute: { component: AttendanceTodayContainer },
      childRoutes: [
        {
          name: 'my_attendance_month',
          path: 'prehled',
          key: 'U18',
          indexRoute: { component: AttendanceEmployeeContainer },
          childRoutes: [
            {
              name: 'my_attendance_records',
              path: ':workShiftId',
              key: 'U18',
              indexRoute: { component: AttendanceRecordsContainer },
            },
            {
              name: 'my_attendance_records',
              path: 'novy/:date',
              key: 'U18',
              indexRoute: { component: AttendanceRecordsContainer },
            },
          ],
        },
      ],
    },
    {
      name: 'my_benefits',
      path: 'benefity',
      key: 'U20',
      indexRoute: { component: MyBenefitsListContainer },
      childRoutes: [{
        name: 'my_benefit_detail',
        path: ':benefitId',
        key: 'U20',
        component: MyBenefitDetailContainer,
      }],
    },
  ],
};
