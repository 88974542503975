import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import Icon from '../Icon/Icon';
import styles from './RaisedButton.scss';


const RaisedButton = (props) => {
  let buttonElement;
  let buttonClassName;
  let disabled = props.disabled;
  let iconElement;
  let labelElement;

  if (props.icon) {
    let size;

    if (props.size === 'small') {
      size = 'small';
    } else if (props.size === 'extralarge') {
      size = 'large';
    }

    iconElement = (
      <span className={`${styles.buttonIcon} ${props.label ? '' : styles.noLabeled}`}>
        <Icon icon={props.icon} size={size} />
      </span>
    );
  }

  labelElement = props.xsLabel ? (
    <>
      <span className={`${styles.buttonLabel} ${styles.xsLabel}`}>{props.xsLabel}</span>
      <span className={`${styles.buttonLabel} ${styles.mdLabel}`}>{props.label}</span>
    </>
  ) : <span className={styles.buttonLabel}>{props.label}</span>;

  if (props.isLoading) {
    disabled = true;
    labelElement = <span className={styles.buttonLabel}>Načítání…</span>;
  }

  if (props.size === 'large') {
    buttonClassName = styles.isButtonLarge;

    if (props.isBlock) {
      buttonClassName = styles.isButtonLargeBlock;
    }

    if (props.priority === 'primary') {
      buttonClassName = styles.isButtonPrimaryLarge;

      if (props.isBlock) {
        buttonClassName = styles.isButtonPrimaryLargeBlock;
      }
    } else if (props.priority === 'important') {
      buttonClassName = styles.isButtonImportantLarge;

      if (props.isBlock) {
        buttonClassName = styles.isButtonImportantLargeBlock;
      }
    } else if (props.priority === 'success') {
      buttonClassName = styles.isButtonSuccessLarge;

      if (props.isBlock) {
        buttonClassName = styles.isButtonSuccessLargeBlock;
      }
    }
  } else if (props.size === 'extralarge') {
    buttonClassName = styles.isButtonExtraLarge;

    if (props.isBlock) {
      buttonClassName = styles.isButtonExtraLargeBlock;
    }

    if (props.priority === 'primary') {
      buttonClassName = styles.isButtonPrimaryExtraLarge;

      if (props.isBlock) {
        buttonClassName = styles.isButtonPrimaryExtraLargeBlock;
      }
    } else if (props.priority === 'important') {
      buttonClassName = styles.isButtonImportantExtraLarge;

      if (props.isBlock) {
        buttonClassName = styles.isButtonImportantExtraLargeBlock;
      }
    } else if (props.priority === 'success') {
      buttonClassName = styles.isButtonSuccessExtraLarge;

      if (props.isBlock) {
        buttonClassName = styles.isButtonSuccessExtraLargeBlock;
      }
    }
  } else {
    buttonClassName = styles.button;

    if (props.isBlock) {
      buttonClassName = styles.isButtonBlock;
    }

    if (props.priority === 'primary') {
      buttonClassName = styles.isButtonPrimary;

      if (props.isBlock) {
        buttonClassName = styles.isButtonPrimaryBlock;
      }
    } else if (props.priority === 'important') {
      buttonClassName = styles.isButtonImportant;

      if (props.isBlock) {
        buttonClassName = styles.isButtonImportantBlock;
      }
    } else if (props.priority === 'success') {
      buttonClassName = styles.isButtonSuccess;

      if (props.isBlock) {
        buttonClassName = styles.isButtonSuccessBlock;
      }
    }
  }

  if (props.isMultiLine) {
    buttonClassName += ` ${styles.multiLine}`;
  }

  if (props.externalHref) {
    buttonElement = (
      <a href={props.externalHref} className={buttonClassName} style={props.style} target={props.targetBlank ? '_blank' : ''}>
        {(props.labelPosition && props.labelPosition === 'before') && labelElement}
        {iconElement}
        {(!props.labelPosition || props.labelPosition === 'after') && labelElement}
      </a>
    );
  } else {
    /* eslint-disable react/button-has-type */
    buttonElement = (
      <button
        type={props.type}
        disabled={disabled}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
          if (props.internalHref) {
            browserHistory.push(props.internalHref);
          }
        }}
        className={buttonClassName}
        style={props.style}
      >
        {(props.labelPosition && props.labelPosition === 'before') && labelElement}
        {iconElement}
        {(!props.labelPosition || props.labelPosition === 'after') && labelElement}
      </button>
    );
  }

  return buttonElement;
};

RaisedButton.defaultProps = {
  type: 'button',
  size: 'large',
};

RaisedButton.propTypes = {
  disabled: PropTypes.bool,
  externalHref: PropTypes.string,
  icon: PropTypes.string,
  internalHref: PropTypes.string,
  isBlock: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMultiLine: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  xsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelPosition: PropTypes.oneOf(['before', 'after']),
  onClick: PropTypes.func,
  priority: PropTypes.oneOf(['important', 'primary', 'success']),
  size: PropTypes.oneOf(['small', 'large', 'extralarge']),
  type: PropTypes.oneOf(['submit', 'button']),
  style: PropTypes.shape({}),
  targetBlank: PropTypes.bool,
};

export default RaisedButton;
