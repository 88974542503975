/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card } from '../../../../component1/Card';
import { widgetMinHeight } from '../../../global/constants';
import styles from './PromoFullWidget.scss';
import RaisedButton from '../../../../component1/RaisedButton/RaisedButton';

export const PromoFullWidget = ({ data, t, onClick }) => (
  <Card layout="vcentered" minHeight={widgetMinHeight} bodyClassName={styles.cardBody} title={data.title}>
    <div className={data.title ? styles.fullImg : styles.fullImgNoTitle} style={{ backgroundImage: `url(${data.background})` }} />
    {data.html_text && <p className={classnames(styles[data.textClass] || styles.text, data.whiteText && styles.whiteText)} dangerouslySetInnerHTML={{ __html: data.html_text }} />}
    {data.logo && <a href={data.href} className={styles.imgContainer} target="_blank" rel="noopener noreferrer"><img className={styles.img} src={data.logo} alt={data.title} /></a>}
    {!data.logo && (
    <RaisedButton
      style={{ position: 'absolute', bottom: 37 }}
      priority="primary"
      size="large"
      type="submit"
      label={data.button || t('Zjistit více')}
      externalHref={data.href}
      onClick={onClick}
      targetBlank
    />
    )}
    {data.comment && <p>{data.comment}</p>}
  </Card>
);

PromoFullWidget.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    html_text: PropTypes.string,
    logo: PropTypes.string,
    background: PropTypes.string,
    button: PropTypes.string,
    comment: PropTypes.string,
    href: PropTypes.string,
    whiteText: PropTypes.bool,
    textClass: PropTypes.oneOf(['text', 'smalltext']),
  }),
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired,
};
