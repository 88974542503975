import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import uniqueId from 'lodash.uniqueid';
import styles from './SelectField.scss';


class SelectField extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     value: props.value !== false ? props.value : '',
  //   };
  // }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const optionByValue = nextProps.options.find((item) => item.value === nextProps.value);
    const firstOption = nextProps.options[0];

    if (optionByValue === undefined && firstOption && !nextProps.value) {
      nextProps.onChange(firstOption.value);
    }

    // this.setState({ value: nextProps.value || '' });
  }

  handleChange(e) {
    e.preventDefault();
    const value = e.target.value;
    this.props.onChange(value);
    // this.setState({ value });
  }

  renderItem(i, option) {
    if (option && option.items !== undefined) {
      return (
        <optgroup key={i} label={option.label}>
          {option.items.map((subopt, i2) => this.renderItem(`${i}-${i2}`, subopt))}
        </optgroup>
      );
    }

    const content = option.marginLevel ? ''.padStart(option.marginLevel * 3, '\u00A0') + option.label : option.label;

    return (
      <option key={i} value={option.value} className={styles.option}>
        {content}
      </option>
    );
  }

  render() {
    const value = this.props.value !== undefined ? this.props.value : '';

    let label = null;
    if (this.props.label) {
      label = (
        <label
          htmlFor={this.props.fieldId}
          className={this.props.isStandalone
            ? styles.isLabelFloatingStandalone
            : styles.isLabelFloating}
        >
          {this.props.label}
        </label>
      );
    }

    return (
      <div className={classnames(
        this.props.hasNoErrors ? styles.hasRootNoErrors : styles.root,
        this.props.isThin && styles.rootIsThin,
        !this.props.label && styles.rootNoLabel,
        this.props.rightAligned && styles.rightAligned
      )}
      >
        {label}
        <select
          id={this.props.fieldId}
          value={value}
          onChange={(e) => this.handleChange(e)}
          disabled={this.props.disabled}
          className={classnames(
            this.props.isStandalone ? styles.isSelectStandalone : styles.select,
            this.props.isFramed && styles.framed
          )}
        >
          {this.props.options.map((option, i) => this.renderItem(i, option))}
        </select>
        {!this.props.hasNoErrors && <div className={styles.errorText}>{this.props.errorText}</div>}
      </div>
    );
  }
}

SelectField.defaultProps = {
  fieldId: uniqueId('field-'),
};

SelectField.propTypes = {
  fieldId: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    label: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  hasNoErrors: PropTypes.bool,
  isStandalone: PropTypes.bool,
  isThin: PropTypes.bool,
  isFramed: PropTypes.bool,
  rightAligned: PropTypes.bool,
};

export default SelectField;

/**
 * Creates groupped options for select from key/value/group pair
 * @item array of key/value/group object
 */
export function getGroupOptions(items) {
  const groups = [];
  items.forEach((itm) => {
    if (!groups.includes(itm.group)) {
      groups.push(itm.group);
    }
  });

  if (groups.length < 2) {
    return items;
  }

  return items.filter((itm) => itm.group === undefined)
    .concat(
      groups.filter((group) => group !== undefined)
        .map((group) => ({
          label: group,
          items: items.filter((itm) => itm.group === group),
        }))
    );
}

/**
 * Creates tree options for select from key/value/group pair
 * @item array of key/value/group object
 */
export function getTreeOptions(allItems, parent = null, level = 0) {
  if (level > 20) {
    return allItems.filter((itm) => itm.parent === parent);
  }
  return allItems.filter((itm) => itm.parent === parent)
    .reduce((acc, curr) => [...acc, {
      ...curr,
      marginLevel: level,
    }, ...getTreeOptions(allItems, curr.value, level + 1)], []);
}
