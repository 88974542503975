import Immutable from 'immutable';
import * as types from './actionTypes';
import { SAVE_USER_LANGUAGE_SUCCESS } from '../user/resources/actionTypes';


const authReducerFactory = (initialState) => (state, action) => {
  if (typeof state === 'undefined') {
    return initialState.get('auth');
  }

  if (action.type === types.LOGIN_REQUEST || action.type === types.LOGIN_AS_USER_REQUEST) {
    return state
      .set('isLoggingIn', true)
      .set('isLoggingInFailure', false);
  }

  if (action.type === types.SET_LOGIN_INFO) {
    const userData = action.payload;
    return state
      .set('user', Immutable.Map({
        email: userData.u01_mail_work,
        name: userData.fullname,
        u01_login: userData.u01_login,
        u01_name: userData.u01_name,
        u01_surname: userData.u01_surname,
        adminFullname: userData.admin_fullname,
        securityCode: userData.u01_security_code,
        x20_code: userData.x20_code,
        theme: Array.isArray(userData.theme) ? {} : userData.theme, // empty php object fix
        u01_user_id: action.payload.u01_user_id,
      }))
      .set('isLoggingIn', false)
      .set('isLoggingInFailure', false);
  }

  if (action.type === SAVE_USER_LANGUAGE_SUCCESS) {
    return state
      .setIn(['user', 'x20_code'], action.payload.x20_code);
  }

  if (action.type === types.LOGIN_SUCCESS || action.type === types.LOGIN_AS_USER_SUCCESS) {
    return state
      .set('isLoggingIn', false)
      .set('isSessionInFailure', false)
      .set('isLoggingInFailure', false);
  }

  if (action.type === types.LOGIN_FAILURE || action.type === types.LOGIN_AS_USER_FAILURE) {
    return state
      .set('isLoggingIn', false)
      .set('isSessionInFailure', false)
      .set('isLoggingInFailure', true);
  }

  if (action.type === types.FETCH_LOGIN_DATA_FAILURE) {
    return state
      .set('isLoggingIn', false)
      .set('isSessionInFailure', true)
      .set('isLoggingInFailure', false);
  }

  if (action.type === types.FETCH_LOGIN_DATA_SUCCESS) {
    return state
      .set('isLoggingIn', false)
      .set('isSessionInFailure', false)
      .set('isLoggingInFailure', false);
  }

  if (action.type === types.LOGOUT_REQUEST) {
    return initialState.get('auth');
  }

  if (action.type === types.SET_AUTHORIZED_PAGES) {
    return state
      .setIn(
        ['authorizedPages', 'pages'],
        Immutable.fromJS(action.payload || [])
      )
      .setIn(['authorizedPages', 'isFetching'], false);
  }

  if (action.type === types.SET_CONTRACT_RIGHTS) {
    return state
      .setIn(
        ['contractRights', 'rights'],
        Immutable.fromJS(action.payload)
      )
      .setIn(['contractRights', 'isFetching'], false);
  }

  if (action.type === types.REMIND_PASSWORD_REQUEST) {
    return state
      .setIn(['remindPassword', 'message'], '')
      .setIn(['remindPassword', 'isReminding'], true)
      .setIn(['remindPassword', 'isRemindingFailure'], false)
      .setIn(['remindPassword', 'isRemindingSuccess'], false);
  }

  if (action.type === types.REMIND_PASSWORD_SUCCESS) {
    return state
      .setIn(['remindPassword', 'message'], '')
      .setIn(['remindPassword', 'isReminding'], false)
      .setIn(['remindPassword', 'isRemindingFailure'], false)
      .setIn(['remindPassword', 'isRemindingSuccess'], true);
  }

  // password hash reminder was sent again
  if (action.type === types.HASH_CHECK_FAILURE && action.payload.response && action.payload.response.code === '0125') {
    return state
      .setIn(['remindPassword', 'message'], '')
      .setIn(['remindPassword', 'isReminding'], false)
      .setIn(['remindPassword', 'isRemindingFailure'], false)
      .setIn(['remindPassword', 'isRemindingSuccess'], true);
  }

  if (action.type === types.REMIND_PASSWORD_FAILURE) {
    return state
      .setIn(['remindPassword', 'message'], action.payload.response.message)
      .setIn(['remindPassword', 'isReminding'], false)
      .setIn(['remindPassword', 'isRemindingFailure'], true)
      .setIn(['remindPassword', 'isRemindingSuccess'], false);
  }

  if (action.type === types.CLEAR_REMIND_PASSWORD) {
    return state
      .setIn(['remindPassword', 'message'], '')
      .setIn(['remindPassword', 'isReminding'], false)
      .setIn(['remindPassword', 'isRemindingFailure'], false)
      .setIn(['remindPassword', 'isRemindingSuccess'], false);
  }

  if (action.type === types.RESET_PASSWORD_REQUEST) {
    return state
      .setIn(['resetPassword', 'message'], '')
      .setIn(['resetPassword', 'isResetting'], true)
      .setIn(['resetPassword', 'isResettingFailure'], false);
  }

  if (action.type === types.RESET_PASSWORD_SUCCESS) {
    return state
      .setIn(['resetPassword', 'message'], '')
      .setIn(['resetPassword', 'isResetting'], false)
      .setIn(['resetPassword', 'isResettingFailure'], false);
  }

  if (action.type === types.RESET_PASSWORD_FAILURE) {
    return state
      .setIn(['resetPassword', 'message'], action.payload.response.message)
      .setIn(['resetPassword', 'isResetting'], false)
      .setIn(['resetPassword', 'isResettingFailure'], true);
  }

  return state;
};


export default authReducerFactory;
