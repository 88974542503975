import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../component1/Card';
import FlatButton from '../../../../component1/FlatButton/FlatButton';
import Overlay from '../../../../component1/Progress/Overlay/Overlay';
import styles from './ContributionOrderWidget.scss';
import { PropTypeWidgetContribution, PropTypePairsList } from '../../../global/proptypes';
import { widgetMinHeight } from '../../../global/constants';
import { OrderPageContent } from './OrderPageContent';
import { PaymentPageContent } from './PaymentPageContent';
import { BankTransferPageContent } from './BankTransferPageContent';
import { OnlinePaymentPageContent } from './OnlinePaymentPageContent';
import { ConfirmPageContent } from './ConfirmPageContent';
import Dialog from '../../../../component1/Dialog/Dialog';


export class ContributionOrderWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmation: false,
      orderAmount: null,
      emissionTransfer: props.data.old_emission_amount !== null,
      showPaymentCancelConfirmation: false,
      showOrderCancelConfirmation: false,
    };

    props.fetchPaymentTypePairs();
  }

  handleCancelOrder = () => {
    this.setState({ showOrderCancelConfirmation: true });
  }

  handleCancelOrderConfirmed = () => {
    const { data } = this.props;
    this.props.onOrder(data.m01_contribution_id, data.c30_company_user_id, null);
    this.setState({ showOrderCancelConfirmation: false });
  }

  handleCancelOrderConfirmationClose = () => {
    this.setState({ showOrderCancelConfirmation: false });
  }

  handleOrderAmount = () => {
    const { data } = this.props;
    let paymentRequired = data.payment_required;

    if (this.state.emissionTransfer) {
      const emplPart = data.participations.find((p) => p.m16_particip_role === 'EMPL');
      const orderEmpl = Math.round(this.state.orderAmount * emplPart.m17_ratio);
      const oldEmission = Math.round(data.old_emission_amount * 100);
      if (!emplPart || orderEmpl < oldEmission) {
        // eslint-disable-next-line no-console
        console.error('order amount error', { emplPart, orderEmpl, oldEmission });
        return;
      }
      paymentRequired = data.payment_required && orderEmpl > oldEmission;
    }

    if (paymentRequired) {
      this.handleOrderConfirm();
    } else {
      this.setState({ showConfirmation: true });
    }
  }

  handleOrderConfirm = async () => {
    const { data } = this.props;
    const ret = await this.props.onOrder(data.m01_contribution_id, data.c30_company_user_id, this.state.orderAmount, this.state.emissionTransfer);

    if (!ret.error) {
      this.setState({ showConfirmation: false, orderAmount: null });
      if (!ret.payload.contribtion) {
        this.props.updateWidget();
      }
    }
  }

  handleCancelPayment = () => {
    this.setState({ showPaymentCancelConfirmation: true });
  }

  handleCancelPaymentConfirmed = () => {
    const { data } = this.props;
    this.props.onSetPayment(data.m01_contribution_id, data.c30_company_user_id, null);
    this.setState({ showPaymentCancelConfirmation: false });
  }

  handleCancelPaymentConfirmationClose = () => {
    this.setState({ showPaymentCancelConfirmation: false });
  }

  handleDownloadInvoice = () => {
    this.props.onInvoiceDownload(this.props.data.invoice.i01_invoice_id, this.props.data.invoice.i01_proforma_number);
  }

  handleSetPayment = async (paymentTypeId) => {
    const { data } = this.props;
    const ret = await this.props.onSetPayment(data.m01_contribution_id, data.c30_company_user_id, paymentTypeId);
    if (!ret.payload.contribution) {
      this.props.updateWidget();
    }

    return ret;
  }

  render() {
    const {
      t, data, isLoading, paymentTypePairs,
    } = this.props;

    const showInvoiceDownload = [1, 2, 5].includes(data.i04_payment_type_id);
    const imgSrc = '/images/logo-RP-dark.svg';

    let content = null;
    let actions = null;

    if (!data.m20_ordered_amount && !this.state.showConfirmation) {
      content = (
        <OrderPageContent
          t={t}
          data={data}
          orderAmount={this.state.orderAmount}
          setOrderAmount={(amount) => this.setState({ orderAmount: amount })}
          onOrderAmount={this.handleOrderAmount}
          emissionTransfer={this.state.emissionTransfer}
          setEmissionTransfer={(val) => this.setState({ emissionTransfer: val })}
        />
      );
    } else if (!data.m20_ordered_amount && this.state.showConfirmation) {
      content = (<ConfirmPageContent t={t} orderAmount={this.state.orderAmount} />);
      actions = [
        <FlatButton key={1} label={t('Zrušit')} onClick={() => this.setState({ showConfirmation: false })} />,
        <FlatButton key={2} label={t('Potvrdit')} onClick={this.handleOrderConfirm} primary />,
      ];
    } else if (!data.i04_payment_type_id || ([1, 2, 5].includes(data.i04_payment_type_id) && !data.invoice)) {
      content = (<PaymentPageContent t={t} onSetPayment={this.handleSetPayment} data={data} paymentTypePairs={paymentTypePairs} />);
      actions = [<FlatButton key={3} label={t('Zrušit')} onClick={this.handleCancelOrder} />];
    } else if ([1, 2, 5].includes(data.i04_payment_type_id) && !!data.invoice) {
      // bank transfer
      content = (<BankTransferPageContent t={t} data={data} />);
      actions = [
        <FlatButton key={4} label={t('Zpět')} onClick={this.handleCancelPayment} />,
        showInvoiceDownload && <FlatButton key={2} label={t('Stáhnout doklad')} onClick={this.handleDownloadInvoice} primary />,
      ];
    } else if ([3, 4].includes(data.i04_payment_type_id)) {
      // online payment
      content = (<OnlinePaymentPageContent t={t} data={data} />);
      actions = [<FlatButton key={4} label={t('Zpět')} onClick={this.handleCancelPayment} />];
    }

    if (!content) {
      throw new Error(`Invalid widget state: ${JSON.stringify(data)}`);
    }

    return (
      <>
        <Card
          layout="fluid"
          title={data.m10_title}
          actions={actions}
          minHeight={widgetMinHeight}
          bodyClassName={styles.cardBody}
        >
          <Overlay show={isLoading} absolute />
          <img className={styles.img} src={imgSrc} alt={data.m10_title} />
          {content}
        </Card>
        {this.state.showOrderCancelConfirmation && (
        <Dialog
          title={t('Zrušení objednávky')}
          actions={[
            {
              label: t('Zrušit objednávku'),
              primary: true,
              onClick: this.handleCancelOrderConfirmed,
            },
            {
              label: t('Nedělat nic'),
              onClick: this.handleCancelOrderConfirmationClose,
            },
          ]}
          onHandleClose={this.handleCancelOrderConfirmationClose}
          isNarrow
          isWarning
        >
          <p>{t('POZOR! Ak ste objednávku už uhradili online, nie je možné objednávku zrušiť. Počkajte, kým bude na Váš účet pripísaná objednaná čiastka, potom bude možné vytvoriť novú objednávku.')}</p>
          <p>{t('Ak ste zvolili platbu bankovým prevodom a želáte si objednávku zrušiť, čiastku NEPLAŤTE. Zrušením objednávky prestane byť variabilný symbol aktívny a my nebudeme schopní Vašu platbu správne priradiť.')}</p>
        </Dialog>
        )}
        {this.state.showPaymentCancelConfirmation && (
        <Dialog
          title={t('Zrušení platby')}
          actions={[
            {
              label: t('Vrátit se o krok zpět'),
              primary: true,
              onClick: this.handleCancelPaymentConfirmed,
            },
            {
              label: t('Nedělat nic'),
              onClick: this.handleCancelPaymentConfirmationClose,
            },
          ]}
          onHandleClose={this.handleCancelPaymentConfirmationClose}
          isNarrow
          isWarning
          centerContent
        >
          <span>{t('Chcete zmeniť spôsob platby alebo zrušiť objednávku? Vráťte sa o krok späť')}</span>
        </Dialog>
        )}
      </>
    );
  }
}

ContributionOrderWidget.propTypes = {
  t: PropTypes.func.isRequired,
  updateWidget: PropTypes.func.isRequired,
  onOrder: PropTypes.func.isRequired,
  // onOnlineConfirm: PropTypes.func.isRequired,
  onSetPayment: PropTypes.func.isRequired,
  onInvoiceDownload: PropTypes.func.isRequired,
  fetchPaymentTypePairs: PropTypes.func.isRequired,
  paymentTypePairs: PropTypePairsList,
  data: PropTypeWidgetContribution.isRequired,
  isLoading: PropTypes.bool,
};
