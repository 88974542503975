import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import styles from './Layout.scss';
import { MOBILE_APP } from '../../config/envspecific';


const Layout = (props) => (
  <div className={classnames(MOBILE_APP ? styles.rootApp : styles.root, props.envBanner && styles.withBanner)}>
    {props.children}
  </div>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  envBanner: PropTypes.bool,
};

export default Layout;
