import React from 'react';
import { ProductIcon } from '../../../../../component1/ProductButton/ProductButton';
import { TranslateFn } from '../../../../global/model';
import styles from './BenefiaOrderBannerDialog.scss';
import { Col, Row } from '../../../../../component1/Grid';
import Dialog from '../../../../../component1/Dialog/Dialog';
import RaisedButton from '../../../../../component1/RaisedButton/RaisedButton';


export const BenefiaOrderBannerDialog: React.FC<{ t: TranslateFn, onOrder(): void, onClose(): void }> = ({ t, onOrder, onClose }) => (
  <Dialog
    onHandleClose={onClose}
    title={t('Poukážka UpCadhoc')}
    hasCross
    forceOpen
  >
    <Row>
      <Col className={styles.col} sm={6}>
        <img src="/images/upcadhoc-image.jpg" alt={t('UpCadhoc image')} className={styles.logo} />
        <p className={styles.desc}>
          {t('Ak si darčekové poukážky UpCadhoc objednáte do 19. dňa v mesiaci, poukážky doručíme do sídla zamestnávateľa do konca daného mesiaca. Ak si ich objednáte po 19. v mesiaci, doručené budú až v nasledujúcom mesiaci. Poukážky Vám odovzdá Váš zamestnávateľ.')}
        </p>
      </Col>
      <Col className={styles.col} sm={6}>
        <h2>{t('Ako získate benefit?')}</h2>
        <h2 className={styles.secondary}>{t('Variabilná hodnota')}</h2>
        <p className={styles.product}>
          <ProductIcon code="BN" />
        &nbsp;
          {t('UpBenefia')}
        </p>
        <p className={styles.textRight}>{t('Po kliknutí na tlačidlo „Kúpiť benefit“ zadajte hodnotu a dokončite objednávku.')}</p>
        <div className={styles.bottomSpacer}>
          <RaisedButton
            priority="primary"
            size="large"
            type="submit"
            label={t('Kúpiť benefit')}
            onClick={onOrder}
          />
        </div>
      </Col>
    </Row>
  </Dialog>
);
