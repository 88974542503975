import React from 'react';
import styles from './ListEmptyMessage.scss';
import { PropTypeChildren } from '../../modules/global/proptypes';

export const ListEmptyMessage = ({ children }) => (
  <div className={styles.empty}>
    <div className={styles.emptyTopBottom} />
    <div className={styles.emptyLeftRight} />
    <div className={styles.emptyContent}>
      {children}
    </div>
  </div>
);


ListEmptyMessage.propTypes = {
  children: PropTypeChildren,
};
