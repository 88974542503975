import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import styles from './Card.scss';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';

const Card = (props) => {
  let rootClassName = styles.root;
  let bodyClassName = styles.body;
  let headerClassName = styles.header;

  if (props.layout === 'fluid' || props.layout === 'space-between' || props.layout === 'centered' || props.layout === 'vcentered' || props.layout === 'fill') {
    rootClassName = styles.isRootFluid;
  } else if (props.layout === 'form') {
    rootClassName = styles.isRootForm;
  } else if (props.layout === 'form-base') {
    rootClassName = styles.isRootFormBase;
  } else if (props.layout === 'form-wider') {
    rootClassName = styles.isRootFormWider;
  }

  if (props.className) {
    rootClassName = `${rootClassName} ${props.className}`;
  }

  if (props.layout === 'centered') {
    bodyClassName = styles.isBodyCentered;
  } else if (props.layout === 'vcentered') {
    bodyClassName = styles.isBodyVCentered;
  } else if (props.layout === 'fill') {
    bodyClassName = styles.isBodyFill;
  } else if (props.layout === 'space-between') {
    bodyClassName = styles.isBodySpaceBetween;
  }

  if (props.bodyClassName) {
    bodyClassName = `${bodyClassName} ${props.bodyClassName}`;
  }

  if (props.type === 'important') {
    headerClassName = styles.isHeaderImportant;
  } else if (props.type === 'success') {
    headerClassName = styles.isHeaderSuccess;
  }

  return (
    <div style={{ minHeight: props.minHeight }} className={rootClassName}>
      {props.title && (
      <div className={classnames(headerClassName, props.headerThinPadding && styles.headerThinPadding)}>
        <h2 className={styles.headerTitle}>{props.title}</h2>
        {props.onClose && (
        <IconButton onClick={props.onClose} className={styles.headerClose} tooltip={props.crossTitle} tooltipPosition="left">
          <Icon icon="close" size="small" />
        </IconButton>
        )}
      </div>
      )}
      <div className={bodyClassName}>
        {props.children}
      </div>
      {props.actions
        && (
        <div className={styles.footer}>
          {props.actions}
        </div>
        )}
    </div>
  );
};

Card.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  layout: PropTypes.oneOf(['centered', 'fill', 'fluid', 'form', 'space-between', 'form-base', 'form-wider', 'vcentered']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  crossTitle: PropTypes.string,
  type: PropTypes.oneOf(['important', 'success']),
  minHeight: PropTypes.number,
  onClose: PropTypes.func,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  headerThinPadding: PropTypes.bool,
};

export default Card;
