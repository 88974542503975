import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'orders']);
  }

  if (action.type === types.FETCH_ORDERS_REQUEST) {
    return state
      .setIn(['ordersList', 'isFetchingFailure'], false)
      .setIn(['ordersList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['ordersList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['ordersList', 'fetchCount'], Immutable.fromJS(action.payload.limit))
      .setIn(['ordersList', 'limit'], Immutable.fromJS(action.payload.limit))
      .setIn(['ordersList', 'lastOffset'], Immutable.fromJS(action.payload.lastOffset))
      .setIn(['ordersList', 'orderBy'], Immutable.fromJS(action.payload.orderBy))
      .setIn(['ordersList', 'filter'], Immutable.fromJS(action.payload.filter));
  }

  if (action.type === types.FETCH_ORDERS_SUCCESS) {
    const orders = action.payload.map((item) => ({
      data: {
        ...item,
        id: item.o01_order_id,
        invoices: item.invoices.map((inv) => ({ ...inv, i01_invoice_id: +inv.i01_invoice_id })),
      },
      actions: {
        downloadPdf: !!item.can_download,
        downloadCsv: !!item.can_download,
        detail: true,
      },
    }));

    const ordersList = state.get('ordersList');
    const fetchCount = ordersList.get('fetchCount');
    const lastOffset = ordersList.get('lastOffset');
    const stateOrders = ordersList.get('orders');
    const lastCount = orders.length;

    return state
      .setIn(['ordersList', 'orders'], getEntitiesFromResponse(orders, stateOrders, lastOffset, fetchCount))
      .setIn(['ordersList', 'lastCount'], lastCount)
      .setIn(['ordersList', 'isFetching'], false)
      .setIn(['ordersList', 'isLoadingMore'], false)
      .setIn(['ordersList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_ORDERS_FAILURE) {
    return state
      .setIn(['ordersList', 'isFetching'], false)
      .setIn(['ordersList', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_FIRM_ORDERS_FILTER_DATA) {
    return state
      .setIn(['ordersList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['ordersList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  if (action.type === types.FETCH_ORDER_DETAIL_REQUEST) {
    return state
      .setIn(['orderDetail', 'isFetching'], true)
      .setIn(['orderDetail', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_ORDER_DETAIL_SUCCESS) {
    return state
      .setIn(['orderDetail', 'isFetching'], false)
      .setIn(['orderDetail', 'isFetchingFailure'], false)
      .setIn(['orderDetail', 'order'], Immutable.fromJS(
        {
          ...action.payload,
          // api to store transformations here
        }
      ));
  }

  if (action.type === types.FETCH_ORDER_DETAIL_FAILURE) {
    return state
      .setIn(['orderDetail', 'isFetching'], false)
      .setIn(['orderDetail', 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
