import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { Card } from '../../../../component1/Card';
import RaisedButton from '../../../../component1/RaisedButton/RaisedButton';
import { widgetMinHeight } from '../../../global/constants';
import { tRoute } from '../../../../i18n';
import styles from './MyPlacesWidget.scss';

export const MyPlacesWidget = ({ t }) => (
  <Card layout="centered" title={t('Akceptační síť')} minHeight={widgetMinHeight}>
    <p className={styles.text}>{t('Seznam míst, kde můžete platit kartou\u00a0Up')}</p>
    <Link to={tRoute('/moje/mista/moje-mista', t)}>
      <img className={styles.logo} src="/images/map-marker-RP.svg?v2" alt="logo" />
    </Link>
    <RaisedButton
      priority="primary"
      size="large"
      type="submit"
      label={t('Zobrazit seznam')}
      internalHref={tRoute('/moje/mista/moje-mista', t)}
    />
  </Card>
);

MyPlacesWidget.propTypes = {
  t: PropTypes.func.isRequired,
};
