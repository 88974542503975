import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Col, Row } from '../Grid';
import OverlayProgress from '../Progress/Overlay/Overlay';
import styles from './CardGridView.scss';

function renderCol(widget, key, transitionEnabled) {
  return widget && (
    <CSSTransition
      classNames={{
        enter: styles.widgetEnter,
        enterActive: styles.widgetEnterActive,
        leave: styles.widgetLeave,
        leaveActive: styles.widgetLeaveActive,
      }}
      timeout={{ enter: 300, exit: 500 }}
      enter={transitionEnabled}
      exit={transitionEnabled}
    >
      <Col
        key={key}
        xs={12}
        sm={widget.props.isPromoted ? 12 : 6}
        md={widget.props.isPromoted ? 8 : 4}
        lg={widget.props.isPromoted ? 6 : 3}
      >
        {widget}
      </Col>
    </CSSTransition>
  );
}

const CardGridView = (props) => (
  <div>
    {props.isFetching ? <OverlayProgress /> : null}
    <TransitionGroup
      component={Row}
    >
      {React.Children.map(props.children,
        (widget, i) => (Array.isArray(widget) ? widget.map((w2, i2) => renderCol(w2, `${i}-${i2}`, props.transitionEnabled))
          : renderCol(widget, i, props.transitionEnabled)))}
    </TransitionGroup>
  </div>
);

CardGridView.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isFetching: PropTypes.bool,
  transitionEnabled: PropTypes.bool,
};

CardGridView.defaultProps = {
  transitionEnabled: false,
};


export default CardGridView;
