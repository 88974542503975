import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import styles from './CheckboxField.scss';
import { PropTypeChildren } from '../../modules/global/proptypes';


class CheckboxField extends React.Component {
  handleOnClick = () => {
    this.props.onClick(this.props.value);
  }

  render() {
    const {
      label,
      value,
      fieldId,
      checked,
      disabled,
      errorText,
      link,
      children,
    } = this.props;

    return (
      <>
        <div className={link || children ? styles.hasRootLink : styles.root}>
          <div className={(link || children) && styles.inputContainer}>
            <FormControlLabel
              control={(
                <Checkbox
                  id={fieldId}
                  value={value}
                  checked={checked}
                  disabled={disabled}
                  onChange={this.handleOnClick}
                  color="primary"
                />
              )}
              label={link ? (
                <>
                  {label}
                  {' '}
                  {link}
                </>
              ) : label}
            />
          </div>
          {children && (
          <div role="presentation" className={styles.children} onClick={this.handleOnClick}>
            {children}
          </div>
          )}
        </div>
        {errorText && <span className={styles.errorText}>{errorText}</span>}
      </>
    );
  }
}

CheckboxField.propTypes = {
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  link: PropTypes.element,
  children: PropTypeChildren,
};


export default CheckboxField;
