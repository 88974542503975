import PropTypes from 'prop-types';
import React from 'react';
import styles from './Fieldset.scss';


const Fieldset = (props) => (
  <fieldset className={props.noMargin ? styles.rootNoMargin : styles.root} style={props.style}>
    {props.legend && (
      <legend className={props.isSecondary ? styles.isLegendSecondary : styles.legend}>
        {props.legend}
      </legend>
    )}
    {props.children}
  </fieldset>
);

Fieldset.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  legend: PropTypes.string,
  isSecondary: PropTypes.bool,
  noMargin: PropTypes.bool,
  style: PropTypes.shape({}),
};

export default Fieldset;
