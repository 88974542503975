import PropTypes from 'prop-types';
import React from 'react';
import styles from './Grid.scss';


const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

class Row extends React.Component {
  getClassNames() {
    const rowClasses = [styles.row];

    if (this.props.className) {
      rowClasses.push(this.props.className);
    }

    if (this.props.isReversed) {
      rowClasses.push(styles.isRowReversed);
    }

    if (this.props.start) {
      rowClasses.push(styles[`start_${this.props.start}`]);
    }

    if (this.props.center) {
      rowClasses.push(styles[`center_${this.props.center}`]);
    }

    if (this.props.end) {
      rowClasses.push(styles[`end_${this.props.end}`]);
    }

    if (this.props.top) {
      rowClasses.push(styles[`top_${this.props.top}`]);
    }

    if (this.props.middle) {
      rowClasses.push(styles[`middle_${this.props.middle}`]);
    }

    if (this.props.bottom) {
      rowClasses.push(styles[`bottom_${this.props.bottom}`]);
    }

    if (this.props.around) {
      rowClasses.push(styles[`around_${this.props.around}`]);
    }

    if (this.props.between) {
      rowClasses.push(styles[`between_${this.props.between}`]);
    }

    return rowClasses.join(' ');
  }

  render() {
    const classNames = this.getClassNames();

    const style = this.props.style ? this.props.style : {};

    if (this.props.hide) {
      style.display = 'none';
    }

    return (
      <div className={classNames} style={style}>
        {this.props.children}
      </div>
    );
  }
}

Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  style: PropTypes.objectOf(Object),
  isReversed: PropTypes.bool,
  start: PropTypes.oneOf(breakpoints),
  center: PropTypes.oneOf(breakpoints),
  end: PropTypes.oneOf(breakpoints),
  top: PropTypes.oneOf(breakpoints),
  middle: PropTypes.oneOf(breakpoints),
  bottom: PropTypes.oneOf(breakpoints),
  around: PropTypes.oneOf(breakpoints),
  between: PropTypes.oneOf(breakpoints),
  hide: PropTypes.bool,
};

export default Row;
