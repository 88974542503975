import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'firm']);
  }

  if (type === types.FETCH_DELIVERY_ADDRESSES_REQUEST) {
    return state
      .setIn(['deliveryAddressList', 'isFetchingFailure'], false)
      .setIn(['deliveryAddressList', 'isFetching'], payload.isReloading === undefined)
      .setIn(['deliveryAddressList', 'isLoadingMore'], payload.isReloading !== undefined)
      .setIn(['deliveryAddressList', 'fetchCount'], Immutable.fromJS(payload.limit))
      .setIn(['deliveryAddressList', 'limit'], Immutable.fromJS(payload.limit))
      .setIn(['deliveryAddressList', 'lastOffset'], Immutable.fromJS(payload.lastOffset))
      .setIn(['deliveryAddressList', 'orderBy'], Immutable.fromJS(payload.orderBy))
      .setIn(['deliveryAddressList', 'filter'], Immutable.fromJS(payload.filter));
  }

  if (type === types.FETCH_DELIVERY_ADDRESSES_SUCCESS) {
    const addresses = payload.map((item) => ({
      data: {
        id: item.c25_contract_address_id,
        c10_number: item.c10_number,
        g60_city_name: item.g60_city_name,
        street: item.street,
        c20_label: item.c20_label,
        contacts: item.contacts,
      },
      actions: {
        edit: true,
        delete: true,
      },
    }));

    const addressList = state.get('deliveryAddressList');
    const fetchCount = addressList.get('fetchCount');
    const lastOffset = addressList.get('lastOffset');
    const stateAddresses = addressList.get('deliveryAddresses');
    const lastCount = addresses.length;

    return state
      .setIn(['deliveryAddressList', 'deliveryAddresses'], getEntitiesFromResponse(addresses, stateAddresses, lastOffset, fetchCount))
      .setIn(['deliveryAddressList', 'lastCount'], lastCount)
      .setIn(['deliveryAddressList', 'isFetching'], false)
      .setIn(['deliveryAddressList', 'isLoadingMore'], false)
      .setIn(['deliveryAddressList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_DELIVERY_ADDRESSES_FAILURE) {
    return state
      .setIn(['deliveryAddressList', 'isFetching'], false)
      .setIn(['deliveryAddressList', 'isFetchingFailure'], true);
  }


  if (type === types.FETCH_DELIVERY_ADDRESS_REQUEST) {
    return state
      .setIn(['deliveryAddress', 'isFetching'], true)
      .setIn(['deliveryAddress', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_DELIVERY_ADDRESS_SUCCESS) {
    return state
      .setIn(['deliveryAddress', 'isFetching'], false)
      .setIn(['deliveryAddress', 'data'], Immutable.fromJS(payload));
  }

  if (type === types.FETCH_DELIVERY_ADDRESS_FAILURE) {
    return state
      .setIn(['deliveryAddress', 'isFetching'], false)
      .setIn(['deliveryAddress', 'isFetchingFailure'], true);
  }

  if (type === types.CREATE_DELIVERY_ADDRESS_REQUEST) {
    return state
      .setIn(['newDeliveryAddress', 'isPostingFailure'], false)
      .setIn(['newDeliveryAddress', 'isPosting'], true);
  }

  if (type === types.CREATE_DELIVERY_ADDRESS_SUCCESS) {
    return state.setIn(['newDeliveryAddress', 'isPosting'], false);
  }

  if (type === types.CREATE_DELIVERY_ADDRESS_FAILURE) {
    return state
      .setIn(['newDeliveryAddress', 'isPostingFailure'], true)
      .setIn(['newDeliveryAddress', 'isPosting'], false);
  }

  if (type === types.UPDATE_DELIVERY_ADDRESS_REQUEST) {
    return state
      .setIn(['deliveryAddress', 'isPostingFailure'], false)
      .setIn(['deliveryAddress', 'isPosting'], true);
  }

  if (type === types.UPDATE_DELIVERY_ADDRESS_SUCCESS) {
    return state.setIn(['deliveryAddress', 'isPosting'], false);
  }

  if (type === types.UPDATE_DELIVERY_ADDRESS_FAILURE) {
    return state
      .setIn(['deliveryAddress', 'isPostingFailure'], true)
      .setIn(['deliveryAddress', 'isPosting'], false);
  }

  if (type === types.REMOVE_DELIVERY_ADDRESS_REQUEST) {
    return state
      .setIn(['removeDeliveryAddress', 'isPostingFailure'], false)
      .setIn(['removeDeliveryAddress', 'isPosting'], true);
  }

  if (type === types.REMOVE_DELIVERY_ADDRESS_SUCCESS) {
    return state
      .setIn(
        ['deliveryAddressList', 'deliveryAddresses'],
        state
          .getIn(['deliveryAddressList', 'deliveryAddresses'])
          .filter((address) => address.getIn(['data', 'id']) !== payload.deliveryAddressId)
      )
      .setIn(['removeDeliveryAddress', 'isPosting'], false);
  }

  if (type === types.REMOVE_DELIVERY_ADDRESS_FAILURE) {
    return state
      .setIn(['removeDeliveryAddress', 'isPostingFailure'], true)
      .setIn(['removeDeliveryAddress', 'isPosting'], false);
  }

  if (type === types.UPDATE_FIRM_DELIVERY_ADDRESSES_FILTER_DATA) {
    return state
      .setIn(['deliveryAddressList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['deliveryAddressList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  if (type === types.FETCH_PARAMS_REQUEST) {
    return state
      .setIn(['settings', 'paramsList', 'isFetching'], true)
      .setIn(['settings', 'paramsList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_PARAMS_SUCCESS) {
    return state
      .setIn(['settings', 'paramsList', 'isFetching'], false)
      .setIn(['settings', 'paramsList', 'isFetchingFailure'], false)
      .setIn(['settings', 'paramsList', 'firmParams'], Immutable.fromJS(payload));
  }

  if (type === types.FETCH_PARAMS_FAILURE) {
    return state
      .setIn(['settings', 'paramsList', 'isFetching'], false)
      .setIn(['settings', 'paramsList', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_PARAMS_REQUEST) {
    return state
      .setIn(['settings', 'paramsList', 'isUpdating'], true)
      .setIn(['settings', 'paramsList', 'isUpdatingFailure'], false);
  }

  if (type === types.UPDATE_PARAMS_SUCCESS) {
    const params = state.getIn(['settings', 'paramsList', 'firmParams']).toJS();
    const updatedParams = {
      ...params,
      ...payload.data,
      // Merge product updated values with orignal product list
      products: params.products.map((prod) => ({
        ...prod,
        ...payload.data.products.find((p) => p.s20_code === prod.s20_code),
      })),
    };

    // prev version handle 0 or null as no value
    // new version with spread operator changes this behaviour
    // remove after proved it is ok!
    // updatedParams.p17_card_perso_id = payload.data.p17_card_perso_id ?
    //   payload.data.p17_card_perso_id : params.p17_card_perso_id;

    // updatedParams.p43_package_order_id = payload.data.p43_package_order_id ?
    //   payload.data.p43_package_order_id : params.p43_package_order_id;

    // updatedParams.p30_line2_perso = payload.data.p30_line2_perso ?
    //   payload.data.p30_line2_perso : params.p30_line2_perso;

    // updatedParams.face_value = payload.data.face_value ?
    // payload.data.face_value : params.face_value;

    return state
      .setIn(['settings', 'paramsList', 'isUpdating'], false)
      .setIn(['settings', 'paramsList', 'isUpdatingFailure'], false)
      .setIn(['settings', 'paramsList', 'firmParams'], Immutable.fromJS(updatedParams));
  }

  if (type === types.UPDATE_PARAMS_FAILURE) {
    return state
      .setIn(['settings', 'paramsList', 'isUpdating'], false)
      .setIn(['settings', 'paramsList', 'isUpdatingFailure'], true);
  }

  if (action.type === types.FETCH_BANK_ACCOUNTS_REQUEST) {
    return state
      .setIn(['bankAccountsList', 'isFetchingFailure'], false)
      .setIn(['bankAccountsList', 'isFetching'], true);
  }

  if (action.type === types.FETCH_BANK_ACCOUNTS_SUCCESS) {
    const bankAccounts = action.payload.map((item) => ({
      data: {
        c01_name: item.c01_name,
        c60_contract_account_id: item.c60_contract_account_id,
        c01_company_id: item.c01_company_id,
        c10_contract_id: item.c10_contract_id,
        c10_number: item.c10_number,
        c60_date_from: item.c60_date_from,
        c60_date_to: item.c60_date_to,
        bank_account: item.bank_account,
        is_active: item.is_active,
      },
    }));

    return state
      .setIn(['bankAccountsList', 'bankAccounts'], Immutable.fromJS(bankAccounts))
      .setIn(['bankAccountsList', 'isFetching'], false);
  }

  if (action.type === types.FETCH_BANK_ACCOUNTS_FAILURE) {
    return state
      .setIn(['bankAccountsList', 'isFetching'], false)
      .setIn(['bankAccountsList', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_FIRM_REQUEST) {
    return state
      .setIn(['firmInfo', 'isFetchingFailure'], false)
      .setIn(['firmInfo', 'isFetching'], true);
  }

  if (action.type === types.FETCH_FIRM_SUCCESS) {
    return state
      .setIn(['firmInfo', 'data'], Immutable.fromJS(action.payload))
      .setIn(['firmInfo', 'isFetching'], false);
  }

  if (action.type === types.FETCH_FIRM_FAILURE) {
    return state
      .setIn(['firmInfo', 'isFetching'], false)
      .setIn(['firmInfo', 'isFetchingFailure'], true);
  }

  if (action.type === types.UPDATE_FIRM_REQUEST) {
    return state
      .setIn(['firmInfo', 'isPosting'], true)
      .setIn(['firmInfo', 'isPostingFailure'], false);
  }

  if (action.type === types.UPDATE_FIRM_SUCCESS) {
    return state
      .setIn(['firmInfo', 'data'], Immutable.fromJS(action.payload))
      .setIn(['firmInfo', 'isPosting'], false);
  }

  if (action.type === types.UPDATE_FIRM_FAILURE) {
    return state
      .setIn(['firmInfo', 'isPosting'], false)
      .setIn(['firmInfo', 'isPostingFailure'], true);
  }

  if (action.type === types.FETCH_FIRM_PAIRS_REQUEST) {
    return state
      .setIn(['firmPairs', 'isFetchingFailure'], false)
      .setIn(['firmPairs', 'isFetching'], true);
  }

  if (action.type === types.FETCH_FIRM_PAIRS_SUCCESS) {
    const firms = action.payload.map((item) => ({
      label: item.c01_name,
      value: item.c01_company_id,
    }));

    return state
      .setIn(['firmPairs', 'firms'], Immutable.fromJS(firms))
      .setIn(['firmPairs', 'isFetching'], false);
  }

  if (action.type === types.FETCH_FIRM_PAIRS_FAILURE) {
    return state
      .setIn(['firmPairs', 'isFetching'], false)
      .setIn(['firmPairs', 'isFetchingFailure'], true);
  }

  if (type === types.CREATE_BANK_ACCOUNT_SUCCESS) {
    const bankAccounts = state.getIn(['bankAccountsList', 'bankAccounts']).push({ data: payload });
    return state.setIn(['bankAccountsList', 'bankAccounts'], bankAccounts);
  }

  if (action.type === types.FETCH_DELIVERY_ADDRESS_PAIRS_REQUEST) {
    return state
      .setIn(['deliveryAddressPairs', 'isFetchingFailure'], false)
      .setIn(['deliveryAddressPairs', 'isFetching'], true);
  }

  if (action.type === types.FETCH_DELIVERY_ADDRESS_PAIRS_SUCCESS) {
    const firms = action.payload.map((item) => ({
      label: item.full_address,
      value: item.c25_contract_address_id,
    }));

    return state
      .setIn(['deliveryAddressPairs', 'deliveryAddressPairs'], Immutable.fromJS(firms))
      .setIn(['deliveryAddressPairs', 'isFetching'], false);
  }

  if (action.type === types.FETCH_DELIVERY_ADDRESS_PAIRS_FAILURE) {
    return state
      .setIn(['deliveryAddressPairs', 'isFetching'], false)
      .setIn(['deliveryAddressPairs', 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
