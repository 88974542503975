import { RSAA } from 'redux-api-middleware';
import { API_URL, INSTANCE_SK } from '../config/envspecific';

const contentTypeHeader = 'Content-Type';

// eslint-disable-next-line import/no-mutable-exports
export let lastApiAction = null;

export const apiRSAAInjectorFactory = (getLang) => () => (next) => (action) => {
  const callApi = action[RSAA];

  if (callApi) {
    // fill json content-type header if not set for request with body
    if (callApi.body && (!callApi.headers || !callApi.headers[contentTypeHeader]) && !(callApi.body instanceof FormData)) {
      callApi.headers = { ...callApi.headers, [contentTypeHeader]: 'application/json' };
    }

    let endpoint = callApi.endpoint;

    // add endpoint root(if needed)
    // replace {LANG placeholder with current language}
    if (endpoint) {
      if (!endpoint.includes('://')) {
        // add api root
        endpoint = endpoint[0] === '/' ? `${API_URL}${endpoint}` : `${API_URL}/${endpoint}`;
      }
      let lang = getLang();
      if (!lang || lang === 'undefined' || lang === 'null') {
        lang = INSTANCE_SK ? 'sk' : 'en';
      }
      endpoint = endpoint.replace('{LANG}', lang);
    }

    const newAction = { ...action, [RSAA]: { ...callApi, endpoint } };
    lastApiAction = newAction;
    return next(newAction);
  }

  return next(action);
};
