// Middleware to normalize _REQUEST with error flag to _FAILURE action
// according: https://github.com/agraboso/redux-api-middleware/blob/e72ac27801cab4f5c5cedfaa8d1787e0ffce6f5b/README.md#lifecycle
// _REQUEST with error:true is dispatched eg. when browser is offline
export const apiRequestErrorHandler = (store) => (next) => (action) => {
  if (action.error && action.payload && action.payload.name === 'RequestError' && action.type.includes('_REQUEST')) {
    return store.dispatch({
      ...action,
      type: action.type.replace('_REQUEST', '_FAILURE'),
    });
  }
  return next(action);
};
