import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';


export const departmentReducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'department']);
  }

  if (type === types.FETCH_FIRM_DEPARTMENT_PAIRS_REQUEST) {
    return state
      .setIn(['departmentPairs', 'isFetchingFailure'], false)
      .setIn(['departmentPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_FIRM_DEPARTMENT_PAIRS_SUCCESS) {
    const departments = payload.map((item) => ({
      label: item.val,
      value: item.id,
      parent: item.grouped,
      cond: item.cond,
      active: item.active,
    }));

    return state
      .setIn(['departmentPairs', 'departmentPairs'], Immutable.fromJS(departments))
      .setIn(['departmentPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_FIRM_DEPARTMENT_PAIRS_FAILURE) {
    return state
      .setIn(['departmentPairs', 'isFetching'], false)
      .setIn(['departmentPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_FIRM_DEPARTMENTS_REQUEST) {
    return state
      .setIn(['departmentList', 'isFetchingFailure'], false)
      .setIn(['departmentList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['departmentList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['departmentList', 'fetchCount'], Immutable.fromJS(payload.limit))
      .setIn(['departmentList', 'limit'], Immutable.fromJS(payload.limit))
      .setIn(['departmentList', 'lastOffset'], Immutable.fromJS(payload.lastOffset))
      .setIn(['departmentList', 'orderBy'], Immutable.fromJS(payload.orderBy))
      .setIn(['departmentList', 'filter'], Immutable.fromJS(payload.filter));
  }

  if (type === types.FETCH_FIRM_DEPARTMENTS_SUCCESS) {
    const rows = payload.map((item) => ({
      data: {
        ...item,
        id: item.c33_department_id,
        is_active: !!+item.is_active,
      },
      actions: {
        edit: true,
        delete: true,
      },
    }));

    const departmentList = state.get('departmentList');
    const fetchCount = departmentList.get('fetchCount');
    const lastOffset = departmentList.get('lastOffset');
    const stateRows = departmentList.get('rows');
    const lastCount = rows.length;

    return state
      .setIn(['departmentList', 'rows'], getEntitiesFromResponse(rows, stateRows, lastOffset, fetchCount))
      .setIn(['departmentList', 'lastCount'], lastCount)
      .setIn(['departmentList', 'isFetching'], false)
      .setIn(['departmentList', 'isLoadingMore'], false)
      .setIn(['departmentList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_FIRM_DEPARTMENTS_FAILURE) {
    return state
      .setIn(['departmentList', 'isFetching'], false)
      .setIn(['departmentList', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_FIRM_DEPARTMENTS_FILTER_DATA) {
    return state
      .setIn(['departmentList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['departmentList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  return state;
};
