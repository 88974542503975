import { RSAA } from 'redux-api-middleware';
import debounce from 'lodash.debounce';
import { API_URL } from '../../config/envspecific';
import { t } from '../../i18n';
import * as types from './actionTypes';
import { addFlashMessage } from '../flashMessages/actions';
import { selectWidgetsList, selectWidgetData } from './selectors';
import downloadFile from '../../service/downloadFile';
import { fetchBenefiaWidgets } from '../benefia/actions';

export const handleApiActionError = (dispatch) => (action) => {
  if (action.error) {
    const message = action.payload.response && action.payload.response.message;
    dispatch(addFlashMessage('error', t('Chyba: {{message}}', { message: message || 'Obecná chyba' })));
  }

  return action;
};

const fetchPortalWidgets = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/widgets`,
    method: 'GET',
    types: [
      types.FETCH_PORTAL_WIDGETS_REQUEST,
      types.FETCH_PORTAL_WIDGETS_SUCCESS,
      types.FETCH_PORTAL_WIDGETS_FAILURE,
    ],
  },
});

export const fetchWidgets = () => async (dispatch) => {
  dispatch({
    type: types.FETCH_WIDGETS_REQUEST,
  });
  const res = await Promise.all([dispatch(fetchPortalWidgets()), dispatch(fetchBenefiaWidgets())]);
  const errorRes = res.find((r) => r.error);
  if (errorRes) {
    return dispatch({
      type: types.FETCH_WIDGETS_FAILURE,
      payload: errorRes.payload,
      error: true,
    });
  }

  const widgets = res.reduce((acc, curr) => {
    if (curr?.payload?.length) {
      return acc.concat(curr.payload);
    }
    return acc;
  }, []);

  return dispatch({
    type: types.FETCH_WIDGETS_SUCCESS,
    payload: widgets,
  });
};

export const widgetActivateCardFetch = (cardNo) => (dispatch) => (dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/widgets/ph10`,
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
    body: JSON.stringify({ card_no: cardNo }),
    types: [
      types.FETCH_WIDGET_PH10_REQUEST,
      types.FETCH_WIDGET_PH10_SUCCESS,
      types.FETCH_WIDGET_PH10_FAILURE,
    ],
  },
}).then((action) => {
  handleApiActionError(dispatch)(action);

  if (!action.error) {
    dispatch(addFlashMessage('success', t('Karta byla úspěšně aktivována.')));
  }
})
);

export const setWidget = (widget, enabled) => ({
  type: types.SET_WIDGET,
  payload: { widget, enabled },
});

export const saveWidgetsSettings = (widgets) => (dispatch) => (dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/widgets`,
    headers: { 'Content-Type': 'application/json' },
    method: 'PUT',
    body: JSON.stringify({ widgets: widgets ? widgets.map((w) => ({ widget: w.widget, w15_enabled: w.enabled })) : [] }),
    types: [
      types.SAVE_WIDGETS_REQUEST,
      types.SAVE_WIDGETS_SUCCESS,
      types.SAVE_WIDGETS_FAILURE,
    ],
  },
})
);

const debouncedWidgetsSave = debounce((dispatch, getState) => {
  const widgets = selectWidgetsList(getState());
  dispatch(saveWidgetsSettings(widgets));
}, 500);

export const setWidgetAndSave = (widget, enabled, flashMessage = false) => (dispatch, getState) => {
  dispatch(setWidget(widget, enabled));

  if (!enabled && flashMessage) {
    dispatch(addFlashMessage('success', t('Dlaždice byla skryta. Zobrazit ji můžete v sekci uživatelské nastavení.', { ns: 'home' })));
  }

  debouncedWidgetsSave(dispatch, getState);
};

export const widgetPartnerActivatePayGate = (contractAddressId) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: '/widgets/ph30',
    method: 'PUT',
    body: JSON.stringify({ c25_contract_address_id: contractAddressId }),
    types: [
      types.FETCH_WIDGET_PH30_REQUEST,
      types.FETCH_WIDGET_PH30_SUCCESS,
      types.FETCH_WIDGET_PH30_FAILURE,
    ],
  },
}).then(handleApiActionError(dispatch));

export const widgetPartnerSavePayGatePayment = (data) => (dispatch) => (dispatch({
  [RSAA]: {
    endpoint: '/widgets/ph31',
    method: 'PUT',
    body: JSON.stringify(data),
    types: [
      types.SAVE_WIDGET_PH31_REQUEST,
      types.SAVE_WIDGET_PH31_SUCCESS,
      types.SAVE_WIDGET_PH31_FAILURE,
    ],
  },
}).then((action) => {
  handleApiActionError(dispatch)(action);

  if (!action.error) {
    dispatch(addFlashMessage('success', t('Platba byla úspěšně vytvořena.')));
  }

  return action;
})
);

export const widgetPartnerConfirmPayGatePayment = (transactionId, confirmed, pin) => (dispatch) => (dispatch({
  [RSAA]: {
    endpoint: '/widgets/ph32',
    method: 'PUT',
    body: JSON.stringify({
      n55_gate_transaction_id: transactionId,
      confirmed,
      n55_pin: pin,
    }),
    types: [
      types.SAVE_WIDGET_PH32_REQUEST,
      types.SAVE_WIDGET_PH32_SUCCESS,
      types.SAVE_WIDGET_PH32_FAILURE,
    ],
  },
}).then(handleApiActionError(dispatch))
);

export const widgetMyPayGateConfirm = (data) => (dispatch) => (dispatch({
  [RSAA]: {
    endpoint: '/widgets/ph33',
    method: 'PUT',
    body: JSON.stringify(data),
    types: [
      types.SAVE_WIDGET_PH33_REQUEST,
      types.SAVE_WIDGET_PH33_SUCCESS,
      types.SAVE_WIDGET_PH33_FAILURE,
    ],
  },
}).then(handleApiActionError(dispatch)));

export const widgetPartnerFetchPayGatePayments = (affiliateId) => (dispatch, getState) => {
  let fetchAffiliateId;
  if (affiliateId !== undefined) {
    dispatch({
      type: types.SET_WIDGET_PH32_AFFILIATE_ID,
      payload: { affiliateId },
    });
    fetchAffiliateId = affiliateId;
  } else {
    fetchAffiliateId = selectWidgetData(getState()).ph32.affiliateId;
  }

  const affiliateFilter = fetchAffiliateId ? `c25_contract_address_id:${fetchAffiliateId}` : '';

  dispatch({
    [RSAA]: {
      endpoint: `/partners_gate_payments?filter=n51_gate_type_id:2;${affiliateFilter}&order=n55_gate_transaction_id+d&limit=5`,
      method: 'GET',
      types: [
        types.FETCH_WIDGET_PH32_PAYMENTS_REQUEST,
        types.FETCH_WIDGET_PH32_PAYMENTS_SUCCESS,
        types.FETCH_WIDGET_PH32_PAYMENTS_FAILURE,
      ],
    },
  }).then(handleApiActionError(dispatch));
};

export const confirmContributionTerms = (contributionId, userId) => (dispatch) => (dispatch({
  [RSAA]: {
    endpoint: '/widgets/ph40/confirm_terms',
    method: 'PUT',
    body: JSON.stringify({
      m01_contribution_id: contributionId,
      c30_company_user_id: userId,
    }),
    types: [
      types.SAVE_WIDGET_PH40_CONFIRM_TERMS_REQUEST,
      types.SAVE_WIDGET_PH40_CONFIRM_TERMS_SUCCESS,
      types.SAVE_WIDGET_PH40_CONFIRM_TERMS_FAILURE,
    ],
  },
}).then(handleApiActionError(dispatch)));

export const createContributionOrder = (contributionId, userId, amount, emissionTransfer) => (dispatch) => (dispatch({
  [RSAA]: {
    endpoint: '/widgets/ph40/create_order',
    method: 'PUT',
    body: JSON.stringify({
      m01_contribution_id: contributionId,
      c30_company_user_id: userId,
      m20_ordered_amount: amount,
      m20_emission_transfer: emissionTransfer ? 1 : 0,
    }),
    types: [
      types.SAVE_WIDGET_PH40_CREATE_ORDER_REQUEST,
      types.SAVE_WIDGET_PH40_CREATE_ORDER_SUCCESS,
      types.SAVE_WIDGET_PH40_CREATE_ORDER_FAILURE,
    ],
  },
}).then(handleApiActionError(dispatch))
  .then((action) => {
    if (!action.error && action.payload.order_sent) {
      dispatch(addFlashMessage('success', t('Objednávka byla úspěšně vytvořena.')));
    }
    return action;
  }));


export const saveContributionSetPayment = (contributionId, userId, paymentTypeId) => (dispatch) => (dispatch({
  [RSAA]: {
    endpoint: '/widgets/ph40/set_payment',
    method: 'PUT',
    body: JSON.stringify({
      m01_contribution_id: contributionId,
      c30_company_user_id: userId,
      i04_payment_type_id: paymentTypeId,
    }),
    types: [
      types.SAVE_WIDGET_PH40_SET_PAYMENT_REQUEST,
      types.SAVE_WIDGET_PH40_SET_PAYMENT_SUCCESS,
      types.SAVE_WIDGET_PH40_SET_PAYMENT_FAILURE,
    ],
  },
}).then(handleApiActionError(dispatch)));

export const saveContributionOnlineConfirm = (goPayId) => (dispatch) => (dispatch({
  [RSAA]: {
    endpoint: '/widgets/ph40/online_confirm',
    method: 'PUT',
    body: JSON.stringify({
      id: goPayId,
    }),
    types: [
      types.SAVE_WIDGET_PH40_ONLINE_CONFIRM_REQUEST,
      types.SAVE_WIDGET_PH40_ONLINE_CONFIRM_SUCCESS,
      types.SAVE_WIDGET_PH40_ONLINE_CONFIRM_FAILURE,
    ],
  },
}).then(handleApiActionError(dispatch)))
  .then((action) => {
    if (!action.error) {
      dispatch(addFlashMessage('success', t('Objednávka byla úspěšně zaplacena.')));
    }
  });

export const downloadContributionInvoice = (invoiceId, number) => ({
  [RSAA]: {
    endpoint: '/my_invoices/pdf',
    body: JSON.stringify({ i01_invoice_id: invoiceId }),
    method: 'PUT',
    types: [
      types.DOWNLOAD_INVOICES_PDF_REQUEST,
      {
        type: types.DOWNLOAD_INVOICES_PDF_SUCCESS,
        payload: (action, state, res) => res.blob().then(
          (blob) => downloadFile(blob, t('KartaUp_faktura_{{number}}.pdf', { number, ns: 'my' }))
        ),
      },
      types.DOWNLOAD_INVOICES_PDF_FAILURE,
    ],
  },
});

export const smsVerificationSetPhone = (phone) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: '/widgets/ph60/set_phone',
    method: 'PUT',
    body: JSON.stringify({
      u01_phone: phone,
    }),
    types: [
      types.SAVE_WIDGET_PH40_ONLINE_CONFIRM_REQUEST,
      types.SAVE_WIDGET_PH40_ONLINE_CONFIRM_SUCCESS,
      types.SAVE_WIDGET_PH40_ONLINE_CONFIRM_FAILURE,
    ],
  },
}).then(handleApiActionError(dispatch));

export const smsVerificationCheckCode = (code) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: '/widgets/ph60/check_code',
    method: 'PUT',
    body: JSON.stringify({
      e25_code: code,
    }),
    types: [
      types.SAVE_WIDGET_PH40_ONLINE_CONFIRM_REQUEST,
      types.SAVE_WIDGET_PH40_ONLINE_CONFIRM_SUCCESS,
      types.SAVE_WIDGET_PH40_ONLINE_CONFIRM_FAILURE,
    ],
  },
})
  .then(handleApiActionError(dispatch))
  .then((action) => {
    if (!action.error) {
      dispatch(addFlashMessage('success', t('Gratulujeme, číslo pro SMS komunikaci bylo ověřeno.\nZměnit jej můžete v sekci MOJE/Karty. Nastavit služby notifikace můžete ve svém Profilu v sekci Nastavení', { ns: 'home' })));
    }
    return action;
  });

export const confirmLemonPayBanner = (confirmed) => (dispatch) => (dispatch({
  [RSAA]: {
    endpoint: '/widgets/ph70',
    method: 'PUT',
    body: JSON.stringify({ confirmed: confirmed ? 1 : 0 }),
    types: [
      types.CONFIRM_WIDGET_PH70_REQUEST,
      types.CONFIRM_WIDGET_PH70_SUCCESS,
      types.CONFIRM_WIDGET_PH70_FAILURE,
    ],
  },
}).then(handleApiActionError(dispatch)));

export const setPopupBannerShown = (shown) => ({
  type: types.SET_POPUP_BANNER_SHOWN,
  payload: { shown },
});
