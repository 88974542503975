/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../component1/Card';
import { widgetMinHeight } from '../../../global/constants';
import styles from './PromoTemplateWidget.scss';
import RaisedButton from '../../../../component1/RaisedButton/RaisedButton';

export const PromoTemplateWidget = ({ data, imgSize, imgPosition }) => {
  const logoExt = ['benefits'].includes(data.template) ? 'jpg' : 'png';
  const logo = data.template && !['boomer-dobrebude', 'no-logo'].includes(data.template) && `/images/logo-${data.template}.${logoExt}`;
  const imgElement = logo && (<img className={imgSize === 'auto' ? styles.imgAutoSize : styles.img} src={logo} alt={data.title} />);
  return (
    <Card title={data.title} layout="vcentered" minHeight={widgetMinHeight}>
      {imgPosition === 'above' && imgElement}
      {data.html_text && <p className={styles.text} dangerouslySetInnerHTML={{ __html: data.html_text }} />}
      {data.comment && <p className={styles.smallMargin}>{data.comment}</p>}
      {imgPosition === 'below' && imgElement}
      {data.html_below && <p className={styles.text} dangerouslySetInnerHTML={{ __html: data.html_below }} />}
      <div className={styles.bottomSpacer}>
        {data.button && data.url && (
        <RaisedButton
          priority="primary"
          size="large"
          type="submit"
          label={data.button}
          externalHref={data.url}
          targetBlank
        />
        )}
      </div>
    </Card>
  );
};

PromoTemplateWidget.defaultProps = {
  imgPosition: 'below',
};

PromoTemplateWidget.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    comment: PropTypes.string,
    html_text: PropTypes.string.isRequired,
    html_below: PropTypes.string,
    template: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  imgSize: PropTypes.oneOf(['auto']),
  imgPosition: PropTypes.oneOf(['above', 'below']),
  // t: PropTypes.func.isRequired,
};
