// MSIE 11 support
import 'react-app-polyfill/ie11';
import 'core-js/es/array';
import 'core-js/es/string';
import 'core-js/es/number';
import 'core-js/es/function/name';
import 'core-js/es/weak-set';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { styles } from './styles/theme1';
import { RootRouter } from './rootRouter';
import store from './store';
import { initOfflineHooks, initPageVisibleHooks } from './service/appStateService';
import { MOBILE_APP } from './config/envspecific';
import { crashReporterInit } from './service/crashReporter';
import Overlay from './component1/Progress/Overlay/Overlay';
import { getLocale } from './i18n';

crashReporterInit(store);

const muiTheme = createMuiTheme(styles);

ReactDOM.render(
  (
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={getLocale()}>
          <Suspense key="suspense" fallback={<Overlay show />}>
            <RootRouter store={store} />
          </Suspense>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Provider>
  ),
  document.getElementById('content')
);

if (!MOBILE_APP) {
  store.dispatch(initOfflineHooks());
  store.dispatch(initPageVisibleHooks());
}
