export const FETCH_FIRM_DEPARTMENT_PAIRS_REQUEST = 'firm/department/FETCH_FIRM_DEPARTMENT_PAIRS_REQUEST';
export const FETCH_FIRM_DEPARTMENT_PAIRS_SUCCESS = 'firm/department/FETCH_FIRM_DEPARTMENT_PAIRS_SUCCESS';
export const FETCH_FIRM_DEPARTMENT_PAIRS_FAILURE = 'firm/department/FETCH_FIRM_DEPARTMENT_PAIRS_FAILURE';

export const ADD_DEPARTMENT_REQUEST = 'firm/department/ADD_DEPARTMENT_REQUEST';
export const ADD_DEPARTMENT_SUCCESS = 'firm/department/ADD_DEPARTMENT_SUCCESS';
export const ADD_DEPARTMENT_FAILURE = 'firm/department/ADD_DEPARTMENT_FAILURE';

export const UPDATE_DEPARTMENT_REQUEST = 'firm/department/UPDATE_DEPARTMENT_REQUEST';
export const UPDATE_DEPARTMENT_SUCCESS = 'firm/department/UPDATE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_FAILURE = 'firm/department/UPDATE_DEPARTMENT_FAILURE';

export const DELETE_DEPARTMENT_REQUEST = 'firm/department/DELETE_DEPARTMENT_REQUEST';
export const DELETE_DEPARTMENT_SUCCESS = 'firm/department/DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAILURE = 'firm/department/DELETE_DEPARTMENT_FAILURE';


export const FETCH_FIRM_DEPARTMENTS_REQUEST = 'firm/position/FETCH_FIRM_DEPARTMENTS_REQUEST';
export const FETCH_FIRM_DEPARTMENTS_SUCCESS = 'firm/position/FETCH_FIRM_DEPARTMENTS_SUCCESS';
export const FETCH_FIRM_DEPARTMENTS_FAILURE = 'firm/position/FETCH_FIRM_DEPARTMENTS_FAILURE';

export const UPDATE_FIRM_DEPARTMENTS_FILTER_DATA = 'firm/position/UPDATE_FIRM_DEPARTMENTS_FILTER_DATA';
