import { createSelector } from 'reselect';
import { RootState } from '../../../../data/storeModel';


const getUser = (state: RootState) => state.getIn(['my', 'user', 'user']).toJS();
const getUpdateUser = (state: RootState) => state.getIn(['my', 'user', 'updateUser']).toJS();
const getUserPairs = (state: RootState) => state.getIn(['my', 'user', 'userPairs', 'userPairs']).toJS();


export const selectUser = createSelector([getUser], (user) => user.data);
export const selectUserMeta = createSelector(
  [getUser],
  (user) => ({ isFetching: user.isFetching })
);
export const selectUpdateUserMeta = createSelector(
  [getUpdateUser],
  (user) => ({ isPosting: user.isPosting })
);

export const selectUserPairs = createSelector([getUserPairs], (pairs) => pairs);

export const selectDstCardsPairs = createSelector([selectUser],
  (data) => data && data.credit_transfer && data.credit_transfer.to_cards.map((c: any) => ({
    label: c.u01_nickname || c.fullname,
    value: c.c30_company_user_id, // p20_card_id,
  })));
