import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';

const getCardForTable = (card) => ({
  data: {
    id: card.p20_card_id,
    fullname: card.fullname,
    card_number: card.card_number,
    c30_personal_nb: card.c30_personal_nb,
    p21_card_status: card.p21_card_status,
    p21_card_status_pending: card.p21_card_status_pending,
    p20_date_expire: card.p20_date_expire,
    full_address: card.full_address,
    c30_company_user_id: card.c30_company_user_id,
    delivery_pending: !!card.delivery_pending,
  },
  actions: {
    assign: !!card.can_assign,
    block: !!card.can_block,
    reissue: !!card.can_reissue,
    unblock: !!card.can_unblock,
    activate: !!card.can_activate || !!card.delivery_pending,
    cancel: !!card.can_cancel || !!card.delivery_pending,
    edit: !!card.can_edit,
  },
});


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'card']);
  }

  if (action.type === types.FETCH_CARDS_REQUEST) {
    return state
      .setIn(['cardList', 'isFetchingFailure'], false)
      .setIn(['cardList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['cardList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['cardList', 'fetchCount'], payload.limit)
      .setIn(['cardList', 'limit'], payload.limit)
      .setIn(['cardList', 'lastOffset'], payload.lastOffset)
      .setIn(['cardList', 'orderBy'], Immutable.fromJS(payload.orderBy))
      .setIn(['cardList', 'filter'], Immutable.fromJS(payload.filter));
  }

  if (action.type === types.FETCH_CARDS_SUCCESS) {
    const cards = action.payload.map((card) => getCardForTable(card));
    const cardList = state.get('cardList');
    const fetchCount = cardList.get('fetchCount');
    const lastOffset = cardList.get('lastOffset');
    const stateCards = cardList.get('cards');
    const lastCount = cards.length;

    return state
      .setIn(['cardList', 'cards'], getEntitiesFromResponse(cards, stateCards, lastOffset, fetchCount))
      .setIn(['cardList', 'lastCount'], lastCount)
      .setIn(['cardList', 'isFetching'], false)
      .setIn(['cardList', 'isLoadingMore'], false)
      .setIn(['cardList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_CARDS_FAILURE) {
    return state
      .setIn(['cardList', 'isFetching'], false)
      .setIn(['cardList', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_FIRM_CARD_STATUS_PAIRS_REQUEST) {
    return state
      .setIn(['cardStatusPairs', 'isFetchingFailure'], false)
      .setIn(['cardStatusPairs', 'isFetching'], true);
  }

  if (type === types.UPDATE_FIRM_CARDS_FILTER_DATA) {
    return state
      .setIn(['cardList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['cardList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  if (type === types.SET_CARD_STATUS_REQUEST) {
    return state
      .setIn(['setCardStatus', 'isPostingFailure'], false)
      .setIn(['setCardStatus', 'isPosting'], true);
  }

  if (type === types.SET_CARD_STATUS_SUCCESS) {
    return state
      .updateIn(['cardList', 'cards'], (cards) => cards.map((card) => {
        const cardId = card.getIn(['data', 'id']);
        const newCard = payload.find((payloadCard) => cardId === payloadCard.p20_card_id);
        return newCard ? Immutable.fromJS(getCardForTable(newCard)) : card;
      }))
      .setIn(['setCardStatus', 'isPosting'], false);
  }

  if (type === types.SET_CARD_STATUS_FAILURE) {
    return state
      .setIn(['setCardStatus', 'isPostingFailure'], true)
      .setIn(['setCardStatus', 'isPosting'], false);
  }

  if (type === types.FETCH_FIRM_CARD_STATUS_PAIRS_SUCCESS) {
    const cardStatuses = payload.map((item) => ({
      label: item.p21_card_status,
      value: item.p21_card_status_id,
    }));

    return state
      .setIn(['cardStatusPairs', 'cardStatuses'], Immutable.fromJS(cardStatuses))
      .setIn(['cardStatusPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_FIRM_CARD_STATUS_PAIRS_FAILURE) {
    return state
      .setIn(['cardStatusPairs', 'isFetching'], false)
      .setIn(['cardStatusPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_CARD_DISTR_ADDRESS_PAIRS_REQUEST) {
    return state
      .setIn(['cardDistrAddressPairs', 'isFetchingFailure'], false)
      .setIn(['cardDistrAddressPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_CARD_DISTR_ADDRESSES_PAIRS_SUCCESS) {
    const cardDistrAddresses = payload.map((item) => ({
      label: item.full_address,
      value: item.c20_company_address_id,
    }));

    return state
      .setIn(['cardDistrAddressPairs', 'cardDistrAddresses'], Immutable.fromJS(cardDistrAddresses))
      .setIn(['cardDistrAddressPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_CARD_DISTR_ADDRESSES_PAIRS_FAILURE) {
    return state
      .setIn(['cardDistrAddressPairs', 'isFetching'], false)
      .setIn(['cardDistrAddressPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_CARD_REQUEST) {
    return state
      .setIn(['cardDetail', 'isFetchingFailure'], false)
      .setIn(['cardDetail', 'isFetching'], true);
  }

  if (type === types.FETCH_CARD_SUCCESS) {
    return state
      .setIn(['cardDetail', 'card'], Immutable.fromJS(payload))
      .setIn(['cardDetail', 'isFetching'], true);
  }

  if (type === types.FETCH_CARD_FAILURE) {
    return state
      .setIn(['cardDetail', 'isFetchingFailure'], true)
      .setIn(['cardDetail', 'isFetching'], false);
  }

  return state;
};


export default reducerFactory;
