import React, { lazy } from 'react';
import { SignUpCenteredLayout } from '../layout/main1';

const PaymentConfirmContainer = lazy(() => import(/* webpackChunkName: "onlinepayment" */ './pages/PaymentConfirmContainer'));

export default {
  path: 'payment-gate/:transactionId',
  key: null,
  loginOptional: true,
  component: (props) => <SignUpCenteredLayout {...props} />,
  indexRoute: { component: PaymentConfirmContainer },
};
