import React from 'react';
import Dialog from '../../../../component1/Dialog/Dialog';
import { TranslateFn } from '../../../global/model';

export const NewsDialog: React.FC<{ onClose(): void; t: TranslateFn; }> = ({ onClose, t }) => (
  <Dialog
    onHandleClose={onClose}
    title={t('Novinky')}
    actions={[{
      label: t('Zavřít'),
      onClick: () => {
      },
    }]}
    hasCross
    forceOpen
  >
    <div dangerouslySetInnerHTML={{ __html: t('benefiaNewsContent') }} />
  </Dialog>
);
