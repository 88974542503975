export const FETCH_DELIVERY_ADDRESSES_REQUEST = 'firm/firm/FETCH_DELIVERY_ADDRESSES_REQUEST';
export const FETCH_DELIVERY_ADDRESSES_SUCCESS = 'firm/firm/FETCH_DELIVERY_ADDRESSES_SUCCESS';
export const FETCH_DELIVERY_ADDRESSES_FAILURE = 'firm/firm/FETCH_DELIVERY_ADDRESSES_FAILURE';

export const FETCH_DELIVERY_ADDRESS_REQUEST = 'firm/firm/FETCH_DELIVERY_ADDRESS_REQUEST';
export const FETCH_DELIVERY_ADDRESS_SUCCESS = 'firm/firm/FETCH_DELIVERY_ADDRESS_SUCCESS';
export const FETCH_DELIVERY_ADDRESS_FAILURE = 'firm/firm/FETCH_DELIVERY_ADDRESS_FAILURE';

export const FETCH_DELIVERY_ADDRESS_PAIRS_REQUEST = 'firm/firm/FETCH_DELIVERY_ADDRESS_PAIRS_REQUEST';
export const FETCH_DELIVERY_ADDRESS_PAIRS_SUCCESS = 'firm/firm/FETCH_DELIVERY_ADDRESS_PAIRS_SUCCESS';
export const FETCH_DELIVERY_ADDRESS_PAIRS_FAILURE = 'firm/firm/FETCH_DELIVERY_ADDRESS_PAIRS_FAILURE';

export const CREATE_DELIVERY_ADDRESS_REQUEST = 'firm/firm/CREATE_DELIVERY_ADDRESS_REQUEST';
export const CREATE_DELIVERY_ADDRESS_SUCCESS = 'firm/firm/CREATE_DELIVERY_ADDRESS_SUCCESS';
export const CREATE_DELIVERY_ADDRESS_FAILURE = 'firm/firm/CREATE_DELIVERY_ADDRESS_FAILURE';

export const UPDATE_DELIVERY_ADDRESS_REQUEST = 'firm/firm/UPDATE_DELIVERY_ADDRESS_REQUEST';
export const UPDATE_DELIVERY_ADDRESS_SUCCESS = 'firm/firm/UPDATE_DELIVERY_ADDRESS_SUCCESS';
export const UPDATE_DELIVERY_ADDRESS_FAILURE = 'firm/firm/UPDATE_DELIVERY_ADDRESS_FAILURE';

export const REMOVE_DELIVERY_ADDRESS_REQUEST = 'firm/firm/REMOVE_DELIVERY_ADDRESS_REQUEST';
export const REMOVE_DELIVERY_ADDRESS_SUCCESS = 'firm/firm/REMOVE_DELIVERY_ADDRESS_SUCCESS';
export const REMOVE_DELIVERY_ADDRESS_FAILURE = 'firm/firm/REMOVE_DELIVERY_ADDRESS_FAILURE';

export const FETCH_PARAMS_REQUEST = 'firm/firm/FETCH_PARAMS_REQUEST';
export const FETCH_PARAMS_SUCCESS = 'firm/firm/FETCH_PARAMS_SUCCESS';
export const FETCH_PARAMS_FAILURE = 'firm/firm/FETCH_PARAMS_FAILURE';

export const UPDATE_PARAMS_REQUEST = 'firm/firm/UPDATE_PARAMS_REQUEST';
export const UPDATE_PARAMS_SUCCESS = 'firm/firm/UPDATE_PARAMS_SUCCESS';
export const UPDATE_PARAMS_FAILURE = 'firm/firm/UPDATE_PARAMS_FAILURE';

export const FETCH_BANK_ACCOUNTS_REQUEST = 'firm/firm/FETCH_BANK_ACCOUNTS_REQUEST';
export const FETCH_BANK_ACCOUNTS_SUCCESS = 'firm/firm/FETCH_BANK_ACCOUNTS_SUCCESS';
export const FETCH_BANK_ACCOUNTS_FAILURE = 'firm/firm/FETCH_BANK_ACCOUNTS_FAILURE';

export const CREATE_BANK_ACCOUNT_REQUEST = 'firm/firm/CREATE_BANK_ACCOUNT_REQUEST';
export const CREATE_BANK_ACCOUNT_SUCCESS = 'firm/firm/CREATE_BANK_ACCOUNT_SUCCESS';
export const CREATE_BANK_ACCOUNT_FAILURE = 'firm/firm/CREATE_BANK_ACCOUNT_FAILURE';

export const FETCH_FIRM_REQUEST = 'firm/firm/FETCH_FIRM_REQUEST';
export const FETCH_FIRM_SUCCESS = 'firm/firm/FETCH_FIRM_SUCCESS';
export const FETCH_FIRM_FAILURE = 'firm/firm/FETCH_FIRM_FAILURE';

export const UPDATE_FIRM_REQUEST = 'firm/firm/UPDATE_FIRM_REQUEST';
export const UPDATE_FIRM_SUCCESS = 'firm/firm/UPDATE_FIRM_SUCCESS';
export const UPDATE_FIRM_FAILURE = 'firm/firm/UPDATE_FIRM_FAILURE';

export const FETCH_FIRM_PAIRS_REQUEST = 'firm/firm/FETCH_FIRM_PAIRS_REQUEST';
export const FETCH_FIRM_PAIRS_SUCCESS = 'firm/firm/FETCH_FIRM_PAIRS_SUCCESS';
export const FETCH_FIRM_PAIRS_FAILURE = 'firm/firm/FETCH_FIRM_PAIRS_FAILURE';

export const UPDATE_FIRM_DELIVERY_ADDRESSES_FILTER_DATA = 'firm/firm/UPDATE_FIRM_DELIVERY_ADDRESSES_FILTER_DATA';
export const COMPANY_PRODUCTS_UPDATED = 'firm/firm/COMPANY_PRODUCTS_UPDATED';
