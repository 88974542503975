import PropTypes from 'prop-types';
import React from 'react';
import styles from './CardLayout.scss';


const CardLayout = (props) => {
  let content = null;

  if (props.type === 'columns') {
    content = (
      <div className={styles.columns}>
        <div className={styles.columnLeft}>
          <img src={props.imageFileName} className={styles.image} width={144} height={144} alt="" />
        </div>
        <div className={styles.columnRight}>
          {props.children.map((item, i) => (
            <div key={i} className={styles.columnsItem}>
              {item}
            </div>
          ))}
        </div>
      </div>
    );
  } else if (props.type === 'form') {
    content = (
      <div className={styles.form}>
        {props.children}
      </div>
    );
  }

  return content;
};

CardLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  cta: PropTypes.node,
  imageFileName: PropTypes.string,
  type: PropTypes.oneOf(['columns', 'form']).isRequired,
};


export default CardLayout;
