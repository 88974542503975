import { RSAA } from 'redux-api-middleware';
import { API_URL } from '../../../config/envspecific';
import { t } from '../../../i18n';
import * as types from './actionTypes';
import downloadFile from '../../../service/downloadFile';

export const fetchContractPairs = (filter) => {
  let queryString = '';
  if (filter && 'c01_company_id' in filter) {
    queryString = `filter=c01_company_id:${filter.c01_company_id}`;
  }

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/firms_contracts?${queryString}`,
      method: 'GET',
      types: [
        types.FETCH_CONTRACT_PAIRS_REQUEST,
        types.FETCH_CONTRACT_PAIRS_SUCCESS,
        types.FETCH_CONTRACT_PAIRS_FAILURE,
      ],
    },
  });
};

export const fetchContracts = (filter) => {
  let queryString = '';
  if (filter && 'c01_company_id' in filter) {
    queryString = `?filter=c01_company_id:${filter.c01_company_id}`;
  }

  return ({
    [RSAA]: {
      endpoint: `${API_URL}/firms_contracts${queryString}`,
      method: 'GET',
      types: [
        types.FETCH_CONTRACT_REQUEST,
        types.FETCH_CONTRACT_SUCCESS,
        types.FETCH_CONTRACT_FAILURE,
      ],
    },
  });
};

export const downloadContractPdf = (contractId, number) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/firms_contracts/${contractId}/pdf`,
    method: 'GET',
    types: [
      types.DOWNLOAD_CONTRACT_PDF_REQUEST,
      {
        type: types.DOWNLOAD_CONTRACT_PDF_SUCCESS,
        payload: (action, state, res) => res.blob().then(
          (blob) => downloadFile(blob, t('KartaUp_smlouva_{{number}}.pdf', { number, ns: 'firm' }))
        ),
      },
      types.DOWNLOAD_CONTRACT_PDF_FAILURE,
    ],
  },
});
