import { RSAA } from 'redux-api-middleware';
import { addFlashMessage } from '../modules/flashMessages/actions';
import { lastApiAction } from './apiRSAAInjectorFactory';

const apiError500MiddlewareFactory = (t) => (store) => (next) => (action) => {
  if (typeof action.payload !== 'undefined' && action.payload.status === 500) {
    let logLastApiAction = lastApiAction;

    if (lastApiAction && lastApiAction[RSAA] && typeof lastApiAction[RSAA].body === 'string' && lastApiAction[RSAA].body.includes('password')) {
      const body = JSON.parse(lastApiAction[RSAA].body);
      logLastApiAction = {
        ...lastApiAction,
        [RSAA]: {
          ...lastApiAction[RSAA],
          body: JSON.stringify({
            ...body,
            password: '*******',
          }),
        },
      };
    }

    const details = {
      action, lastApi: logLastApiAction, version: process.env.VERSION, userAgent: window.navigator.userAgent,
    };

    store.dispatch(addFlashMessage('error', t('Došlo k chybě při komunikaci se serverem.'), undefined, undefined, JSON.stringify(details)));
    return next(action);
  }

  return next(action);
};

export default apiError500MiddlewareFactory;
