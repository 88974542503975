import { browserHistory } from 'react-router';
import { RSAA } from 'redux-api-middleware';
import { API_URL, MOBILE_APP } from '../../config/envspecific';
import { t, tRoute } from '../../i18n';
import { authFactory, clearLoginSession } from './Auth';
import * as types from './actionTypes';
import { addFlashMessage, removeFlashMessageGroup } from '../flashMessages/actions';
import {
  setOrderFileId,
  setOrderInProgress,
  selectContract,
} from '../order/actions';
import {
  setMyOrderInProgress,
} from '../my/resources/order';
import { updateAuthorizedPages } from '../../cordova/mobileApp';
import { changeLanguage } from '../global';
import { requestBenefiaLoginInfo } from '../benefia/actions';


const setUserData = (userData, userId, theme) => ({
  type: types.SET_LOGIN_INFO,
  payload: { ...userData, theme, u01_user_id: userId },
});

const setAuthorizedPages = (userPages) => ({
  type: types.SET_AUTHORIZED_PAGES,
  payload: userPages,
});

const setContractRights = (contractRights) => ({
  type: types.SET_CONTRACT_RIGHTS,
  payload: contractRights,
});

export const storeLoginInfoData = (dispatch, cardPayload, benefiaPayload) => {
  const pages = benefiaPayload?.pages?.length ? cardPayload.pages.concat(benefiaPayload.pages) : cardPayload.pages;

  dispatch(removeFlashMessageGroup('login_failures'));
  dispatch(changeLanguage(cardPayload.data.x20_code));
  dispatch(setUserData(cardPayload.data, cardPayload.u01_user_id, cardPayload.theme));
  dispatch(setAuthorizedPages(MOBILE_APP ? updateAuthorizedPages(pages) : pages));
  dispatch(setContractRights(cardPayload.contr_rights));
};

/**
 * Used for fetching login info when auth token already exists (session login)
 */
export const requestLoginInfo = () => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/login`,
    method: 'GET',
    types: [
      types.FETCH_LOGIN_DATA_REQUEST,
      types.FETCH_LOGIN_DATA_SUCCESS,
      types.FETCH_LOGIN_DATA_FAILURE,
    ],
  },
}).then(async (response) => {
  if (!response || response.error) {
    return null;
  }

  const benefiaPayload = await dispatch(requestBenefiaLoginInfo());
  storeLoginInfoData(dispatch, response.payload, benefiaPayload);

  return response.payload;
});

/** Sets all auth data after login or after login data changed */
export const logUserIn = async (payload, dispatch, redirect = '/') => {
  const auth = authFactory();

  if (auth.isLoggedIn()) {
    auth.logout();
  }
  auth.login(payload.sid);

  const benefiaPayload = await dispatch(requestBenefiaLoginInfo());
  storeLoginInfoData(dispatch, payload, benefiaPayload);

  if (payload.data.pending_my_order.has_pending_order) {
    dispatch(setMyOrderInProgress(true));
  }

  if (payload.data.pending_order.has_pending_order) {
    dispatch(setOrderFileId(parseInt(payload.data.pending_order.o50_order_file, 10)));
    dispatch(setOrderInProgress(payload.data.pending_order.has_pending_order));
    dispatch(selectContract({
      c01_name: payload.data.pending_order.c01_name,
      c10_contract_id: payload.data.pending_order.c10_contract_id,
      c01_company_id: payload.data.pending_order.c01_company_id,
    }));
  }

  if (redirect) {
    browserHistory.push((redirect === true ? window.landingPathname : redirect) || '/');
  }
};

export const requestLogUserIn = (user, redirect = null) => (dispatch) => {
  dispatch({
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${API_URL}/login`,
      method: 'POST',
      body: JSON.stringify({ username: user.username, password: user.password }),
      types: [types.LOGIN_REQUEST, types.LOGIN_SUCCESS, types.LOGIN_FAILURE],
    },
  }).then(async (res) => {
    if (res.type === types.LOGIN_SUCCESS) {
      await logUserIn(res.payload, dispatch, redirect);
      return;
    }

    dispatch(addFlashMessage('error', t('Přihlášení se nezdařilo.', { ns: 'auth' }), 'login_failures'));
  });
};

const logUserInAsUser = async (payload, dispatch, redirect = '/') => {
  // const userPages = payload.pages;
  const auth = authFactory();

  if (auth) {
    auth.logout();
  }

  await logUserIn(payload, dispatch, redirect);
};

export const requestLogUserInAsUser = (hash) => (dispatch) => {
  dispatch({
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${API_URL}/login/as_user`,
      method: 'POST',
      body: JSON.stringify({ u90_hash: hash }),
      types: [
        types.LOGIN_AS_USER_REQUEST,
        types.LOGIN_AS_USER_SUCCESS,
        types.LOGIN_AS_USER_FAILURE,
      ],
    },
  }).then(async (res) => {
    if (res.type === types.LOGIN_AS_USER_SUCCESS) {
      await logUserInAsUser(res.payload, dispatch);
      return;
    }

    dispatch(addFlashMessage('error', t('Přihlášení za uživatele se nezdařilo.', { ns: 'auth' }), 'login_failures'));
  });
};

export const requestLogUserOutFactory = (localStorage, location, history) => (dispatch) => {
  dispatch({
    [RSAA]: {
      headers: { Sid: localStorage.auth_token },
      endpoint: `${API_URL}/logout`,
      method: 'GET',
      types: [types.LOGOUT_REQUEST, types.LOGOUT_SUCCESS, types.LOGOUT_FAILURE],
    },
  }).then(() => {
    clearLoginSession();
    history.push(tRoute('/prihlaseni'));
    if (!MOBILE_APP) {
      // do not reload on mobile (it leads to open index.html instead of xxx-app.html)
      // TODO: if needed, use device.platform for right index page detection
      location.reload(true);
    }
  });
};

export const requestRemindPassword = (data) => (dispatch) => {
  dispatch({
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${API_URL}/login/remind_password`,
      method: 'POST',
      body: JSON.stringify(data),
      types: [
        types.REMIND_PASSWORD_REQUEST,
        types.REMIND_PASSWORD_SUCCESS,
        types.REMIND_PASSWORD_FAILURE,
      ],
    },
  }).then((response) => {
    if (response.type === types.REMIND_PASSWORD_SUCCESS) {
      dispatch(addFlashMessage('success', t('Poslali jsme vám na Váši emailovou adresu zprávu. Stačí kliknout na link a heslo si obnovit.', { ns: 'auth' })));
    } else if (response.type === types.REMIND_PASSWORD_FAILURE) {
      dispatch(addFlashMessage('error', response.payload.response.message));
    }
  });
};

export const clearRemindPassword = () => ({
  type: types.CLEAR_REMIND_PASSWORD,
});

export const requestResetPassword = (data, redirect = '/') => (dispatch) => {
  dispatch({
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${API_URL}/login/reset_password`,
      method: 'POST',
      body: JSON.stringify(data),
      types: [
        types.RESET_PASSWORD_REQUEST,
        types.RESET_PASSWORD_SUCCESS,
        types.RESET_PASSWORD_FAILURE,
      ],
    },
  }).then(async (response) => {
    const { payload, type } = response;

    if (type === types.RESET_PASSWORD_FAILURE) {
      return dispatch(addFlashMessage('error', payload.response.message));
    }

    if (type === types.RESET_PASSWORD_SUCCESS) {
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload,
      });
      await logUserIn(payload, dispatch, redirect);
    }

    return false;
  });
};

export const loginHashCheck = (hash) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `/login/hash_check/${hash}`,
    method: 'GET',
    types: [
      types.HASH_CHECK_REQUEST,
      types.HASH_CHECK_SUCCESS,
      types.HASH_CHECK_FAILURE,
    ],
  },
}).then((action) => {
  if (action.error) {
    dispatch(addFlashMessage(action.payload.response && action.payload.response.code === '0125' ? 'warning' : 'error', action.payload.response && action.payload.response.message));
  }
  return action;
});
