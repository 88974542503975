import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import Icon from '../Icon/Icon';
import styles from './FlatButton.scss';


const FlatButton = (props) => {
  let buttonElement;
  let buttonClassName;
  let disabled = props.disabled;
  let iconElement;
  let labelElement = null;

  if (props.icon) {
    iconElement = (
      <span className={styles.buttonIcon}>
        <Icon icon={props.icon} size={props.isSmall ? 'small' : null} tooltip={props.tooltip} />
      </span>
    );
  }

  if (props.label) {
    labelElement = <span className={styles.buttonLabel}>{props.label}</span>;
  }

  if (props.isLoading) {
    disabled = true;
    labelElement = <span className={styles.buttonLabel}>Načítání…</span>;
  }

  if (props.size === 'small') {
    buttonClassName = styles.isButtonSmall;

    if (props.primary) {
      buttonClassName = styles.isButtonPrimarySmall;
    }
  } else if (props.size === 'large') {
    buttonClassName = styles.isButtonLarge;

    if (props.primary) {
      buttonClassName = styles.isButtonPrimaryLarge;
    }
  } else {
    buttonClassName = styles.button;

    if (props.primary) {
      buttonClassName = styles.isButtonPrimary;
    }
    if (props.simple) {
      buttonClassName = styles.isButtonSimple;
    }
  }

  if (props.externalHref) {
    buttonElement = (
      <a
        href={props.externalHref}
        className={buttonClassName}
        rel="noopener noreferrer"
        target={props.externalHref.startsWith('tel:') || props.externalHref.startsWith('mailto:')
          ? '_self'
          : '_blank'}
      >
        {(props.labelPosition && props.labelPosition === 'before') && labelElement}
        {iconElement}
        {(!props.labelPosition || props.labelPosition === 'after') && labelElement}
      </a>
    );
  } else {
    /* eslint-disable react/button-has-type */
    buttonElement = (
      <button
        type={props.type}
        disabled={disabled}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
          if (props.internalHref) {
            browserHistory.push(props.internalHref);
          }
        }}
        className={buttonClassName}
      >
        {(props.labelPosition && props.labelPosition === 'before') && labelElement}
        {iconElement}
        {(!props.labelPosition || props.labelPosition === 'after') && labelElement}
      </button>
    );
  }

  return buttonElement;
};

FlatButton.defaultProps = {
  type: 'button',
};

FlatButton.propTypes = {
  disabled: PropTypes.bool,
  externalHref: PropTypes.string,
  icon: PropTypes.string,
  internalHref: PropTypes.string,
  isLoading: PropTypes.bool,
  primary: PropTypes.bool,
  label: PropTypes.string.isRequired,
  simple: PropTypes.bool,
  labelPosition: PropTypes.oneOf(['before', 'after']),
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  type: PropTypes.oneOf(['submit', 'button']),
};

export default FlatButton;
