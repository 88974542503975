import Immutable from 'immutable';
import * as types from './actionTypes';

const contractNotConfirmedResponse = (payload) => payload.status === 400 && payload.response.code === '0146';

const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['my', 'card']);
  }

  if (type === types.FETCH_BASKET_REQUEST) {
    return state
      .setIn(['basket', 'isFetching'], true)
      .setIn(['basket', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_BASKET_SUCCESS) {
    return state
      .setIn(['basket', 'isFetching'], false)
      .setIn(['basket', 'isFetchingFailure'], false)
      .setIn(['basket', 'data'], Immutable.fromJS(payload));
  }

  if (type === types.FETCH_BASKET_FAILURE) {
    return state
      .setIn(['basket', 'isFetching'], false)
      .setIn(['basket', 'isFetchingFailure'], true)
      .set('contractNotConfirmed', contractNotConfirmedResponse(payload));
  }

  if (type === types.SET_OPEN_ADDCARD) {
    return state
      .setIn(['basket', 'openAddCard'], payload);
  }

  if (type === types.FETCH_CREDITS_REQUEST) {
    return state
      .setIn(['credits', 'isFetching'], true)
      .setIn(['credits', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_CREDITS_SUCCESS) {
    return state
      .setIn(['credits', 'isFetching'], false)
      .setIn(['credits', 'isFetchingFailure'], false)
      .setIn(['credits', 'data'], Immutable.fromJS(payload));
  }

  if (type === types.FETCH_CREDITS_FAILURE) {
    return state
      .setIn(['credits', 'isFetching'], false)
      .setIn(['credits', 'isFetchingFailure'], true)
      .set('contractNotConfirmed', contractNotConfirmedResponse(payload));
  }

  if (type === types.UNSET_CREDITS) {
    return state.setIn(['credits', 'data'], Immutable.fromJS([]));
  }

  if (type === types.FETCH_BASKET_OVERVIEW_REQUEST) {
    return state
      .setIn(['basketOverview', 'isFetching'], true)
      .setIn(['basketOverview', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_BASKET_OVERVIEW_SUCCESS) {
    return state
      .setIn(['basketOverview', 'isFetching'], false)
      .setIn(['basketOverview', 'isFetchingFailure'], false)
      .setIn(['basketOverview', 'data'], Immutable.fromJS(payload));
  }

  if (type === types.FETCH_BASKET_OVERVIEW_FAILURE) {
    return state
      .setIn(['basketOverview', 'isFetching'], false)
      .setIn(['basketOverview', 'isFetchingFailure'], true);
  }

  if (type === types.CONFIRM_ORDER_REQUEST) {
    return state.setIn(['basketOverview', 'isConfirmingOrder'], true);
  }

  if (type === types.CONFIRM_ORDER_SUCCESS) {
    return state
    // probably unused part of store, proof it and remove completely
    // .setIn(['basketOverview', 'paymentInfo'], Immutable.fromJS(payload))
      .setIn(['basketOverview', 'isConfirmingOrder'], false);
  }

  if (type === types.CONFIRM_ORDER_FAILURE) {
    return state.setIn(['basketOverview', 'isConfirmingOrder'], false);
  }

  if (type === types.DELETE_ORDER_REQUEST) {
    return state.set('isDeletingOrder', true);
  }

  if (type === types.DELETE_ORDER_SUCCESS) {
    return state.set('isDeletingOrder', false);
  }

  if (type === types.DELETE_ORDER_FAILURE) {
    return state.set('isDeletingOrder', false);
  }

  if (type === types.UPDATE_BASKET_PARAMETERS_REQUEST) {
    return state
      .setIn(['basketOverview', 'data', 'payments', 'i04_payment_type_id'], payload.i04_payment_type_id);
  }

  if (type === types.FETCH_CARDS_REQUEST) {
    return state
      .setIn(['cardList', 'isFetching'], true)
      .setIn(['cardList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_CARDS_SUCCESS) {
    return state
      .setIn(['cardList', 'isFetching'], false)
      .setIn(['cardList', 'isFetchingFailure'], false)
      .setIn(['cardList', 'cards'], Immutable.fromJS(payload));
  }

  if (type === types.FETCH_CARDS_FAILURE) {
    return state
      .setIn(['cardList', 'isFetching'], false)
      .setIn(['cardList', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_ASSIGNED_CARDS_REQUEST) {
    return state
      .setIn(['creditCardOverview', 'isFetching'], true)
      .setIn(['creditCardOverview', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_ASSIGNED_CARDS_SUCCESS) {
    const { cards, ...widget } = payload;
    return state
      .setIn(['creditCardOverview', 'isFetching'], false)
      .setIn(['creditCardOverview', 'isFetchingFailure'], false)
      .setIn(['creditCardOverview', 'cards'], Immutable.fromJS(cards))
      .setIn(['creditCardOverview', 'widget'], Immutable.fromJS(widget));
  }

  if (type === types.FETCH_ASSIGNED_CARDS_FAILURE) {
    return state
      .setIn(['creditCardOverview', 'isFetching'], false)
      .setIn(['creditCardOverview', 'isFetchingFailure'], true);
  }

  if (type === types.CONFIRM_CONTRACT_SUCCESS) {
    return state
      .set('contractNotConfirmed', false);
  }

  return state;
};


export default reducerFactory;
