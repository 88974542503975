import Immutable from 'immutable';
import * as types from './actionTypes';


export const userSettingsReducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['user', 'settings']);
  }

  if (type === types.FETCH_USER_COMMUNICATION_REQUEST) {
    return state
      .setIn(['communication', 'isFetchingFailure'], false)
      .setIn(['communication', 'isFetching'], true);
  }

  if (type === types.FETCH_USER_COMMUNICATION_SUCCESS) {
    return state
      .setIn(['communication', 'items'], Immutable.fromJS(payload.items ? payload.items.map((i) => ({
        ...i,
        e34_enabled: !!i.e34_enabled,
      })) : []))
      .setIn(['communication', 'isFetching'], false);
  }

  if (type === types.FETCH_USER_COMMUNICATION_FAILURE) {
    return state
      .setIn(['communication', 'isFetching'], false)
      .setIn(['communication', 'isFetchingFailure'], true);
  }

  if (type === types.SAVE_USER_COMMUNICATION_SUCCESS) {
    const idx = state.getIn(['communication', 'items']).findIndex((w) => w.get('e02_email_type_id') === action.payload.e02_email_type_id);
    if (idx === -1) {
      return state;
    }
    return state.setIn(['communication', 'items', idx, 'e34_enabled'], action.payload.e34_enabled)
      .setIn(['communication', 'items', idx, 'param_value'], action.payload.param_value);
  }

  return state;
};
