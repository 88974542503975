import PropTypes from 'prop-types';
import React from 'react';
import { Card } from '../../../../component1/Card';
import { widgetMinHeight } from '../../../global/constants';
import FlatButton from '../../../../component1/FlatButton/FlatButton';
import styles from './ContributionOldEmissionWidget.scss';

type Props = PropTypes.InferProps<typeof ContributionOldEmissionWidget.propTypes>;

class ContributionOldEmissionWidget extends React.Component<Props> {
  state = {
    isConfirmed: false,
  };

  render() {
    const { t } = this.props;
    const { isConfirmed } = this.state;
    const imgSrc = '/images/logo-RP-dark.svg';

    return (
      <Card
        layout="fluid"
        title={t('Rekreační poukaz {{oldYear}}', { oldYear: 2020 })}
        actions={[
          !isConfirmed && (
          <FlatButton
            key={1}
            label={t('Mám zájem')}
            onClick={() => this.setState({ isConfirmed: true })}
          />
          ),
          isConfirmed && (
          <FlatButton
            key={2}
            label={t('Zavřít')}
            onClick={() => this.props.onClose()}
          />
          ),
        ]}
        minHeight={widgetMinHeight}
        bodyClassName={styles.cardBody}
        crossTitle={t('Skrýt dlaždici')}
        onClose={this.props.onClose}
      >
        <img className={styles.img} src={imgSrc} alt="" />
        <p
          style={{ marginTop: 15 }}
            // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: isConfirmed
              ? t('Výborně! Při objednávce rekreačního poukazu budete mít vybranou možnost "Použít na úhradu loňský zůstatek".', { year: 2021 })
              : t('Pro úhradu rekreačního poukazu pro rok {{year}} můžete použít 45% z nevyčerpané částky loňského poukazu.', { year: 2021 }),
          }}
        />
      </Card>
    );
  }

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    data: PropTypes.shape({
      amount: PropTypes.number.isRequired,
    }),
    t: PropTypes.func.isRequired,
  };
}

export default ContributionOldEmissionWidget;
