import React, { useCallback, useMemo, useState } from 'react';
import { FormModel, TranslateFn } from '../../../../global/model';
import Dialog from '../../../../../component1/Dialog/Dialog';
import { BenefiaOrderValue } from '../BenefiaOrderWidget';
import SelectField from '../../../../../component1/SelectField/SelectField';
import { moneyFormat } from '../../../../../service/number';
import TextField from '../../../../../component1/TextField/TextField';
import * as validation from '../../../../../service/validation';

export const BenefiaOrderDialog: React.FC<{ value: BenefiaOrderValue, setValue(val: BenefiaOrderValue): void, onOrder(): Promise<boolean>, onClose(): void, t: TranslateFn }> = ({
  value, setValue, onOrder, onClose, t,
}) => {
  const upCadhocFaceValuesPairs = useMemo(() => [5, 10, 20].map((v) => ({ label: moneyFormat(v, t), value: v })), [t]);
  const [form, setForm] = useState({
    isValid: false,
    elements: {
      faceCount: {
        errorText: '',
        rules: [validation.required],
      },
    },
  } as FormModel);

  const onSubmit = useCallback(() => {
    if (validation.validateForm(value, form, setForm)) {
      return onOrder();
    }
    return false;
  }, [value, form, onOrder]);

  return (
    <Dialog
      onHandleClose={onClose}
      title={t('Objednanie benefitu – UpCadhoc')}
      actions={[{
        label: t('Zrušit'),
        onClick: () => {
        },
      }, {
        primary: true,
        label: t('Objednať benefit'),
        onClick: onSubmit,
      }]}
      hasCross
      forceOpen
      isNarrow
    >
      <SelectField
        label={t('Hodnota benefitu')}
        fieldId="faceValue"
        options={upCadhocFaceValuesPairs}
        onChange={(val: any) => setValue({ ...value, faceValue: (+val) || null })}
        value={value.faceValue || ''}
      />
      <TextField
        fieldId="faceCnt"
        type="number"
        label={t('Počet poukážok')}
        onChange={(e: any, val: any) => setValue({ ...value, faceCount: (+val) || null })}
        value={value.faceCount}
        errorText={form.elements.faceCount.errorText}
      />
      <p>
        {t('Kredit použitý na nákup UpCadhoc poukážok podlieha odvodom a daniam.')}
      </p>
    </Dialog>
  );
};
