export const inflect = (count, words) => {
  const absCount = Math.abs(count);

  if (absCount === 1) {
    return words[0];
  } if (absCount > 1 && absCount < 5) {
    return words[1];
  }

  return words[2];
};

export default inflect;
