import Immutable from 'immutable';
import * as types from './actionTypes';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['registration', 'firstLogin']);
  }

  if (type === types.REGISTER_CARD_REQUEST) {
    return state
      .setIn(['registerCard', 'isPosting'], true)
      .setIn(['registerCard', 'isPostingFailure'], false);
  }

  if (type === types.REGISTER_CARD_SUCCESS) {
    return state
      .setIn(['registerCard', 'data'], Immutable.fromJS(payload))
      .setIn(['registerCard', 'isPosting'], false);
  }

  if (type === types.REGISTER_CARD_FAILURE) {
    return state
      .setIn(['registerCard', 'isPosting'], false)
      .setIn(['registerCard', 'isPostingFailure'], true);
  }

  if (type === types.REGISTER_CARD_WITH_EMAIL_REQUEST) {
    return state
      .setIn(['registerCardWithEmail', 'isPosting'], true)
      .setIn(['registerCardWithEmail', 'isPostingFailure'], false);
  }

  if (type === types.REGISTER_CARD_WITH_EMAIL_SUCCESS) {
    return state
      .setIn(['registerCard', 'data'], Immutable.fromJS(payload))
      .setIn(['registerCardWithEmail', 'isPosting'], false);
  }

  if (type === types.REGISTER_CARD_WITH_EMAIL_FAILURE) {
    return state
      .setIn(['registerCardWithEmail', 'isPosting'], false)
      .setIn(['registerCardWithEmail', 'isPostingFailure'], true);
  }

  return state;
};


export default reducerFactory;
