import { RSAA } from 'redux-api-middleware';
import { browserHistory } from 'react-router';
import { API_URL } from '../../../../config/envspecific';
import { t, tRoute } from '../../../../i18n';
import * as types from './actionTypes';
import { addFlashMessage } from '../../../flashMessages/actions';
import { setMyOrderInProgress } from '../order';
import { logUserIn } from '../../../auth/actions';
import { apiErrorMessageHandler } from '../../../../service/helpers';
import { handleApiActionError } from '../../../home1/actions';
import { BANK_CARD_ASSIGNED_OK } from '../../../../data/constants';

export const activateCard = (cardId, cardNumber) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_cards/activate_by_num`,
    method: 'PUT',
    body: JSON.stringify({
      p20_card_id: cardId,
      card_no: cardNumber,
    }),
    types: [
      types.ACTIVATE_CARD_REQUEST,
      {
        type: types.ACTIVATE_CARD_SUCCESS,
        payload: (action, state, res) => {
          dispatch(addFlashMessage('success', t('Aktivace karty proběhla úspěšně.', { ns: 'my' })));
          return res.json().then((json) => json);
        },
      },
      {
        type: types.ACTIVATE_CARD_FAILURE,
        payload: (action, state, res) => {
          res.json().then((response) => {
            dispatch(addFlashMessage('error', response.message));
          });
          return res.json().then((json) => json);
        },
      },
    ],
  },
});


export const blockCard = (cardId) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_cards/set_status`,
    method: 'PUT',
    body: JSON.stringify({
      p20_card_id: cardId,
      operation: 'block',
    }),
    types: [
      types.BLOCK_CARD_REQUEST,
      types.BLOCK_CARD_SUCCESS,
      {
        type: types.BLOCK_CARD_FAILURE,
        payload: (action, state, res) => {
          res.json().then((response) => {
            dispatch(addFlashMessage('error', response.message));
          });
        },
      },
    ],
  },
});

export const unblockCard = (cardId) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_cards/set_status`,
    method: 'PUT',
    body: JSON.stringify({
      p20_card_id: cardId,
      operation: 'unblock',
    }),
    types: [
      types.BLOCK_CARD_REQUEST,
      types.BLOCK_CARD_SUCCESS,
      {
        type: types.BLOCK_CARD_FAILURE,
        payload: (action, state, res) => {
          res.json().then((response) => {
            dispatch(addFlashMessage('error', response.message));
          });
        },
      },
    ],
  },
});

export const renewCard = (userId, cardId) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_cards/set_status`,
    method: 'PUT',
    body: JSON.stringify({
      p20_card_id: cardId,
      operation: 'reissue',
    }),
    types: [
      {
        type: types.RENEW_CARD_REQUEST,
        payload: () => ({ userId }),
      },
      {
        type: types.RENEW_CARD_SUCCESS,
        payload: () => {
          browserHistory.push(tRoute('/moje/objednavka/karty'));

          return { userId };
        },
      },
      {
        type: types.RENEW_CARD_FAILURE,
        payload: (action, state, res) => {
          res.json().then((response) => {
            dispatch(addFlashMessage('error', response.message));
          });

          return ({ userId });
        },
      },
    ],
  },
});

export const fetchBasket = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/my_basket/cards`,
    method: 'GET',
    types: [
      types.FETCH_BASKET_REQUEST,
      types.FETCH_BASKET_SUCCESS,
      types.FETCH_BASKET_FAILURE,
    ],
  },
});

export const requestConfirmBasket = (basket) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_basket/cards`,
    method: 'PUT',
    body: JSON.stringify(basket),
    types: [
      types.CONFIRM_BASKET_REQUEST,
      {
        type: types.CONFIRM_BASKET_SUCCESS,
        payload: (action, state, res) => {
          dispatch(setMyOrderInProgress(true));

          return res.json();
        },
      },
      types.CONFIRM_BASKET_FAILURE,
    ],
  },
});

export const fetchCredits = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/my_basket/credits`,
    method: 'GET',
    types: [
      types.FETCH_CREDITS_REQUEST,
      types.FETCH_CREDITS_SUCCESS,
      types.FETCH_CREDITS_FAILURE,
    ],
  },
});

export const unsetCredits = () => ({
  type: types.UNSET_CREDITS,
});

export const setOpenAddCard = (open) => ({
  type: types.SET_OPEN_ADDCARD,
  payload: open,
});

export const requestConfirmCredits = (basket) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_basket/credits`,
    method: 'PUT',
    body: JSON.stringify(basket),
    types: [
      types.CONFIRM_CREDITS_REQUEST,
      {
        type: types.CONFIRM_CREDITS_SUCCESS,
        payload: (action, state, res) => {
          dispatch(setMyOrderInProgress(true));

          return res.json();
        },
      },
      types.CONFIRM_CREDITS_FAILURE,
    ],
  },
});

export const fetchBasketOverview = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/my_basket/overview`,
    method: 'GET',
    types: [
      types.FETCH_BASKET_OVERVIEW_REQUEST,
      types.FETCH_BASKET_OVERVIEW_SUCCESS,
      types.FETCH_BASKET_OVERVIEW_FAILURE,
    ],
  },
});

export const requestUpdateBasketParameters = (parameters) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_basket/parameters`,
    method: 'PUT',
    body: JSON.stringify(parameters),
    types: [
      {
        type: types.UPDATE_BASKET_PARAMETERS_REQUEST,
        payload: () => (parameters),
      },
      {
        type: types.UPDATE_BASKET_PARAMETERS_SUCCESS,
        payload: (action, state, res) => {
          dispatch(setMyOrderInProgress(true));

          return res.json();
        },
      },
      types.UPDATE_BASKET_PARAMETERS_FAILURE,
    ],
  },
});

export const saveBasketAddress = (data) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: 'my_basket/address',
    method: 'PUT',
    body: JSON.stringify(data),
    types: [
      types.SAVE_BASKET_ADDRESS_REQUEST,
      types.SAVE_BASKET_ADDRESS_SUCCESS,
      types.SAVE_BASKET_ADDRESS_FAILURE,
    ],
  },
});

export const requestConfirmOrder = () => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_basket`,
    method: 'POST',
    body: JSON.stringify({}),
    types: [
      types.CONFIRM_ORDER_REQUEST,
      {
        type: types.CONFIRM_ORDER_SUCCESS,
        payload: (action, state, res) => {
          dispatch(setMyOrderInProgress(false));

          return res.json();
        },
      },
      types.CONFIRM_ORDER_FAILURE,
    ],
  },
});

export const requestDeleteOrder = () => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/my_basket`,
    method: 'DELETE',
    types: [
      types.DELETE_ORDER_REQUEST,
      {
        type: types.DELETE_ORDER_SUCCESS,
        payload: (action, state, res) => {
          dispatch(setMyOrderInProgress(false));

          return res.json();
        },
      },
      types.DELETE_ORDER_FAILURE,
    ],
  },
});

export const fetchCards = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/my_cards_list`,
    method: 'GET',
    types: [
      types.FETCH_CARDS_REQUEST,
      types.FETCH_CARDS_SUCCESS,
      types.FETCH_CARDS_FAILURE,
    ],
  },
});

export const fetchAssignedCards = () => ({
  [RSAA]: {
    endpoint: 'bank_card_assign',
    method: 'GET',
    types: [
      types.FETCH_ASSIGNED_CARDS_REQUEST,
      types.FETCH_ASSIGNED_CARDS_SUCCESS,
      types.FETCH_ASSIGNED_CARDS_FAILURE,
    ],
  },
});

export const contractConfirmation = () => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_contracts`,
    method: 'POST',
    body: JSON.stringify({}),
    types: [
      types.CONFIRM_CONTRACT_REQUEST,
      types.CONFIRM_CONTRACT_SUCCESS,
      types.CONFIRM_CONTRACT_FAILURE,
    ],
  },
}).then(apiErrorMessageHandler(dispatch))
  .then(async (response) => {
    if (response.type === types.CONFIRM_CONTRACT_SUCCESS) {
      await logUserIn(response.payload, dispatch, null);
    }

    return response;
  });

export const saveLimitChange = (data) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_cards/limits`,
    method: 'PUT',
    body: JSON.stringify(data),
    types: [
      types.SAVE_CARD_LIMIT_REQUEST,
      types.SAVE_CARD_LIMIT_SUCCESS,
      types.SAVE_CARD_LIMIT_FAILURE,
      // {
      //   type: types.SAVE_CARD_LIMIT_FAILURE,
      //   payload: (action, state, res) => {
      //     res.json().then((response) => {
      //       dispatch(addFlashMessage('error', response.message));
      //     });
      //     return res.json().then(json => json);
      //   },
      // },
    ],
  },
});

export const transferCredit = (cardFrom, cardTo, balances) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_credits/transfer/${cardFrom}/${cardTo}`,
    method: 'PUT',
    body: JSON.stringify({ balances }),
    types: [
      types.SAVE_CREDIT_TRANSFER_REQUEST,
      types.SAVE_CREDIT_TRANSFER_SUCCESS,
      {
        type: types.SAVE_CREDIT_TRANSFER_FAILURE,
        payload: (action, state, res) => {
          res.json().then((response) => {
            dispatch(addFlashMessage('error', response.message));
          });
          return res.json().then((json) => json);
        },
      },
    ],
  },
});

export const checkCreditTransfer = (cardFrom, cardTo) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/my_credits/transfer/${cardFrom}/${cardTo}`,
    method: 'GET',
    types: [
      types.CHECK_CREDIT_TRANSFER_REQUEST,
      types.CHECK_CREDIT_TRANSFER_SUCCESS,
      {
        type: types.CHECK_CREDIT_TRANSFER_FAILURE,
        payload: (action, state, res) => {
          res.json().then((response) => {
            dispatch(addFlashMessage('error', response.message));
          });
          return res.json().then((json) => json);
        },
      },
    ],
  },
});

const saveCardAutoCreditProduct = (cardId, productCode, minBalance, creditAmount) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: 'my_cards/auto_credit',
    method: 'PUT',
    body: JSON.stringify({
      p20_card_id: cardId,
      s20_code: productCode,
      p65_min_balance: minBalance,
      p65_credit_amount: creditAmount,
    }),
    types: [
      types.SAVE_CARD_AUTO_CREDIT_REQUEST,
      types.SAVE_CARD_AUTO_CREDIT_SUCCESS,
      types.SAVE_CARD_AUTO_CREDIT_FAILURE,
    ],
  },
});
// .then(apiErrorMessageHandler(dispatch));

export const saveCardAutoCredit = (cardId, settings, showSuccessMessage) => async (dispatch) => {
  const ret = await Promise.all(settings.map((set) => dispatch(saveCardAutoCreditProduct(cardId, set.productCode, set.minBalance, set.creditAmount))));
  const error = ret.find((a) => a.error);

  if (error) {
    apiErrorMessageHandler(dispatch)(error);
    return error;
  }
  if (showSuccessMessage) {
    dispatch(addFlashMessage('success', t('Nastavení dobíjení karty bylo uloženo.', { ns: 'my' })));
  }
  return ret[0];
};

export const createBankCardAssign = (conditionsConfirmed) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: 'bank_card_assign',
    method: 'PUT',
    body: JSON.stringify({
      conditions_confirmed: conditionsConfirmed ? 1 : 0,
    }),
    types: [
      types.BANK_CARD_ASSIGN_REQUEST,
      types.BANK_CARD_ASSIGN_SUCCESS,
      types.BANK_CARD_ASSIGN_FAILURE,
    ],
  },
}).then(apiErrorMessageHandler(dispatch));

export const removeBankCardAssign = () => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: 'bank_card_assign',
    method: 'DELETE',
    types: [
      types.BANK_CARD_ASSIGN_REQUEST,
      types.BANK_CARD_ASSIGN_SUCCESS,
      types.BANK_CARD_ASSIGN_FAILURE,
    ],
  },
}).then(apiErrorMessageHandler(dispatch))
  .then((result) => {
    if (!result.error) {
      dispatch(addFlashMessage('success', t('Zrušení autorizace a nabíjení karet proběhlo úspěšně.', { ns: 'my' })));
    }
  });


export const onlineConfirmBankCardAssign = (id) => (dispatch) => (dispatch({
  [RSAA]: {
    endpoint: '/bank_card_assign/online_confirm',
    method: 'PUT',
    body: JSON.stringify({
      id,
    }),
    types: [
      types.CARD_ASSIGN_ONLINE_CONFIRM_REQUEST,
      types.CARD_ASSIGN_ONLINE_CONFIRM_SUCCESS,
      types.CARD_ASSIGN_ONLINE_CONFIRM_FAILURE,
    ],
  },
}).then(handleApiActionError(dispatch)))
  .then((action) => {
    if (!action.error) {
      const msg = action.payload.bank_card_assigned === BANK_CARD_ASSIGNED_OK
        ? t('Spojení karet se povedlo, gratulujeme. Nastavení můžete změnit v Detailu karty.', { ns: 'my' })
        : t('Spojení karet se povedlo, gratulujeme. Nyní jen vyčkejte na dokončení procesu na straně platební brány.', { ns: 'my' });
      dispatch(addFlashMessage('success', msg));
    }
    return action;
  });
