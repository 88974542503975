import React from 'react';
import PropTypes from 'prop-types';
import styles from './ContributionOrderWidget.scss';
// import { PropTypeWidgetContribution } from '../../../global/proptypes';

export const OnlinePaymentPageContent = ({ t }) => (
  <div className={styles.content}>
    <h3>{t('Úhrada online')}</h3>
    <p>{t('Zvolili jste platbu online, budete přesměrováni na platební bránu.')}</p>
  </div>
);

OnlinePaymentPageContent.propTypes = {
  t: PropTypes.func.isRequired,
  // data: PropTypeWidgetContribution.isRequired,
};
