import * as types from './actionTypes';


const reducerFactory = (initialState) => (state, action) => {
  const { type } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['registration', 'my']);
  }

  if (type === types.REGISTER_MY_REQUEST) {
    return state
      .setIn(['registrationData', 'isPostingFailure'], true)
      .setIn(['registrationData', 'isPosting'], true);
  }

  if (type === types.REGISTER_MY_SUCCESS) {
    return state
      .setIn(['registrationData', 'isPosting'], false);
  }

  if (type === types.REGISTER_MY_FAILURE) {
    return state
      .setIn(['registrationData', 'isPosting'], false)
      .setIn(['registrationData', 'isPostingFailure'], true);
  }

  return state;
};


export default reducerFactory;
