import Immutable from 'immutable';
import * as types from './actionTypes';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['registration', 'firm']);
  }

  if (type === types.FETCH_ARES_DATA_REQUEST) {
    return state
      .setIn(['aresData', 'isFetchingFailure'], true)
      .setIn(['aresData', 'isFetching'], true)
      .setIn(['aresData', 'isFetchingFailureMessage'], null);
  }

  if (type === types.FETCH_ARES_DATA_SUCCESS) {
    return state
      .setIn(['aresData', 'data'], Immutable.fromJS(payload))
      .setIn(['aresData', 'isFetching'], false);
  }

  if (type === types.FETCH_ARES_DATA_FAILURE) {
    let isFetchingFailureMessage = null;

    if (payload.status === 400) {
      isFetchingFailureMessage = payload.response.message;
    }

    return state
      .setIn(['aresData', 'isFetching'], false)
      .setIn(['aresData', 'isFetchingFailure'], true)
      .setIn(['aresData', 'isFetchingFailureMessage'], isFetchingFailureMessage);
  }

  if (type === types.REGISTER_FIRM_REQUEST) {
    return state
      .setIn(['registrationData', 'isPostingFailure'], true)
      .setIn(['registrationData', 'isPosting'], true);
  }

  if (type === types.REGISTER_FIRM_SUCCESS) {
    return state
      .setIn(['registrationData', 'isPosting'], false);
  }

  if (type === types.REGISTER_FIRM_FAILURE) {
    return state
      .setIn(['registrationData', 'isPosting'], false)
      .setIn(['registrationData', 'isPostingFailure'], true);
  }

  if (type === types.CONFIRM_FIRM_REGISTRATION_REQUEST) {
    return state
      .setIn(['confirmRegistrationData', 'isPostingFailure'], true)
      .setIn(['confirmRegistrationData', 'isPosting'], true);
  }

  if (type === types.CONFIRM_FIRM_REGISTRATION_SUCCESS) {
    return state
      .setIn(['confirmRegistrationData', 'data'], Immutable.fromJS(payload))
      .setIn(['confirmRegistrationData', 'isPosting'], false);
  }

  if (type === types.CONFIRM_FIRM_REGISTRATION_FAILURE) {
    return state
      .setIn(['confirmRegistrationData', 'isPosting'], false)
      .setIn(['confirmRegistrationData', 'isPostingFailure'], true);
  }

  return state;
};


export default reducerFactory;
