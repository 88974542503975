import { sendLogMessage } from '../modules/global/actions';

let lastReportedData = null;

export function crashReporterInit(store) {
  const handler = (msg, url, lineNo, columnNo, error) => {
    // var string = msg.toLowerCase();
    // var substring = 'script error';

    let state = null;
    try {
      // eslint-disable-next-line
      state = store.GetState();
    } catch (e) {
      state = {};
    }

    const data = {
      error: {
        msg, url, lineNo, columnNo, error, stack: error && error.stack.substring(0, 600),
      },
      context: {
        userId: state.user && state.user.email,
      },
      timestamp: new Date(),
    };
    // report data
    // check if error is the same or it is too early to report again
    const lastReportedAgo = lastReportedData
      && (data.timestamp.getTime() - lastReportedData.timestamp.getTime());
    if (JSON.stringify(data.error) !== JSON.stringify(lastReportedData && lastReportedData.error)
      && (!lastReportedAgo || lastReportedAgo > 1000 * 60 * 60)) {
      store.dispatch(sendLogMessage(JSON.stringify(data)));
      lastReportedData = data;
    }
    return false;
  };

  window.onerror = handler;

  window.addEventListener('unhandledrejection', (e) => {
    handler(e.message, window.location.href, null, null, e.reason);
  });
}
