import PropTypes from 'prop-types';
import React from 'react';
import styles from './Subheader.scss';


const Subheader = (props) => (
  <div className={props.hasTabs ? styles.hasRootTabs : styles.root}>
    {props.children}
  </div>
);

Subheader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  hasTabs: PropTypes.bool,
};

export default Subheader;
