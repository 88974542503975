import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../../service/entityList';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['my', 'place']);
  }

  if (type === types.FETCH_PRODUCT_DESC_PAIRS_REQUEST) {
    return state
      .setIn(['productsPairs', 'isFetchingFailure'], false)
      .setIn(['productsPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_PRODUCT_DESC_PAIRS_SUCCESS) {
    const productsDescPairs = payload.map((item) => ({
      label: item.val,
      value: item.id,
      group: item.grouped,
      cond: item.cond,
    }));

    return state
      .setIn(['productsDescPairs', 'data'], Immutable.fromJS(productsDescPairs))
      .setIn(['productsDescPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_PRODUCT_DESC_PAIRS_FAILURE) {
    return state
      .setIn(['productsDescPairs', 'isFetching'], false)
      .setIn(['productsDescPairs', 'isFetchingFailure'], true);
  }


  if (type === types.FETCH_AFFILIATE_TYPE_PAIRS_REQUEST) {
    return state
      .setIn(['affiliateTypePairs', 'isFetchingFailure'], false)
      .setIn(['affiliateTypePairs', 'isFetching'], true);
  }

  if (type === types.FETCH_AFFILIATE_TYPE_PAIRS_SUCCESS) {
    const affiliateTypes = payload.map((item) => ({
      label: item.val,
      value: item.id,
      group: item.grouped,
      cond: item.cond,
    }));

    return state
      .setIn(['affiliateTypePairs', 'data'], Immutable.fromJS(affiliateTypes))
      .setIn(['affiliateTypePairs', 'isFetching'], false);
  }

  if (type === types.FETCH_AFFILIATE_TYPE_PAIRS_FAILURE) {
    return state
      .setIn(['affiliateTypePairs', 'isFetching'], false)
      .setIn(['affiliateTypePairs', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_AFFILIATES_FILTER_DATA) {
    const newFilter = Immutable.fromJS(payload.filter);
    const newOrderBy = Immutable.fromJS(payload.orderBy);
    const filterEquals = newFilter.equals(state.getIn(['affiliateList', 'filter']));
    const orderEquals = newOrderBy.equals(state.getIn(['affiliateList', 'orderBy']));

    return state
      .setIn(['affiliateList', 'filter'], newFilter)
      .setIn(['affiliateList', 'orderBy'], newOrderBy)
      .updateIn(['affiliateList', 'isFetched'], (prev) => prev && filterEquals && orderEquals) // reset fetched flag to signal that list wre not reloaded yet with this filter and order by data
      .updateIn(['affiliateMarkers', 'isFetched'], (prev) => prev && filterEquals);
  }

  if (type === types.FETCH_AFFILIATES_REQUEST) {
    return state
      .setIn(['affiliateList', 'isFetchingFailure'], false)
      .setIn(['affiliateList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['affiliateList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['affiliateList', 'fetchCount'], Immutable.fromJS(payload.limit))
      .setIn(['affiliateList', 'limit'], Immutable.fromJS(payload.limit))
      .setIn(['affiliateList', 'lastOffset'], Immutable.fromJS(payload.lastOffset))
      .setIn(['affiliateList', 'orderBy'], Immutable.fromJS(payload.orderBy))
      .setIn(['affiliateList', 'filter'], Immutable.fromJS(payload.filter));
  }

  if (type === types.FETCH_AFFILIATES_SUCCESS) {
    const affiliates = payload.map((item) => ({
      data: {
        ...item,
        distance: +item.distance,
      },
    }));

    const affiliateList = state.get('affiliateList');
    const fetchCount = affiliateList.get('fetchCount');
    const lastOffset = affiliateList.get('lastOffset');
    const stateAffiliates = affiliateList.get('affiliates');
    const lastCount = affiliates.length;

    return state
      .setIn(['affiliateList', 'affiliates'], getEntitiesFromResponse(affiliates, stateAffiliates, lastOffset, fetchCount))
      .setIn(['affiliateList', 'lastCount'], lastCount)
      .setIn(['affiliateList', 'isFetching'], false)
      .setIn(['affiliateList', 'isLoadingMore'], false)
      .setIn(['affiliateList', 'isFetchingFailure'], false)
      .setIn(['affiliateList', 'isFetched'], true);
  }

  if (type === types.FETCH_AFFILIATES_FAILURE) {
    return state
      .setIn(['affiliateList', 'isFetching'], false)
      .setIn(['affiliateList', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_AFFILIATES_MARKERS_REQUEST) {
    return state
      .setIn(['affiliateMarkers', 'isFetchingFailure'], false)
      .setIn(['affiliateMarkers', 'isFetching'], true);
  }

  if (type === types.FETCH_AFFILIATES_MARKERS_SUCCESS) {
    const affiliateMarkers = payload.map((item) => ({
      position: { lat: item.c20_gps_lat, lng: item.c20_gps_lng },
      showInfo: false,
      c25_contract_address_id: item.c25_contract_address_id,
      c23_branch_type_id: item.c23_branch_type_id,
      id: `${item.c25_contract_address_id} ${item.c23_branch_type_id}`,
      label: item.c20_label,
      s20_code: item.s20_code,
      c23_branch_type: item.c23_branch_type,
      c25_comment: item.c25_comment,
      pos_kinds: item.pos_kinds,
    }));

    return state
      .setIn(['affiliateMarkers', 'markers'], Immutable.fromJS(affiliateMarkers))
      .setIn(['affiliateMarkers', 'isFetching'], false)
      .setIn(['affiliateMarkers', 'isFetchingFailure'], false)
      .setIn(['affiliateMarkers', 'isFetched'], true);
  }

  if (type === types.FETCH_AFFILIATES_MARKERS_FAILURE) {
    return state
      .setIn(['affiliateMarkers', 'isFetching'], false)
      .setIn(['affiliateMarkers', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_AFFILIATE_REQUEST) {
    return state
      .setIn(['affiliate', 'isFetchingFailure'], false)
      .setIn(['affiliate', 'isFetching'], true);
  }

  if (type === types.FETCH_AFFILIATE_SUCCESS) {
    return state
      .setIn(['affiliate', 'data'], Immutable.fromJS(payload))
      .setIn(['affiliate', 'isFetching'], false);
  }

  if (type === types.FETCH_AFFILIATE_FAILURE) {
    return state
      .setIn(['affiliate', 'isFetching'], false)
      .setIn(['affiliate', 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
