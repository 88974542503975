import PropTypes from 'prop-types';
import React from 'react';
import RaisedButton from '../../../../../component1/RaisedButton/RaisedButton';
import styles from './CreditCardLink.scss';

export const CreditCardLinkAnotherCards = ({
  t, cards, onClose, onSelect,
}) => (
  <div className={`${styles.container} ${styles.centered}`}>
    <p>{t('Chcete svou platební kartu propojit i s dalšími Vašimi kartami?')}</p>
    <div className={styles.anotherCards}>
      {cards.map((card) => (
        <div key={card.p20_card_id}>
          <span>{card.u01_nickname || card.fullname}</span>
          <RaisedButton label={t('Nastavit')} priority="primary" size="large" onClick={() => onSelect(card)} />
        </div>
      ))}
    </div>
    {onClose && <RaisedButton label={t('Hotovo')} priority="primary" size="large" onClick={onClose} />}
  </div>
);

CreditCardLinkAnotherCards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    p20_card_id: PropTypes.number.isRequired,
    u01_nickname: PropTypes.string,
  })),
  onClose: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
