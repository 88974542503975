import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';


export const positionReducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'position']);
  }

  if (type === types.FETCH_FIRM_POSITION_PAIRS_REQUEST) {
    return state
      .setIn(['positionPairs', 'isFetchingFailure'], false)
      .setIn(['positionPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_FIRM_POSITION_PAIRS_SUCCESS) {
    // "c32_position_id": 1,
    //   "c32_name": "Ředitel",
    //   "c01_company_id": 100,
    //   "c01_name": "První testovací a.s.",
    //   "c01_ico": "12312312"
    const positions = payload.map((item) => ({
      label: item.c32_name,
      value: item.c32_position_id,
      active: item.active,
    }));

    return state
      .setIn(['positionPairs', 'positionPairs'], Immutable.fromJS(positions))
      .setIn(['positionPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_FIRM_POSITION_PAIRS_FAILURE) {
    return state
      .setIn(['positionPairs', 'isFetching'], false)
      .setIn(['positionPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_FIRM_POSITIONS_REQUEST) {
    return state
      .setIn(['positionList', 'isFetchingFailure'], false)
      .setIn(['positionList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['positionList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['positionList', 'fetchCount'], Immutable.fromJS(payload.limit))
      .setIn(['positionList', 'limit'], Immutable.fromJS(payload.limit))
      .setIn(['positionList', 'lastOffset'], Immutable.fromJS(payload.lastOffset))
      .setIn(['positionList', 'orderBy'], Immutable.fromJS(payload.orderBy))
      .setIn(['positionList', 'filter'], Immutable.fromJS(payload.filter));
  }

  if (type === types.FETCH_FIRM_POSITIONS_SUCCESS) {
    const rows = payload.map((item) => ({
      data: {
        ...item,
        id: item.c32_position_id,
        is_active: !!+item.is_active,
      },
      actions: {
        edit: true,
        delete: true,
      },
    }));

    const positionList = state.get('positionList');
    const fetchCount = positionList.get('fetchCount');
    const lastOffset = positionList.get('lastOffset');
    const stateRows = positionList.get('rows');
    const lastCount = rows.length;

    return state
      .setIn(['positionList', 'rows'], getEntitiesFromResponse(rows, stateRows, lastOffset, fetchCount))
      .setIn(['positionList', 'lastCount'], lastCount)
      .setIn(['positionList', 'isFetching'], false)
      .setIn(['positionList', 'isLoadingMore'], false)
      .setIn(['positionList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_FIRM_POSITIONS_FAILURE) {
    return state
      .setIn(['positionList', 'isFetching'], false)
      .setIn(['positionList', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_FIRM_POSITIONS_FILTER_DATA) {
    return state
      .setIn(['positionList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['positionList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  return state;
};
