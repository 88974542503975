import pathToRegexp from 'path-to-regexp';
import scanRouteChildren from './scanRouteChildren';
import transformOptionalPathParameters from './transformOptionalPathParameters';

const pathToRegexpCustom = (path) => pathToRegexp(path === '/*' ? '/(.*)' : path);

export default (routingTable, path) => {
  const scanRoute = (routingTableNode, connectedPath) => {
    let currentLevelPath = (`${connectedPath}/${routingTableNode.path || ''}`).replace('//', '/');

    if (connectedPath !== currentLevelPath) {
      currentLevelPath = transformOptionalPathParameters(currentLevelPath);

      if (pathToRegexpCustom(currentLevelPath).exec(path)) {
        return {
          name: routingTableNode.name,
          key: routingTableNode.key || null,
          requireLogout: routingTableNode.requireLogout || false,
          loginOptional: routingTableNode.loginOptional,
          isLanding: routingTableNode.isLanding,
          fullPath: currentLevelPath,
        };
      }
    }

    return scanRouteChildren(routingTableNode, currentLevelPath, scanRoute);
  };

  return scanRoute(routingTable, '');
};
