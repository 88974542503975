import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../component1/Card';
import Fieldset from '../../../../component1/Form/Fieldset';
import SelectField from '../../../../component1/SelectField/SelectField';
import TextField from '../../../../component1/TextField/TextField';
import DatePicker from '../../../../component1/DatePicker/DatePicker';
import CardNoField from '../../../../component1/CardNoField/CardNoField';
import * as validation from '../../../../service/validation';
import FlatButton from '../../../../component1/FlatButton/FlatButton';
import { dateGUItoApi } from '../../../../service/date';

const initialState = {
  form: {
    isValid: false,
    elements: {
      c25_contract_address_id: {
        errorText: '',
        rules: [validation.required],
      },
      n55_value: {
        errorText: '',
        rules: [validation.required],
      },
      card_no: {
        errorText: '',
        rules: [validation.required],
      },
      n55_ext_id: {
        errorText: '',
        rules: [validation.required],
      },
      n55_description: {
        errorText: '',
        rules: [],
      },
      n55_date_use: {
        errorText: '',
        rules: [],
      },
    },
  },
  n55_value: null,
  card_no: null,
  n55_ext_id: null,
  n55_description: null,
  n55_date_use: null,
};

export class PartnerPayGatePaymentWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      c25_contract_address_id: null,
      use_date_required: null,
      ...this.getInitialState(props),
    };
  }

  getInitialState(props) {
    if (props.data.affiliates && props.data.affiliates.length === 1) {
      return {
        ...initialState,
        ...this.getStateForAffiliate(props.data.affiliates[0].c25_contract_address_id),
      };
    }

    return {
      ...initialState,
    };
  }

  getStateForAffiliate(addressId) {
    const affiliate = this.props.data.affiliates && this.props.data.affiliates.find((aff) => aff.c25_contract_address_id === addressId);
    const useDateRequired = (affiliate && affiliate.date_use_required);
    const form = {
      ...initialState.form,
      elements: {
        ...initialState.form.elements,
        n55_date_use: {
          errorText: '',
          rules: useDateRequired ? [validation.required] : null,
        },
      },
    };

    return { c25_contract_address_id: addressId, use_date_required: useDateRequired, form };
  }

  handleAffiliateChange = (value) => {
    const addressId = value ? +value : value;
    this.setState(this.getStateForAffiliate(addressId));
  }

  handleReset = () => {
    this.setState(this.getInitialState(this.props));
  }

  handleSubmit = async () => {
    const isValid = validation.validateFormInState(this.state, this.state.form, (upd) => this.setState(upd));

    if (!isValid) {
      return false;
    }

    const data = {
      c25_contract_address_id: this.state.c25_contract_address_id,
      n55_value: this.state.n55_value,
      card_no: this.state.card_no,
      n55_ext_id: this.state.n55_ext_id,
      n55_description: this.state.n55_description,
      n55_date_use: dateGUItoApi(this.state.n55_date_use),
    };
    const ret = await this.props.widgetPartnerSavePayGatePayment(data);

    if (!ret.error) {
      this.handleReset(); // reset?
    }

    this.props.updateWidget(); // update widgets...
    return ret;
  }

  render() {
    const { t, data } = this.props;
    const { form } = this.state;
    const useDateRequired = this.state.use_date_required;

    return (
      <Card
        layout="fluid"
        title={t('Platební brána')}
        actions={[
          <FlatButton
            key={2}
            label={t('Obnovit')}
            onClick={this.handleReset}
          />,
          <FlatButton
            key={1}
            label={t('Vyžádat platbu')}
            onClick={this.handleSubmit}
          />,
        ]}
      >
        <Fieldset>
          {data.affiliates.length > 1 && (
          <SelectField
            label={t('Provozovna')}
            fieldId="c25_contract_address"
            options={[{ label: t('Nezvoleno'), value: '' }].concat(data.affiliates.map((itm) => ({
              label: itm.full_address,
              value: itm.c25_contract_address_id,
            })))}
            onChange={this.handleAffiliateChange}
            value={this.state.c25_contract_address_id || ''}
            errorText={form.elements.c25_contract_address_id.errorText}
            disabled={data.affiliates.length === 1}
            isThin
          />
          )}
          <TextField
            fieldId="tf-n55_value"
            formatValue="number"
            label={t('Částka')}
            value={this.state.n55_value}
            onChange={(e, val) => this.setState({ n55_value: val })}
            errorText={form.elements.n55_value.errorText}
            isThin
          />
          <CardNoField
            fieldId="card_no"
            label={t('Číslo karty (posledních 8 číslic)')}
            onChange={(e, value) => this.setState({ card_no: value })}
            value={this.state.card_no || ''}
            errorText={form.elements.card_no.errorText}
            isThin
          />
          {useDateRequired ? (
            <DatePicker
              name="n55_date_use"
              label={t('Datum začátku rekreace')}
              value={this.state.n55_date_use}
              onChange={(value) => this.setState({ n55_date_use: value })}
              t={t}
              errorText={form.elements.n55_date_use.errorText}
              isThin
            />
          ) : null}
          <TextField
            fieldId="tf-n55_ext_id"
            label={t('Číslo objednávky')}
            value={this.state.n55_ext_id}
            onChange={(e, val) => this.setState({ n55_ext_id: val })}
            errorText={form.elements.n55_ext_id.errorText}
            isThin
          />
          <TextField
            fieldId="tf-n55_description"
            label={t('Popis')}
            value={this.state.n55_description}
            onChange={(e, val) => this.setState({ n55_description: val })}
            errorText={form.elements.n55_description.errorText}
            isThin
          />
        </Fieldset>
      </Card>
    );
  }
}

PartnerPayGatePaymentWidget.propTypes = {
  t: PropTypes.func.isRequired,
  widgetPartnerSavePayGatePayment: PropTypes.func.isRequired,
  updateWidget: PropTypes.func.isRequired,
  data: PropTypes.shape({
    affiliates: PropTypes.arrayOf(PropTypes.shape({
      c25_contract_address_id: PropTypes.number.isRequired,
      full_address: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    show: PropTypes.number.isRequired,
    w15_enabled: PropTypes.number.isRequired,
    widget: PropTypes.string.isRequired,
  }).isRequired,
};
