import pathToRegexp from 'path-to-regexp';
import checkParameters from './checkParameters';
import scanRouteChildren from './scanRouteChildren';
import transformOptionalPathParameters from './transformOptionalPathParameters';


export default (routingTable, name, parameters, authorizedPageKeys) => {
  const scanRoute = (routingTableNode, connectedPath) => {
    let currentLevelPath = (`${connectedPath}/${routingTableNode.path || ''}`).replace('//', '/');

    if (connectedPath !== currentLevelPath) {
      currentLevelPath = transformOptionalPathParameters(currentLevelPath);
    }

    if (routingTableNode.name === name && checkParameters(currentLevelPath, parameters)) {
      if (!routingTableNode.key || authorizedPageKeys === 'allow-all' || authorizedPageKeys.indexOf(routingTableNode.key) !== -1) {
        return pathToRegexp.compile(currentLevelPath)(parameters);
      }

      return null;
    }

    return scanRouteChildren(routingTableNode, currentLevelPath, scanRoute);
  };

  return scanRoute(routingTable, '');
};
