import Immutable from 'immutable';
import * as types from './actionTypes';
import { SAVE_CREDIT_TRANSFER_SUCCESS, SAVE_CARD_LIMIT_SUCCESS } from '../card/actionTypes';
import {
  ReducerFactory, IImmutableMap, IRootState, ActionWithPayload,
} from '../../../../data/storeModel';

export type MyUserState = IImmutableMap<IRootState['my']['user']>;
type Actions = types.Actions | ActionWithPayload<typeof SAVE_CREDIT_TRANSFER_SUCCESS|typeof SAVE_CARD_LIMIT_SUCCESS>;

const reducerFactory: ReducerFactory<MyUserState, Actions> = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['my', 'user']);
  }

  if (action.type === types.FETCH_USER_REQUEST) {
    return state
      .setIn(['user', 'isFetchingFailure'], false)
      .setIn(['user', 'isFetching'], true);
  }

  if (action.type === types.FETCH_USER_SUCCESS) {
    return state
      .setIn(['user', 'data'], Immutable.fromJS(action.payload))
      .setIn(['user', 'isFetching'], false);
  }

  if (type === types.FETCH_USER_FAILURE) {
    return state
      .setIn(['user', 'isFetching'], false)
      .setIn(['user', 'isFetchingFailure'], true);
  }

  // Store updated card data
  if (type === SAVE_CARD_LIMIT_SUCCESS) {
    const userCardId = state.getIn(['user', 'data', 'widget', 'p20_card_id']);

    if (userCardId === payload.p20_card_id) {
      return state.setIn(['user', 'data', 'widget'], action.payload);
    }
    return state;
  }

  // Store update after balance transfer
  if (type === SAVE_CREDIT_TRANSFER_SUCCESS) {
    return state
      .setIn(['user', 'data'], Immutable.fromJS(payload));
  }

  if (type === types.UPDATE_USER_REQUEST) {
    return state
      .setIn(['updateUser', 'isPosting'], true)
      .setIn(['updateUser', 'isPostingFailure'], false);
  }

  if (type === types.UPDATE_USER_SUCCESS) {
    return state
      .setIn(['updateUser', 'isPosting'], false);
  }

  if (type === types.UPDATE_USER_FAILURE) {
    return state
      .setIn(['updateUser', 'isPosting'], false)
      .setIn(['updateUser', 'isPostingFailure'], true);
  }

  if (type === types.FETCH_USER_PAIRS_REQUEST) {
    return state
      .setIn(['userPairs', 'isFetchingFailure'], false)
      .setIn(['userPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_USER_PAIRS_SUCCESS) {
    const cashSystems = payload.map((item: any) => ({
      label: item.fullname,
      value: item.c30_company_user_id,
    }));

    return state
      .setIn(['userPairs', 'userPairs'], Immutable.fromJS(cashSystems))
      .setIn(['userPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_USER_PAIRS_FAILURE) {
    return state
      .setIn(['userPairs', 'isFetching'], false)
      .setIn(['userPairs', 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
