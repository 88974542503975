import React from 'react';
import styles from './VersionLabel.scss';
import { MOBILE_APP, NON_PROD_ENV } from '../../config/envspecific';

const versionText = MOBILE_APP ? (NON_PROD_ENV && `${process.env.VERSION} (mobile)`) : process.env.VERSION;

const VersionLabel = () => (
  <div className={styles.version}>
    <span>
      {versionText ? `v${versionText}` : null}
    </span>
  </div>
);

export default VersionLabel;
