import { createSelector } from 'reselect';

const getContracts = (state) => state
  .getIn(['firm', 'contract', 'contractList', 'contracts'])
  .toJS();
const getContractPairs = (state) => state
  .getIn(['firm', 'contract', 'contractPairs'])
  .toJS();

export const selectContracts = createSelector([getContracts], (contracts) => contracts);

// All firm contract pairs - used by order and other B2B pages
export const selectContractPairs = createSelector([getContractPairs], (pairs) => pairs.contractPairs);
export const selectContractPairsIsFetching = createSelector([getContractPairs], (pairs) => pairs.isFetching);
