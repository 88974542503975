import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../component1/Card';
import { widgetMinHeight } from '../../../global/constants';
import { PropTypeCreditCardLinkData, CreditCardLinkContainer } from '../../../my/pages/cardList/components/CreditCardLinkContainer';
import styles from './CreditCardLinkWidget.scss';
import { PropTypeCard } from '../../../global/proptypes';

export const CreditCardLinkWidget = (props) => (
  <Card
    layout="fluid"
    title={props.t('Připojit platební kartu')}
    minHeight={widgetMinHeight}
    bodyClassName={styles.cardBody}
  >
    <CreditCardLinkContainer {...props} />
  </Card>
);

CreditCardLinkWidget.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypeCreditCardLinkData.isRequired,
  cards: PropTypes.arrayOf(PropTypeCard).isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchCards: PropTypes.func.isRequired,
  createBankCardAssign: PropTypes.func.isRequired,
  saveCardAutoCredit: PropTypes.func.isRequired,
  contractConfirmation: PropTypes.func.isRequired,
};
