import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'user']);
  }

  if (type === types.UPDATE_FIRM_USERS_FILTER_DATA) {
    return state
      .setIn(['userList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['userList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  if (type === types.FETCH_USERS_REQUEST) {
    return state
      .setIn(['userList', 'isFetchingFailure'], false)
      .setIn(['userList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['userList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['userList', 'fetchCount'], Immutable.fromJS(payload.limit))
      .setIn(['userList', 'limit'], Immutable.fromJS(payload.limit))
      .setIn(['userList', 'lastOffset'], Immutable.fromJS(payload.lastOffset))
      .setIn(['userList', 'orderBy'], Immutable.fromJS(payload.orderBy))
      .setIn(['userList', 'filter'], Immutable.fromJS(payload.filter));
  }

  if (type === types.FETCH_USERS_SUCCESS) {
    const users = payload.map((item) => ({
      data: {
        id: item.c30_company_user_id,
        c01_company_id: item.c01_company_id,
        c30_company_user_id: item.c30_company_user_id,
        c01_ico: item.c01_ico,
        c01_name: item.c01_name,
        c30_personal_nb: item.c30_personal_nb,
        fullname: item.fullname,
        u01_login: item.u01_login,
        u01_mail_work: item.u01_mail_work,
        authorization: item.authorization,
        c85_branch_id: item.c85_branch_id,
        c85_name: item.c85_name,
        c87_cost_center_id: item.c87_cost_center_id,
        c87_name: item.c87_name,
        p20_card_id: item.p20_card_id,
        p21_card_status: item.p21_card_status,
        p21_card_status_pending: item.p21_card_status_pending,
        c30_active: !!item.c30_active,
      },
      actions: {
        renewCard: !!item.can_reissue,
        assignCard: !!item.can_assign,
        changeBranch: !!item.can_edit,
        changeCostCenter: !!item.can_edit,
      },
      flags: {
        isRenewing: false,
        isRenewingFailure: false,
        isAssigning: false,
        isAssigningFailure: false,
        isChangingBranch: false,
        isChangingBranchFailure: false,
        isChangingCostCenter: false,
        isChangingCostCenterFailure: false,
      },
    }));

    const userList = state.get('userList');
    const fetchCount = userList.get('fetchCount');
    const lastOffset = userList.get('lastOffset');
    const stateUsers = userList.get('users');
    const lastCount = users.length;

    return state
      .setIn(['userList', 'users'], getEntitiesFromResponse(users, stateUsers, lastOffset, fetchCount))
      .setIn(['userList', 'lastCount'], lastCount)
      .setIn(['userList', 'isFetching'], false)
      .setIn(['userList', 'isLoadingMore'], false)
      .setIn(['userList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_USERS_FAILURE) {
    return state
      .setIn(['userList', 'isFetching'], false)
      .setIn(['userList', 'isFetchingFailure'], true);
  }


  if (type === types.CHANGE_COST_CENTER_REQUEST) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        if (payload.costCenterName === undefined) {
          return user.set(
            'data',
            user.get('data').set('c87_name', '').set('c87_cost_center_id', null)
          );
        }

        return user.set('flags', user.get('flags')
          .set('isChangingCostCenter', true)
          .set('isChangingCostCenterFailure', false));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (action.type === types.CHANGE_COST_CENTER_SUCCESS) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('data', user.get('data')
          .set('c87_name', payload.costCenterName))
          .set('c87_cost_center_id', payload.costCenterId)
          .set('flags', user.get('flags')
            .set('isChangingCostCenter', false)
            .set('isChangingCostCenterFailure', false));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (action.type === types.CHANGE_COST_CENTER_FAILURE) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('flags', user.get('flags')
          .set('isChangingCostCenter', false)
          .set('isChangingCostCenterFailure', true));
      }
      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (type === types.CHANGE_COST_CENTER_REQUEST) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('flags', user.get('flags')
          .set('isChangingCostCenter', true)
          .set('isChangingCostCenterFailure', false));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (action.type === types.FETCH_USER_PAIRS_REQUEST) {
    return state
      .setIn(['userPairsList', 'isFetchingFailure'], false)
      .setIn(['userPairsList', 'isFetching'], true);
  }

  if (action.type === types.FETCH_USER_PAIRS_SUCCESS) {
    const users = action.payload.map((item) => ({
      label: item.fullname,
      value: item.c30_company_user_id,
    }));

    return state
      .setIn(['userPairsList', 'users'], Immutable.fromJS(users))
      .setIn(['userPairsList', 'isFetching'], false);
  }

  if (action.type === types.FETCH_USER_PAIRS_FAILURE) {
    return state
      .setIn(['userPairsList', 'isFetching'], false)
      .setIn(['userPairsList', 'isFetchingFailure'], true);
  }

  if (action.type === types.CHANGE_COST_CENTER_SUCCESS) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('data', user.get('data')
          .set('c87_name', payload.costCenterName))
          .set('flags', user.get('flags')
            .set('isChangingCostCenter', false)
            .set('isChangingCostCenterFailure', false));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (action.type === types.CHANGE_COST_CENTER_FAILURE) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('flags', user.get('flags')
          .set('isChangingCostCenter', false)
          .set('isChangingCostCenterFailure', true));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (type === types.RENEW_CARD_REQUEST) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('flags', user.get('flags')
          .set('isRenewing', true)
          .set('isRenewingFailure', false));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (action.type === types.RENEW_CARD_SUCCESS) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('flags', user.get('flags')
          .set('isRenewing', false)
          .set('isRenewingFailure', false));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (action.type === types.RENEW_CARD_FAILURE) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('flags', user.get('flags')
          .set('isRenewing', false)
          .set('isRenewingFailure', true));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (type === types.ASSIGN_CARD_REQUEST) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('flags', user.get('flags')
          .set('isAssigning', true)
          .set('isAssigningFailure', false));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (action.type === types.ASSIGN_CARD_SUCCESS) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('flags', user.get('flags')
          .set('isAssigning', false)
          .set('isAssigningFailure', false));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (action.type === types.ASSIGN_CARD_FAILURE) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('flags', user.get('flags')
          .set('isAssigning', false)
          .set('isAssigningFailure', true));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (type === types.CHANGE_BRANCH_REQUEST) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        if (payload.branchName === undefined) {
          return user.set(
            'data',
            user.get('data').set('c85_name', '').set('c85_branch_id', null)
          );
        }

        return user.set('flags', user.get('flags')
          .set('isChangingBranch', true)
          .set('isChangingBranchFailure', false));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (action.type === types.CHANGE_BRANCH_SUCCESS) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('data', user.get('data')
          .set('c85_name', payload.branchName))
          .set('c87_cost_center_id', payload.branchId)
          .set('flags', user.get('flags')
            .set('isChangingBranch', false)
            .set('isChangingBranchFailure', false));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (action.type === types.CHANGE_BRANCH_FAILURE) {
    const users = state.getIn(['userList', 'users']).map((user) => {
      if (user.get('data').get('id') === payload.userId) {
        return user.set('flags', user.get('flags')
          .set('isChangingBranch', false)
          .set('isChangingBranchFailure', true));
      }

      return user;
    });

    return state.setIn(['userList', 'users'], Immutable.fromJS(users));
  }

  if (action.type === types.FETCH_USER_REQUEST) {
    return state
      .setIn(['userDetail', 'isFetching'], true)
      .setIn(['userDetail', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_USER_SUCCESS) {
    return state
      .setIn(['userDetail', 'isFetching'], false)
      .setIn(['userDetail', 'isFetchingFailure'], false)
      .setIn(['userDetail', 'user'], Immutable.fromJS(
        {
          ...action.payload,
          can_assign_card: !!action.payload.can_assign_card,
          can_edit_login: !!action.payload.can_edit_login,
          home_is_primary: !!action.payload.home_is_primary,
          u01_phone_validated: !!action.payload.u01_phone_validated,
        }
      ));
  }

  if (action.type === types.UPDATE_USER_SUCCESS) {
    return state
      .setIn(['userDetail', 'user'], Immutable.fromJS(
        {
          ...action.payload,
          can_assign_card: !!action.payload.can_assign_card,
          can_edit_login: !!action.payload.can_edit_login,
          home_is_primary: !!action.payload.home_is_primary,
        }
      ));
  }

  if (action.type === types.FETCH_USER_FAILURE) {
    return state
      .setIn(['userDetail', 'isFetching'], false)
      .setIn(['userDetail', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_USER_BRANCHES_REQUEST) {
    return state
      .setIn(['userDetail', 'branchesList', 'isFetching'], true)
      .setIn(['userDetail', 'branchesList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_USER_BRANCHES_SUCCESS) {
    return state
      .setIn(['userDetail', 'branchesList', 'isFetching'], false)
      .setIn(['userDetail', 'branchesList', 'branches'], Immutable.fromJS(action.payload));
  }

  if (action.type === types.FETCH_USER_BRANCHES_FAILURE) {
    return state
      .setIn(['userDetail', 'branchesList', 'isFetching'], false)
      .setIn(['userDetail', 'branchesList', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_FIRMS_COST_CENTERS_REQUEST) {
    return state
      .setIn(['userDetail', 'firmsCostCentersList', 'isFetching'], true)
      .setIn(['userDetail', 'firmsCostCentersList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_FIRMS_COST_CENTERS_SUCCESS) {
    return state
      .setIn(['userDetail', 'firmsCostCentersList', 'isFetching'], false)
      .setIn(['userDetail', 'firmsCostCentersList', 'costCenters'], Immutable.fromJS(action.payload));
  }

  if (action.type === types.FETCH_FIRMS_COST_CENTERS_FAILURE) {
    return state
      .setIn(['userDetail', 'firmsCostCentersList', 'isFetching'], false)
      .setIn(['userDetail', 'firmsCostCentersList', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_USER_ROLES_REQUEST) {
    return state
      .setIn(['userDetail', 'userRolesList', 'isFetching'], true)
      .setIn(['userDetail', 'userRolesList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_USER_ROLES_SUCCESS) {
    return state
      .setIn(['userDetail', 'userRolesList', 'isFetching'], false)
      .setIn(['userDetail', 'userRolesList', 'userRoles'], Immutable.fromJS(action.payload));
  }

  if (action.type === types.FETCH_USER_ROLES_FAILURE) {
    return state
      .setIn(['userDetail', 'userRolesList', 'isFetching'], false)
      .setIn(['userDetail', 'userRolesList', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_USER_RIGHTS_TEMPLATES_REQUEST) {
    return state
      .setIn(['userDetail', 'userRightsTemplatesList', 'isFetching'], true)
      .setIn(['userDetail', 'userRightsTemplatesList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_USER_RIGHTS_TEMPLATES_SUCCESS) {
    return state
      .setIn(['userDetail', 'userRightsTemplatesList', 'isFetching'], false)
      .setIn(['userDetail', 'userRightsTemplatesList', 'userRightsTemplates'], Immutable.fromJS(action.payload));
  }

  if (action.type === types.FETCH_USER_RIGHTS_TEMPLATES_FAILURE) {
    return state
      .setIn(['userDetail', 'userRightsTemplatesList', 'isFetching'], false)
      .setIn(['userDetail', 'userRightsTemplatesList', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_USER_RIGHTS_REQUEST) {
    return state
      .setIn(['userDetail', 'userRightsList', 'isFetching'], true)
      .setIn(['userDetail', 'userRightsList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_USER_RIGHTS_SUCCESS) {
    return state
      .setIn(['userDetail', 'userRightsList', 'isFetching'], false)
      .setIn(['userDetail', 'userRightsList', 'userRights'], Immutable.fromJS(action.payload));
  }

  if (action.type === types.FETCH_USER_RIGHTS_FAILURE) {
    return state
      .setIn(['userDetail', 'userRightsList', 'isFetching'], false)
      .setIn(['userDetail', 'userRightsList', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_USER_CARDS_REQUEST) {
    return state
      .setIn(['userDetail', 'userCards', 'isFetching'], true)
      .setIn(['userDetail', 'userCards', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_USER_CARDS_SUCCESS) {
    return state
      .setIn(['userDetail', 'userCards', 'isFetching'], false)
      .setIn(
        ['userDetail', 'userCards', 'userCards'],
        Immutable.fromJS(action.payload.map((row) => ({ data: row })))
      );
  }

  if (action.type === types.FETCH_USER_CARDS_FAILURE) {
    return state
      .setIn(['userDetail', 'userCards', 'isFetching'], false)
      .setIn(['userDetail', 'userCards', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_USER_TRANSACTIONS_REQUEST) {
    return state
      .setIn(['userDetail', 'userTransactions', 'isFetching'], true)
      .setIn(['userDetail', 'userTransactions', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_USER_TRANSACTIONS_SUCCESS) {
    return state
      .setIn(['userDetail', 'userTransactions', 'isFetching'], false)
      .setIn(
        ['userDetail', 'userTransactions', 'userTransactions'],
        Immutable.fromJS(action.payload.map((row) => ({ data: row })))
      );
  }

  if (action.type === types.FETCH_USER_TRANSACTIONS_FAILURE) {
    return state
      .setIn(['userDetail', 'userTransactions', 'isFetching'], false)
      .setIn(['userDetail', 'userTransactions', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_USER_RIGHTS_DETAIL_REQUEST) {
    return state
      .setIn(['userDetail', 'userRightsDetail', 'isFetching'], true)
      .setIn(['userDetail', 'userRightsDetail', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_USER_RIGHTS_DETAIL_SUCCESS) {
    return state
      .setIn(['userDetail', 'userRightsDetail', 'isFetching'], false)
      .setIn(['userDetail', 'userRightsDetail', 'data'], Immutable.fromJS(action.payload));
  }

  if (action.type === types.FETCH_USER_RIGHTS_DETAIL_FAILURE) {
    return state
      .setIn(['userDetail', 'userRightsDetail', 'isFetching'], false)
      .setIn(['userDetail', 'userRightsDetail', 'isFetchingFailure'], true);
  }

  if (action.type === types.UPDATE_USER_RIGHTS_DETAIL_SUCCESS) {
    return state.setIn(['userDetail', 'userRightsDetail', 'data'], Immutable.fromJS(action.payload));
  }

  return state;
};


export default reducerFactory;
