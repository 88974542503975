import React from 'react';
// import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styles from './DialogTitle.scss';

const BaseDialogTitle: React.FC<{
  showCross: boolean;
  isWarning: boolean;
  isSuccess: boolean;
  children: string|JSX.Element|JSX.Element[];
  classes?: any;
  onClose: () => void;
}> = (props) => {
  const {
    showCross, isWarning, isSuccess, children, onClose,
  } = props;
  return (
    <div>
      <h3 className={`${styles.root} ${isWarning ? styles.rootWarning : ''} ${isSuccess ? styles.rootSuccess : ''}`}>{children}</h3>
      {onClose && showCross ? (
        <IconButton aria-label="close" className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

export const DialogTitle = BaseDialogTitle;
