/* eslint-disable */
import { requestLogUserOutFactory, clearLoginSession } from '../modules/auth';
import { matchRoute } from '../router';
import { SEND_LOG_MESSAGE_FAILURE } from '../modules/global/actionTypes';

const sessionTimeoutMiddlewareFactory = (localStorage, location, history) => store => next => (action) => {
  if (typeof action.payload !== 'undefined' && action.payload.status === 401) {
    const currLocation = history.getCurrentLocation();
    const route = matchRoute(currLocation.pathname);

    // console.log('sessionTimeoutMiddleware', route, currLocation);

    if (action.type === SEND_LOG_MESSAGE_FAILURE) {
      // Noop
    } else if (route && (route.loginOptional || route.isLanding)) {
      // for route with optional login or special landing pages (eg. payment gateway) only clear auth info and continue without login
      // less secure but needed for redirect after login (can be removed when landingPathname is stored to query param)
      clearLoginSession();
    } else {
      requestLogUserOutFactory(localStorage, location, history)(store.dispatch);
      return false;
    }
  }

  return next(action);
};

export default sessionTimeoutMiddlewareFactory;
