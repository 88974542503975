import Immutable from 'immutable';
import * as types from './actionTypes';
import { IImmutableMap, IRootState, ReducerFactory } from '../../../data/storeModel';
import { getEntitiesFromResponse } from '../../../service/entityList';

export type FirmBenefitState = IImmutableMap<IRootState['firm']['benefits']>;
type Actions = types.Actions;


export const firmBenefitsReducerFactory: ReducerFactory<FirmBenefitState, Actions> = (initialState) => (state, action) => {
  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'benefits']);
  }

  if (action.type === types.FETCH_BENEFITS_REQUEST) {
    return state
      .setIn(['benefitsList', 'isFetchingFailure'], false)
      .setIn(['benefitsList', 'isFetching'], !action.payload.isLoadingMore)
      .setIn(['benefitsList', 'isLoadingMore'], !!action.payload.isLoadingMore)
      .setIn(['benefitsList', 'fetchCount'], Immutable.fromJS(action.payload.limit))
      .setIn(['benefitsList', 'limit'], Immutable.fromJS(action.payload.limit))
      .setIn(['benefitsList', 'lastOffset'], Immutable.fromJS(action.payload.lastOffset))
      .setIn(['benefitsList', 'orderBy'], Immutable.fromJS(action.payload.orderBy))
      .setIn(['benefitsList', 'filter'], Immutable.fromJS(action.payload.filter));
  }

  if (action.type === types.FETCH_BENEFITS_SUCCESS) {
    const groups = action.payload.map((item) => ({
      data: {
        ...item,
        id: item.d20_benefit_id,
      },
    }));

    const benefitsList = state.get('benefitsList');
    const fetchCount = benefitsList.get('fetchCount');
    const lastOffset = benefitsList.get('lastOffset');
    const stateTransactions = benefitsList.get('rows');
    const lastCount = groups.length;

    return state
      .setIn(['benefitsList', 'rows'], getEntitiesFromResponse(groups, stateTransactions, lastOffset, fetchCount))
      .setIn(['benefitsList', 'lastCount'], lastCount)
      .setIn(['benefitsList', 'isFetching'], false)
      .setIn(['benefitsList', 'isLoadingMore'], false)
      .setIn(['benefitsList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_BENEFITS_FAILURE) {
    return state
      .setIn(['benefitsList', 'isFetching'], false)
      .setIn(['benefitsList', 'isFetchingFailure'], true);
  }

  if (action.type === types.UPDATE_BENEFITS_FILTER_DATA) {
    return state
      .setIn(['benefitsList', 'filter'], Immutable.fromJS(action.payload.filter))
      .setIn(['benefitsList', 'orderBy'], Immutable.fromJS(action.payload.orderBy));
  }

  if (action.type === types.FETCH_BENEFIT_ITEM_REQUEST) {
    return state
      .setIn(['benefitDetail', 'isFetchingFailure'], false)
      .setIn(['benefitDetail', 'isFetching'], true);
  }

  if (action.type === types.FETCH_BENEFIT_ITEM_SUCCESS) {
    return state
      .setIn(['benefitDetail', 'data'], action.payload)
      .setIn(['benefitDetail', 'isFetching'], false)
      .setIn(['benefitDetail', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_BENEFIT_ITEM_FAILURE) {
    return state
      .setIn(['benefitDetail', 'isFetching'], false)
      .setIn(['benefitDetail', 'isFetchingFailure'], true);
  }

  return state;
};
