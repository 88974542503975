import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../../service/entityList';
import { fillAttendanceMonth } from './fillAttendanceMonth';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['my', 'attendance']);
  }

  if (type === types.FETCH_ATTENDANCE_TODAY_REQUEST) {
    return state
      .setIn(['today', 'isFetchingFailure'], false)
      .setIn(['today', 'isFetching'], !payload.isLoadingMore)
      .setIn(['today', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['today', 'fetchCount'], Immutable.fromJS(payload.limit))
      .setIn(['today', 'limit'], Immutable.fromJS(payload.limit))
      .setIn(['today', 'lastOffset'], Immutable.fromJS(payload.lastOffset))
      .setIn(['today', 'orderBy'], Immutable.fromJS(payload.orderBy))
      .setIn(['today', 'filter'], Immutable.fromJS(payload.filter));
  }

  if (type === types.FETCH_ATTENDANCE_TODAY_SUCCESS) {
    const rows = payload.events.map((item) => ({
      data: {
        ...item,
        id: item.d10_event_id,
      },
    }));
    const today = state.get('today');
    const fetchCount = today.get('fetchCount');
    const lastOffset = today.get('lastOffset');
    const stateRows = today.get('rows');
    const lastCount = rows.length;

    return state
      .setIn(['today', 'rows'], getEntitiesFromResponse(rows, stateRows, lastOffset, fetchCount))
      .setIn(['today', 'shiftSummary'], Immutable.fromJS(payload.shift_summary))
      .setIn(['today', 'monthSummary'], Immutable.fromJS(payload.month_summary))
      .setIn(['today', 'lastCount'], lastCount)
      .setIn(['today', 'isFetching'], false)
      .setIn(['today', 'isLoadingMore'], false)
      .setIn(['today', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_ATTENDANCE_TODAY_FAILURE) {
    return state
      .setIn(['today', 'isFetching'], false)
      .setIn(['today', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_ATTENDANCE_TODAY_FILTER_DATA) {
    return state
      .setIn(['today', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['today', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  if (action.type === types.FETCH_ATTENDANCE_EMPLOYEE_REQUEST) {
    return state
      .setIn(['employee', 'isFetchingFailure'], false)
      .setIn(['employee', 'isFetching'], !action.payload.isLoadingMore)
      // .setIn(['employee', 'isLoadingMore'], !!action.payload.isLoadingMore)
      // .setIn(['employee', 'fetchCount'], Immutable.fromJS(action.payload.limit))
      // .setIn(['employee', 'limit'], Immutable.fromJS(action.payload.limit))
      // .setIn(['employee', 'lastOffset'], Immutable.fromJS(action.payload.lastOffset))
      .setIn(['employee', 'orderBy'], Immutable.fromJS(action.payload.orderBy))
      .setIn(['employee', 'filter'], Immutable.fromJS(action.payload.filter));
  }

  if (action.type === types.FETCH_ATTENDANCE_EMPLOYEE_SUCCESS) {
    const filter = state.getIn(['employee', 'filter']).toJS();
    const attendance = fillAttendanceMonth(action.payload.shifts, filter.d30_year, filter.d30_month).map((item) => ({
      data: {
        ...item,
        id: item.d30_work_shift_id,
      },
    }));

    // const employee = state.get('employee');
    // const stateAttendance = employee.get('rows');
    // const fetchCount = employee.get('fetchCount');
    // const lastOffset = employee.get('lastOffset');
    // const lastCount = attendance.length;

    return state
      .setIn(['employee', 'rows'], Immutable.fromJS(attendance))
      // .setIn(['employee', 'lastCount'], lastCount)
      .setIn(['employee', 'isFetching'], false)
      // .setIn(['employee', 'isLoadingMore'], false)
      .setIn(['employee', 'isFetchingFailure'], false)
      .setIn(['employee', 'employee'], Immutable.fromJS(action.payload.employee));
  }

  if (action.type === types.FETCH_ATTENDANCE_EMPLOYEE_FAILURE) {
    return state
      .setIn(['employee', 'isFetching'], false)
      .setIn(['employee', 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
