import i18nBase from 'i18next';
import XHR from 'i18next-xhr-backend';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import routes from './locales/routes.json';
import { initMomentLocale } from '../service/date';

const resourceLoaders = {
  cs: () => import('./locales/cs.json'),
  sk: () => import('./locales/sk.json'),
  en: () => import('./locales/en.json'),
};

export const languagesSupported = Object.keys(resourceLoaders);

export function getLangCode(lng) {
  return lng && (lng.includes('-') ? lng.substring(0, lng.indexOf('-')) : lng);
}

export const i18n = i18nBase
  .use(initReactI18next)
  .use(LngDetector)
  .use(XHR);

i18n.init({
  // debug: NON_PROD_ENV && ENV_NAME !== 'test' && process.env.NODE_ENV !== 'test', // ENVIRONMENT === 'dev',
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  load: 'languageOnly',
  // react i18next special options (optional)
  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: false,
  },
  // allow keys to be phrases having `:`, `.`
  nsSeparator: false,
  keySeparator: false,
  // do not ue fallback cause CS is a dev lang
  fallbackLng: false,
  // set current language
  // lng: LANG,
  ns: ['routes', 'common', 'validation', 'auth'], // pre load namespaces used outside of translate HOC
  backend: {
    loadPath: '{{lng}}|{{ns}}',
    parse: (data) => data,
    ajax: (url, options, callback) => {
      const [lang, ns] = url.split('|');
      const loader = resourceLoaders[lang];

      if (!loader) {
        callback(null, { status: '500', message: `Loader for ${url} not found` });
        return;
      }

      loader().then((res) => {
        callback(res[ns] || {}, { status: '200' });
      }).catch((err) => {
        callback(null, { status: '500', message: err });
      });
    },
  },
  defaultNS: 'common',
  fallbackNS: 'common',
  whitelist: languagesSupported,

  // https://github.com/i18next/i18next-browser-languageDetector#detector-options
  detection: {
    // order and from where user language should be detected
    order: ['cookie', 'localStorage', 'navigator'],

    // keys or params to lookup language from
    lookupCookie: 'portalLang',
    lookupLocalStorage: 'portalLang',

    // cache user language on
    caches: ['localStorage'],

    checkWhitelist: true,
  },
});

// Immediatelly add routes translations
Object.keys(routes).forEach((lang) => i18n.addResourceBundle(lang, 'routes', routes[lang]));

i18n.on('languageChanged', (lang) => {
  initMomentLocale(lang, getLangCode(lang));
});

if (process.env.NODE_ENV !== 'test') {
  i18n.changeLanguage(); // autodetect language immediatelly
}

if (process.env.NODE_ENV === 'test') {
  i18n.changeLanguage('cs');
}

if (i18n.language && i18n.language.startsWith('cs')) {
  i18n.translator.logger.setDebug(false);
}

export default i18n;

export const tFn = (key, options) => i18n.t(key, options);
export const t = tFn;
export const getLocale = () => i18n.language;
export const getLang = () => getLangCode(i18n.language);
export const setLanguage = (lang) => {
  const code = getLangCode(lang);

  if (languagesSupported.includes(code)) {
    i18n.changeLanguage(lang);
    localStorage.setItem('portalLang', lang);
  }
};
export const routesParts = [];

export const tRoute = (url, myT, myOpts) => url.replace(/[^)(/]+/g, (part) => {
  // do not translate parts with : (:variables), * or 404 and numeric parts (ids)
  if (part.length === 0 || part.includes(':') || ['*', '404', 'undefined', 'null'].includes(part) || /^[0-9]+$/.test(part)) {
    return part;
  }

  const useT = myT || t;

  const qIdx = part.indexOf('?');

  const options = { ns: 'routes', ...myOpts };

  // translate without query string
  if (qIdx !== -1) {
    // routesParts.push(part.substr(0, qIdx));
    return `${useT(part.substr(0, qIdx), options)}${part.substr(qIdx)}`;
  }

  // routesParts.push(part);
  return useT(part, options);
});
