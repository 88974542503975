import PropTypes from 'prop-types';
import React from 'react';
import { Icon as MUIFontIcon, Tooltip } from '@material-ui/core';
import styles from './Icon.scss';


const Icon = (props) => {
  const style = props.style ? props.style : {};
  const color = props.color ? props.color : 'inherit';

  if (props.size === 'small') {
    style.fontSize = '18px';
  } else if (props.size === 'large') {
    style.fontSize = '32px';
  } else if (props.size === 'x1large') {
    style.fontSize = '44px';
  } else if (props.size === 'xlarge') {
    style.fontSize = '50px';
  } else if (props.size === 'xx1large') {
    style.fontSize = '90px';
  } else if (props.size === 'xxlarge') {
    style.fontSize = '100px';
  }

  if (props.onClick && style.cursor === undefined) {
    style.cursor = 'pointer';
  }

  const iconElm = typeof props.icon === 'function' ? props.icon({ color, style, size: props.size }) : (
    <MUIFontIcon
      className={`${styles.root} ${props.className || ''} ${props.block ? styles.block : ''}`}
      color={color}
      style={style}
      onClick={props.onClick}
    >
      {props.icon}
    </MUIFontIcon>
  );

  if (props.tooltip || props.title) {
    return (
      <Tooltip title={props.tooltip || props.title}>
        {iconElm}
      </Tooltip>
    );
  }

  return iconElm;
};

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  block: PropTypes.bool,
  color: PropTypes.string,
  tooltip: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.objectOf(Object),
  size: PropTypes.oneOf(['small', 'large', 'xlarge', 'x1large', 'xxlarge']),
  onClick: PropTypes.func,
};

export default Icon;
