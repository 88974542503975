import PropTypes from 'prop-types';
import React from 'react';
import MyCardBox from '../../../../component1/MyCardBox/MyCardBox';
import { PropTypeCard } from '../../../global/proptypes';
import { widgetMinHeight } from '../../../global/constants';
import { INSTANCE_SK } from '../../../../config/envspecific';


const MyCardWidget = (props) => (
  <MyCardBox card={props.data} minHeight={widgetMinHeight} cardEditAllowed={props.cardEditAllowed} t={props.t} onPendingBalanceClick={props.onPendingBalanceClick} showIcon={INSTANCE_SK} />
);

MyCardWidget.propTypes = {
  data: PropTypeCard.isRequired,
  cardEditAllowed: PropTypes.bool,
  onPendingBalanceClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default MyCardWidget;
