import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { CARD_KINDS_ADD } from './constants';

const getBankPairs = (state) => state
  .getIn(['global', 'bankPairs', 'bankPairs'])
  .toJS();
const getTransactionTypePairs = (state) => state
  .getIn(['global', 'transactionTypePairs', 'transactionTypePairs'])
  .toJS();
const getDeliveryStatusPairs = (state) => state
  .getIn(['global', 'deliveryStatusPairs', 'deliveryStatusPairs'])
  .toJS();
const getCashSystemPairs = (state) => state
  .getIn(['global', 'cashSystemPairs', 'cashSystemPairs'])
  .toJS();
const getAffiliateTypes = (state) => state
  .getIn(['global', 'affiliateTypePairs', 'affiliateTypePairs'])
  .toJS();
const getNameOnCardPairs = (state) => state
  .getIn(['global', 'nameOnCardPairs', 'nameOnCardPairs'])
  .toJS();
const getDeliveryCardOrderPairs = (state) => state
  .getIn(['global', 'deliveryCardOrderPairs', 'deliveryCardOrderPairs'])
  .toJS();

const getBrand = (state) => state
  .getIn(['global', 'brand'])
  .toJS();

const getOffline = (state) => state
  .getIn(['global', 'offline']);

const getAppActive = (state) => state
  .getIn(['global', 'appActive']);

const getProducts = (state) => state
  .getIn(['global', 'productsPairs', 'productsPairs'])
  .toJS();

const getProductsDesc = (state) => state
  .getIn(['global', 'productsDescPairs', 'data'])
  .toJS();

const getCardKinds = (state) => state
  .getIn(['global', 'cardKindsPairs', 'data'])
  .toJS();

const getCardType = (state) => state
  .getIn(['global', 'cardTypePairs', 'data'])
  .toJS();

const getGateTransStatus = (state) => state
  .getIn(['global', 'gateTransStatusPairs', 'data'])
  .toJS();

const getPaymentTypes = (state) => state
  .getIn(['global', 'paymentTypePairs', 'data'])
  .toJS();

const getAttendanceTypes = (state) => state
  .getIn(['global', 'attendanceTypePairs', 'data'])
  .toJS();

const getNewsCategories = (state) => state
  .getIn(['global', 'newsCategoryPairs', 'data'])
  .toJS();

export const selectBankPairs = createSelector([getBankPairs], (bankPairs) => bankPairs);
export const selectCashSystemPairs = createSelector([getCashSystemPairs], (pairs) => pairs);
export const selectTransactionTypePairs = createSelector([getTransactionTypePairs], (pairs) => pairs);
export const selectDeliveryStatusPairs = createSelector([getDeliveryStatusPairs], (pairs) => pairs);
export const selectAffiliateTypePairs = createSelector([getAffiliateTypes], (pairs) => pairs);
export const selectNameOnCardPairs = createSelector([getNameOnCardPairs], (pairs) => pairs);
export const selectDeliveryCardOrderPairs = createSelector([getDeliveryCardOrderPairs], (pairs) => pairs);

export const selectGeoDistancePairs = createSelector([], () => [
  { label: '20 km', value: 20000 },
  { label: '10 km', value: 10000 },
  { label: '5 km', value: 5000 },
  { label: '2 km', value: 2000 },
  { label: '1 km', value: 1000 },
  { label: '500 m', value: 500 },
  { label: '100 m', value: 100 },
]);

export const PairsPropType = PropTypes.arrayOf(PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  group: PropTypes.string,
}));

export const selectBrandCode = createSelector([getBrand], (brand) => brand.code);

export const selectProductPairs = createSelector([getProducts], (pairs) => pairs);
export const selectProductCodePairs = createSelector([getProducts], (pairs) => pairs.map((item) => ({ label: item.label, value: item.cond })));
export const selectProductDescPairs = createSelector([getProductsDesc],
  (pairs) => pairs.map((item) => ({ label: item.label, value: item.cond })));

export const selectProductExPairs = createSelector([getProductsDesc, getProducts],
  (descPairs, codePairs) => descPairs.map((item) => ({ label: item.label || codePairs.find((i2) => i2.cond === item.cond)?.label || null, value: item.cond })));

export const selectCardKindsPairs = createSelector([getCardKinds], (pairs) => pairs);
export const selectCardKindsToAddPairs = createSelector([selectCardKindsPairs],
  (pairs) => pairs.filter((k) => CARD_KINDS_ADD.includes(k.value)));

export const selectCardTypePairs = createSelector([getCardType], (pairs) => pairs);

export const selectGateTransStatusPairs = createSelector([getGateTransStatus], (pairs) => pairs);

export const selectOffline = createSelector([getOffline], (offline) => offline);
export const selectAppActive = createSelector([getAppActive], (active) => active);
export const selectPaymentTypePairs = createSelector([getPaymentTypes], (pairs) => pairs);
export const selectAttendanceTypePairs = createSelector([getAttendanceTypes], (pairs) => pairs);
export const selectAttendanceTypeMap = createSelector([getAttendanceTypes], (pairs) => pairs.reduce((acc, curr) => {
  acc[curr.value] = curr.label;
  return acc;
}, {}));
export const selectNewsCategoryPairs = createSelector([getNewsCategories], (pairs) => pairs);
