import { ActionWithPayload, ApiActions } from '../../../data/storeModel';

export const FETCH_USER_GROUPS_REQUEST = 'firm/user-group/FETCH_USER_GROUPS_REQUEST';
export const FETCH_USER_GROUPS_SUCCESS = 'firm/user-group/FETCH_USER_GROUPS_SUCCESS';
export const FETCH_USER_GROUPS_FAILURE = 'firm/user-group/FETCH_USER_GROUPS_FAILURE';

export const FETCH_GROUP_USERS_REQUEST = 'firm/user-group/FETCH_GROUP_USERS_REQUEST';
export const FETCH_GROUP_USERS_SUCCESS = 'firm/user-group/FETCH_GROUP_USERS_SUCCESS';
export const FETCH_GROUP_USERS_FAILURE = 'firm/user-group/FETCH_GROUP_USERS_FAILURE';

export const GET_GROUP_USERS_REQUEST = 'firm/user-group/GET_GROUP_USERS_REQUEST';
export const GET_GROUP_USERS_SUCCESS = 'firm/user-group/GET_GROUP_USERS_SUCCESS';
export const GET_GROUP_USERS_FAILURE = 'firm/user-group/GET_GROUP_USERS_FAILURE';

export const FETCH_USER_GROUP_PAIRS_REQUEST = 'firm/user-group/FETCH_USER_GROUP_PAIRS_REQUEST';
export const FETCH_USER_GROUP_PAIRS_SUCCESS = 'firm/user-group/FETCH_USER_GROUP_PAIRS_SUCCESS';
export const FETCH_USER_GROUP_PAIRS_FAILURE = 'firm/user-group/FETCH_USER_GROUP_PAIRS_FAILURE';

export const ADD_USER_GROUP_REQUEST = 'firm/user-group/ADD_USER_GROUP_REQUEST';
export const ADD_USER_GROUP_SUCCESS = 'firm/user-group/ADD_USER_GROUP_SUCCESS';
export const ADD_USER_GROUP_FAILURE = 'firm/user-group/ADD_USER_GROUP_FAILURE';

export const UPDATE_USER_GROUP_REQUEST = 'firm/user-group/UPDATE_USER_GROUP_REQUEST';
export const UPDATE_USER_GROUP_SUCCESS = 'firm/user-group/UPDATE_USER_GROUP_SUCCESS';
export const UPDATE_USER_GROUP_FAILURE = 'firm/user-group/UPDATE_USER_GROUP_FAILURE';

export const ADD_USER_TO_GROUP_REQUEST = 'firm/user-group/ADD_USER_TO_GROUP_REQUEST';
export const ADD_USER_TO_GROUP_SUCCESS = 'firm/user-group/ADD_USER_TO_GROUP_SUCCESS';
export const ADD_USER_TO_GROUP_FAILURE = 'firm/user-group/ADD_USER_TO_GROUP_FAILURE';

export const REMOVE_USER_FROM_GROUP_REQUEST = 'firm/user-group/REMOVE_USER_FROM_GROUP_REQUEST';
export const REMOVE_USER_FROM_GROUP_SUCCESS = 'firm/user-group/REMOVE_USER_FROM_GROUP_SUCCESS';
export const REMOVE_USER_FROM_GROUP_FAILURE = 'firm/user-group/REMOVE_USER_FROM_GROUP_FAILURE';

export const DELETE_USER_GROUP_REQUEST = 'firm/user-group/DELETE_USER_GROUP_REQUEST';
export const DELETE_USER_GROUP_SUCCESS = 'firm/user-group/DELETE_USER_GROUP_SUCCESS';
export const DELETE_USER_GROUP_FAILURE = 'firm/user-group/DELETE_USER_GROUP_FAILURE';

export const FETCH_USER_GROUP_REQUEST = 'firm/user-group/FETCH_USER_GROUP_REQUEST';
export const FETCH_USER_GROUP_SUCCESS = 'firm/user-group/FETCH_USER_GROUP_SUCCESS';
export const FETCH_USER_GROUP_FAILURE = 'firm/user-group/FETCH_USER_GROUP_FAILURE';

export const DOWNLOAD_EXPORT_TEMPLATE_REQUEST = 'firm/user-group/DOWNLOAD_EXPORT_TEMPLATE_REQUEST';
export const DOWNLOAD_EXPORT_TEMPLATE_SUCCESS = 'firm/user-group/DOWNLOAD_EXPORT_TEMPLATE_SUCCESS';
export const DOWNLOAD_EXPORT_TEMPLATE_FAILURE = 'firm/user-group/DOWNLOAD_EXPORT_TEMPLATE_FAILURE';

export const UPDATE_USER_GROUPS_FILTER_DATA = 'firm/user-group/UPDATE_USER_GROUPS_FILTER_DATA';
export const UPDATE_GROUP_USERS_FILTER_DATA = 'firm/user-group/UPDATE_GROUP_USERS_FILTER_DATA';

export type Actions =
  ActionWithPayload<typeof UPDATE_USER_GROUPS_FILTER_DATA> | ActionWithPayload<typeof UPDATE_GROUP_USERS_FILTER_DATA> |
  ApiActions<typeof FETCH_USER_GROUP_PAIRS_REQUEST, typeof FETCH_USER_GROUP_PAIRS_SUCCESS, typeof FETCH_USER_GROUP_PAIRS_FAILURE, any[], any> |
  ApiActions<typeof FETCH_GROUP_USERS_REQUEST, typeof FETCH_GROUP_USERS_SUCCESS, typeof FETCH_GROUP_USERS_FAILURE, { users: any[], z02_group_type_id: number, z02_group_name: string }, any> |
  ApiActions<typeof FETCH_USER_GROUPS_REQUEST, typeof FETCH_USER_GROUPS_SUCCESS, typeof FETCH_USER_GROUPS_FAILURE, any[], any>;
