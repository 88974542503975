export const DOWNLOAD_ORDER_PDF_REQUEST = 'firm/orders/DOWNLOAD_ORDER_PDF_REQUEST';
export const DOWNLOAD_ORDER_PDF_SUCCESS = 'firm/orders/DOWNLOAD_ORDER_PDF_SUCCESS';
export const DOWNLOAD_ORDER_PDF_FAILURE = 'firm/orders/DOWNLOAD_ORDER_PDF_FAILURE';

export const DOWNLOAD_FILE_REQUEST = 'firm/orders/DOWNLOAD_FILE_REQUEST';
export const DOWNLOAD_FILE_SUCCESS = 'firm/orders/DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAILURE = 'firm/orders/DOWNLOAD_FILE_FAILURE';

export const FETCH_ORDERS_REQUEST = 'firm/orders/FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'firm/orders/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'firm/orders/FETCH_ORDERS_FAILURE';

export const UPDATE_FIRM_ORDERS_FILTER_DATA = 'firm/orders/UPDATE_FIRM_ORDERS_FILTER_DATA';

export const FETCH_ORDER_DETAIL_REQUEST = 'firm/orders/FETCH_ORDER_DETAIL_REQUEST';
export const FETCH_ORDER_DETAIL_SUCCESS = 'firm/orders/FETCH_ORDER_DETAIL_SUCCESS';
export const FETCH_ORDER_DETAIL_FAILURE = 'firm/orders/FETCH_ORDER_DETAIL_FAILURE';
