export const DOWNLOAD_INVOICES_CSV_REQUEST = 'firm/invoice/DOWNLOAD_INVOICES_CSV_REQUEST';
export const DOWNLOAD_INVOICES_CSV_SUCCESS = 'firm/invoice/DOWNLOAD_INVOICES_CSV_SUCCESS';
export const DOWNLOAD_INVOICES_CSV_FAILURE = 'firm/invoice/DOWNLOAD_INVOICES_CSV_FAILURE';

export const DOWNLOAD_INVOICES_PDF_REQUEST = 'firm/invoice/DOWNLOAD_INVOICES_PDF_REQUEST';
export const DOWNLOAD_INVOICES_PDF_SUCCESS = 'firm/invoice/DOWNLOAD_INVOICES_PDF_SUCCESS';
export const DOWNLOAD_INVOICES_PDF_FAILURE = 'firm/invoice/DOWNLOAD_INVOICES_PDF_FAILURE';

export const FETCH_INVOICES_REQUEST = 'firm/invoice/FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_SUCCESS = 'firm/invoice/FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'firm/invoice/FETCH_INVOICES_FAILURE';

export const FETCH_INVOICE_REPORTS_REQUEST = 'firm/invoice/FETCH_INVOICE_REPORTS_REQUEST';
export const FETCH_INVOICE_REPORTS_SUCCESS = 'firm/invoice/FETCH_INVOICE_REPORTS_SUCCESS';
export const FETCH_INVOICE_REPORTS_FAILURE = 'firm/invoice/FETCH_INVOICE_REPORTS_FAILURE';

export const DOWNLOAD_INVOICE_REPORT_PDF_REQUEST = 'firm/invoice/DOWNLOAD_INVOICE_REPORT_PDF_REQUEST';
export const DOWNLOAD_INVOICE_REPORT_PDF_SUCCESS = 'firm/invoice/DOWNLOAD_INVOICE_REPORT_PDF_SUCCESS';
export const DOWNLOAD_INVOICE_REPORT_PDF_FAILURE = 'firm/invoice/DOWNLOAD_INVOICE_REPORT_PDF_FAILURE';

export const ONLINE_PAY_REQUEST = 'firm/invoice/ONLINE_PAY_REQUEST';
export const ONLINE_PAY_SUCCESS = 'firm/invoice/ONLINE_PAY_SUCCESS';
export const ONLINE_PAY_FAILURE = 'firm/invoice/ONLINE_PAY_FAILURE';

export const UPDATE_FIRM_INVOICES_FILTER_DATA = 'firm/invoice/UPDATE_FIRM_INVOICES_FILTER_DATA';
