import { RSAA } from 'redux-api-middleware';
// eslint-disable-next-line no-unused-vars
import { lastApiAction } from './apiRSAAInjectorFactory';

const apiUserTokenInjectorMiddlewareFactory = (ls) => () => (next) => (action) => {
  const callApi = action[RSAA];

  if (callApi && ls.auth_token) {
    callApi.headers = { ...callApi.headers, Sid: ls.auth_token };

    const newAction = { ...action, [RSAA]: callApi };
    return next(newAction);
  }

  return next(action);
};

export default apiUserTokenInjectorMiddlewareFactory;
