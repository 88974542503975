import matchRoute from './matchRoute';


export default (routingTable, path, authorizedPageKeys) => {
  const matchedRoute = matchRoute(routingTable, path);

  if (matchedRoute) {
    return !matchedRoute.key
      || (matchedRoute.key && authorizedPageKeys.indexOf(matchedRoute.key) !== -1);
  }

  throw new Error('Path does not match to any of defined routes.');
};
