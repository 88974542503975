
export function getExternalLink(url) {
  return url && (url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`);
}

export function capitalizeFirstLetter(str) {
  if (typeof str !== 'string') {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}
