export const FETCH_ATTENDANCE_REQUEST = 'firm/attendance/FETCH_ATTENDANCE_REQUEST';
export const FETCH_ATTENDANCE_SUCCESS = 'firm/attendance/FETCH_ATTENDANCE_SUCCESS';
export const FETCH_ATTENDANCE_FAILURE = 'firm/attendance/FETCH_ATTENDANCE_FAILURE';

export const FETCH_ATTENDANCE_EMPLOYEE_REQUEST = 'firm/attendance/FETCH_ATTENDANCE_EMPLOYEE_REQUEST';
export const FETCH_ATTENDANCE_EMPLOYEE_SUCCESS = 'firm/attendance/FETCH_ATTENDANCE_EMPLOYEE_SUCCESS';
export const FETCH_ATTENDANCE_EMPLOYEE_FAILURE = 'firm/attendance/FETCH_ATTENDANCE_EMPLOYEE_FAILURE';

export const FETCH_ATTENDANCE_DETAIL_REQUEST = 'firm/attendance/FETCH_ATTENDANCE_DETAIL_REQUEST';
export const FETCH_ATTENDANCE_DETAIL_SUCCESS = 'firm/attendance/FETCH_ATTENDANCE_DETAIL_SUCCESS';
export const FETCH_ATTENDANCE_DETAIL_FAILURE = 'firm/attendance/FETCH_ATTENDANCE_DETAIL_FAILURE';

export const DELETE_ATTENDANCE_DETAIL_REQUEST = 'firm/attendance/DELETE_ATTENDANCE_DETAIL_REQUEST';
export const DELETE_ATTENDANCE_DETAIL_SUCCESS = 'firm/attendance/DELETE_ATTENDANCE_DETAIL_SUCCESS';
export const DELETE_ATTENDANCE_DETAIL_FAILURE = 'firm/attendance/DELETE_ATTENDANCE_DETAIL_FAILURE';

export const CREATE_ATTENDANCE_DETAIL_REQUEST = 'firm/attendance/CREATE_ATTENDANCE_DETAIL_REQUEST';
export const CREATE_ATTENDANCE_DETAIL_SUCCESS = 'firm/attendance/CREATE_ATTENDANCE_DETAIL_SUCCESS';
export const CREATE_ATTENDANCE_DETAIL_FAILURE = 'firm/attendance/CREATE_ATTENDANCE_DETAIL_FAILURE';

export const UPDATE_ATTENDANCE_DETAIL_REQUEST = 'firm/attendance/UPDATE_ATTENDANCE_DETAIL_REQUEST';
export const UPDATE_ATTENDANCE_DETAIL_SUCCESS = 'firm/attendance/UPDATE_ATTENDANCE_DETAIL_SUCCESS';
export const UPDATE_ATTENDANCE_DETAIL_FAILURE = 'firm/attendance/UPDATE_ATTENDANCE_DETAIL_FAILURE';

export const UPDATE_ATTENDANCE_FILTER_DATA = 'firm/attendance/UPDATE_ATTENDANCE_FILTER_DATA';

export const DOWNLOAD_FILE_REQUEST = 'firm/attendance/DOWNLOAD_FILE_REQUEST';
export const DOWNLOAD_FILE_SUCCESS = 'firm/attendance/DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAILURE = 'firm/attendance/DOWNLOAD_FILE_FAILURE';
