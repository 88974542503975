import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectAppActive, selectOffline } from '../../modules/global/selectors';

class BaseActiveAppUpdater extends PureComponent {
  timerHandle = null;

  lastUpdate = null;

  constructor(props) {
    super(props);
    this.setupTimer();
  }

  setupTimer() {
    this.clearTimer();
    this.timerHandle = setTimeout(this.handleTimer, this.props.interval);
  }

  clearTimer() {
    clearTimeout(this.timerHandle);
    this.timerHandle = null;
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  componentDidUpdate(prevProps) {
    if (this.props.appActive !== prevProps.appActive || this.props.isOffline !== prevProps.isOffline) {
      this.handleAppStateChanged();
    }
  }

  processUpdate() {
    const prevUpdate = this.lastUpdate;

    if (prevUpdate && (Date.now() - prevUpdate < this.props.minInterval)) {
      return;
    }

    this.lastUpdate = Date.now();
    this.props.onUpdate();
  }

  handleAppStateChanged() {
    // console.log('handleAppStateChanged', this.props.appActive);
    if (this.props.appActive && !this.props.isOffline) {
      this.processUpdate();
      this.setupTimer();
    } else {
      this.clearTimer();
    }
  }

  handleTimer = () => {
    if (this.props.appActive && !this.props.isOffline) {
      this.processUpdate();
      this.setupTimer();
    } else {
      this.clearTimer();
    }
  }

  render() {
    return null;
  }
}

BaseActiveAppUpdater.defaultProps = {
  interval: 60000,
  minInterval: 10000,
};

BaseActiveAppUpdater.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  appActive: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  interval: PropTypes.number,
  minInterval: PropTypes.number,
};

const mapStateToProps = (state) => ({
  appActive: selectAppActive(state),
  isOffline: selectOffline(state),
});

const mapDispatchToProps = () => ({
});

export const ActiveAppUpdater = connect(mapStateToProps, mapDispatchToProps)(BaseActiveAppUpdater);
