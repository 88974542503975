export default (pathForProcessing) => {
  let path = pathForProcessing;
  const pattern = /\(([-a-zA-Z0-9]*)\/?(:[-a-zA-Z0-9]+)\)/gi;
  let optionalParamMatches = null;

  while ((optionalParamMatches = pattern.exec(pathForProcessing))) {
    // This transform React Router v3 optional parameters to v4
    // For example: `/path/sub(/:param)` to `/path/sub/:param?`
    if (optionalParamMatches && optionalParamMatches.length > 0) {
      // remove ( and )
      let replaceMatch = optionalParamMatches[0].replace(/[()]/g, '');
      optionalParamMatches.forEach((optionalParam, idx) => {
        if (idx === 0 || optionalParam === '') {
          return;
        }

        // add ? after parameter
        // or add brackets and ? when part is only optional text before param
        replaceMatch = replaceMatch.replace(
          optionalParam,
          optionalParam[0] === ':' ? `${optionalParam}?` : `(${optionalParam})?`
        );
      });

      path = path.replace(optionalParamMatches[0], replaceMatch);
    }
  }

  return path;
};
