import { browserHistory } from 'react-router';
import { RSAA, getJSON, ApiError } from 'redux-api-middleware';
import { API_URL } from '../../config/envspecific';
import { t, tRoute } from '../../i18n';
import * as types from './actionTypes';
import { addFlashMessage } from '../flashMessages';
import downloadFile from '../../service/downloadFile';


function handleFailureServerMessage(dispatch, json) {
  dispatch(addFlashMessage('error', json.message));
}

function apiFailureServerMessageDescritor(dispatch, type, codes, restCallback) {
  return {
    type,
    payload: (action, state, res) => getJSON(res).then(
      (json) => {
        if (json && json.code && codes.includes(json.code)) {
          handleFailureServerMessage(dispatch, json);
        } else if (restCallback !== undefined) {
          restCallback(dispatch, json);
        }

        return new ApiError(res.status, res.statusText, json);
      }
    ),
  };
}

export const selectContract = (contract) => ({
  type: types.SELECT_CONTRACT,
  payload: contract,
});

export const deleteContract = () => ({
  type: types.DELETE_CONTRACT,
});

export const setOrderBasket = (basket) => ({
  type: types.SET_ORDER_BASKET,
  payload: basket,
});

export const setOrderProductCode = (productCode) => ({
  type: types.SET_ORDER_PRODUCTCODE,
  payload: productCode,
});

export const loadOrderInProgressFailure = (json) => (dispatch) => {
  dispatch(addFlashMessage('error', json && json.message ? json && json.message : t('Nepodařilo se načíst obsah objednávky.', { ns: 'order' })));
  browserHistory.push(tRoute('/firma/objednavka/import'));
};

export const loadOrderInProgress = () => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/basket`,
    method: 'GET',
    types: [
      types.LOAD_ORDER_IN_PROGRESS_REQUEST,
      {
        type: types.LOAD_ORDER_IN_PROGRESS_SUCCESS,
        payload: (action, state, res) => res.text().then((response) => dispatch(setOrderBasket(JSON.parse(response)))),
      },
      apiFailureServerMessageDescritor(
        dispatch,
        types.LOAD_ORDER_IN_PROGRESS_FAILURE,
        ['0143'],
        (d, json) => loadOrderInProgressFailure(json)(dispatch)
      ),
    ],
  },
});

export const requestFileDownload = (contractId) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/basket/${contractId}/template`,
    method: 'GET',
    types: [
      types.FILE_DOWNLOAD_REQUEST,
      {
        type: types.FILE_DOWNLOAD_SUCCESS,
        payload: (action, state, res) => res.blob().then(
          (blob) => downloadFile(blob, `order-${contractId}.xlsx`)
        ),
      },
      apiFailureServerMessageDescritor(
        dispatch,
        types.FILE_DOWNLOAD_FAILURE,
        ['0143'],
        () => dispatch(addFlashMessage('error', t('Stažení souboru selhalo.', { ns: 'order' })))
      ),
    ],
  },
});

export const setValidationChanges = (changes) => ({
  type: types.SET_VALIDATION_CHANGES,
  payload: changes,
});

export const requestCheckChangesSuccess = (orderFileId, res) => (dispatch) => {
  res.json().then((changes) => {
    if (changes.length) {
      dispatch(setValidationChanges(changes));
      browserHistory.push(
        tRoute(`/firma/objednavka/kontrola-importu/potvrzeni-zmen${window.location.search}`)
      );
    } else {
      dispatch(loadOrderInProgress());
      browserHistory.push(
        tRoute(`/firma/objednavka/upravy${window.location.search}`)
      );
    }
  });
};

export const requestCheckChanges = (orderFileId) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/basket/imports/${orderFileId}/check_changes`,
    method: 'GET',
    types: [
      types.CHECK_CHANGES_REQUEST,
      {
        type: types.CHECK_CHANGES_SUCCESS,
        payload: (action, state, res) => requestCheckChangesSuccess(orderFileId, res)(dispatch),
      },
      types.CHECK_CHANGES_FAILURE,
    ],
  },
});

export const setValidationAddressesErrors = (errors) => ({
  type: types.SET_VALIDATION_ADDRESSES_ERRORS,
  payload: errors,
});

export const requestImportValidationAddressesSuccess = (orderFileId, res) => (dispatch) => {
  res.json().then((errors) => {
    if (errors.length) {
      dispatch(setValidationAddressesErrors(errors));
      browserHistory.push(tRoute(`/firma/objednavka/kontrola-importu/adresy${window.location.search}`));
    } else {
      dispatch(requestCheckChanges(orderFileId));
    }
  });
};

export const requestImportValidationAddresses = (orderFileId) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/basket/imports/${orderFileId}/check_addresses`,
    method: 'GET',
    types: [
      types.VALIDATION_ADDRESSES_REQUEST,
      {
        type: types.VALIDATION_ADDRESSES_SUCCESS,
        payload: (action, state, res) => requestImportValidationAddressesSuccess(orderFileId, res)(dispatch),
      },
      types.VALIDATION_ADDRESSES_FAILURE,
    ],
  },
});

export const setValidationNamesErrors = (errors) => ({
  type: types.SET_VALIDATION_NAMES_ERRORS,
  payload: errors,
});

export const requestImportValidationNamesSuccess = (orderFileId, res) => (dispatch) => {
  res.json().then((errors) => {
    if (errors.length) {
      Promise.resolve(dispatch(setValidationNamesErrors(errors))).then(() => {
        browserHistory.push(tRoute(`/firma/objednavka/kontrola-importu/jmena-na-karte${window.location.search}`));
      });
    } else {
      dispatch(requestImportValidationAddresses(orderFileId));
    }
  });
};

export const requestImportValidationNames = (orderFileId) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/basket/imports/${orderFileId}/check_names`,
    method: 'GET',
    types: [
      types.VALIDATION_NAMES_REQUEST,
      {
        type: types.VALIDATION_NAMES_SUCCESS,
        payload: (action, state, res) => requestImportValidationNamesSuccess(orderFileId, res)(dispatch),
      },
      types.VALIDATION_NAMES_FAILURE,
    ],
  },
});

export const setOrderFileId = (fileId) => ({
  type: types.SET_ORDER_FILE_ID,
  payload: fileId,
});

export const deleteOrderFileId = () => ({
  type: types.DELETE_ORDER_FILE_ID,
});

export const setOrderInProgress = (orderInProgress) => ({
  type: types.SET_ORDER_IN_PROGRESS,
  payload: orderInProgress,
});

export const createImportFileRequestSuccess = (action, state, res) => (dispatch) => {
  res.json().then((response) => {
    const orderFileId = response.o50_order_file;
    dispatch(setOrderInProgress(true));
    dispatch(setOrderFileId(orderFileId));
    dispatch(requestImportValidationNames(orderFileId));
  });
};

export const setInitializationErrors = (errors) => ({
  type: types.SET_INITIALIZATION_ERRORS,
  payload: errors,
});

export const createDeleteLastImportFileRequest = (fileId) => ({
  [RSAA]: {
    endpoint: `${API_URL}/basket/imports/${fileId}`,
    method: 'DELETE',
    types: [
      'REQUEST_TYPE',
      'SUCCESS_TYPE',
      'FAILURE_TYPE',
    ],
  },
});

export const createImportFileRequestFailure = (action, state, res) => (dispatch, getState) => getJSON(res).then((json) => {
  if (json && json.code === '0112') {
    const fileId = getState().getIn(['order', 'steps', 'initialization', 'fileId']);
    Promise.resolve(dispatch(createDeleteLastImportFileRequest(fileId))).then(() => dispatch(action));
  } else if ((res.status === 400 && json && json.code && json.message) || (json && json.code === '0147')) {
    dispatch(addFlashMessage('error', t('Chyba importu: {{message}}', { ns: 'order', message: json.message })));
  } else if ((res.status === 500 && json && json.code)) {
    dispatch(addFlashMessage('error', json.message ? t('Chyba importu: {{message}}', { ns: 'order', message: json.message }) : t('Chyba importu')));
  } else if (res.status !== 500) {
    dispatch(setInitializationErrors(json));
    browserHistory.push(tRoute('/firma/objednavka/import/chyby'));
  }

  return res;
});

// eslint-disable-next-line
export const uploadFile = (file, contractId, productCode, branchId) => (dispatch, getState) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('s20_code', productCode);

  if (branchId) {
    formData.append('c85_branch_id', branchId);
  }

  return dispatch({
    [RSAA]: {
      endpoint: `${API_URL}/basket/${contractId}/import`,
      method: 'POST',
      body: formData,
      types: [
        types.IMPORT_FILE_REQUEST,
        {
          type: types.IMPORT_FILE_SUCCESS,
          payload: (action, state, res) => createImportFileRequestSuccess(action, state, res)(dispatch),
        },
        {
          type: types.IMPORT_FILE_FAILURE,
          payload: (action, state, res) => createImportFileRequestFailure(action, state, res)(dispatch, getState),
        },
      ],
    },
  });
};

export const requestDeleteOrder = () => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/basket`,
    method: 'DELETE',
    types: [
      types.DELETE_ORDER_REQUEST,
      types.DELETE_ORDER_SUCCESS,
      apiFailureServerMessageDescritor(dispatch, types.DELETE_ORDER_FAILURE, ['0143']),
    ],
  },
}).then(() => {
  dispatch(deleteOrderFileId());
  dispatch(deleteContract());
  browserHistory.push(tRoute('/'));
});

export const requestDeleteCardFailure = () => (dispatch) => {
  dispatch(addFlashMessage(
    'error',
    t('Řádek není možné smazat. Pravděpodobně obsahuje povinnou kartu, nelze samostatně smazat.', { ns: 'order' })
  ));
};

export const requestDeleteFreeCard = (lineId) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/basket/cards/${lineId}`,
    method: 'DELETE',
    types: [
      types.DELETE_CARD_REQUEST,
      {
        type: types.DELETE_CARD_SUCCESS,
        payload: () => ({
          lineId,
          types: ['free_cards'],
        }),
      },
      {
        type: types.DELETE_CARD_FAILURE,
        meta: () => requestDeleteCardFailure()(dispatch),
      },
    ],
  },
});

export const requestDeleteCard = (lineId) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/basket/cards/${lineId}`,
    method: 'DELETE',
    types: [
      types.DELETE_CARD_REQUEST,
      {
        type: types.DELETE_CARD_SUCCESS,
        payload: () => ({
          lineId,
          types: ['new_cards'],
        }),
      },
      {
        type: types.DELETE_CARD_FAILURE,
        meta: () => requestDeleteCardFailure()(dispatch),
      },
    ],
  },
});

export const requestCreateFreeCardSuccess = (data) => (dispatch) => {
  dispatch(addFlashMessage(
    'success',
    t('Karta byla úspěšně vytvořena.', { ns: 'order' })
  ));
  dispatch(loadOrderInProgress());
  return data;
};

export const requestCreateFreeCardFailure = () => (dispatch) => {
  dispatch(addFlashMessage(
    'error',
    t('Nepodařilo se vytvořit novou prázdnou kartu.', { ns: 'order' })
  ));
};

export const requestCreateFreeCard = (data) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/basket/free_cards`,
    method: 'POST',
    body: JSON.stringify(data),
    types: [
      types.CREATE_FREE_CARD_REQUEST,
      {
        type: types.CREATE_FREE_CARD_SUCCESS,
        payload: () => requestCreateFreeCardSuccess(data)(dispatch),
      },
      {
        type: types.CREATE_FREE_CARD_FAILURE,
        meta: () => requestCreateFreeCardFailure()(dispatch),
      },
    ],
  },
});

export const addNameBeingValidated = (cardId) => ({
  type: types.ADD_NAME_BEING_VALIDATED,
  payload: { id: cardId },
});

export const removeNameBeingValidated = (cardId) => ({
  type: types.REMOVE_NAME_BEING_VALIDATED,
  payload: { id: cardId },
});

export const requestEditOrderCard = (data, cardId) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/basket/card/${cardId}`,
    method: 'PUT',
    body: JSON.stringify(data),
    types: [
      types.EDIT_ORDER_CARD_REQUEST,
      {
        type: types.EDIT_ORDER_CARD_SUCCESS,
        payload: () => ({ id: cardId, data }),
      },
      types.EDIT_ORDER_CARD_FAILURE,
    ],
  },
});

// PO24
export const requestEditOrderCredit = (data, creditId) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/basket/credit/${creditId}`,
    method: 'PUT',
    body: JSON.stringify(data),
    types: [
      types.EDIT_ORDER_CREDIT_REQUEST,
      {
        type: types.EDIT_ORDER_CREDIT_SUCCESS,
        payload: () => ({ id: creditId, data }),
      },
      types.EDIT_ORDER_CREDIT_FAILURE,
    ],
  },
});

export const setFilter = (filterSettings) => ({
  type: types.SET_ORDER_FILTER,
  payload: filterSettings,
});

export const setOrderOverview = (overview) => ({
  type: types.SET_ORDER_OVERVIEW,
  payload: overview,
});

export const requestOrderOverview = () => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/basket/overview`,
    method: 'GET',
    types: [
      types.ORDER_OVERVIEW_REQUEST,
      {
        type: types.ORDER_OVERVIEW_SUCCESS,
        payload: (action, state, res) => res.text().then((response) => dispatch(setOrderOverview(JSON.parse(response)))),
      },
      types.ORDER_OVERVIEW_FAILURE,
    ],
  },
});

export const requestEditOrder = (data) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/basket/parameters`,
    method: 'PUT',
    body: JSON.stringify(data),
    types: [
      types.EDIT_ORDER_REQUEST,
      {
        type: types.EDIT_ORDER_SUCCESS,
        payload: () => {
          if ('i04_payment_type_id' in data) {
            dispatch(requestOrderOverview());
          }
          return data;
        },
      },
      types.EDIT_ORDER_FAILURE,
    ],
  },
});


const setCardAddressChangeSuccess = (data) => ({
  type: types.SET_CARD_ADDRESS_CHANGE_SUCCESS,
  payload: data,
});

const setCardAddressChangeFailure = (data) => ({
  type: types.SET_CARD_ADDRESS_CHANGE_FAILURE,
  payload: data,
});

export const requestCardAddressChange = (data) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/basket/addresses_spec`,
    method: 'PUT',
    body: JSON.stringify([data]),
    types: [
      types.CARD_ADDRESS_CHANGE_REQUEST,
      {
        type: types.CARD_ADDRESS_CHANGE_SUCCESS,
        payload: () => dispatch(setCardAddressChangeSuccess(data)),
      },
      {
        type: types.CARD_ADDRESS_CHANGE_FAILURE,
        meta: () => dispatch(setCardAddressChangeFailure(data)),
      },
    ],
  },
});

export const requestCompleteOrder = () => (dispatch) => {
  dispatch({
    [RSAA]: {
      endpoint: `${API_URL}/basket`,
      method: 'POST',
      types: [
        types.COMPLETE_ORDER_REQUEST,
        {
          type: types.COMPLETE_ORDER_SUCCESS,
          payload: (action, state, res) => {
            dispatch(deleteOrderFileId());
            dispatch(deleteContract());
            return res.json().then((data) => data);
          },
        },
        apiFailureServerMessageDescritor(
          dispatch,
          types.COMPLETE_ORDER_FAILURE,
          ['0143'],
          (d, json) => dispatch(addFlashMessage('error', json.message))
        ),
      ],
    },
  }).then((response) => {
    const { payload, type } = response;

    if (type === types.COMPLETE_ORDER_SUCCESS) {
      if (payload.redirect) {
        window.location.href = payload.redirect;
      }
    }
    browserHistory.push(tRoute('/firma/objednavka/platba'));
  });
};

export const requestOnlineConfirm = (id, redirectToError = true) => (dispatch) => {
  if (!id) {
    dispatch(addFlashMessage('error', t('Zadané externí ID nebylo nalezeno', { ns: 'order' })));
    browserHistory.push(tRoute('/firma/objednavka/import'));
  }

  dispatch({
    [RSAA]: {
      endpoint: `${API_URL}/basket/online_confirm/${id}`,
      method: 'PUT',
      types: [
        types.ONLINE_CONFIRM_REQUEST,
        types.ONLINE_CONFIRM_SUCCESS,
        types.ONLINE_CONFIRM_FAILURE,
      ],
    },
  }).then((response) => {
    if (response.error && response.type === types.ONLINE_CONFIRM_FAILURE) {
      if (response.payload.response.code === '9995') {
        dispatch(addFlashMessage('error', response.payload.response.message));
        browserHistory.push(tRoute('/firma/objednavka/import'));
      }

      if (redirectToError) {
        browserHistory.push(tRoute(`/firma/objednavka/prijata?id=${id}`));
      }
    }

    return true;
  });
};

export const requestOnlinePay = (invoiceId) => (dispatch) => {
  dispatch({
    [RSAA]: {
      endpoint: `${API_URL}/firms_invoices/${invoiceId}/online_pay`,
      method: 'PUT',
      types: [
        types.ONLINE_PAY_REQUEST,
        types.ONLINE_PAY_SUCCESS,
        types.ONLINE_PAY_FAILURE,
      ],
    },
  }).then((response) => {
    const { payload, type } = response;

    if (type === types.ONLINE_PAY_SUCCESS) {
      if (payload.redirect) {
        window.location.href = payload.redirect;
      }
    }

    return false;
  });
};

export const fetchDeliveryAddressPairs = () => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/basket/delivery_addresses`,
    method: 'GET',
    types: [
      types.LOAD_DELIVERY_ADDRESSES_REQUEST,
      types.LOAD_DELIVERY_ADDRESSES_SUCCESS,
      types.LOAD_DELIVERY_ADDRESSES_FAILURE,
    ],
  },
});

export const fetchCardImage = () => ({
  [RSAA]: {
    headers: { 'Content-Type': 'image/svg+xml' },
    endpoint: `${API_URL}/basket/card_image`,
    method: 'GET',
    types: [
      types.FETCH_CARD_IMAGE_REQUEST,
      {
        type: types.FETCH_CARD_IMAGE_SUCCESS,
        payload: (action, state, res) => res.text((text) => text),
      },
      types.FETCH_CARD_IMAGE_FAILURE,
    ],
  },
});

export const fetchCardPersonalization = (orderLineTempId) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/basket/card_perso/${orderLineTempId}`,
    method: 'GET',
    types: [
      types.FETCH_CARD_PERSONALIZATION_REQUEST,
      types.FETCH_CARD_PERSONALIZATION_SUCCESS,
      types.FETCH_CARD_PERSONALIZATION_FAILURE,
    ],
  },
});

export const fetchOrderProducts = (contractId) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/basket/products/${contractId}`,
    method: 'GET',
    types: [
      types.FETCH_PRODUCTS_REQUEST,
      types.FETCH_PRODUCTS_SUCCESS,
      types.FETCH_PRODUCTS_FAILURE,
    ],
  },
});

export const redirectToValidation = () => ({
  type: types.REDIRECT_TO_VALIDATION,
});

export const resetRedirectToValidation = () => ({
  type: types.RESET_REDIRECT_TO_VALIDATION,
});

export const redirectToContractSelection = () => ({
  type: types.REDIRECT_TO_CONTRACT_SELECTION,
});

export const resetRedirectToContractSelection = () => ({
  type: types.RESET_REDIRECT_TO_CONTRACT_SELECTION,
});

export const redirectToAdjustment = () => ({
  type: types.REDIRECT_TO_ADJUSTMENT,
});

export const resetRedirectToAdjustment = () => ({
  type: types.RESET_REDIRECT_TO_ADJUSTMENT,
});
