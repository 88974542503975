import { createSelector } from 'reselect';

const getPartner = (state) => {
  const partner = state.getIn(['partner', 'partner', 'partnerDetail', 'data']);
  return partner ? partner.toJS() : null;
};
const getBankAccounts = (state) => state.getIn(['partner', 'partner', 'bankAccountsList', 'bankAccounts']).toJS();
const getPartnerPairs = (state) => state.getIn(['partner', 'partner', 'partnerPairsList', 'partnerPairs']).toJS();

export const selectBankAccountsForTable = createSelector(
  [getBankAccounts],
  (bankAccounts) => bankAccounts.map((account) => account)
);
export const selectPartner = createSelector([getPartner], (partner) => partner);
export const selectPartnerPairs = createSelector([getPartnerPairs], (partnerPairs) => partnerPairs);
