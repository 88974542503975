import PropTypes from 'prop-types';
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import Icon from '../Icon/Icon';
import styles from './ProductButton.scss';
import { PropTypeProduct, PropTypeProductCode } from '../../modules/global/proptypes';
import { benefiaIcons } from './BenefiaIcons';

export interface IconProps {
  color: string;
  style: React.CSSProperties;
  size?: string;
}

const smallGD: React.FC<IconProps> = ({ color, style }) => (
  <SvgIcon width="22.13px" height="19.764px" viewBox="0 0 22.13 19.764" style={style}>
    <g>
      <path fill={color || '#FFFFFF'} d="M13.467,0.709c0,0-0.09,2.447-2.325,2.447h-0.152c-2.233,0-2.325-2.447-2.325-2.447V0l4.803,0.07V0.709z" />
      <path
        fill={color || '#FFFFFF'}
        d="M18.71,1.005c-0.47-0.47-1.514-0.718-2.641-0.848c-0.003,0.068-0.219,5.094-4.844,5.094h-0.318 c-4.622,0-4.841-5.029-4.844-5.094C4.937,0.286,3.891,0.535,3.421,1.005C2.157,2.269,1.092,3.235,0,9.926l3.867,1.091l0.694-2.479 v9.788c0,0-0.051,1.438,1.436,1.438c1.487,0,2.875,0,2.875,0h4.387c0,0,1.388,0,2.873,0c1.488,0,1.438-1.438,1.438-1.438V8.538 l0.695,2.479l3.865-1.091C21.039,3.235,19.974,2.269,18.71,1.005z"
      />
    </g>
  </SvgIcon>
);

const largeGD: React.FC<IconProps> = ({ color, style }) => (
  <SvgIcon width="53.409px" height="52.241px" viewBox="0 0 53.409 52.241" style={style}>
    <path
      fill={color || '#FFFFFF'}
      d="M36.992,49.994H17.297c-1.124,0-2.027-0.346-2.688-1.028c-1.036-1.071-1.017-2.537-1.011-2.701
l-0.001-13.387c0,0,0,0-0.001,0c-0.209,0.118-0.459,0.146-0.688,0.082l-7.515-2.12c-0.443-0.125-0.722-0.562-0.647-1.016
c2.133-13.068,4.282-15.218,6.772-17.707l0.126-0.125c1.438-1.438,5.054-2.165,10.745-2.167h9.51
c5.691,0.002,9.306,0.729,10.741,2.166l0.124,0.124c2.492,2.488,4.646,4.638,6.776,17.709c0.073,0.454-0.204,0.891-0.647,1.016
l-7.513,2.12c-0.23,0.064-0.479,0.036-0.688-0.081c-0.001-0.001-0.002-0.002-0.003-0.002v13.419c0.005,0.132,0.025,1.598-1.011,2.67
C39.022,49.648,38.116,49.994,36.992,49.994z M14.501,26.368c0.041,0,0.082,0.003,0.124,0.009c0.446,0.063,0.78,0.443,0.78,0.896
v19.022c-0.001,0.032-0.01,0.892,0.513,1.421c0.31,0.315,0.761,0.47,1.379,0.47h19.695c0.618,0,1.069-0.153,1.379-0.47
c0.521-0.529,0.513-1.388,0.512-1.396V27.272c0-0.452,0.334-0.834,0.78-0.896c0.438-0.064,0.871,0.216,0.994,0.651l1.104,3.944
l5.862-1.653c-1.99-11.785-3.915-13.706-6.138-15.924l-0.126-0.125c-1.022-1.023-4.561-1.637-9.462-1.638h-9.508
c-4.902,0.001-8.441,0.613-9.467,1.638l-0.126,0.126c-2.221,2.219-4.145,4.141-6.135,15.923l5.863,1.653l1.104-3.943
C13.74,26.633,14.102,26.368,14.501,26.368z"
    />
    <path
      fill={color || '#FFFFFF'}
      d="M27.379,19.242h-0.47c-6.074,0-7.881-5.452-7.986-8.335c-0.02-0.498,0.37-0.916,0.868-0.937
c0.012-0.001,0.022-0.001,0.035-0.001c0.482,0,0.884,0.383,0.902,0.868c0.012,0.271,0.34,6.595,6.181,6.595h0.47
c5.841,0,6.169-6.324,6.181-6.595c0.02-0.499,0.481-0.918,0.938-0.867c0.497,0.021,0.887,0.438,0.867,0.937
C35.26,13.79,33.453,19.242,27.379,19.242z"
    />
    <path
      fill={color || '#FFFFFF'}
      d="M26.303,7.649c-14.298,0-23.395-2.567-23.529-2.606c-0.479-0.139-0.755-0.64-0.616-1.12
c0.14-0.479,0.638-0.758,1.12-0.616C3.476,3.365,23.506,8.998,50.94,3.29c0.487-0.107,0.969,0.211,1.069,0.7
c0.103,0.489-0.212,0.968-0.7,1.068C41.98,7.002,33.485,7.649,26.303,7.649z"
    />
    <path
      fill={color || '#FFFFFF'}
      d="M37.783,14.343c-0.689,0-1.25-0.56-1.25-1.25V3.545c0-0.689,0.561-1.25,1.25-1.25
c0.69,0,1.25,0.561,1.25,1.25v9.548C39.033,13.784,38.475,14.343,37.783,14.343z"
    />
    <path
      fill={color || '#FFFFFF'}
      d="M16.591,14.343c-0.69,0-1.25-0.56-1.25-1.25V3.545c0-0.689,0.56-1.25,1.25-1.25
c0.689,0,1.25,0.561,1.25,1.25v9.548C17.841,13.784,17.281,14.343,16.591,14.343z"
    />
  </SvgIcon>
);

function iconGD(props: IconProps) {
  return (props.size === 'small' || !props.size) ? smallGD(props) : largeGD(props);
}

const iconX: React.FC<IconProps> = ({ color, style }) => (
  <SvgIcon height="512" viewBox="0 0 512 512" width="512" style={style}>
    <g>
      <path fill={color || '#FFFFFF'} d="m393.915 173.554-48.669-42.18c4.433-11.205 6.754-23.226 6.754-35.374 0-52.93-43.07-96-96-96-49.43 0-90.27 37.57-95.44 85.65-.37 3.4-.56 6.85-.56 10.35 0 1.97.06 3.93.18 5.89l.002.035c.072 1.288 1.582 1.944 2.573 1.118l32.117-26.761c.221-.184.385-.427.477-.699 2.097-6.242 5.146-12.058 8.961-17.272 11.65-15.941 30.48-26.311 51.69-26.311 35.29 0 64 28.71 64 64 0 4.191-.413 8.358-1.219 12.438l-10.166-8.811c-14.603-12.657-33.289-19.627-52.615-19.627-19.325 0-38.011 6.97-52.616 19.627l-7.907 6.852c-1.421 1.231-2.039 3.158-1.587 4.983 1.398 5.635 3.558 11.068 6.434 16.124 8.394 14.756 22.214 25.378 38.111 29.935.892.256 1.795-.319 2.009-1.222 1.704-7.196 8.256-12.515 16.024-12.293 8.727.25 15.531 7.709 15.531 16.439v31.102c0 8.615-6.621 16.028-15.226 16.434-9.187.434-16.773-6.888-16.773-15.981v-.002c0-.781-.56-1.442-1.329-1.584-27.585-5.088-52.014-22.137-66.159-47.003-1.111-1.951-2.148-3.941-3.113-5.963-1.422-2.98-5.283-3.789-7.778-1.627l-43.538 37.733c-14.034 12.163-22.083 29.791-22.083 48.364v242.082c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-242.082c0-18.572-8.05-36.2-22.085-48.364zm-225.915 114.446c0-17.645 14.355-32 32-32s32 14.355 32 32-14.355 32-32 32-32-14.355-32-32zm31.989 144.001c-3.434 0-6.892-1.1-9.812-3.371-6.975-5.425-8.231-15.478-2.807-22.453l112-144c5.426-6.975 15.479-8.23 22.453-2.807 6.975 5.425 8.231 15.478 2.807 22.453l-112 144c-3.154 4.055-7.873 6.178-12.641 6.178zm112.011-.001c-17.645 0-32-14.355-32-32s14.355-32 32-32 32 14.355 32 32-14.355 32-32 32z" />
    </g>
  </SvgIcon>
);

const iconRP: React.FC<IconProps> = ({ color, style }) => (
  <SvgIcon width="232pt" height="168.70053pt" viewBox="0 0 232 168.70053" style={style}>
    <g transform="matrix(0.1,0,0,-0.1,-9,209.70052)" fill={color || '#FFFFFF'}>
      <path d="m 1241,1780 c -102,-176 -187,-320 -190,-320 -4,0 -44,68 -91,150 -47,82 -88,149 -91,150 -7,0 -779,-1331 -779,-1343 0,-4 522,-7 1160,-7 638,0 1160,3 1160,8 -1,14 -965,1676 -975,1679 -5,1 -93,-141 -194,-317 z m 546,-580 c 192,-333 355,-615 362,-627 l 12,-23 h -113 -113 l -246,427 c -136,235 -250,428 -255,430 -5,1 -47,-65 -94,-147 -46,-81 -88,-146 -92,-144 -4,2 -30,45 -58,94 l -52,90 147,256 c 81,140 149,254 150,252 2,-2 160,-275 352,-608 z m -768,5 c 78,-134 141,-248 141,-252 0,-4 -51,-96 -113,-203 L 934,555 636,552 c -163,-1 -295,1 -293,5 97,178 522,901 527,898 5,-3 72,-115 149,-250 z m 590,-390 150,-260 -321,-3 c -177,-1 -323,-1 -325,1 -2,3 70,131 159,286 150,260 163,279 174,259 7,-13 80,-140 163,-283 z" />
    </g>
  </SvgIcon>
);

export function getProductIcon(code: string) {
  switch (code) {
    case 'GK':
      return 'restaurant';
    case 'GF':
      return 'favorite';
    case 'RP':
      return iconRP;
    case 'GD':
      return iconGD;
    default:
  }

  if (code.startsWith('X')) {
    return iconX;
  }

  // benefia products
  if (benefiaIcons[code]) {
    return benefiaIcons[code];
  }

  return 'payment';
}

export const ProductIcon: React.FC<{ code: string, color?: string, size?: string, style?: React.CSSProperties }> = (props) => (
  <Icon icon={getProductIcon(props.code)} {...props} />
);

ProductIcon.propTypes = {
  code: PropTypeProductCode.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.shape({}),
};

const productButtonPropTypes = {
  product: PropTypeProduct.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ProductButton: React.FC<PropTypes.InferProps<typeof productButtonPropTypes>> = (props) => {
  const { product } = props;

  return (
    <button type="button" className={product.disabled ? styles.disabledButton : styles.button} onClick={() => props.onClick(product.s20_code)}>
      <ProductIcon code={product.s20_code} size="xx1large" />
      <span className={styles.label}>{product.s20_name}</span>
      <span className={styles.subLabel}>{product.s20_desc}</span>
    </button>
  );
};

ProductButton.propTypes = productButtonPropTypes;

export default ProductButton;
