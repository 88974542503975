import React from 'react';
import PropTypes from 'prop-types';
import styles from './GatePaymentDialog.scss';
import Dialog from '../../../../../component1/Dialog/Dialog';
import { PropTypePayGateTransaction } from '../../../../global/proptypes';
import { getTransactionStatus, PayGateTransactionStatusBanner } from '../../../../../component1/PayGateTransactionsList/PayGateTransactionsList';
import ViewField from '../../../../../component1/ViewField/ViewField';
import { dateTimeApiToGUI, dateApiToGUI } from '../../../../../service/date';
import { moneyFormat } from '../../../../../service/number';
import TextField from '../../../../../component1/TextField/TextField';
import * as validation from '../../../../../service/validation';

export class GatePaymentDialog extends React.Component {
  state = {
    transactionAction: null,
    pin: null,
    pinError: '',
  };

  handleTransactionConfirm = async () => {
    if (!this.validatePIN()) {
      return false;
    }

    return this.processTransaction(true, this.state.pin);
  };

  handleTransactionReject = async () => this.processTransaction(false, this.state.pin);

  handleTransactionClose = () => {
    this.setState({ transactionAction: null, pin: null });
    this.props.onClose();
  };

  handleTransactionAction(action) {
    this.setState({ transactionAction: action });
    return false;
  }

  validatePIN() {
    const error = validation.required(this.state.pin) || validation.exactLength(4)(this.state.pin);
    this.setState({ pinError: error || '' });
    return !error;
  }

  async processTransaction(confirmed, pin) {
    const ret = await this.props.onSave(this.props.payment.n55_gate_transaction_id, confirmed, pin);

    if (!ret.error) {
      this.handleTransactionClose();
    }

    return ret;
  }

  render() {
    const {
      t, payment,
    } = this.props;

    if (!payment) {
      return null;
    }

    const opennedTransactionStatus = payment && getTransactionStatus(payment);
    const transactionAction = this.state.transactionAction;

    let dialogActions;
    let dialogTitle;

    if (!transactionAction) {
      dialogTitle = t('Detail platby');
      dialogActions = [
        {
          label: t('Zavřít'),
          onClick: this.handleTransactionClose,
        },
        ['waiting', 'success'].includes(opennedTransactionStatus) ? {
          label: t('Zamítnout'),
          onClick: () => this.handleTransactionAction('reject'),
        } : null,
        opennedTransactionStatus === 'waiting' ? {
          label: t('Potvrdit'),
          onClick: () => this.handleTransactionAction('confirm'),
          primary: true,
        } : null,
      ];
    } else {
      dialogTitle = transactionAction === 'confirm' ? t('Potvrzení platby') : t('Zrušení platby');
      dialogActions = [
        {
          label: t('Zavřít'),
          onClick: this.handleTransactionClose,
        },
        transactionAction === 'confirm' ? {
          label: t('Potvrdit platbu'),
          onClick: this.handleTransactionConfirm,
          primary: true,
        } : {
          label: t('Zamítnout platbu'),
          onClick: this.handleTransactionReject,
          primary: true,
        },
      ];
    }

    return payment && (
    <Dialog
      title={dialogTitle}
      onHandleClose={this.handleTransactionClose}
      actions={dialogActions}
      beforeBodyElement={<PayGateTransactionStatusBanner transaction={payment} t={t} />}
      forceOpen
      hasCross
      isNarrow
    >
      {!transactionAction && (
        <>
          <ViewField label={t('Objednávka')} value={payment.n55_ext_id} />
          <ViewField label={t('Vytvořeno')} value={dateTimeApiToGUI(payment.n55_date_created)} />
          {payment.date_authorized && <ViewField label={t('Autorizováno uživatelem')} value={dateTimeApiToGUI(payment.date_authorized)} />}
          <ViewField label={t('Karta')} value={payment.p20_pan} />
          <ViewField label={t('Pobočka')} value={payment.full_address} />
          <ViewField label={t('Částka')} value={moneyFormat(payment.n55_value, t)} />
          {payment.n55_date_use !== null && <ViewField label={t('Datum začátku rekreace')} value={dateApiToGUI(payment.n55_date_use)} />}
          <ViewField label={t('Popis')} value={payment.n55_description} />
          <ViewField label={t('Stav')} value={payment.n58_gate_trans_status} />
          <ViewField label={t('Popis chyby')} value={payment.ac_error_message} />
        </>
      )}
      {transactionAction === 'reject' && <p>{t('Opravdu chcete zrušit požadavek na platbu?')}</p>}
      {transactionAction === 'confirm' && (
        <>
          <p>{t('Platbu můžete potvrdit sami zadáním 4-místného PINu od držitele karty:')}</p>
          <div className={styles.pinContainer}>
            <TextField
              fieldId="tf-n55_value"
              label={t('PIN')}
              value={this.state.pin}
              onChange={(e, val) => this.setState({ pin: val })}
              errorText={this.state.pinError}
            />
          </div>
        </>
      )}
    </Dialog>
    );
  }
}

GatePaymentDialog.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  payment: PropTypePayGateTransaction,
};
