import { authFactory } from '../modules/auth/Auth';
import { generatePath } from '.';

export function onEnterHookLogged(nextState, replace) {
  if (!window.landingPathname) {
    window.landingPathname = window.location.pathname;
  }
  const auth = authFactory();
  if (!auth.isLoggedIn()) {
    const loginUrl = generatePath('login', { r: 'r' }, []);
    replace(loginUrl);
  }
}
