import PropTypes from 'prop-types';
import React from 'react';
import RaisedButton from '../../../../../component1/RaisedButton/RaisedButton';
import styles from './CreditCardLink.scss';

export const CreditCardNotLinked = ({ t, onClick }) => (
  <div className={`${styles.container} ${styles.centered}`}>
    <p className={styles.noMargin}>{t('Propojte si platební kartu s Gustem. Už nikdy nebudete muset řešit, jestli máte na kartě dostatečný zůstatek.')}</p>
    <img className={styles.logo} src="/images/cc-link.svg" alt={t('Připojit platební kartu')} />
    <RaisedButton label={t('Zjistit více')} priority="primary" size="large" onClick={onClick} />
  </div>
);

CreditCardNotLinked.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};
