import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../TextField/TextField';
import { normalizeCardNo } from '../../service/number';

const CardNoField = (props) => {
  const { value, onChange, ...rest } = props;

  return (
    <TextField
      type="text"
      value={normalizeCardNo(value)}
      onChange={(e, val) => onChange(e, normalizeCardNo(val).replace(' ', ''))}
      fieldId="tf-cardno-field"
      {...rest}
    />
  );
};

CardNoField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CardNoField;
