import { RSAA } from 'redux-api-middleware';
import { browserHistory } from 'react-router';
import { API_URL } from '../../../../config/envspecific';
import { t, tRoute } from '../../../../i18n';
import * as types from './actionTypes';
import {
  addFlashMessage,
} from '../../../flashMessages/actions';
import downloadFile from '../../../../service/downloadFile';


export const fetchOrders = (queryString, payload, callback) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/my_orders?${queryString}`,
    method: 'GET',
    types: [
      { type: types.FETCH_ORDERS_REQUEST, payload },
      types.FETCH_ORDERS_SUCCESS,
      types.FETCH_ORDERS_FAILURE,
    ],
  },
}).then(() => callback());

export const fetchOrder = (id) => (dispatch) => {
  if (!id) {
    dispatch(addFlashMessage('error', t('Zadaná objednávka nebyla nalezena.', { ns: 'my' })));
    browserHistory.push(tRoute('/moje/objednavky'));
  }

  dispatch({
    [RSAA]: {
      endpoint: `${API_URL}/my_orders?filter=o01_order_id:${id}`,
      method: 'GET',
      types: [
        types.FETCH_ORDER_REQUEST,
        types.FETCH_ORDER_SUCCESS,
        types.FETCH_ORDER_FAILURE,
      ],
    },
  }).then((response) => {
    if (response.payload instanceof Array && !response.payload.length) {
      dispatch(addFlashMessage('error', t('Zadaná objednávka nebyla nalezena.', { ns: 'my' })));
      browserHistory.push(tRoute('/moje/objednavky'));
    }

    return true;
  });
};

export const updateOrderListFilterData = (filterData, orderColumnData) => ({
  type: types.UPDATE_ORDERS_FILTER_DATA,
  payload: {
    filter: filterData,
    orderBy: orderColumnData,
  },
});

export const requestOnlineConfirm = (id, redirectToError = true) => (dispatch) => {
  if (!id) {
    dispatch(addFlashMessage('error', t('Zadané externí ID nebylo nalezeno', { ns: 'my' })));
    browserHistory.push(tRoute('/moje/objednavka/karty'));
  }

  dispatch({
    [RSAA]: {
      endpoint: `${API_URL}/my_basket/online_confirm/${id}`,
      method: 'PUT',
      types: [
        types.ONLINE_CONFIRM_REQUEST,
        types.ONLINE_CONFIRM_SUCCESS,
        types.ONLINE_CONFIRM_FAILURE,
      ],
    },
  }).then((response) => {
    if (response.error && response.type === types.ONLINE_CONFIRM_FAILURE) {
      if (response.payload.response.code === '9995') {
        return Promise.resolve(dispatch(addFlashMessage('error', response.payload.response.message))).then(() => {
          browserHistory.push(tRoute('/moje/objednavka/karty'));
        });
      }

      if (redirectToError) {
        browserHistory.push(tRoute(`/moje/objednavka/prijata?id=${id}`));
      }
    }

    return true;
  });
};

export const requestOnlinePay = (invoiceId) => (dispatch) => {
  dispatch({
    [RSAA]: {
      endpoint: `${API_URL}/my_orders/${invoiceId}/online_pay`,
      method: 'PUT',
      types: [
        types.ONLINE_PAY_REQUEST,
        types.ONLINE_PAY_SUCCESS,
        types.ONLINE_PAY_FAILURE,
      ],
    },
  }).then((response) => {
    const { payload, type } = response;

    if (type === types.ONLINE_PAY_SUCCESS) {
      if (payload.redirect) {
        window.location.href = payload.redirect;
      }
    } else if (type === types.ONLINE_PAY_FAILURE) {
      const errCode = parseInt(payload.response.code, 10);
      if (errCode === 110) {
        dispatch(addFlashMessage('error', t('Selhalo on-line spojení s platební bránou.', { ns: 'my' })));
      } else if (errCode === 9991) {
        dispatch(addFlashMessage('error', t('Danou fakturu není možné uhradit on-line.', { ns: 'my' })));
      }
    }

    return false;
  });
};

export const downloadInvoicePdf = (orderId, number) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_orders/invoices/pdf`,
    body: JSON.stringify({ o01_order_id: orderId }),
    method: 'PUT',
    types: [
      types.DOWNLOAD_INVOICES_PDF_REQUEST,
      {
        type: types.DOWNLOAD_INVOICES_PDF_SUCCESS,
        payload: (action, state, res) => res.blob().then(
          (blob) => downloadFile(blob, t('KartaUp_faktura_{{number}}.pdf', { number, ns: 'my' }))
        ),
      },
      types.DOWNLOAD_INVOICES_PDF_FAILURE,
    ],
  },
});


export const setMyOrderInProgress = (myOrderInProgress) => ({
  type: types.SET_MY_ORDER_IN_PROGRESS,
  payload: myOrderInProgress,
});

export const fetchOrderProducts = () => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_basket/products`,
    method: 'GET',
    types: [
      types.FETCH_PRODUCTS_REQUEST,
      types.FETCH_PRODUCTS_SUCCESS,
      types.FETCH_PRODUCTS_FAILURE,
    ],
  },
});
