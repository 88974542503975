import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Card from '../Card/Card';
import FlatButton from '../FlatButton/FlatButton';
import Icon from '../Icon/Icon';
import { tRoute } from '../../i18n';
import { momentFromString, momentNow } from '../../service/date';
import {
  PropTypeCard, PropTypesCardProduct, PropTypeProductCode, PropTypeChildren, PropTypesCardLimit,
} from '../../modules/global/proptypes';
import { moneyFormat2, moneyFormat } from '../../service/number';
import styles from './MyCardBox.scss';
import { getProductIcon } from '../ProductButton/ProductButton';

const shortTimeLimit = 24 * 60 * 60 * 1000;
const refreshLimit = 2 * 60000;
const noRefreshLimit = 30 * 60000;

export const cardStatusIconName = {
  // 1: 'check_circle_outline', // Objednaná
  2: 'pause_circle_outline', // Perzonalizovaná
  3: 'check_circle_outline', // Aktivní
  4: 'block', // Blokovaná
  5: 'highlight_off', // Exspirovaná
  6: 'highlight_off', // Zrušená
};

export class CardProductPanel extends React.PureComponent {
  componentDidMount() {
    this.timeTick();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  componentDidUpdate(prevProps) {
    if (this.props.limit?.limit_till_date !== prevProps.limit?.limit_till_date) {
      this.timeTick();
    }
  }

  timeTick() {
    if (this.timerID) { clearInterval(this.timerID); }
    this.timerID = null;
    const tillDate = this.props.limit?.limit_till_date;

    if (tillDate) {
      const mmnt = momentFromString(tillDate);
      const ms = +mmnt - +momentNow();

      if (ms > noRefreshLimit) {
        return;
      }

      this.timerID = setTimeout(
        () => {
          this.timeTick();
          this.forceUpdate();
        },
        ms < refreshLimit ? 1000 : (ms - refreshLimit)
      );
    }
  }

  handlePendingClick = () => {
    if (this.props.onPendingBalanceClick) {
      this.props.onPendingBalanceClick(this.props.data.s20_code);
    }
  }

  render() {
    const {
      data, limit, showLimitTime, t,
    } = this.props;
    const mmnt = limit?.limit_till_date && momentFromString(limit.limit_till_date);
    const ms = mmnt ? +mmnt - +momentNow() : null;

    let limitTime = null;
    let limitRemain = null;
    const limitChangeExpired = ms !== null && ms <= 0;

    if (ms > 0 && (ms < shortTimeLimit || showLimitTime)) {
      limitTime = ms < shortTimeLimit ? mmnt.format('H:mm') : mmnt.format('D. MMMM H:mm');
      limitRemain = mmnt.fromNow(true);
    }

    const limitPending = limit?.limit_pending;
    const balancePending = data.balance_pending;

    let panelInfoMessage = null;
    let panelInfoClick = null;

    if (!limitPending && limitChangeExpired) {
      panelInfoMessage = t('Probíhá návrat na původní hodnotu limitu');
    } else if (limitPending) {
      panelInfoMessage = t('Probíhá změna limitu na hodnotu {{limitPending}}', { limitPending: moneyFormat2(limitPending, t) });
    } else if (balancePending) {
      panelInfoMessage = t('Na kartě jsou nezaúčtované transakce');
      panelInfoClick = this.props.onPendingBalanceClick && this.handlePendingClick;
    } else if (!limitPending && limitTime) {
      panelInfoMessage = t('Limit navýšen do: {{limitTime}} (zbývá {{limitRemain}})', { limitTime, limitRemain });
    }

    return (
      <CardsPanelBase
        code={data.s20_code}
        name={data.s20_name}
        left={[
          t('Zůstatek'),
          <>
            {moneyFormat(data.balance, t)}
            {data.balance_pending
              ? (
                <Icon
                  icon="timelapse"
                  title={t('Probíhá změna')}
                  style={{ top: '5px', left: '3px', position: 'relative' }}
                  onClick={this.handlePendingClick}
                />
              ) : null}
          </>]}
        right={[t('Denní limit'), limit?.limit ? moneyFormat2(limit.limit, t) : '--']}
      >
        {panelInfoMessage && (
        <div className={classnames(styles.panelLimitInfo, panelInfoClick && styles.panelClickable)} onClick={panelInfoClick} role="button" tabIndex="0">
          {panelInfoMessage}
        </div>
        )}
      </CardsPanelBase>
    );
  }
}

CardProductPanel.propTypes = {
  data: PropTypesCardProduct.isRequired,
  limit: PropTypesCardLimit.isRequired,
  showLimitTime: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onPendingBalanceClick: PropTypes.func,
};

export const CardsBalancePanel = ({ data, t }) => (
  <CardsPanelBase
    code={data.s20_code}
    name={data.s20_name}
    left={[t('Zůstatek'), moneyFormat(data.balance, t)]}
    right={[t('Karet'), data.cards_cnt]}
  />
);

CardsBalancePanel.propTypes = {
  data: PropTypesCardProduct.isRequired,
  t: PropTypes.func.isRequired,
};

export const CardsPanelBase = ({
  code, name, left, right, children, isTopAligned,
}) => (
  <div className={styles.panelContainer}>
    <div className={classnames(styles.panel, isTopAligned && styles.alignTop)}>
      <div className={styles.amountContainer}>
        <Icon icon={getProductIcon(code)} size="xlarge" />
        <span className={styles.productLabel}>{name}</span>
      </div>
      <div className={styles.amountContainer}>
        <span>{left[0]}</span>
        <span className={styles.amount}>{left[1]}</span>
      </div>
      <div className={styles.amountContainer}>
        <span>{right[0]}</span>
        <span className={styles.amount}>{right[1]}</span>
      </div>
    </div>
    {children}
  </div>
);

CardsPanelBase.propTypes = {
  code: PropTypeProductCode.isRequired,
  name: PropTypes.string,
  left: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])),
  right: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])),
  children: PropTypeChildren,
  isTopAligned: PropTypes.bool,
};

export const MyCardContent = ({
  minHeight, card, showLimitTime, simpleMode, t, children, onPendingBalanceClick,
}) => (
  <>
    <div className={styles.cardContent} style={{ minHeight }}>
      {card.products.map((prod) => (
        <CardProductPanel
          key={prod.s20_code}
          data={prod}
          limit={card.limits.find((l) => l.s20_code === prod.s20_code || l.s20_code === null)}
          userId={card.c30_company_user_id}
          showLimitTime={showLimitTime}
          t={t}
          onPendingBalanceClick={onPendingBalanceClick}
        />
      ))}
      {card.products.length === 0 && (
      <div className={styles.cardNoProducts}>
        <Icon
          icon="refresh"
        />
        {t('Zatím na kartě nejsou žádné prostředky. Jakmile se nahrají, bude s kartou možné platit.')}
      </div>
      )}
      {children}
    </div>
    {card.p21_card_status && !simpleMode && (
    <div className={styles.cardDetails}>
      <div className={styles.cardStatus}>
        <span>
          {card.p21_card_status}
          {card.p21_card_status_pending_id
            ? (
              <Icon
                icon="timelapse"
                title={card.p21_card_status_pending}
                style={{ top: '5px', left: '3px', position: 'relative' }}
              />
            ) : (card.p21_card_status_id
              && (
              <Icon
                icon={cardStatusIconName[card.p21_card_status_id]}
                style={{ top: '5px', left: '3px', position: 'relative' }}
              />
              )
            )}
        </span>
      </div>
      <span>{t('Typ: {{kind}}', { kind: t(`cardKind${card.p24_card_kind_id}Name`) })}</span>
    </div>
    )}
  </>
);

MyCardContent.propTypes = {
  card: PropTypeCard.isRequired,
  minHeight: PropTypes.number,
  simpleMode: PropTypes.bool,
  showLimitTime: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onPendingBalanceClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

const MyCardBox = (props) => {
  const {
    minHeight, card, cardEditAllowed, showButtons, simpleMode, showIcon, t,
  } = props;

  const title = simpleMode ? null : (
    <>
      {showIcon && <Icon size="large" icon="credit_card" style={{ marginRight: 5 }} />}
      {card.u01_nickname || card.fullname}
    </>
  );

  return (
    <Card
      layout="space-between"
      title={title}
      actions={showButtons && [
        <FlatButton
          key={1}
          internalHref={tRoute(`/moje/karty/karta/${card.c30_company_user_id}`, t)}
          label={t('Detail karty')}
        />,
        cardEditAllowed && (
        <FlatButton
          key={2}
          internalHref={tRoute(`/moje/karty/uprava-karty/${card.c30_company_user_id}`, t)}
          label={t('Upravit')}
        />
        ),
      ]}
      minHeight={minHeight}
    >
      <MyCardContent {...{ ...props, minHeight: 195 }} />
    </Card>
  );
};

MyCardBox.propTypes = {
  card: PropTypeCard.isRequired,
  minHeight: PropTypes.number,
  simpleMode: PropTypes.bool,
  showIcon: PropTypes.bool,
  cardEditAllowed: PropTypes.bool,
  //  showLimitTime: PropTypes.bool,
  showButtons: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onPendingBalanceClick: PropTypes.func,
};

MyCardBox.defaultProps = {
  showButtons: true,
};

export default MyCardBox;
