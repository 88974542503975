import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../../service/entityList';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['my', 'order']);
  }

  if (type === types.FETCH_ORDERS_REQUEST) {
    return state
      .setIn(['orderList', 'isFetchingFailure'], false)
      .setIn(['orderList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['orderList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['orderList', 'fetchCount'], Immutable.fromJS(action.payload.limit))
      .setIn(['orderList', 'limit'], Immutable.fromJS(action.payload.limit))
      .setIn(['orderList', 'lastOffset'], Immutable.fromJS(action.payload.lastOffset))
      .setIn(['orderList', 'orderBy'], Immutable.fromJS(action.payload.orderBy))
      .setIn(['orderList', 'filter'], Immutable.fromJS(action.payload.filter));
  }

  if (type === types.FETCH_ORDERS_SUCCESS) {
    const orders = payload.map((item) => ({
      data: {
        id: item.o01_order_id,
        o01_number: item.o01_number,
        o01_date_order: item.o01_date_order,
        o03_order_status: item.o03_order_status,
        invoice_numbers: item.invoice_numbers,
        o01_amount: item.o01_amount,
        i01_pay_online: item.i01_pay_online,
      },
      actions: {
        download: true,
        payOnline: !!item.i01_pay_online,
      },
    }));

    const orderList = state.get('orderList');
    const fetchCount = orderList.get('fetchCount');
    const lastOffset = orderList.get('lastOffset');
    const stateOrders = orderList.get('orders');
    const lastCount = orders.length;

    return state
      .setIn(['orderList', 'orders'], getEntitiesFromResponse(orders, stateOrders, lastOffset, fetchCount))
      .setIn(['orderList', 'lastCount'], lastCount)
      .setIn(['orderList', 'isFetching'], false)
      .setIn(['orderList', 'isLoadingMore'], false)
      .setIn(['orderList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_ORDERS_FAILURE) {
    return state
      .setIn(['orderList', 'isFetching'], false)
      .setIn(['orderList', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_ORDER_REQUEST) {
    return state
      .setIn(['order', 'isFetching'], true)
      .setIn(['order', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_ORDER_SUCCESS) {
    let order = {};

    if (payload instanceof Array && !!payload.length) {
      order = payload[0];
    }

    return state
      .setIn(['order', 'data'], Immutable.fromJS(order))
      .setIn(['order', 'isFetching'], false)
      .setIn(['order', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_ORDER_FAILURE) {
    return state
      .setIn(['order', 'isFetching'], false)
      .setIn(['order', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_ORDERS_FILTER_DATA) {
    return state
      .setIn(['orderList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['orderList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  if (type === types.ONLINE_CONFIRM_REQUEST) {
    return state
      .setIn(['onlineConfirm', 'isFetching'], true)
      .setIn(['onlineConfirm', 'isFetchingFailure'], false);
  }

  if (type === types.ONLINE_CONFIRM_SUCCESS) {
    return state
      .setIn(['onlineConfirm', 'data'], Immutable.fromJS(payload))
      .setIn(['onlineConfirm', 'isFetching'], false)
      .setIn(['onlineConfirm', 'isFetchingFailure'], false);
  }

  if (type === types.ONLINE_CONFIRM_FAILURE) {
    return state
      .setIn(['onlineConfirm', 'data'], Immutable.fromJS(payload.response))
      .setIn(['onlineConfirm', 'isFetching'], false)
      .setIn(['onlineConfirm', 'isFetchingFailure'], true);
  }

  if (type === types.SET_MY_ORDER_IN_PROGRESS) {
    return state.set('isInProgress', payload);
  }

  if (type === types.FETCH_PRODUCTS_REQUEST) {
    return state
      .setIn(['products', 'isFetching'], true)
      .setIn(['products', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_PRODUCTS_SUCCESS) {
    return state
      .setIn(['products', 'data'], Immutable.fromJS(payload))
      .setIn(['products', 'isFetching'], false)
      .setIn(['products', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_PRODUCTS_FAILURE) {
    return state
      .setIn(['products', 'isFetching'], false)
      .setIn(['products', 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
