import PropTypes from 'prop-types';
import React from 'react';
import RaisedButton from '../../../../../component1/RaisedButton/RaisedButton';
import styles from './CreditCardLink.scss';

export const CreditCardLinkPending = ({ t, gopayRedirect, readonly }) => (
  <div className={`${styles.container} ${styles.centered}`}>
    <p className={styles.noMargin}>{t('Probíhá zpracování vašeho požadavkou platební bránou GoPay. Zde můžete znovu otevřít stránku se stavem zpracování vašeho požadavku.')}</p>
    {/* <img className={styles.logo} src="/images/cc-link.svg" alt={t('Připojit platební kartu')} /> */}
    <RaisedButton label={t('Otevřít platební bránu')} priority="primary" size="large" externalHref={gopayRedirect} disabled={readonly} />
  </div>
);

CreditCardLinkPending.propTypes = {
  gopayRedirect: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired,
};
