import React, { lazy } from 'react';
import { SignUpCenteredLayout } from '../layout/main1';

const LoginContainer = lazy(() => import(/* webpackChunkName: "user" */ './pages/login/LoginContainer'));
const ForgotPasswordContainer = lazy(() => import(/* webpackChunkName: "user" */ './pages/ForgotPassword/ForgotPasswordContainer'));
const ResetPasswordContainer = lazy(() => import(/* webpackChunkName: "user" */ './pages/ResetPassword/ResetPasswordContainer'));
const LogoutPage = lazy(() => import(/* webpackChunkName: "user" */ './pages/logout/LogoutPage'));

export default {
  childRoutes: [
    {
      name: 'login',
      path: 'prihlaseni(/:r)',
      component: (props) => <SignUpCenteredLayout {...props} />,
      indexRoute: { component: LoginContainer },
      requireLogout: true,
    },
    {
      name: 'logout',
      path: 'odhlaseni',
      component: LogoutPage,
    },
    {
      name: 'forgotten_password',
      path: 'zapomenute-heslo',
      component: (props) => <SignUpCenteredLayout {...props} />,
      indexRoute: { component: ForgotPasswordContainer },
      requireLogout: true,
    },
    { // not used??
      name: 'reset_password',
      path: 'obnova-hesla',
      component: (props) => <SignUpCenteredLayout {...props} />,
      indexRoute: { component: ResetPasswordContainer },
      requireLogout: true,
    },
  ],
};
