import React from 'react';
import PropTypes from 'prop-types';
import styles from './PartnerPayGateOverviewWidget.scss';
import { Card } from '../../../../component1/Card';
import FlatButton from '../../../../component1/FlatButton/FlatButton';
import { tRoute } from '../../../../i18n';
import { PropTypePayGateTransactions } from '../../../global/proptypes';
import { PayGateTransactionsList } from '../../../../component1/PayGateTransactionsList/PayGateTransactionsList';
import SelectField from '../../../../component1/SelectField/SelectField';
import OverlayProgress from '../../../../component1/Progress/Overlay/Overlay';
import { GatePaymentDialog } from '../../../partner/gatePayment/components/GatePaymentDialog/GatePaymentDialog';

export class PartnerPayGateOverviewWidget extends React.Component {
  state = {
    transactionDetail: null,
  };

  constructor(props) {
    super(props);
    this.fetchTransactions(props.affiliateId);
  }

  handleTransactionDetail = (transactionId) => {
    this.setState({ transactionDetail: transactionId });
  }

  handleTransactionClose = () => {
    this.setState({ transactionDetail: null });
  };

  handleAffiliateChange = (value) => {
    this.fetchTransactions(value);
  }

  async fetchTransactions(affiliateId) {
    return this.props.widgetPartnerFetchPayGatePayments(affiliateId ? +affiliateId : null);
  }

  render() {
    const {
      t, data, isLoading, payments,
    } = this.props;

    const openedTransaction = this.state.transactionDetail && payments && payments.find((trn) => trn.n55_gate_transaction_id === this.state.transactionDetail);

    return (
      <Card
        layout="fluid"
        className={styles.cardRoot}
        bodyClassName={styles.cardBody}
        title={(
          <div className={styles.headerTitle}>
            {t('Poslední platby')}
            {data.affiliates.length > 1 && (
            <SelectField
              fieldId="overview-c25_contract_address"
              options={[{ label: t('Všechny pobočky'), value: '' }].concat(data.affiliates.map((itm) => ({
                label: itm.full_address,
                value: itm.c25_contract_address_id,
              })))}
              onChange={this.handleAffiliateChange}
              value={this.props.affiliateId || ''}
              isThin
            />
            )}
          </div>
)}
        actions={[
          <FlatButton
            key={2}
            label={t('Všechny platby')}
            internalHref={tRoute('/provozovny/platby-branou', t)}
          />,
        ]}
      >
        <OverlayProgress show={isLoading} absolute />
        {openedTransaction && (
        <GatePaymentDialog
          payment={openedTransaction}
          onClose={this.handleTransactionClose}
          onSave={async (transactionId, confirmed, pin) => {
            const ret = await this.props.widgetPartnerConfirmPayGatePayment(transactionId, confirmed, pin);
            this.fetchTransactions();
            this.props.updateWidget();
            return ret;
          }}
          t={t}
        />
        )}
        <PayGateTransactionsList
          onClick={this.handleTransactionDetail}
          transactions={payments || []}
          t={t}
        />
      </Card>
    );
  }
}

PartnerPayGateOverviewWidget.propTypes = {
  t: PropTypes.func.isRequired,
  widgetPartnerConfirmPayGatePayment: PropTypes.func.isRequired,
  widgetPartnerFetchPayGatePayments: PropTypes.func.isRequired,
  updateWidget: PropTypes.func.isRequired,
  affiliateId: PropTypes.number,
  isLoading: PropTypes.bool,
  payments: PropTypePayGateTransactions,
  data: PropTypes.shape({
    show: PropTypes.number.isRequired,
    affiliates: PropTypes.arrayOf(PropTypes.shape({
      c25_contract_address_id: PropTypes.number.isRequired,
      full_address: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    w15_enabled: PropTypes.number.isRequired,
    widget: PropTypes.string.isRequired,
  }).isRequired,
};
