import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { Card } from '../../../../component1/Card';
import RaisedButton from '../../../../component1/RaisedButton/RaisedButton';
import { widgetMinHeight } from '../../../global/constants';
import { tRoute } from '../../../../i18n';
import styles from './OnlineShopsWidget.scss';

export const OnlineShopsWidget = ({ t }) => (
  <Card layout="centered" title={t('Nakupujte on-line')} minHeight={widgetMinHeight}>
    <p className={styles.text}>{t('Nakupujte kartou\u00a0Up přímo na webu.')}</p>
    <Link to={tRoute('/nakupujte-online', t)}>
      <img className={styles.logo} src="/images/shop-online.svg" alt={t('Nakupujte kartou\u00a0Up přímo na webu.')} />
    </Link>
    <div className={styles.bottomSpacer}>
      <RaisedButton
        priority="primary"
        size="large"
        type="submit"
        label={t('Zobrazit nabídky')}
        internalHref={tRoute('/nakupujte-online', t)}
      />
    </div>
  </Card>
);

OnlineShopsWidget.propTypes = {
  t: PropTypes.func.isRequired,
};
