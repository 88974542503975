import Immutable from 'immutable';
import { dateTimeFromString } from '../../../service/date';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';


const reducerFactory = (initialState) => (state, action) => {
  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'attendance']);
  }

  if (action.type === types.FETCH_ATTENDANCE_REQUEST) {
    return state
      .setIn(['list', 'isFetchingFailure'], false)
      .setIn(['list', 'isFetching'], !action.payload.isLoadingMore)
      .setIn(['list', 'isLoadingMore'], !!action.payload.isLoadingMore)
      .setIn(['list', 'fetchCount'], Immutable.fromJS(action.payload.limit))
      .setIn(['list', 'limit'], Immutable.fromJS(action.payload.limit))
      .setIn(['list', 'lastOffset'], Immutable.fromJS(action.payload.lastOffset))
      .setIn(['list', 'orderBy'], Immutable.fromJS(action.payload.orderBy))
      .setIn(['list', 'filter'], Immutable.fromJS(action.payload.filter));
  }

  if (action.type === types.FETCH_ATTENDANCE_SUCCESS) {
    const attendance = action.payload.employees.map((item) => ({
      data: {
        ...item,
        id: item.t01_attendance_id,
        t01_date_attendance: dateTimeFromString(item.t01_date_attendance),
      },
    }));

    const list = state.get('list');
    const fetchCount = list.get('fetchCount');
    const lastOffset = list.get('lastOffset');
    const stateAttendance = list.get('rows');
    const lastCount = attendance.length;

    return state
      .setIn(['list', 'rows'], getEntitiesFromResponse(attendance, stateAttendance, lastOffset, fetchCount))
      .setIn(['list', 'lastCount'], lastCount)
      .setIn(['list', 'isFetching'], false)
      .setIn(['list', 'isLoadingMore'], false)
      .setIn(['list', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_ATTENDANCE_FAILURE) {
    return state
      .setIn(['list', 'isFetching'], false)
      .setIn(['list', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_ATTENDANCE_EMPLOYEE_REQUEST) {
    return state
      .setIn(['employee', 'isFetchingFailure'], false)
      .setIn(['employee', 'isFetching'], !action.payload.isLoadingMore)
      .setIn(['employee', 'isLoadingMore'], !!action.payload.isLoadingMore)
      .setIn(['employee', 'fetchCount'], Immutable.fromJS(action.payload.limit))
      .setIn(['employee', 'limit'], Immutable.fromJS(action.payload.limit))
      .setIn(['employee', 'lastOffset'], Immutable.fromJS(action.payload.lastOffset))
      .setIn(['employee', 'orderBy'], Immutable.fromJS(action.payload.orderBy))
      .setIn(['employee', 'filter'], Immutable.fromJS(action.payload.filter));
  }

  if (action.type === types.FETCH_ATTENDANCE_EMPLOYEE_SUCCESS) {
    const attendance = action.payload.shifts.map((item) => ({
      data: {
        ...item,
        id: item.d30_work_shift_id,
      },
    }));

    const employee = state.get('employee');
    const fetchCount = employee.get('fetchCount');
    const lastOffset = employee.get('lastOffset');
    const stateAttendance = employee.get('rows');
    const lastCount = attendance.length;

    return state
      .setIn(['employee', 'rows'], getEntitiesFromResponse(attendance, stateAttendance, lastOffset, fetchCount))
      .setIn(['employee', 'lastCount'], lastCount)
      .setIn(['employee', 'isFetching'], false)
      .setIn(['employee', 'isLoadingMore'], false)
      .setIn(['employee', 'isFetchingFailure'], false)
      .setIn(['employee', 'employee'], Immutable.fromJS(action.payload.employee));
  }

  if (action.type === types.FETCH_ATTENDANCE_EMPLOYEE_FAILURE) {
    return state
      .setIn(['employee', 'isFetching'], false)
      .setIn(['employee', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_ATTENDANCE_DETAIL_REQUEST) {
    return state
      .setIn(['records', 'isFetchingFailure'], false)
      .setIn(['records', 'isFetching'], !action.payload.isLoadingMore)
      .setIn(['records', 'isLoadingMore'], !!action.payload.isLoadingMore)
      .setIn(['records', 'fetchCount'], Immutable.fromJS(action.payload.limit))
      .setIn(['records', 'limit'], Immutable.fromJS(action.payload.limit))
      .setIn(['records', 'lastOffset'], Immutable.fromJS(action.payload.lastOffset))
      .setIn(['records', 'orderBy'], Immutable.fromJS(action.payload.orderBy))
      .setIn(['records', 'filter'], Immutable.fromJS(action.payload.filter));
  }

  if (action.type === types.FETCH_ATTENDANCE_DETAIL_SUCCESS) {
    const attendance = action.payload.events.map((item) => ({
      data: {
        ...item,
        id: item.d10_event_id,
      },
    }));

    const records = state.get('records');
    const fetchCount = records.get('fetchCount');
    const lastOffset = records.get('lastOffset');
    const stateAttendance = records.get('rows');
    const lastCount = attendance.length;

    return state
      .setIn(['records', 'rows'], getEntitiesFromResponse(attendance, stateAttendance, lastOffset, fetchCount))
      .setIn(['records', 'lastCount'], lastCount)
      .setIn(['records', 'isFetching'], false)
      .setIn(['records', 'isLoadingMore'], false)
      .setIn(['records', 'isFetchingFailure'], false)
      .setIn(['records', 'shiftSummary'], Immutable.fromJS(action.payload.shift_summary))
      .setIn(['records', 'monthSummary'], Immutable.fromJS(action.payload.month_summary));
  }

  if (action.type === types.FETCH_ATTENDANCE_DETAIL_FAILURE) {
    return state
      .setIn(['records', 'isFetching'], false)
      .setIn(['records', 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
