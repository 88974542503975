import { createSelector } from 'reselect';
import { selectIsInProgress } from '../my/resources/order/selectors';
import { selectOrderIsInProgress } from '../order/selectors/global';
import { selectWidgetBenefitsEnabled } from '../home1/selectors';

const getAuth = (state) => state.get('auth');

export const selectLoginMeta = createSelector([getAuth], (auth) => ({
  isLoggingIn: auth.get('isLoggingIn'),
  isLoggingInFailure: auth.get('isLoggingInFailure'),
}));

export const selectAuthorizedPages = createSelector([getAuth], (auth) => auth.getIn(['authorizedPages', 'pages']).toJS());
export const selectContractRights = createSelector([getAuth], (auth) => auth.getIn(['contractRights', 'rights']).toJS());
export const selectCartInfo = createSelector(
  [selectIsInProgress, selectOrderIsInProgress],
  (myOrderisInProgress, orderIsInProgress) => ({
    b2c: myOrderisInProgress,
    b2b: orderIsInProgress,
  })
);

export const selectAuthUserInfo = createSelector([getAuth], (auth) => auth.get('user') && auth.get('user').toJS());
export const selectAuthUserId = createSelector([getAuth], (auth) => auth.get('user') && auth.getIn(['user', 'u01_user_id']));
export const selectAuthIsAdmin = createSelector([getAuth], (auth) => auth.get('user') && !!auth.getIn(['user', 'adminFullname']));

export const selectAuthorizedPagesForMenu = createSelector([selectAuthorizedPages, selectWidgetBenefitsEnabled],
  (pages, benefitsEnabled) => (benefitsEnabled ? pages : pages.filter((page) => page !== 'U20')));
