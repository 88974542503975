import { combineReducers } from 'redux-immutable';
import { Reducer, AnyAction } from 'redux';
import { reducer as registrationFirstLoginReducer } from './modules/registration/resources/firstLogin';
import registrationFirmReducer from './modules/registration/firm/reducer';
import registrationMyReducer from './modules/registration/my/reducer';
import authReducer from './modules/auth/reducer';
import flashMessagesReducer from './modules/flashMessages/reducer';
import { reducer as globalReducer } from './modules/global';
import orderReducer from './modules/order/reducer';
import { initialState } from './data/initialState';
import firmReducer from './modules/firm/reducer';
import partnerReducer from './modules/partner/reducer';
import { reducer as myOrderReducer } from './modules/my/resources/order';
import { reducer as myPlaceReducer } from './modules/my/resources/place';
import { reducer as myTransactionReducer } from './modules/my/resources/transaction';
import { reducer as myUserReducer } from './modules/my/resources/user';
import { reducer as myCardsReducer } from './modules/my/resources/card';
import mySettlementReducer from './modules/my/resources/settlement/reducer';
import myAttendanceReducer from './modules/my/resources/attendance/reducer';
import { LOGOUT_REQUEST } from './modules/auth/actionTypes';
import { paymentGateReducerFactory } from './modules/paymentGate/reducer';
import { userSettingsReducerFactory } from './modules/user/resources/reducer';
import { RootState } from './data/storeModel';
import { myBenefiaOrdersReducerFactory } from './modules/my/resources/benefiaOrder/reducer';
import { homeNewsReducerFactory } from './modules/home1/news/reducer';
import { homeWidgetsReducerFactory } from './modules/home1/reducer';
import { myBenefitsReducerFactory } from './modules/my/resources/benefits/reducer';

const appReducers: Reducer<RootState> = combineReducers<any>({
  auth: authReducer(initialState),
  home: combineReducers({
    widgets: homeWidgetsReducerFactory(initialState),
    news: homeNewsReducerFactory(initialState),
  }),
  firm: firmReducer(initialState),
  partner: partnerReducer(initialState),
  order: orderReducer(initialState),
  registration: combineReducers({
    firm: registrationFirmReducer(initialState),
    my: registrationMyReducer(initialState),
    firstLogin: registrationFirstLoginReducer(initialState),
  }),
  flashMessages: flashMessagesReducer(initialState),
  global: globalReducer(initialState),
  my: combineReducers({
    place: myPlaceReducer(initialState),
    order: myOrderReducer(initialState),
    transaction: myTransactionReducer(initialState),
    benefiaOrder: myBenefiaOrdersReducerFactory(initialState),
    user: myUserReducer(initialState),
    card: myCardsReducer(initialState),
    settlement: mySettlementReducer(initialState),
    attendance: myAttendanceReducer(initialState),
    benefits: myBenefitsReducerFactory(initialState),
  }),
  paymentGate: paymentGateReducerFactory(initialState),
  user: combineReducers({
    settings: userSettingsReducerFactory(initialState),
  }),
});

const topReducer: Reducer<RootState, any> = (state: RootState|undefined, action: AnyAction) => {
  let newState = state;
  if (action.type === LOGOUT_REQUEST) {
    newState = initialState;
  }

  return appReducers(newState, action);
};

export default topReducer;
