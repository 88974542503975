import PropTypes from 'prop-types';
import React from 'react';
import styles from './PayGateTransactionsList.scss';
import { PropTypePayGateTransactions, PropTypePayGateTransaction } from '../../modules/global/proptypes';
import Icon from '../Icon/Icon';
import { ListEmptyMessage } from '../ListEmptyMessage/ListEmptyMessage';
import { dateTimeApiToGUI } from '../../service/date';
import { moneyFormat } from '../../service/number';

export function getTransactionStatus(transaction) {
  // failed: (n58_open_state=0 AND n58_success_state=0) jen tlačítko zavřít
  // waiting: (n58_open_state=1 AND n58_success_state=0) tlačítka potvrdit/zamítnout/zavřít
  // success: (n58_open_state=0 AND n58_success_state=1) tlačítka zamítnout/zavřít

  if (transaction.n58_open_state === 1) {
    return 'waiting';
  }

  if (transaction.n58_open_state === 0 && transaction.n58_success_state === 0) {
    return 'failed';
  }

  if (transaction.n58_open_state === 0 && transaction.n58_success_state === 1) {
    return 'success';
  }

  return null;
}

export const statusIcons = {
  waiting: 'watch_later',
  success: 'check_circle',
  failed: 'cancel',
};

export const PayGateTransactionStatusBanner = ({ transaction, t }) => {
  const status = getTransactionStatus(transaction);
  return (
    <div className={`${styles.banner} ${styles[`${status}Status`]}`}>
      <Icon icon={statusIcons[status]} size="large" />
      <span>{t(`transactionStatus-${status}`)}</span>
    </div>
  );
};

PayGateTransactionStatusBanner.propTypes = {
  t: PropTypes.func.isRequired,
  transaction: PropTypePayGateTransaction.isRequired,
};

export const PayGateTransactionsList = ({ transactions, t, onClick }) => (
  <div className={styles.root}>
    {transactions.map((trn) => (
      <div onClick={() => onClick(trn.n55_gate_transaction_id, trn)} role="presentation" className={styles.row} key={trn.n55_gate_transaction_id}>
        <div className={styles[`${getTransactionStatus(trn)}Status`]}><Icon icon={statusIcons[getTransactionStatus(trn)]} size="large" /></div>
        <div>
          <table className={styles.details}>
            <tbody>
              <tr className={styles.nowrap}>
                <td>{trn.date_authorized ? t('Autorizováno:') : t('Vytvořeno:')}</td>
                <td>{dateTimeApiToGUI(trn.date_authorized ? trn.date_authorized : trn.n55_date_created)}</td>
                <td className={styles.hiddenXs}>{t('Karta:')}</td>
                <td className={styles.hiddenXs}>{trn.p20_pan}</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>{t('Objednávka:')}</td>
                <td>{trn.n55_ext_id}</td>
              </tr>
              <tr className={`${styles.nowrap} ${styles.visibleXs}`}>
                <td>{t('Karta:')}</td>
                <td>{trn.p20_pan}</td>
              </tr>
              <tr>
                <td>{t('Popis:')}</td>
                <td colSpan="4">{trn.n55_description}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div><span>{moneyFormat(trn.n55_value, t)}</span></div>
      </div>
    ))}

    {transactions.length === 0 && (
    <ListEmptyMessage>{t('Žádné platby.')}</ListEmptyMessage>
    )}
  </div>
);


PayGateTransactionsList.propTypes = {
  t: PropTypes.func.isRequired,
  transactions: PropTypePayGateTransactions.isRequired,
  onClick: PropTypes.func.isRequired,
};
