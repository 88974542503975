import Immutable from 'immutable';
import * as types from './actionTypes';
import { dateFromString } from '../../../service/date';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['partner', 'contract']);
  }

  if (type === types.UPDATE_PAYOUT_FREQ_SUCCESS) {
    return state.updateIn(['contractList', 'contracts'], (list) => list.map((contract) => {
      if (contract.getIn(['data', 'c10_contract_id']) === payload.c10_contract_id) {
        return contract
          .setIn(['data', 'freq'], payload.freq)
          .setIn(['data', 'prov'], payload.prov)
          .setIn(['data', 'fee'], payload.fee);
      }
      return contract;
    }));
  }

  if (type === types.FETCH_CONTRACT_PAIRS_REQUEST) {
    return state
      .setIn(['contractPairs', 'isFetchingFailure'], true)
      .setIn(['contractPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_CONTRACT_PAIRS_SUCCESS) {
    const contractPairs = payload.map((item) => ({
      label: item.name,
      value: item.c10_contract_id,
    }));

    return state
      .setIn(['contractPairs', 'contractPairs'], Immutable.fromJS(contractPairs))
      .setIn(['contractPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_CONTRACT_PAIRS_FAILURE) {
    return state
      .setIn(['contractPairs', 'isFetching'], false)
      .setIn(['contractPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_CONTRACTS_REQUEST) {
    return state
      .setIn(['contractList', 'isFetching'], true)
      .setIn(['contractList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_CONTRACTS_SUCCESS) {
    const contracts = payload.map((contract) => ({
      data: {
        c10_contract_id: contract.c10_contract_id,
        c01_company_id: contract.c01_company_id,
        c10_number: contract.c10_number,
        c10_date_from: dateFromString(contract.c10_date_from) !== 'invalid date'
          ? dateFromString(contract.c10_date_from) : '',
        c01_name: contract.c01_name,
        c01_ico: contract.c01_ico,
        is_active: contract.is_active,
        freq: contract.freq,
        prov: contract.prov,
        fee: contract.fee,
        enum: contract.enum,
      },
      actions: {
        can_change_freq: !!contract.can_change_freq,
        downloadPdf: true,
      },
    }));

    return state
      .setIn(['contractList', 'isFetching'], false)
      .setIn(['contractList', 'isFetchingFailure'], false)
      .setIn(['contractList', 'contracts'], Immutable.fromJS(contracts));
  }

  if (type === types.FETCH_CONTRACTS_FAILURE) {
    return state
      .setIn(['contractList', 'isFetching'], false)
      .setIn(['contractList', 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
