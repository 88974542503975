/* eslint-disable no-param-reassign */
import { inflect } from './wordInflection';
import { tFn } from '../i18n';
import { CURRENCY } from '../modules/global/constants';
import { amountFormat3, normalizePhone } from './number';

const t = (key, options) => tFn(key, { ns: 'validation', ...options });

const isEmpty = (value) => value === undefined || value === null || value === '';

export const addressFilled = (address) => {
  if (address && (address.g01_google_place_id || address.orig_address)) {
    return false; // OK
  }

  return t('Zvolte platnou adresu z našeptávače nebo ji zadejte ručně.');
};


export const email = (value) => {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return t('Neplatná emailová adresa.');
  }
  return false;
};

export const required = (value) => {
  if (isEmpty(value)) {
    return t('Musíte vyplnit toto pole.');
  }
  return false;
};

export const requiredWhen = (conditionFn) => (value, context) => {
  if (conditionFn(context.state)) {
    return required(value);
  }
  return false;
};

export const isNotZero = (value) => {
  if (value === 0) {
    return t('Musíte vyplnit toto pole.');
  }
  return false;
};

export const minLength = (min) => (value) => {
  if (!isEmpty(value) && value.length < min) {
    return t('Pole musí mít alespoň {{min}} {{units}}.', {
      min,
      units: inflect(min, [t('znak'), t('znaky'), t('znaků')]),
    });
  }
  return false;
};

export const maxLength = (max) => (value) => {
  if (!isEmpty(value) && value.length > max) {
    return t('Pole musí mít maximálně {{max}} {{units}}.', {
      max,
      units: inflect(max, [t('znak'), t('znaky'), t('znaků')]),
    });
  }
  return false;
};

export const exactLength = (exact) => (value) => {
  if (!isEmpty(value) && String(value).length !== exact) {
    return t('Pole musí mít přesně {{exact}} {{units}}.', {
      exact,
      units: inflect(exact, [t('znak'), t('znaky'), t('znaků')]),
    });
  }
  return false;
};

export const lengthBetween = (min, max) => (value) => {
  if (!isEmpty(value) && (minLength(min)(value) || maxLength(max)(value))) {
    return t('Pole musí mít mezi {{min}} a {{max}} znaky.', {
      min,
      max,
    });
  }
  return false;
};

export const isNumberBetween = (min, max) => (value) => {
  if (!isEmpty(value) && (+value < min || +value > max)) {
    return t('Pole musí mít hodnotu mezi {{min}} a {{max}}.', {
      min,
      max,
    });
  }
  return false;
};

export const amountBetween = (min, max, equal) => (value) => {
  if (!isEmpty(value) && (+value < min || +value > max) && !(equal && (+value === min || +value === max))) {
    return t('Zadejte částku {{min}} až {{max}} {{currency}}.', {
      min: amountFormat3(min),
      max: amountFormat3(max),
      currency: t('currency', { context: CURRENCY }),
    });
  }
  return false;
};

export const validPhone = (defaultPrefix) => (value) => {
  const phone = normalizePhone(value, defaultPrefix);
  if (!phone || phone.length === 16) {
    return false;
  }

  return t('Zadejte platné telefonní číslo.');
};


export const typeString = (value) => {
  if (typeof value !== 'string') {
    return t('Pole musí být text.');
  }
  return false;
};

export const typeNumber = (value) => {
  if (value !== null && value !== undefined && value !== '' && typeof value !== 'number') {
    return t('Pole musí být číslo.');
  }
  return false;
};

export const hasLowerCaseLetter = (value) => {
  if (!/^(?=.*[a-z]).+$/.test(value)) {
    return t('Neobsahuje malé písmeno.');
  }
  return false;
};

export const hasUpperCaseLetter = (value) => {
  if (!/^(?=.*[A-Z]).+$/.test(value)) {
    return t('Neobsahuje velké písmeno.');
  }
  return false;
};

export const hasOneDigit = (value) => {
  if (!/^(?=.*\d).+$/.test(value)) {
    return t('Neobsahuje číslici.');
  }
  return false;
};

export const hasSpecialCharacter = (value) => {
  if (!/^(?=.*[!#$%&()\]*+,-.:=\\[?@_{}|~]).+$/.test(value)) {
    return t('Neobsahuje speciální znak.');
  }
  return false;
};

export const cloneElements = (elements, patcher) => Object.keys(elements)
  .reduce((acc, curr) => {
    const patchedElms = patcher ? patcher(curr, elements[curr], elements) : null;
    acc[curr] = {
      ...elements[curr],
      ...patchedElms,
    };
    return acc;
  }, {});

export const updateForm = (formData, elements) => ({
  ...formData,
  elements,
});

function updateElementRecordsErrors(element, value) {
  const values = value || [];
  const elements = element?.elements || {};
  const records = values.map((v, idx) => (element?.records && element?.records[idx]) || Object.keys(elements).reduce((acc, curr) => { acc[curr] = { errorText: '' }; return acc; }, {}));
  let firstError = null;

  values.forEach((v, idx) => {
    // eslint-disable-next-line no-use-before-define
    if (findErrors(v, records[idx], elements) && !firstError) {
      const errorPropKey = Object.keys(records[idx]).find((k) => !!records[idx][k].errorText);
      firstError = (errorPropKey && records[idx][errorPropKey].errorText) || null;
    }
  });

  return {
    records,
    elements,
    errorText: firstError || '',
  };
}


function updateElementErrors(formElement, elementValue, context, rules = undefined) {
  if ('records' in formElement) {
    return updateElementRecordsErrors(formElement, elementValue);
  }

  if (!rules) {
    rules = formElement?.rules;
  }

  let elementErrorsCount = 0;
  let isMap = false;
  let resultElement = formElement;

  if (rules) {
    resultElement = {
      ...formElement,
      errorText: null,
    };

    rules.every((validate) => {
      const validationResult = validate(elementValue, context);
      if (validationResult) {
        resultElement.errorText = resultElement.errorText || validationResult;

        if (typeof validationResult === 'object') {
          isMap = true;
          elementErrorsCount += Object.keys(validationResult)
            .some((k) => !!validationResult[k]) ? 1 : 0;
        } else {
          elementErrorsCount += 1;
        }
        return false;
      }
      return true;
    });
  }

  if (!elementErrorsCount) {
    resultElement.errorText = isMap ? null : '';
  }

  return resultElement;
}


// __BEWARE__!!!.
// findErrors() modifies state passed in.
// This needs to be rewritten as a pure function.
// martin@visionapps.cz on 2017-03-25
export const findErrors = (state, formElements, rules = undefined) => {
  const formElementsKeys = Object.keys(formElements);
  const newFormElements = formElements;
  let totalErrorsCount = 0;

  formElementsKeys.every((key) => {
    const elm = formElements[key];
    const resultElement = updateElementErrors(elm, state[key], { state, elements: formElements }, rules && rules[key]);

    if (resultElement.errorText) {
      totalErrorsCount += 1;
    }

    newFormElements[key] = resultElement;

    return true;
  });

  if (totalErrorsCount) {
    return formElements;
  }

  return false;
};

/**
 * Validates form and call updatecallback to set new data object
 * @param data
 * @param form
 * @param updateCallback
 * @returns {boolean|*}
 */
export const validateForm = (data, form, updateCallback) => {
  const updatedElements = findErrors(data, form.elements);

  const updateData = updatedElements ? {
    ...form,
    elements: updatedElements,
    isValid: false,
  } : {
    ...form,
    isValid: true,
  };

  updateCallback(updateData);

  return updateData.isValid;
};

/**
 * validates form and call updatecallback to update form property do new object data
 * @param data
 * @param form
 * @param updateCallback
 * @returns {boolean}
 */
export const validateFormInState = (data, form, updateCallback, formKey = 'form') => validateForm(data, form, (updateData) => updateCallback({ [formKey]: updateData }));

export function getErrorsTextMap(elements) {
  return Object.keys(elements || {}).reduce((acc, curr) => {
    acc[curr] = elements[curr]?.errorText;
    return acc;
  }, {});
}
