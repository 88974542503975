import Immutable from 'immutable';
import { RootState } from './storeModel';
import { MyUser } from '../modules/my/model';
import {
  OrderByType,
  AttendanceMonthRow,
  TableRow,
  AttendanceDayRow,
  AttendanceDetailRow,
  ReslistPairsType,
  AttendanceShiftSummary,
  AttendanceMonthSummary,
  AttendanceEmployeeInfo,
  NewsItemType,
  FilterType,
  BenefitItemType,
  NewsAdminListItemType,
  NewsAdminDetailType,
  BenefitAdminItemType,
  TableRows,
} from '../modules/global/model';

export const initialStateJS = {
  flashMessages: [],
  partner: {
    activeContractId: null,
    activePartnerId: null,
    partner: {
      partnerPairsList: {
        partnerPairs: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      bankAccountsList: {
        bankAccounts: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      partnerDetail: {
        data: null,
        isFetching: false,
        isFetchingFailure: false,
        isPosting: false,
        isPostingFailure: false,
      },
    },
    affiliate: {
      affiliateList: {
        affiliates: [],
        filter: {},
        orderBy: { column: 'c20_label', order: 'ASC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      affiliate: {
        data: null,
        isFetching: false,
        isFetchingFailure: false,
        isPosting: false,
        isPostingFailure: false,
      },
      newAffiliate: {
        isPosting: false,
        isPostingFailure: false,
      },
      affiliatePairsList: {
        affiliates: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      mPOSAvailable: {
        data: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      mPOSActive: {
        data: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      productAffiliateTypes: [],
    },
    contract: {
      contractPairs: {
        contractPairs: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      contractList: {
        contracts: [],
        isFetching: false,
        isFetchingFailure: false,
      },
    },
    invoice: {
      invoiceList: {
        invoices: [],
        filter: {},
        orderBy: { column: 'date', order: 'DESC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    transaction: {
      transactionList: {
        transactions: [],
        filter: {},
        orderBy: { column: 't01_date_transaction', order: 'DESC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    gatePayment: {
      paymentsList: {
        payments: [],
        filter: {},
        orderBy: { column: 'n55_date_created', order: 'DESC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    user: {
      userPairsList: {
        users: [],
        isFetching: false,
        isFetchingFailure: false,
      },
    },
  },
  auth: {
    resetPassword: {
      u90_hash_id: '',
      message: '',
      isResetting: false,
      isResettingFailure: false,
      data: [],
    },
    remindPassword: {
      message: '',
      isReminding: false,
      isRemindingFailure: false,
      isRemindingSuccess: false,
    },
    authorizedPages: {
      isFetching: false,
      pages: [],
    },
    contractRights: {
      isFetching: false,
      rights: {},
    },
    user: null,
    isLoggingIn: false,
    isLoggingInFailure: false,
    isSessionInFailure: false, // failed last session login try?
  },
  home: {
    widgets: {
      isFetching: false,
      widgets: [],
      widgetsData: {
        ph10: {
          isLoading: false,
        },
        ph32: {
          isLoading: false,
          affiliateId: null,
          payments: null,
        },
        ph40: {
          isLoading: false,
        },
      },
      popupShown: false,
    },
    news: {
      newsList: {
        rows: [] as TableRows<NewsItemType>,
        filter: {} as FilterType,
        orderBy: { column: 'z30_order', order: 'DESC' } as OrderByType,
        limit: 6,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      newsDetail: {
        data: null as (NewsItemType | null),
        isFetching: false,
        isFetchingFailure: false,
      },
    },
  },
  firm: {
    activeContractId: null,
    activeFirmId: null,
    firm: {
      settings: {
        paramsList: {
          isUpdating: false,
          isUpdatingFailure: false,
          isFetching: false,
          isFetchingFailure: false,
          firmParams: null,
        },
      },
      deliveryAddressList: {
        deliveryAddresses: [],
        filter: {},
        orderBy: { column: 'g60_city_name', order: 'ASC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      deliveryAddressPairs: {
        deliveryAddressPairs: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      branchesCostCentersList: {
        branchesCostCenters: [],
        filter: {},
        orderBy: { column: 'name', order: 'ASC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      newDeliveryAddress: {
        isPosting: false,
        isPostingFailure: false,
      },
      deliveryAddress: {
        data: {},
        isFetching: false,
        isFetchingFailure: false,
        isPosting: false,
        isPostingFailure: false,
      },
      firmList: {
        firms: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      cardList: {
        cards: [],
        isFetching: false,
      },
      firmInfo: {
        data: null,
        isFetching: false,
        isFetchingFailure: false,
        isPosting: false,
        isPostingFailure: false,
      },
      bankAccountsList: {
        bankAccounts: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      firmPairs: {
        firms: [],
        isFetching: false,
        isFetchingFailure: false,
      },
    },
    user: {
      userList: {
        users: [],
        filter: {},
        orderBy: { column: 'u01_surname', order: 'ASC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      userPairsList: {
        users: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      userDetail: {
        isFetching: false,
        isFetchingFailure: false,
        id: null,
        user: null,
        branchesList: {
          isFetching: false,
          isFetchingFailure: false,
          branches: [],
        },
        firmsCostCentersList: {
          isFetching: false,
          isFetchingFailure: false,
          costCenters: [],
        },
        userRolesList: {
          isFetching: false,
          isFetchingFailure: false,
          userRoles: [],
        },
        userRightsList: {
          isFetching: false,
          isFetchingFailure: false,
          userRights: [],
        },
        userRightsTemplatesList: {
          isFetching: false,
          isFetchingFailure: false,
          userRightsTemplates: [],
        },
        userRightsDetail: {
          isFetching: false,
          isFetchingFailure: false,
          data: null,
        },
        userTransactions: {
          userTransactions: [],
          isFetching: false,
          isFetchingFailure: false,
        },
        userCards: {
          userCards: [],
          isFetching: false,
          isFetchingFailure: false,
        },
      },
    },
    delivery: {
      deliveryList: {
        deliveries: [],
        filter: {},
        orderBy: { column: 'o10_delivery_way_id', order: 'DESC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    contract: {
      contractList: {
        contracts: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      contractPairs: {
        contractPairs: [],
        isFetching: false,
        isFetchingFailure: false,
      },
    },
    branch: {
      branchPairs: {
        branchPairs: [],
        isFetching: false,
        isFetchingFailure: false,
      },
    },
    costCenter: {
      costCenterPairs: {
        costCenterPairs: [],
        isFetching: false,
        isFetchingFailure: false,
      },
    },
    position: {
      positionPairs: {
        positionPairs: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      positionList: {
        rows: [],
        filter: { },
        orderBy: { column: 'c32_name', order: 'ASC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    department: {
      departmentPairs: {
        departmentPairs: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      departmentList: {
        rows: [],
        filter: { },
        orderBy: { column: 'c33_name', order: 'ASC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    branchAndCostCenter: {
      branchesCostCentersList: {
        branchesCostCenters: [],
        filter: { is_active: 1 },
        orderBy: { column: 'name', order: 'ASC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    card: {
      cardList: {
        cards: [],
        filter: {},
        orderBy: { column: 'card_number', order: 'DESC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      cardDetail: {
        card: null,
        isFetching: false,
        isFetchingFailure: false,
      },
      setCardStatus: {
        isPosting: false,
        isPostingFailure: false,
      },
      cardStatusPairs: {
        cardStatuses: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      cardDistrAddressPairs: {
        cardDistrAddresses: [],
        isFetching: false,
        isFetchingFailure: false,
      },
    },
    invoice: {
      invoiceList: {
        invoices: [],
        filter: {},
        orderBy: { column: 'date', order: 'DESC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      reportsList: {
        reports: [],
        isFetching: false,
        isFetchingFailure: false,
      },
    },
    orders: {
      ordersList: {
        orders: [],
        filter: {},
        orderBy: { column: 'o01_date_order', order: 'DESC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      orderDetail: {
        isFetching: false,
        isFetchingFailure: false,
        order: null,
      },
    },
    transaction: {
      transactionList: {
        transactions: [],
        filter: {},
        orderBy: { column: 't01_date_transaction', order: 'DESC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    benefiaOrder: {
      ordersList: {
        rows: [],
        filter: {},
        orderBy: { column: 'updated_at', order: 'DESC' } as OrderByType,
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    attendance: {
      list: {
        rows: [] as TableRow<AttendanceMonthRow>[],
        filter: {},
        orderBy: { column: 'd30_month', order: 'DESC' } as OrderByType,
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      employee: {
        rows: [] as TableRow<AttendanceDayRow>[],
        employee: null as AttendanceEmployeeInfo|null,
        filter: {},
        orderBy: { column: 'd30_date', order: 'ASC' } as OrderByType,
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      records: {
        rows: [] as TableRow<AttendanceDetailRow>[],
        shiftSummary: null as AttendanceShiftSummary|null,
        monthSummary: null as AttendanceMonthSummary|null,
        filter: {},
        orderBy: { column: 'd10_event_datetime', order: 'ASC' } as OrderByType,
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    userGroup: {
      groupsList: {
        rows: [],
        filter: {},
        orderBy: { column: 'z01_name', order: 'ASC' } as OrderByType,
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      groupUsersList: {
        group: null as any,
        rows: [],
        filter: {},
        orderBy: { column: 'fullname', order: 'ASC' } as OrderByType,
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      groupsPairs: {
        items: [] as ReslistPairsType,
        isFetching: false,
        isFetchingFailure: false,
      },
    },
    news: {
      newsList: {
        rows: [] as TableRows<NewsAdminListItemType>,
        filter: {} as FilterType,
        orderBy: { column: 'z30_order', order: 'DESC' } as OrderByType,
        limit: 6,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      newsDetail: {
        data: null as (NewsAdminDetailType | null),
        isFetching: false,
        isFetchingFailure: false,
      },
    },
    benefits: {
      benefitsList: {
        rows: [] as TableRows<BenefitItemType>,
        filter: {} as FilterType,
        orderBy: { column: 'd20_order', order: 'ASC' } as OrderByType,
        limit: 6,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      benefitDetail: {
        data: null as (BenefitAdminItemType | null),
        isFetching: false,
        isFetchingFailure: false,
      },
    },
  },
  order: {
    isRedirectedToValidation: false,
    isRedirectedToContractSelection: false,
    isRedirectedToAdjustment: false,
    isInProgress: false,
    isLoading: false,
    data: [],
    firm: null,
    isDeletingOrder: false,
    isDeletingOrderFailure: false,
    contract: null,
    productCode: null,
    products: {
      data: null,
      isLoading: null,
      isError: null,
    },
    filter: {
      search: '',
      c85_name: '',
      c87_name: '',
      address: '',
    },
    steps: {
      contractSelection: {
        passed: false,
      },
      initialization: {
        passed: false,
        isDownloading: false,
        isDownloadingFailure: false,
        isImporting: false,
        isImportingFailure: false,
        fileId: null,
        errors: [],
      },
      validation: {
        passed: false,
        addressChangesValid: false,
        addresses: [],
        names: [],
        namesBeingValidated: [],
        changes: [],
      },
      adjustmentOfValues: {
        deliveryAddressPairs: {
          deliveryAddresses: [],
          isFetching: false,
          isFetchingFailure: false,
        },
        passed: false,
        isLoadingBasket: false,
        isLoadingBasketFailure: false,
        basket: {},
      },
      finalInspection: {
        passed: false,
        overview: {
          parameters: null,
        },
        cardImage: null,
        cardPersonalization: {},
      },
      onlineConfirm: {
        isFetching: true,
        isFetchingFailure: false,
        data: {},
      },
      paymentInfo: null,
    },
  },
  global: {
    affiliateTypePairs: {
      affiliateTypePairs: [],
      isFetching: false,
      isFetchingFailure: false,
    },
    bankPairs: {
      bankPairs: [],
      isFetching: false,
      isFetchingFailure: false,
    },
    transactionTypePairs: {
      transactionTypePairs: [],
      isFetching: false,
      isFetchingFailure: false,
    },
    deliveryStatusPairs: {
      deliveryStatusPairs: [],
      isFetching: false,
      isFetchingFailure: false,
    },
    cashSystemPairs: {
      cashSystemPairs: [],
      isFetching: false,
      isFetchingFailure: false,
    },
    nameOnCardPairs: {
      nameOnCardPairs: [],
      isFetching: false,
      isFetchingFailure: false,
    },
    deliveryCardOrderPairs: {
      deliveryCardOrderPairs: [],
      isFetching: false,
      isFetchingFailure: false,
    },
    brand: {
      code: null,
    },
    productsPairs: {
      productsPairs: [],
      isFetching: false,
      isFetchingFailure: false,
    },
    productsDescPairs: {
      data: [],
      isFetching: false,
      isFetchingFailure: false,
    },
    cardTypePairs: {
      data: [] as ReslistPairsType[],
      isFetching: false,
      isFetchingFailure: false,
    },
    cardKindsPairs: {
      data: [] as ReslistPairsType[],
      isFetching: false,
      isFetchingFailure: false,
    },
    gateTransStatusPairs: {
      data: [] as ReslistPairsType[],
      isFetching: false,
      isFetchingFailure: false,
    },
    paymentTypePairs: {
      data: [] as ReslistPairsType[],
      isFetching: false,
      isFetchingFailure: false,
    },
    attendanceTypePairs: {
      data: [] as ReslistPairsType[],
      isFetching: false,
      isFetchingFailure: false,
    },
    newsCategoryPairs: {
      data: [] as ReslistPairsType[],
      isFetching: false,
      isFetchingFailure: false,
    },
    newsCategoryTypePairs: {
      data: [] as ReslistPairsType[],
      isFetching: false,
      isFetchingFailure: false,
    },
    languagePairs: {
      data: [] as ReslistPairsType[],
      isFetching: false,
      isFetchingFailure: false,
    },
    benefitPeriodPairs: {
      data: [] as ReslistPairsType[],
      isFetching: false,
      isFetchingFailure: false,
    },
    offline: false,
    appActive: true,
  },
  registration: {
    firm: {
      aresData: {
        data: {},
        isFetching: false,
        isFetchingFailure: false,
        isFetchingFailureMessage: null,
      },
      registrationData: {
        isPosting: false,
        isPostingFailure: false,
      },
      confirmRegistrationData: {
        data: {},
        isPosting: false,
        isPostingFailure: false,
      },
    },
    my: {
      registrationData: {
        isPosting: false,
        isPostingFailure: false,
      },
      confirmRegistrationData: {
        data: {},
        isPosting: false,
        isPostingFailure: false,
      },
    },
    firstLogin: {
      registerCard: {
        data: null,
        isPosting: false,
        isPostingFailure: false,
      },
      registerCardWithEmail: {
        data: null,
        isPosting: false,
        isPostingFailure: false,
      },
    },
  },
  my: {
    transaction: {
      transactionList: {
        transactions: [],
        filter: {},
        orderBy: { column: 't01_date_transaction', order: 'DESC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    benefiaOrder: {
      ordersList: {
        rows: [],
        filter: {},
        orderBy: { column: 'updated_at', order: 'DESC' } as OrderByType,
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    order: {
      onlineConfirm: {
        isFetching: true,
        isFetchingFailure: false,
        data: {},
      },
      order: {
        isFetching: true,
        isFetchingFailure: false,
        data: {},
      },
      orderList: {
        orders: [],
        filter: {},
        orderBy: { column: 'o01_date_order', order: 'DESC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      products: {
        data: [],
        isFetching: false,
        isFetchingFailure: false,
      },
    },
    place: {
      productsDescPairs: {
        data: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      affiliateTypePairs: {
        data: [],
        isFetching: false,
        isFetchingFailure: false,
      },

      affiliateList: {
        isFetching: false,
        isFetchingFailure: false,
        affiliates: [],
        filter: {},
        orderBy: { column: 'c20_label', order: 'ASC' },
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isLoadingMore: false,
      },
      affiliateMarkers: {
        isFetching: false,
        isFetchingFailure: false,
        markers: [],
      },
      affiliate: {
        isFetching: false,
        isFetchingFailure: false,
        data: {},
      },
      wishlist: {
        isPosting: false,
        isFetching: false,
      },
    },
    user: {
      user: {
        data: null as unknown as MyUser,
        isFetching: false,
        isFetchingFailure: false,
      },
      updateUser: {
        isPosting: false,
        isPostingFailure: false,
      },
      userPairs: {
        userPairs: [],
        isFetching: false,
        isFetchingFailure: false,
      },
    },
    card: {
      cardList: {
        cards: [],
        isFetching: false,
        isFetchingFailure: false,
      },
      creditCardOverview: {
        cards: [],
        isFetching: false,
        isFetchingFailure: false,
        widget: null,
      },
      basket: {
        data: {},
        isFetching: false,
        isFetchingFalse: false,
        openAddCard: false,
      },
      credits: {
        data: [],
        isFetching: false,
        isFetchingFalse: false,
      },
      basketOverview: {
        data: {},
        isFetching: false,
        isFetchingFalse: false,
        isConfirmingOrder: false,
      },
      isDeletingOrder: false,
      contractNotConfirmed: false,
    },
    settlement: {
      isPosting: false,
      isSent: false,
      isLoading: false,
      isChecked: false,
    },
    attendance: {
      today: {
        rows: [] as TableRow<AttendanceDetailRow>[],
        shiftSummary: null as AttendanceShiftSummary|null,
        monthSummary: null as AttendanceMonthSummary|null,
        filter: {},
        orderBy: { column: 'd10_event_datetime', order: 'ASC' } as OrderByType,
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      employee: {
        rows: [] as TableRow<AttendanceDayRow>[],
        employee: null as AttendanceEmployeeInfo|null,
        filter: {},
        orderBy: { column: 'd30_date', order: 'ASC' } as OrderByType,
        limit: 10,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
    },
    benefits: {
      benefitsList: {
        rows: [] as TableRows<BenefitItemType>,
        filter: {} as FilterType,
        orderBy: { column: 'd20_order', order: 'ASC' } as OrderByType,
        limit: 6,
        lastOffset: 0,
        lastCount: -1,
        fetchCount: -1,
        isFetching: false,
        isFetchingFailure: false,
        isLoadingMore: false,
      },
      benefitDetail: {
        data: null as (BenefitItemType | null),
        isFetching: false,
        isFetchingFailure: false,
      },
    },
  },
  paymentGate: {
    isFetching: false,
    isFetchingFailure: false,
    transaction: null,
    result: null,
    error: null,
  },
  user: {
    settings: {
      communication: {
        items: [],
        isFetching: false,
        isFetchingFailure: false,
      },
    },
  },
};

export const initialState: RootState = Immutable.fromJS(initialStateJS);
export default initialState;
