import PropTypes from 'prop-types';
import React from 'react';
import { Card } from '../../../../component1/Card';
import Fieldset from '../../../../component1/Form/Fieldset';
import RaisedButton from '../../../../component1/RaisedButton/RaisedButton';
import CardNoField from '../../../../component1/CardNoField/CardNoField';
import { widgetMinHeight } from '../../../global/constants';

class CardActivationWidget extends React.Component {
  state = {
    card_no: '',
  };

  handleActivateCard = () => {
    const cardNo = this.nornalize();
    this.props.onCardActivate(cardNo);
  }

  nornalize() {
    return this.state.card_no.replace(/[^0-9]+/g, '');
  }

  validate() {
    const cardNo = this.nornalize();

    if (cardNo.length < 8) {
      return true;
    }

    return null;
  }

  render() {
    const { t } = this.props;
    const hasErrors = this.validate();

    return (
      <Card layout="centered" title={t('Aktivace karty')} minHeight={widgetMinHeight}>
        <Fieldset>
          <p>{t('Zadejte posledních 8\u00a0číslic své nové karty')}</p>
          <CardNoField
            fieldId="tf-activate-cardno"
            label={t('Posledních 8 číslic vaší nové karty')}
            value={this.state.card_no}
            onChange={(e, value) => this.setState({ card_no: value })}
          />
        </Fieldset>
        <Fieldset>
          <RaisedButton
            priority="primary"
            size="large"
            type="submit"
            label={t('Aktivovat')}
            isLoading={this.props.isLoading}
            disabled={this.props.isLoading || hasErrors}
            onClick={this.handleActivateCard}
          />
        </Fieldset>
      </Card>
    );
  }
}

CardActivationWidget.propTypes = {
  onCardActivate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default CardActivationWidget;
