import React from 'react';
import Dialog from '../../../../component1/Dialog/Dialog';
import { BenefiaOrderBannerDialog } from './components/BenefiaOrderBannerDialog';
import { BenefiaOrderDialog } from './components/BenefiaOrderDialog';
import { DispatchedApiProp } from '../../../../data/storeModel';
import { saveBenefiaOrder } from '../../../benefia/actions';
import { TranslateFn } from '../../../global/model';
import { moneyFormat } from '../../../../service/number';
import { PromoFullWidget } from '../PromoFullWidget/PromoFullWidget';

export interface BenefiaOrderValue {
  faceValue: number | null;
  faceCount: number | null;
}

interface Props {
  companyUserId: number;
  saveBenefiaOrder: DispatchedApiProp<typeof saveBenefiaOrder>;
  t: TranslateFn;
}

interface State {
  showModal: null | 'info' | 'order';
  showConfirm: boolean;
  showSuccess: boolean;
  data: BenefiaOrderValue;
}


export class BenefiaOrderWidget extends React.Component<Props, State> {
  state: State = {
    showModal: null,
    showConfirm: false,
    showSuccess: false,
    data: { faceValue: 5, faceCount: 1 },
  };

  handleCancel = () => {
    this.setState({ showModal: null, showConfirm: false, showSuccess: false });
  }

  handleConfirmCancel = () => {
    this.setState({ showConfirm: false });
  }

  handleOpenInfoModal = () => {
    this.setState({ showModal: 'info' });
  }

  handleOpenOrderModal = () => {
    this.setState({ showModal: 'order' });
    return false;
  }

  handleSubmitOrder = async () => {
    const res = await this.props.saveBenefiaOrder({
      company_user_id: this.props.companyUserId,
      price: this.state.data.faceValue,
      quantity: this.state.data.faceCount,
    });

    if (res.error === true) {
      this.setState({ showSuccess: false, showConfirm: false });
      return false;
    }

    this.setState({ showSuccess: true, showModal: null, showConfirm: false });
    return false;
  }

  handleShowConfirmation = async () => {
    this.setState({ showConfirm: true });
    return false;
  }

  handleSetOrderData = (data: BenefiaOrderValue) => {
    this.setState({ data });
  };

  render() {
    const { t } = this.props;
    const {
      showModal, showConfirm, showSuccess, data,
    } = this.state;

    // <Card
    //   layout="vcentered"
    //   title={t('UpCadhoc')}
    //   minHeight={widgetMinHeight}
    //   bodyClassName={styles.cardBody}
    // >
    //   <p className={styles.text}>&nbsp;</p>
    //   <p className={styles.text}>
    //     <Trans i18nKey="upCadhocWidgetText" t={t}>
    //       Univerzálny darček, použiteľný vo viac ako
    //       {' '}
    //       <a href="https://up-dejeuner.sk/akceptacne-miesta" target="_blank" rel="noreferrer noopener">5000 akceptačných miestach</a>
    //       .
    //     </Trans>
    //   </p>
    //   <div className={styles.bottomSpacer}>
    //     <RaisedButton
    //       priority="primary"
    //       size="large"
    //       type="submit"
    //       label={t('Zakúpiť poukážku')}
    //       onClick={this.handleOpenInfoModal}
    //     />
    //   </div>
    // </Card>

    return (
      <>
        <PromoFullWidget
          key="ph72"
          data={{
            title: t('UpCadhoc'),
            button: t('Zakúpiť poukážku'),
            html_text: t('upCadhocWidgetText'),
            background: '/images/bg-upcadhoc.jpg',
            whiteText: true,
            textClass: 'smalltext',
          }}
          onClick={this.handleOpenInfoModal}
          t={t}
        />
        {showModal === 'info'
        && <BenefiaOrderBannerDialog t={t} onOrder={this.handleOpenOrderModal} onClose={this.handleCancel} />}
        {showModal === 'order'
        && (
        <BenefiaOrderDialog
          value={data}
          setValue={this.handleSetOrderData}
          t={t}
          onOrder={this.handleShowConfirmation}
          onClose={this.handleCancel}
        />
        )}
        {showConfirm && (
          <Dialog
            onHandleClose={this.handleConfirmCancel}
            title={t('Potvrzení')}
            actions={[{
              label: t('Zrušit'),
              onClick: () => {
              },
            }, {
              primary: true,
              label: t('Objednať benefit'),
              onClick: this.handleSubmitOrder,
            }]}
            hasCross
            forceOpen
          >
            <p>
              {t('Naozaj si chcete objednať benefit {{benefitName}} za {{totalValue}}?', { benefitName: 'UpCadhoc', totalValue: moneyFormat(!this.state.data.faceValue || !this.state.data.faceCount ? 0 : this.state.data.faceValue * this.state.data.faceCount, t) })}
            </p>
          </Dialog>
        )}
        {showSuccess && (
          <Dialog
            onHandleClose={this.handleCancel}
            title={t('Objednávka byla úspěšná')}
            actions={[{
              primary: true,
              label: t('Zavřít'),
              onClick: () => {
              },
            }]}
            hasCross
            forceOpen
          >
            <p>
              {t('Prijali sme Vašu objednávku.')}
            </p>
            <p>
              {t('Poukážky UpCadhoc Vám osobne odovzdá zamestnanec ľudských zdrojov Vašej spoločnosti.')}
            </p>
          </Dialog>
        )}
      </>
    );
  }
}
