import { createSelector } from 'reselect';

const statePath = ['partner', 'contract'];
const getContractPairs = (state) => state
  .getIn(statePath.concat(['contractPairs', 'contractPairs']))
  .toJS();
const getContracts = (state) => state
  .getIn(statePath.concat(['contractList', 'contracts']))
  .toJS();


export const selectContractPairs = createSelector(
  [getContractPairs],
  (contractPairs) => contractPairs
);
export const selectContracts = createSelector([getContracts], (contracts) => contracts);
