import { RSAA } from 'redux-api-middleware';
import { API_URL } from '../../../config/envspecific';
import { t } from '../../../i18n';
import * as types from './actionTypes';
import downloadFile from '../../../service/downloadFile';


export const fetchInvoices = (queryString, payload, callback) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/partners_invoices?${queryString}`,
    method: 'GET',
    types: [
      { type: types.FETCH_INVOICES_REQUEST, payload },
      types.FETCH_INVOICES_SUCCESS,
      types.FETCH_INVOICES_FAILURE,
    ],
  },
}).then(() => callback());

export const updateInvoicesFilterData = (filterData, orderColumnData) => ({
  type: types.UPDATE_INVOICES_FILTER_DATA,
  payload: {
    filter: filterData,
    orderBy: orderColumnData,
  },
});

export const requestInvoiceDownload = (invoiceId, number) => ({
  [RSAA]: {
    endpoint: `${API_URL}/partners_invoices/${invoiceId}/pdf`,
    method: 'GET',
    types: [
      types.DOWNLOAD_INVOICE_REQUEST,
      {
        type: types.DOWNLOAD_INVOICE_SUCCESS,
        payload: (action, state, res) => res.blob().then(
          (blob) => downloadFile(blob, t('KartaUp_faktura_{{number}}.pdf', { number, ns: 'partner' }))
        ),
      },
      types.DOWNLOAD_INVOICE_FAILURE,
    ],
  },
});
