/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { MOBILE_APP } from '../../config/envspecific';
import styles from './TextField.scss';
import { formatInputNumber } from '../../service/number';
import { PropTypeErrorText } from '../../modules/global/proptypes';
import Icon from '../Icon/Icon';


class TextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: (props.value !== false && props.value !== undefined) ? props.value : '',
      inputFocused: false,
      passVisible: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value !== false ? nextProps.value : '' });
  }

  handleChange(e) {
    e.preventDefault();
    const value = e.target.value;
    if (this.props.onChange) {
      this.props.onChange(e, value);
    }
    this.setState({ value });
  }

  handleLabelClick = () => {
    if (!this.state.inputFocused && this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const {
      disabled, helpText, errorText, hasNoErrors, label, placeholder, type, noTopSpacing, isThin,
      autoComplete, isFramed, isFilled, formatValue, step, maxLength, rightAligned, isExtraThin,
      bigText, hasNoUnderline, InputProps, onClick, icon,
    } = this.props;
    const { value, passVisible } = this.state;

    let helpTextElement = null;

    if (!hasNoErrors && errorText) {
      helpTextElement = <div className={styles.errorText}>{errorText}</div>;
    } else if (helpText) {
      helpTextElement = <div className={styles.helpText}>{helpText}</div>;
    }

    // const floatLabel = (value !== null && value !== undefined && value !== '') || inputFocused;
    const floatLabel = true;

    const passIcon = type === 'password' && (passVisible ? 'visibility_off' : 'visibility');
    // eslint-disable-next-line no-nested-ternary
    const inputType = type === 'password' ? (passVisible ? 'text' : 'password') : type;

    return (
      <div
        className={classnames(
          hasNoErrors ? styles.hasRootNoErrors : styles.root,
          noTopSpacing && styles.rootNoTopSpacing,
          isThin && styles.rootIsThin,
          isExtraThin && styles.rootIsExtraThin,
          !label && styles.noLabel,
          isFramed && styles.framed,
          isFilled && styles.filled
        )}
        style={this.props.style}
      >
        <label
          htmlFor={this.props.fieldId}
          className={floatLabel ? styles.isLabelFloating : styles.label}
          onClick={this.handleLabelClick}
        >
          {label}
        </label>
        <div className={classnames(styles.inputContainer, hasNoUnderline && styles.noUnderline)}>
          <input
            id={this.props.fieldId}
            type={inputType || (formatValue === 'number' && MOBILE_APP ? 'number' : 'text')}
            value={value !== null && value !== undefined ? value : ''}
            onChange={(e) => this.handleChange(e)}
            disabled={disabled}
            className={classnames(styles.input, rightAligned && styles.rightAligned, bigText && styles.bigText)}
            placeholder={placeholder || label}
            pattern={formatValue === 'number' ? '[0-9,.]*' : undefined}
            inputMode={formatValue === 'number' ? 'numeric' : undefined}
            onBlur={(e) => {
              this.setState({ inputFocused: false });

              if (this.props.formatValue === 'number') {
                const fmtedValue = formatInputNumber(this.state.value);

                if (this.props.onChange) {
                  this.props.onChange(e, fmtedValue);
                }
                this.setState({ value: fmtedValue });
              }

              if (this.props.onBlur) {
                this.props.onBlur(e, this.state.value);
              }
            }}
            onFocus={() => {
              this.setState({ inputFocused: true });

              if (this.props.onFocus) {
                this.props.onFocus();
              }
            }}
            autoComplete={autoComplete ? 'on' : 'off'}
            step={step}
            maxLength={maxLength}
            onClick={onClick}
            {...InputProps}
          />
          {passIcon && <Icon icon={passIcon} className={styles.inputIcon} onClick={() => this.setState({ passVisible: !passVisible })} />}
          {icon && <Icon icon={icon} className={styles.inputIcon} />}
        </div>
        {!isFramed && !isFilled && !hasNoUnderline && <div className={hasNoErrors ? styles.hasUnderlineNoErrors : styles.underline} />}
        {helpTextElement}
      </div>
    );
  }
}

TextField.propTypes = {
  fieldId: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.shape({}),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  helpText: PropTypes.string,
  errorText: PropTypeErrorText,
  type: PropTypes.oneOf(['email', 'number', 'password', 'tel', 'text', 'url']),
  formatValue: PropTypes.oneOf(['number']),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  hasNoErrors: PropTypes.bool,
  noTopSpacing: PropTypes.bool,
  isThin: PropTypes.bool,
  isExtraThin: PropTypes.bool,
  autoComplete: PropTypes.bool,
  isFramed: PropTypes.bool,
  isFilled: PropTypes.bool,
  step: PropTypes.number,
  maxLength: PropTypes.number,
  rightAligned: PropTypes.bool,
  bigText: PropTypes.bool,
  hasNoUnderline: PropTypes.bool,
  InputProps: PropTypes.shape({
    readOnly: PropTypes.bool,
  }),
  icon: PropTypes.string,
};

export default TextField;
