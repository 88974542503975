import PropTypes from 'prop-types';
import React from 'react';
import { IconButton as MUIIconButton, Tooltip } from '@material-ui/core';

const IconButton = (props) => {
  const button = (
    <MUIIconButton
      style={props.style}
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
      color={props.color}
      disableFocusRipple
    >
      {props.children}
    </MUIIconButton>
  );

  if (!props.tooltip) {
    return button;
  }
  return (
    <Tooltip title={props.tooltip} placement={props.tooltipPosition} leaveDelay={0} enterDelay={250}>
      {button}
    </Tooltip>
  );
};

IconButton.defaultProps = {
  tooltipPosition: 'top',
  disabled: false,
};

IconButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  color: PropTypes.oneOf(['inherit', 'default', 'primary', 'secondary', 'error']),
};

export default IconButton;
