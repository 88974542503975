import { createSelector } from 'reselect';
import { selectContractPairs } from '../../firm/contract';

export const getOrder = (state) => state.getIn(['order']).toJS();

export const selectOrderInfo = createSelector(
  [getOrder], (order) => ({
    contract: order.contract,
    productCode: order.productCode,
    firm: order.firm,
    isDeletingOrder: order.isDeletingOrder,
    isDeletingOrderFailure: order.isDeletingOrderFailure,
    isInProgress: order.isInProgress,
    isRedirectedToContractSelection: order.isRedirectedToContractSelection,
    isRedirectedToAdjustment: order.isRedirectedToAdjustment,
    isRedirectedToValidation: order.isRedirectedToValidation,
  })
);

export const selectOrderContract = createSelector(
  [getOrder, selectContractPairs], (order, contractPairs) => order.contract && contractPairs && contractPairs
    .find((c) => c.c10_contract_id === order.contract.c10_contract_id && c.c01_company_id === order.contract.c01_company_id)
);

export const selectOrderIsInProgress = createSelector([getOrder], (order) => order.isInProgress);

export const selectOrderIsLoading = createSelector([getOrder], (order) => order.steps.initialization.isImporting
  || order.steps.initialization.isDownloading
  || order.steps.adjustmentOfValues.isLoadingBasket
  || order.isLoading);

export const selectOrderProducts = createSelector([getOrder], (order) => order.products);
export const selectOrderEnabledProducts = createSelector([getOrder], (order) => order.products && order.products.data && order.products.data.filter((p) => p.is_enabled));
