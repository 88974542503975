import { addFlashMessage } from '../modules/flashMessages/actions';

const apiAuthorizationMiddlewareFactory = (localStorage, history, t) => (store) => (next) => (action) => {
  if (typeof action.payload !== 'undefined' && action.payload.status === 403) {
    store.dispatch(addFlashMessage('error', t('Nemáte dostatečná oprávnění pro provedení Vašeho požadavku.')));
    // removed due to infinite loop in combination with callback and setUrlFromFilter in getFetchEntities factory
    // history.push('/');
    // return false;
  }

  return next(action);
};

export default apiAuthorizationMiddlewareFactory;
