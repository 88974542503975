import { RSAA } from 'redux-api-middleware';
import { API_URL } from '../../../../config/envspecific';
import { ThunkApiActionCreator } from '../../../../data/storeModel';
import * as types from './actionTypes';
import { apiErrorMessageHandler } from '../../../../service/helpers';
import { encryptPin } from '../../../../service/encryptPin';
import { addFlashMessage } from '../../../flashMessages/actions';
import { t } from '../../../../i18n';

export * from './actions1';

export const fetchCardPin: ThunkApiActionCreator = (data: { p20_card_id: number; password: string; size: number }) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_cards/pin/show`,
    method: 'POST',
    body: JSON.stringify(data),
    types: [
      types.FETCH_CARD_PIN_REQUEST,
      types.FETCH_CARD_PIN_SUCCESS,
      types.FETCH_CARD_PIN_FAILURE,
    ],
  },
}).then(apiErrorMessageHandler(dispatch, undefined, undefined, ['0102']));

export const changeCardPin: ThunkApiActionCreator = (data: { p20_card_id: number; password: string; pin: string }) => async (dispatch) => {
  const res1 = await dispatch({
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${API_URL}/my_cards/pin/request`,
      method: 'PUT',
      body: JSON.stringify({ p20_card_id: data.p20_card_id, password: data.password }),
      types: [
        types.REQUEST_UPDATE_CARD_PIN_REQUEST,
        types.REQUEST_UPDATE_CARD_PIN_SUCCESS,
        types.REQUEST_UPDATE_CARD_PIN_FAILURE,
      ],
    },
  }).then(apiErrorMessageHandler(dispatch, undefined, undefined, ['0102']));

  if (res1.error === true) {
    return res1;
  }

  const encrypted = await encryptPin(data.pin, res1.payload.uuid);

  const res2 = await dispatch({
    [RSAA]: {
      headers: { 'Content-Type': 'application/json' },
      endpoint: `${API_URL}/my_cards/pin/update`,
      method: 'PUT',
      body: JSON.stringify({ p20_card_id: data.p20_card_id, encrypted }),
      types: [
        types.SAVE_UPDATE_CARD_PIN_REQUEST,
        types.SAVE_UPDATE_CARD_PIN_SUCCESS,
        types.SAVE_UPDATE_CARD_PIN_FAILURE,
      ],
    },
  }).then(apiErrorMessageHandler(dispatch))
    .then((res) => {
      if (!res.error) {
        dispatch(addFlashMessage('success', t('Zmena PIN bola úspešná.', { ns: 'my' })));
      }
      return res;
    });

  return res2;
};
