export const MARK_AFFILIATE_HIDDEN_REQUEST = 'partner/affiliate/MARK_AFFILIATE_HIDDEN_REQUEST';
export const MARK_AFFILIATE_HIDDEN_SUCCESS = 'partner/affiliate/MARK_AFFILIATE_HIDDEN_SUCCESS';
export const MARK_AFFILIATE_HIDDEN_FAILURE = 'partner/affiliate/MARK_AFFILIATE_HIDDEN_FAILURE';

export const MARK_AFFILIATE_VISIBLE_REQUEST = 'partner/affiliate/MARK_AFFILIATE_VISIBLE_REQUEST';
export const MARK_AFFILIATE_VISIBLE_SUCCESS = 'partner/affiliate/MARK_AFFILIATE_VISIBLE_SUCCESS';
export const MARK_AFFILIATE_VISIBLE_FAILURE = 'partner/affiliate/MARK_AFFILIATE_VISIBLE_FAILURE';

export const FETCH_AFFILIATES_REQUEST = 'partner/affiliate/FETCH_AFFILIATES_REQUEST';
export const FETCH_AFFILIATES_SUCCESS = 'partner/affiliate/FETCH_AFFILIATES_SUCCESS';
export const FETCH_AFFILIATES_FAILURE = 'partner/affiliate/FETCH_AFFILIATES_FAILURE';

export const FETCH_AFFILIATE_REQUEST = 'partner/affiliate/FETCH_AFFILIATE_REQUEST';
export const FETCH_AFFILIATE_SUCCESS = 'partner/affiliate/FETCH_AFFILIATE_SUCCESS';
export const FETCH_AFFILIATE_FAILURE = 'partner/affiliate/FETCH_AFFILIATE_FAILURE';

export const CREATE_AFFILIATE_REQUEST = 'partner/affiliate/CREATE_AFFILIATE_REQUEST';
export const CREATE_AFFILIATE_SUCCESS = 'partner/affiliate/CREATE_AFFILIATE_SUCCESS';
export const CREATE_AFFILIATE_FAILURE = 'partner/affiliate/CREATE_AFFILIATE_FAILURE';

export const UPDATE_AFFILIATE_REQUEST = 'partner/affiliate/UPDATE_AFFILIATE_REQUEST';
export const UPDATE_AFFILIATE_SUCCESS = 'partner/affiliate/UPDATE_AFFILIATE_SUCCESS';
export const UPDATE_AFFILIATE_FAILURE = 'partner/affiliate/UPDATE_AFFILIATE_FAILURE';

export const FETCH_AFFILIATE_PAIRS_REQUEST = 'partner/affiliate/FETCH_AFFILIATE_PAIRS_REQUEST';
export const FETCH_AFFILIATE_PAIRS_SUCCESS = 'partner/affiliate/FETCH_AFFILIATE_PAIRS_SUCCESS';
export const FETCH_AFFILIATE_PAIRS_FAILURE = 'partner/affiliate/FETCH_AFFILIATE_PAIRS_FAILURE';

export const FETCH_AFFILIATE_MPOS_AVAIL_REQUEST = 'partner/affiliate/FETCH_AFFILIATE_MPOS_AVAIL_REQUEST';
export const FETCH_AFFILIATE_MPOS_AVAIL_SUCCESS = 'partner/affiliate/FETCH_AFFILIATE_MPOS_AVAIL_SUCCESS';
export const FETCH_AFFILIATE_MPOS_AVAIL_FAILURE = 'partner/affiliate/FETCH_AFFILIATE_MPOS_AVAIL_FAILURE';

export const FETCH_AFFILIATE_MPOS_ACTIVE_REQUEST = 'partner/affiliate/FETCH_AFFILIATE_MPOS_ACTIVE_REQUEST';
export const FETCH_AFFILIATE_MPOS_ACTIVE_SUCCESS = 'partner/affiliate/FETCH_AFFILIATE_MPOS_ACTIVE_SUCCESS';
export const FETCH_AFFILIATE_MPOS_ACTIVE_FAILURE = 'partner/affiliate/FETCH_AFFILIATE_MPOS_ACTIVE_FAILURE';

export const FETCH_AFFILIATE_ACTIVATE_MPOS_REQUEST = 'partner/affiliate/FETCH_AFFILIATE_ACTIVATE_MPOS_REQUEST';
export const FETCH_AFFILIATE_ACTIVATE_MPOS_SUCCESS = 'partner/affiliate/FETCH_AFFILIATE_ACTIVATE_MPOS_SUCCESS';
export const FETCH_AFFILIATE_ACTIVATE_MPOS_FAILURE = 'partner/affiliate/FETCH_AFFILIATE_ACTIVATE_MPOS_FAILURE';

export const FETCH_AFFILIATE_PRODUCT_TYPES_REQUEST = 'partner/FETCH_AFFILIATE_PRODUCT_TYPES_REQUEST';
export const FETCH_AFFILIATE_PRODUCT_TYPES_SUCCESS = 'partner/FETCH_AFFILIATE_PRODUCT_TYPES_SUCCESS';
export const FETCH_AFFILIATE_PRODUCT_TYPES_FAILURE = 'partner/FETCH_AFFILIATE_PRODUCT_TYPES_FAILURE';

export const UPDATE_AFFILIATES_FILTER_DATA = 'partner/affiliate/UPDATE_AFFILIATES_FILTER_DATA';
