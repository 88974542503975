import checkParameters from './checkParameters';
import scanRouteChildren from './scanRouteChildren';
import transformOptionalPathParameters from './transformOptionalPathParameters';

export function hasContractRight(rightKey, contractId, contractRights) {
  return contractId in contractRights
  && contractRights[contractId].indexOf(rightKey) !== -1;
}

export default (routingTable, name, parameters, contractId, contractRights) => {
  const scanRoute = (routingTableNode, connectedPath) => {
    let currentLevelPath = (`${connectedPath}/${routingTableNode.path || ''}`).replace('//', '/');

    if (connectedPath !== currentLevelPath) {
      currentLevelPath = transformOptionalPathParameters(currentLevelPath);
    }

    if (routingTableNode.name === name && checkParameters(currentLevelPath, parameters)) {
      return hasContractRight(routingTableNode.key, contractId, contractRights);
    }

    return scanRouteChildren(routingTableNode, currentLevelPath, scanRoute);
  };

  return scanRoute(routingTable, '') || false;
};
