import React from 'react';
import PropTypes from 'prop-types';
import RaisedButton from '../../../../component1/RaisedButton/RaisedButton';
import Icon from '../../../../component1/Icon/Icon';
import styles from './ContributionOrderWidget.scss';
import { PropTypeWidgetContribution, PropTypePairsList } from '../../../global/proptypes';
import { moneyFormat3 } from '../../../../service/number';

const paymentTypeIcons = {
  3: 'credit_card',
  4: 'cloud_upload',
  1: 'money',
  2: 'money',
  5: 'money',
};

export const PaymentTypeLabel = ({ t, fee, type }) => (type ? (
  <div className={styles.paymentTypeLabel}>
    <Icon icon={paymentTypeIcons[type.value]} size="xlarge" />
    <span>
      {/* {t(`contributionPayment-${type.value}`)} */}
      {type.label}
    </span>
    <span className={styles.feeText}>{fee ? moneyFormat3(fee, t) : t('zdarma')}</span>
  </div>
) : null);

PaymentTypeLabel.propTypes = {
  t: PropTypes.func.isRequired,
  fee: PropTypes.string,
  type: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
};

export const PaymentPageContent = ({
  t, data, onSetPayment, paymentTypePairs,
}) => (
  <div className={styles.content}>
    <h3>{t('Úhrada poukazu')}</h3>
    <p>
      {t('Abysme vám mohli nahrát celou částku, zaplaťte prosím částku:')}
      <span className={styles.amount}>{moneyFormat3(data.amount_to_pay, t)}</span>
    </p>
    <div className={styles.paymentTypes}>
      {data.payments.map((payment) => (
        <RaisedButton
          key={payment.i04_payment_type_id}
          label={<PaymentTypeLabel t={t} fee={payment.fee} type={paymentTypePairs.find((p) => p.value === payment.i04_payment_type_id)} />}
          onClick={async () => {
            const ret = await onSetPayment(payment.i04_payment_type_id);
            if (!ret.error && ret.payload.redirect) {
              window.location.assign(ret.payload.redirect);
            }
          }}
          priority="primary"
          isBlock
          isMultiLine
        />
      ))}
    </div>
  </div>
);

PaymentPageContent.propTypes = {
  t: PropTypes.func.isRequired,
  onSetPayment: PropTypes.func.isRequired,
  data: PropTypeWidgetContribution.isRequired,
  paymentTypePairs: PropTypePairsList,
};
