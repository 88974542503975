import pathToRegexp from 'path-to-regexp';


export default (path, parameters) => {
  const inputParamsCount = Object.keys(parameters).length;

  const parsedPath = pathToRegexp.parse(path);
  const paramsCount = parsedPath.reduce(
    (count, parameter) => {
      if (typeof parameter === 'string') {
        return count;
      }

      return count + 1;
    },
    0
  );
  let mandatoryParamsCount = 0;

  for (let i = 0; i < parsedPath.length; i += 1) {
    if (!parsedPath[i].optional && typeof parsedPath[i] !== 'string') {
      if (Object.keys(parameters).indexOf(parsedPath[i].name) === -1) {
        return false;
      }

      mandatoryParamsCount += 1;
    }
  }

  return inputParamsCount >= mandatoryParamsCount && inputParamsCount <= paramsCount;
};
