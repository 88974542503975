export const FETCH_ARES_DATA_REQUEST = 'registration/firm/FETCH_ARES_DATA_REQUEST';
export const FETCH_ARES_DATA_SUCCESS = 'registration/firm/FETCH_ARES_DATA_SUCCESS';
export const FETCH_ARES_DATA_FAILURE = 'registration/firm/FETCH_ARES_DATA_FAILURE';

export const REGISTER_FIRM_REQUEST = 'registration/firm/REGISTER_FIRM_REQUEST';
export const REGISTER_FIRM_SUCCESS = 'registration/firm/REGISTER_FIRM_SUCCESS';
export const REGISTER_FIRM_FAILURE = 'registration/firm/REGISTER_FIRM_FAILURE';

export const CONFIRM_FIRM_REGISTRATION_REQUEST = 'registration/firm/CONFIRM_FIRM_REGISTRATION_REQUEST';
export const CONFIRM_FIRM_REGISTRATION_SUCCESS = 'registration/firm/CONFIRM_FIRM_REGISTRATION_SUCCESS';
export const CONFIRM_FIRM_REGISTRATION_FAILURE = 'registration/firm/CONFIRM_FIRM_REGISTRATION_FAILURE';
