import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { tRoute } from '../i18n';
import { initOfflineHooks, initOfflineState, appActiveChange } from '../service/appStateService';

class _CordovaExtension extends React.Component {
  UNSAFE_componentWillMount() {
    document.addEventListener('deviceready', this.handleDeviceReady, false);
  }

  handleDeviceReady = () => {
    document.addEventListener('backbutton', this.handleBackButton, false);
    this.props.initOfflineHooks();
    this.props.appActiveChange(true);
    document.addEventListener('pause', this.handlePause, false);
    document.addEventListener('resume', this.handleResume, false);
    // document.addEventListener("menubutton", onMenuKeyDown, false);
  }

  handleResume = () => {
    this.props.initOfflineState();
    this.props.appActiveChange(true);
  }

  handlePause = () => {
    this.props.appActiveChange(false);
  }

  handleBackButton = () => {
    const location = this.props.router && this.props.router.getCurrentLocation();
    if (location && (location.pathname === tRoute('/') || location.pathname === tRoute('/prihlaseni'))) {
      // Code to exit app
      navigator.app.exitApp();
    } else {
      // Code to navigate to the history action
      navigator.app.backHistory();
    }
  }

  render() {
    return null;
  }
}

_CordovaExtension.propTypes = {
  // t: PropTypes.func.isRequired,
  initOfflineHooks: PropTypes.func.isRequired,
  initOfflineState: PropTypes.func.isRequired,
  appActiveChange: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
    getCurrentLocation: PropTypes.func.isRequired,
  }).isRequired,
};


const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  initOfflineHooks: () => dispatch(initOfflineHooks()),
  initOfflineState: () => dispatch(initOfflineState()),
  appActiveChange: (visible) => dispatch(appActiveChange(visible)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('auth')(_CordovaExtension));
