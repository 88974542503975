import Immutable from 'immutable';
import * as types from './actionTypes';
import { SET_LOGIN_INFO } from '../auth/actionTypes';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['global']);
  }

  if (type === types.FETCH_BANK_PAIRS_REQUEST) {
    return state
      .setIn(['bankPairs', 'isFetchingFailure'], false)
      .setIn(['bankPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_BANK_PAIRS_SUCCESS) {
    const banks = payload.map((item) => ({
      label: item.val,
      value: item.id,
    }));

    return state
      .setIn(['bankPairs', 'bankPairs'], Immutable.fromJS(banks))
      .setIn(['bankPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_BANK_PAIRS_FAILURE) {
    return state
      .setIn(['bankPairs', 'isFetching'], false)
      .setIn(['bankPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_TRANSACTION_TYPE_PAIRS_REQUEST) {
    return state
      .setIn(['transactionTypePairs', 'isFetchingFailure'], false)
      .setIn(['transactionTypePairs', 'isFetching'], true);
  }

  if (type === types.FETCH_TRANSACTION_TYPE_PAIRS_SUCCESS) {
    const transactionTypePairs = payload.map((item) => ({
      label: item.val,
      value: item.id,
    }));

    return state
      .setIn(['transactionTypePairs', 'transactionTypePairs'], Immutable.fromJS(transactionTypePairs))
      .setIn(['transactionTypePairs', 'isFetching'], false);
  }

  if (type === types.FETCH_TRANSACTION_TYPE_PAIRS_FAILURE) {
    return state
      .setIn(['transactionTypePairs', 'isFetching'], false)
      .setIn(['transactionTypePairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_DELIVERY_STATUS_PAIRS_REQUEST) {
    return state
      .setIn(['deliveryStatusPairs', 'isFetchingFailure'], false)
      .setIn(['deliveryStatusPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_DELIVERY_STATUS_PAIRS_SUCCESS) {
    const deliveryStatusPairs = payload.map((item) => ({
      label: item.val,
      value: item.id,
    }));

    return state
      .setIn(['deliveryStatusPairs', 'deliveryStatusPairs'], Immutable.fromJS(deliveryStatusPairs))
      .setIn(['deliveryStatusPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_DELIVERY_STATUS_PAIRS_FAILURE) {
    return state
      .setIn(['deliveryStatusPairs', 'isFetching'], false)
      .setIn(['deliveryStatusPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_CASH_SYSTEM_PAIRS_REQUEST) {
    return state
      .setIn(['cashSystemPairs', 'isFetchingFailure'], false)
      .setIn(['cashSystemPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_CASH_SYSTEM_PAIRS_SUCCESS) {
    const cashSystems = payload.map((item) => ({
      label: item.val,
      value: item.id,
    }));

    return state
      .setIn(['cashSystemPairs', 'cashSystemPairs'], Immutable.fromJS(cashSystems))
      .setIn(['cashSystemPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_CASH_SYSTEM_PAIRS_FAILURE) {
    return state
      .setIn(['cashSystemPairs', 'isFetching'], false)
      .setIn(['cashSystemPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_AFFILIATE_TYPE_PAIRS_REQUEST) {
    return state
      .setIn(['affiliateTypePairs', 'isFetchingFailure'], false)
      .setIn(['affiliateTypePairs', 'isFetching'], true);
  }

  if (type === types.FETCH_AFFILIATE_TYPE_PAIRS_SUCCESS) {
    const affiliateTypes = payload.map((item) => ({
      label: item.val,
      value: item.id,
      group: item.grouped,
      cond: item.cond,
    }));

    return state
      .setIn(['affiliateTypePairs', 'affiliateTypePairs'], Immutable.fromJS(affiliateTypes))
      .setIn(['affiliateTypePairs', 'isFetching'], false);
  }

  if (type === types.FETCH_AFFILIATE_TYPE_PAIRS_FAILURE) {
    return state
      .setIn(['affiliateTypePairs', 'isFetching'], false)
      .setIn(['affiliateTypePairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_NAME_ON_CARD_PAIRS_REQUEST) {
    return state
      .setIn(['nameOnCardPairs', 'isFetchingFailure'], false)
      .setIn(['nameOnCardPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_NAME_ON_CARD_PAIRS_SUCCESS) {
    const nameOnCardPairs = payload.map((item) => ({
      label: item.val,
      value: item.id,
    }));

    return state
      .setIn(['nameOnCardPairs', 'nameOnCardPairs'], Immutable.fromJS(nameOnCardPairs))
      .setIn(['nameOnCardPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_NAME_ON_CARD_PAIRS_FAILURE) {
    return state
      .setIn(['nameOnCardPairs', 'isFetching'], false)
      .setIn(['nameOnCardPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_DELIVERY_CARD_ORDER_PAIRS_REQUEST) {
    return state
      .setIn(['deliveryCardOrderPairs', 'isFetchingFailure'], false)
      .setIn(['deliveryCardOrderPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_DELIVERY_CARD_ORDER_PAIRS_SUCCESS) {
    const deliveryCardOrderPairs = payload.map((item) => ({
      label: item.val,
      value: item.id,
    }));

    return state
      .setIn(['deliveryCardOrderPairs', 'deliveryCardOrderPairs'], Immutable.fromJS(deliveryCardOrderPairs))
      .setIn(['deliveryCardOrderPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_DELIVERY_CARD_ORDER_PAIRS_FAILURE) {
    return state
      .setIn(['deliveryCardOrderPairs', 'isFetching'], false)
      .setIn(['deliveryCardOrderPairs', 'isFetchingFailure'], true);
  }

  if (type === types.SET_BRAND) {
    return state.set('brand', Immutable.fromJS({
      code: payload.code,
    }));
  }

  if (type === types.SET_OFFLINE) {
    return state.set('offline', payload.offline);
  }

  if (type === types.SET_APP_ACTIVE) {
    return state.set('appActive', payload.active);
  }

  if (type === SET_LOGIN_INFO) {
    return state.set('brand', Immutable.fromJS({
      code: payload.brand,
    }));
  }

  if (type === types.FETCH_PRODUCT_PAIRS_REQUEST) {
    return state
      .setIn(['productsPairs', 'isFetchingFailure'], false)
      .setIn(['productsPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_PRODUCT_PAIRS_SUCCESS) {
    const productsPairs = payload.map((item) => ({
      label: item.val,
      value: item.id,
      group: item.grouped,
      cond: item.cond,
    }));

    return state
      .setIn(['productsPairs', 'productsPairs'], Immutable.fromJS(productsPairs))
      .setIn(['productsPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_PRODUCT_PAIRS_FAILURE) {
    return state
      .setIn(['productsPairs', 'isFetching'], false)
      .setIn(['productsPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_PRODUCT_DESC_PAIRS_REQUEST) {
    return state
      .setIn(['productsPairs', 'isFetchingFailure'], false)
      .setIn(['productsPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_PRODUCT_DESC_PAIRS_SUCCESS) {
    const productsDescPairs = payload.map((item) => ({
      label: item.val,
      value: item.id,
      group: item.grouped,
      cond: item.cond,
    }));

    return state
      .setIn(['productsDescPairs', 'data'], Immutable.fromJS(productsDescPairs))
      .setIn(['productsDescPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_PRODUCT_DESC_PAIRS_FAILURE) {
    return state
      .setIn(['productsDescPairs', 'isFetching'], false)
      .setIn(['productsDescPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_CARD_KINDS_PAIRS_REQUEST) {
    return state
      .setIn(['cardKindsPairs', 'isFetchingFailure'], false)
      .setIn(['cardKindsPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_CARD_KINDS_PAIRS_SUCCESS) {
    const cardKindsPairs = payload.map((item) => ({
      label: item.val,
      value: item.id,
    }));

    return state
      .setIn(['cardKindsPairs', 'data'], Immutable.fromJS(cardKindsPairs))
      .setIn(['cardKindsPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_CARD_KINDS_PAIRS_FAILURE) {
    return state
      .setIn(['cardKindsPairs', 'isFetching'], false)
      .setIn(['cardKindsPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_CARD_TYPE_PAIRS_REQUEST) {
    return state
      .setIn(['cardTypePairs', 'isFetchingFailure'], false)
      .setIn(['cardTypePairs', 'isFetching'], true);
  }

  if (type === types.FETCH_CARD_TYPE_PAIRS_SUCCESS) {
    const cardTypePairs = payload.map((item) => ({
      label: item.val,
      value: item.id,
    }));

    return state
      .setIn(['cardTypePairs', 'data'], Immutable.fromJS(cardTypePairs))
      .setIn(['cardTypePairs', 'isFetching'], false);
  }

  if (type === types.FETCH_CARD_TYPE_PAIRS_FAILURE) {
    return state
      .setIn(['cardTypePairs', 'isFetching'], false)
      .setIn(['cardTypePairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_GATE_TRANS_STATUS_PAIRS_REQUEST) {
    return state
      .setIn(['gateTransStatusPairs', 'isFetchingFailure'], false)
      .setIn(['gateTransStatusPairs', 'isFetching'], true);
  }

  if (type === types.FETCH_GATE_TRANS_STATUS_PAIRS_SUCCESS) {
    const pairs = payload.map((item) => ({
      label: item.val,
      value: item.id,
    }));

    return state
      .setIn(['gateTransStatusPairs', 'data'], Immutable.fromJS(pairs))
      .setIn(['gateTransStatusPairs', 'isFetching'], false);
  }

  if (type === types.FETCH_GATE_TRANS_STATUS_PAIRS_FAILURE) {
    return state
      .setIn(['gateTransStatusPairs', 'isFetching'], false)
      .setIn(['gateTransStatusPairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_PAYMENT_TYPE_PAIRS_REQUEST) {
    return state
      .setIn(['paymentTypePairs', 'isFetchingFailure'], false)
      .setIn(['paymentTypePairs', 'isFetching'], true);
  }

  if (type === types.FETCH_PAYMENT_TYPE_PAIRS_SUCCESS) {
    const pairs = payload.map((item) => ({
      label: item.val,
      value: item.id,
    }));

    return state
      .setIn(['paymentTypePairs', 'data'], Immutable.fromJS(pairs))
      .setIn(['paymentTypePairs', 'isFetching'], false);
  }

  if (type === types.FETCH_PAYMENT_TYPE_PAIRS_FAILURE) {
    return state
      .setIn(['paymentTypePairs', 'isFetching'], false)
      .setIn(['paymentTypePairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_ATTENDANCE_TYPE_PAIRS_REQUEST) {
    return state
      .setIn(['attendanceTypePairs', 'isFetchingFailure'], false)
      .setIn(['attendanceTypePairs', 'isFetching'], true);
  }

  if (type === types.FETCH_ATTENDANCE_TYPE_PAIRS_SUCCESS) {
    const pairs = payload.map((item) => ({
      label: item.val,
      value: item.id,
      standard: item.standard !== '0',
    }));

    return state
      .setIn(['attendanceTypePairs', 'data'], Immutable.fromJS(pairs))
      .setIn(['attendanceTypePairs', 'isFetching'], false);
  }

  if (type === types.FETCH_ATTENDANCE_TYPE_PAIRS_FAILURE) {
    return state
      .setIn(['attendanceTypePairs', 'isFetching'], false)
      .setIn(['attendanceTypePairs', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_GLOBAL_PAIRS_REQUEST) {
    return state
      .setIn([payload.name, 'isFetchingFailure'], false)
      .setIn([payload.name, 'isFetching'], true);
  }

  if (type === types.FETCH_GLOBAL_PAIRS_SUCCESS) {
    const pairs = payload.items.map((item) => ({
      label: item.val,
      value: item.id,
      standard: item.standard !== '0',
      cond: item.cond,
    }));

    return state
      .setIn([payload.name, 'data'], Immutable.fromJS(pairs))
      .setIn([payload.name, 'isFetching'], false);
  }

  if (type === types.FETCH_GLOBAL_PAIRS_FAILURE) {
    return state
      .setIn([payload.name, 'isFetching'], false)
      .setIn([payload.name, 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
