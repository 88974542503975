export const FETCH_CONTRACT_PAIRS_REQUEST = 'partner/contract/FETCH_CONTRACT_PAIRS_REQUEST';
export const FETCH_CONTRACT_PAIRS_SUCCESS = 'partner/contract/FETCH_CONTRACT_PAIRS_SUCCESS';
export const FETCH_CONTRACT_PAIRS_FAILURE = 'partner/contract/FETCH_CONTRACT_PAIRS_FAILURE';

export const FETCH_CONTRACTS_REQUEST = 'partner/partner/FETCH_CONTRACT_REQUEST';
export const FETCH_CONTRACTS_SUCCESS = 'partner/partner/FETCH_CONTRACT_SUCCESS';
export const FETCH_CONTRACTS_FAILURE = 'partner/partner/FETCH_CONTRACT_FAILURE';

export const FETCH_PAYOUT_FREQS_REQUEST = 'partner/partner/FETCH_PAYOUT_FREQS_REQUEST';
export const FETCH_PAYOUT_FREQS_SUCCESS = 'partner/partner/FETCH_PAYOUT_FREQS_SUCCESS';
export const FETCH_PAYOUT_FREQS_FAILURE = 'partner/partner/FETCH_PAYOUT_FREQS_FAILURE';

export const UPDATE_PAYOUT_FREQ_REQUEST = 'partner/partner/CHANGE_PAYOUT_FREQ_REQUEST';
export const UPDATE_PAYOUT_FREQ_SUCCESS = 'partner/partner/CHANGE_PAYOUT_FREQ_SUCCESS';
export const UPDATE_PAYOUT_FREQ_FAILURE = 'partner/partner/CHANGE_PAYOUT_FREQ_FAILURE';

export const DOWNLOAD_CONTRACT_PDF_REQUEST = 'partner/contract/DOWNLOAD_CONTRACT_PDF_REQUEST';
export const DOWNLOAD_CONTRACT_PDF_SUCCESS = 'partner/contract/DOWNLOAD_CONTRACT_PDF_SUCCESS';
export const DOWNLOAD_CONTRACT_PDF_FAILURE = 'partner/contract/DOWNLOAD_CONTRACT_PDF_FAILURE';
