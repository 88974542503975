import * as types from './actionTypes';


const authReducerFactory = (initialState) => (state, action) => {
  if (typeof state === 'undefined') {
    return initialState.get('flashMessages');
  }

  if (action.type === types.ADD_FLASH_MESSAGE) {
    return state.filter((item) => item.text !== action.payload.text).push({
      id: new Date().getTime(),
      text: action.payload.text,
      details: action.payload.details,
      type: action.payload.type,
      links: action.payload.links,
      group: action.payload.group,
    });
  }

  if (action.type === types.REMOVE_FLASH_MESSAGE) {
    return state.delete(action.payload.messageIndex);
  }

  if (action.type === types.REMOVE_FLASH_MESSAGE_GROUP) {
    return state.filter((item) => item.group !== action.payload.group);
  }

  return state;
};


export default authReducerFactory;
