export const FETCH_USERS_REQUEST = 'firm/user/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'firm/user/FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'firm/user/FETCH_USERS_FAILURE';

export const RENEW_CARD_REQUEST = 'firm/user/RENEW_CARD_REQUEST';
export const RENEW_CARD_SUCCESS = 'firm/user/RENEW_CARD_SUCCESS';
export const RENEW_CARD_FAILURE = 'firm/user/RENEW_CARD_FAILURE';

export const ASSIGN_CARD_REQUEST = 'firm/user/ASSIGN_CARD_REQUEST';
export const ASSIGN_CARD_SUCCESS = 'firm/user/ASSIGN_CARD_SUCCESS';
export const ASSIGN_CARD_FAILURE = 'firm/user/ASSIGN_CARD_FAILURE';

export const CHANGE_BRANCH_REQUEST = 'firm/user/CHANGE_BRANCH_REQUEST';
export const CHANGE_BRANCH_SUCCESS = 'firm/user/CHANGE_BRANCH_SUCCESS';
export const CHANGE_BRANCH_FAILURE = 'firm/user/CHANGE_BRANCH_FAILURE';

export const CHANGE_COST_CENTER_REQUEST = 'firm/user/CHANGE_COST_CENTER_REQUEST';
export const CHANGE_COST_CENTER_SUCCESS = 'firm/user/CHANGE_COST_CENTER_SUCCESS';
export const CHANGE_COST_CENTER_FAILURE = 'firm/user/CHANGE_COST_CENTER_FAILURE';

export const CREATE_USER_REQUEST = 'firm/user/CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'firm/user/CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'firm/user/CREATE_USER_FAILURE';

export const FETCH_USER_PAIRS_REQUEST = 'firm/user/FETCH_USER_PAIRS_REQUEST';
export const FETCH_USER_PAIRS_SUCCESS = 'firm/user/FETCH_USER_PAIRS_SUCCESS';
export const FETCH_USER_PAIRS_FAILURE = 'firm/user/FETCH_USER_PAIRS_FAILURE';

export const FETCH_USER_REQUEST = 'firm/user/FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'firm/user/FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'firm/user/FETCH_USER_FAILURE';

export const FETCH_USER_BRANCHES_REQUEST = 'firm/user/FETCH_USER_BRANCHES_REQUEST';
export const FETCH_USER_BRANCHES_SUCCESS = 'firm/user/FETCH_USER_BRANCHES_SUCCESS';
export const FETCH_USER_BRANCHES_FAILURE = 'firm/user/FETCH_USER_BRANCHES_FAILURE';

export const FETCH_FIRMS_COST_CENTERS_REQUEST = 'firm/user/FETCH_FIRMS_COST_CENTERS_REQUEST';
export const FETCH_FIRMS_COST_CENTERS_SUCCESS = 'firm/user/FETCH_FIRMS_COST_CENTERS_SUCCESS';
export const FETCH_FIRMS_COST_CENTERS_FAILURE = 'firm/user/FETCH_FIRMS_COST_CENTERS_FAILURE';

export const FETCH_USER_CARDS_REQUEST = 'firm/user/FETCH_USER_CARDS_REQUEST';
export const FETCH_USER_CARDS_SUCCESS = 'firm/user/FETCH_USER_CARDS_SUCCESS';
export const FETCH_USER_CARDS_FAILURE = 'firm/user/FETCH_USER_CARDS_FAILURE';

export const FETCH_USER_ROLES_REQUEST = 'firm/user/FETCH_USER_ROLES_REQUEST';
export const FETCH_USER_ROLES_SUCCESS = 'firm/user/FETCH_USER_ROLES_SUCCESS';
export const FETCH_USER_ROLES_FAILURE = 'firm/user/FETCH_USER_ROLES_FAILURE';

export const FETCH_USER_RIGHTS_REQUEST = 'firm/user/FETCH_USER_RIGHTS_REQUEST';
export const FETCH_USER_RIGHTS_SUCCESS = 'firm/user/FETCH_USER_RIGHTS_SUCCESS';
export const FETCH_USER_RIGHTS_FAILURE = 'firm/user/FETCH_USER_RIGHTS_FAILURE';

export const FETCH_USER_RIGHTS_TEMPLATES_REQUEST = 'firm/user/FETCH_USER_RIGHTS_TEMPLATES_REQUEST';
export const FETCH_USER_RIGHTS_TEMPLATES_SUCCESS = 'firm/user/FETCH_USER_RIGHTS_TEMPLATES_SUCCESS';
export const FETCH_USER_RIGHTS_TEMPLATES_FAILURE = 'firm/user/FETCH_USER_RIGHTS_TEMPLATES_FAILURE';

export const FETCH_USER_RIGHTS_DETAIL_REQUEST = 'firm/user/FETCH_USER_RIGHTS_DETAIL_REQUEST';
export const FETCH_USER_RIGHTS_DETAIL_SUCCESS = 'firm/user/FETCH_USER_RIGHTS_DETAIL_SUCCESS';
export const FETCH_USER_RIGHTS_DETAIL_FAILURE = 'firm/user/FETCH_USER_RIGHTS_DETAIL_FAILURE';

export const UPDATE_USER_RIGHTS_DETAIL_REQUEST = 'firm/user/UPDATE_USER_RIGHTS_DETAIL_REQUEST';
export const UPDATE_USER_RIGHTS_DETAIL_SUCCESS = 'firm/user/UPDATE_USER_RIGHTS_DETAIL_SUCCESS';
export const UPDATE_USER_RIGHTS_DETAIL_FAILURE = 'firm/user/UPDATE_USER_RIGHTS_DETAIL_FAILURE';

export const FETCH_USER_TRANSACTIONS_REQUEST = 'firm/user/FETCH_USER_TRANSACTIONS_REQUEST';
export const FETCH_USER_TRANSACTIONS_SUCCESS = 'firm/user/FETCH_USER_TRANSACTIONS_SUCCESS';
export const FETCH_USER_TRANSACTIONS_FAILURE = 'firm/user/FETCH_USER_TRANSACTIONS_FAILURE';

export const UPDATE_USER_REQUEST = 'firm/user/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'firm/user/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'firm/user/UPDATE_USER_FAILURE';

export const UPDATE_FIRM_USERS_FILTER_DATA = 'firm/user/UPDATE_FIRM_USERS_FILTER_DATA';
