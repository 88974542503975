import React from 'react';
import PropTypes from 'prop-types';
import styles from './ContributionOrderWidget.scss';
import { PropTypeWidgetContribution } from '../../../global/proptypes';
import { moneyFormat3 } from '../../../../service/number';

export const BankTransferPageContent = ({ t, data }) => (
  <div className={styles.content}>
    <h3>{t('Úhrada poukazu převodem')}</h3>
    <ul>
      <li>
        {t('Účet:')}
        <span className={styles.amount}>{data.invoice.account}</span>
      </li>
      <li>
        {t('Částka:')}
        <span className={styles.amount}>{moneyFormat3(data.invoice.total, t)}</span>
      </li>
      <li>
        {t('Variabilní symbol:')}
        <span className={styles.amount}>{data.invoice.i01_proforma_number}</span>
      </li>
    </ul>
    <p className={styles.banner}>
      {t('Nyní čekáme na Vaši platbu. Jakmile nám dorazí, navýšíme zůstatek na kartě a bude možné vytvořit novou objednávku.')}
    </p>
  </div>
);

BankTransferPageContent.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypeWidgetContribution.isRequired,
};
