import { ApiActions } from '../../../../data/storeModel';

export const SAVE_SETTLEMENT_REQUEST = 'my/settlement/SAVE_SETTLEMENT_REQUEST';
export const SAVE_SETTLEMENT_SUCCESS = 'my/settlement/SAVE_SETTLEMENT_SUCCESS';
export const SAVE_SETTLEMENT_FAILURE = 'my/settlement/SAVE_SETTLEMENT_FAILURE';

export const GET_SETTLEMENT_REQUEST = 'my/settlement/GET_SETTLEMENT_REQUEST';
export const GET_SETTLEMENT_SUCCESS = 'my/settlement/GET_SETTLEMENT_SUCCESS';
export const GET_SETTLEMENT_FAILURE = 'my/settlement/GET_SETTLEMENT_FAILURE';

export type Actions =
  ApiActions<typeof SAVE_SETTLEMENT_REQUEST, typeof SAVE_SETTLEMENT_SUCCESS, typeof SAVE_SETTLEMENT_FAILURE, any> |
  ApiActions<typeof GET_SETTLEMENT_REQUEST, typeof GET_SETTLEMENT_SUCCESS, typeof GET_SETTLEMENT_FAILURE, any>;
