
let devEnv = {};

if (process.env.NODE_ENV !== 'production') {
  const envName = 'gcmock';
  // const envName = 'gustokarta';
  devEnv = {
    apiUrl: `https://portal-api.${envName}.sk/1.0/{LANG}`,
    benefiaApiUrl: `https://benefia-api.${envName}.sk/1.0/{LANG}/portal`,
    envType: 'dev',
    envName,
    instance: 'sk',
    defaultLang: 'sk',
  };
}

const envs = {
  gustokarta: {
    // googleMapKey: 'AIzaSyAwudQTXCdxRdA4A0pe5yMtCAfxuajm7NI',
    googleMapKey: 'AIzaSyDpqFq8fr10yU-PkgqWZTK_LS16NMFs8UU', // somm.cz organization
  },
  auto: {
    envType: 'production',
  },
  def: { // default env config
    googleMapKey: 'AIzaSyD-kOWBNdjRfFjHYAQwFhm1pAutnPcAp4w',
  },
};

export default function autodetectEnv() {
  if (process.env.NODE_ENV === 'test') {
    return {
      ...envs.def, apiUrl: null, envType: 'test', envName: 'gcmock', instance: 'sk', defaultLang: 'cs',
    };
  }

  if (['localhost', 'localhost.sk', '192.168.56.1'].includes(window.location.hostname) && process.env.NODE_ENV !== 'production') {
    return { ...envs.def, ...devEnv };
  }

  if (window.location.hostname === 'up-portal.fivecode.cz' || window.location.hostname === 'localhost') {
    const lang = 'sk';
    return {
      ...envs.def,
      apiUrl: 'https://portal-api.gcmock.sk/1.0/{LANG}',
      benefiaApiUrl: 'https://benefia-api.gcmock.sk/1.0/{LANG}/portal',
      envType: 'dev',
      envName: 'fivecode',
      instance: 'sk',
      defaultLang: lang,
    };
  }

  const parts = window.location.host.split('.');
  // const parts = 'portal.gustokarta.cz'.split('.');

  if (parts[0] !== 'portal') {
    throw new Error(`Invalid URL at ${window.location.host}`);
  }

  parts[0] = 'portal-api';

  const topDomain = parts[parts.length - 1];

  if (!['cz', 'sk'].includes(topDomain)) {
    // eslint-disable-next-line
    console.warn(`TLD not recognized (${topDomain}, ${window.location.host}) falling back to .cz`);
  }

  const instance = ['cz', 'sk'].includes(topDomain) ? topDomain.toLowerCase() : 'cz';
  const defaultLang = instance === 'sk' ? 'sk' : 'cs';

  const autoApiUrl = `${window.location.protocol}//${parts.join('.')}/1.0/{LANG}`;

  parts[0] = 'benefia-api';
  const autoApiUrlBenefia = `${window.location.protocol}//${parts.join('.')}/1.0/{LANG}/portal`;
  // const autoApiUrl = `https://${parts.join('.')}/1.0/cs`;

  return {
    ...envs.def,
    ...envs.auto,
    apiUrl: autoApiUrl,
    benefiaApiUrl: autoApiUrlBenefia,
    instance,
    defaultLang,
    ...envs[parts[1]] || {},
    envName: parts[1],
  };
}
