import moment from 'moment';
import { AttendanceDayRow } from '../../../global/model';
import { momentFromString } from '../../../../service/date';

export function fillAttendanceMonth(shifts: AttendanceDayRow[], year: number, month: number): AttendanceDayRow[] {
  if (!shifts || !year || !month) {
    return shifts;
  }
  const shiftsMap = shifts.reduce((acc, curr) => {
    const date = momentFromString(curr.d30_date).format('YYYY-MM-DD');
    acc[date] = curr;
    return acc;
  }, { } as { [date: string]: AttendanceDayRow });

  const currMonth: moment.Moment = momentFromString(`${year}-${month}`, 'YYYY-M');

  const monthBegin = currMonth.clone().startOf('month');
  const monthEnd = currMonth.clone().endOf('month');

  const newShifts: AttendanceDayRow[] = [];

  for (const m = monthBegin.clone(); m.isSameOrBefore(monthEnd); m.add(1, 'day')) {
    const shift = shiftsMap[m.format('YYYY-MM-DD')];

    if (shift) {
      newShifts.push(shift);
    } else {
      newShifts.push({
        d30_date: m.format(),
        d30_work_shift_id: null,
      } as any);
    }
  }

  return newShifts;
}
