import { CURRENCY } from '../modules/global/constants';
import { getLocale } from '../i18n';

// export const amountFormat = val => (Math.round(val * 100) / 100).toFixed(2);

/**
 * Two fixed fractinon digits
 */
export function amountFormat(value) {
  const nVal = Number(value);

  if (Number.isNaN(nVal)) { return value; }

  return nVal.toLocaleString(getLocale(), { maximumFractionDigits: 2, minimumFractionDigits: 2 });
}

export function fileSizeFormat(value) {
  const nVal = Number(value);
  if (Number.isNaN(nVal)) { return value; }
  const mbSize = 1000 * 1000;
  return nVal < mbSize
    ? `${(nVal / 1000).toLocaleString(getLocale(), { maximumFractionDigits: 1, minimumFractionDigits: 0 })} kB`
    : `${(nVal / mbSize).toLocaleString(getLocale(), { maximumFractionDigits: 1, minimumFractionDigits: 0 })} MB`;
}

/**
 * Show fractions digits when needed
 */
export function amountFormat3(value) {
  const nVal = Number(value);

  if (Number.isNaN(nVal)) { return value; }

  return nVal.toLocaleString(getLocale(), { maximumFractionDigits: 2, minimumFractionDigits: 0 });
}

export const percentFormat = (val) => `${(Math.round(val * 100) / 100)} %`;

export const moneyFormat = (val, t, currency) => t('currencyValue', { value: amountFormat(val), context: currency || CURRENCY });

export const moneyFormat3 = (val, t, currency) => t('currencyValue', { value: amountFormat3(val), context: currency || CURRENCY });

export const pcsFormat = (val, t) => t('{{value}}\u00a0Ks', { value: val });

export const roundAmount = (val) => Math.round(val * 100) / 100;

/**
 * No fraction digits
 */
export function amountFormat2(value) {
  const nVal = Number(value);

  if (Number.isNaN(nVal)) { return value; }

  return nVal.toLocaleString(getLocale(), { maximumFractionDigits: 0 });
}

export const moneyFormat2 = (val, t, currency) => t('currencyValue', { value: amountFormat2(val), context: currency || CURRENCY });

export const formatInputNumber = (val) => {
  const defVal = '';
  const strVal = (String(val).replace(/,/g, '.').replace(/[^0-9.]+/g, '').replace(/\.+/g, '.'));

  if (strVal.length > 1 && (strVal.endsWith('.') || strVal.endsWith('.0'))) {
    return strVal;
  }

  const numVal = +strVal;
  return strVal !== '' && typeof numVal === 'number' ? numVal : defVal;
};

export const alignNumberToInverval = (val, min, max) => {
  let value = val;

  if (min !== undefined && value < min) {
    value = min;
  }

  if (max !== undefined && value > max) {
    value = max;
  }

  return value;
};

export function normalizeCardNo(val) {
  const value = val ? val.replace(/[^0-9]+/g, '').substr(0, 8) : '';

  if (value.length === 0) {
    return '';
  }

  const parts = [value.substr(0, 4)];

  if (value.length > 4) {
    parts.push(value.substr(4));
  }

  return parts.join(' ');
}

export function numberDefault(str, def) {
  const num = +str;
  return Number.isNaN(num) || str === null ? def : num;
}

export function normalizePhone(val, prefix) {
  const value = val ? String(formatInputNumber(val)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') : '';

  if (value.length === 0) {
    return '';
  }

  if (prefix && value.length === 11) {
    return `${prefix} ${value}`;
  }

  if (value.length === 15) {
    return `+${value}`;
  }

  return value;
}

/**
 * Converts exporation from 2006 into 06/20
 * @param {*} expiration
 */
export function cardExpirationFormat(expiration) {
  if (!expiration || expiration.length !== 4) {
    return expiration;
  }

  return `${expiration.slice(2, 2)}/${expiration.slice(0, 2)} `;
}
