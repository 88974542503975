import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';
import {
  IImmutableMap, IRootState, ReducerFactory,
} from '../../../data/storeModel';

export type HomeNewsState = IImmutableMap<IRootState['home']['news']>;
type Actions = types.Actions;


export const homeNewsReducerFactory: ReducerFactory<HomeNewsState, Actions> = (initialState) => (state, action) => {
  if (typeof state === 'undefined') {
    return initialState.getIn(['home', 'news']);
  }

  if (action.type === types.FETCH_MY_NEWS_REQUEST) {
    return state
      .setIn(['newsList', 'isFetchingFailure'], false)
      .setIn(['newsList', 'isFetching'], !action.payload.isLoadingMore)
      .setIn(['newsList', 'isLoadingMore'], !!action.payload.isLoadingMore)
      .setIn(['newsList', 'fetchCount'], Immutable.fromJS(action.payload.limit))
      .setIn(['newsList', 'limit'], Immutable.fromJS(action.payload.limit))
      .setIn(['newsList', 'lastOffset'], Immutable.fromJS(action.payload.lastOffset))
      .setIn(['newsList', 'orderBy'], Immutable.fromJS(action.payload.orderBy))
      .setIn(['newsList', 'filter'], Immutable.fromJS(action.payload.filter));
  }

  if (action.type === types.FETCH_MY_NEWS_SUCCESS) {
    const groups = action.payload.map((item) => ({
      data: {
        ...item,
        id: item.z30_news_id,
      },
    }));

    const newsList = state.get('newsList');
    const fetchCount = newsList.get('fetchCount');
    const lastOffset = newsList.get('lastOffset');
    const stateTransactions = newsList.get('rows');
    const lastCount = groups.length;

    return state
      .setIn(['newsList', 'rows'], getEntitiesFromResponse(groups, stateTransactions, lastOffset, fetchCount))
      .setIn(['newsList', 'lastCount'], lastCount)
      .setIn(['newsList', 'isFetching'], false)
      .setIn(['newsList', 'isLoadingMore'], false)
      .setIn(['newsList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_MY_NEWS_FAILURE) {
    return state
      .setIn(['newsList', 'isFetching'], false)
      .setIn(['newsList', 'isFetchingFailure'], true);
  }

  if (action.type === types.UPDATE_NEWS_FILTER_DATA) {
    return state
      .setIn(['newsList', 'filter'], Immutable.fromJS(action.payload.filter))
      .setIn(['newsList', 'orderBy'], Immutable.fromJS(action.payload.orderBy));
  }

  if (action.type === types.FETCH_MY_NEWS_ITEM_REQUEST) {
    return state
      .setIn(['newsDetail', 'isFetchingFailure'], false)
      .setIn(['newsDetail', 'isFetching'], true);
  }

  if (action.type === types.FETCH_MY_NEWS_ITEM_SUCCESS) {
    return state
      .setIn(['newsDetail', 'data'], action.payload)
      .setIn(['newsDetail', 'isFetching'], false)
      .setIn(['newsDetail', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_MY_NEWS_ITEM_FAILURE) {
    return state
      .setIn(['newsDetail', 'isFetching'], false)
      .setIn(['newsDetail', 'isFetchingFailure'], true);
  }

  return state;
};
