import { t } from '../i18n';

export const getPosKindsReslist = () => [
  { label: t('Platební terminál na místě', { ns: 'my' }), value: 1 },
  { label: t('Platební brána na webu obchodníka', { ns: 'my' }), value: 2 },
  { label: t('Gusto platba přes portál', { ns: 'my' }), value: 3 },
];

export const getPosKindsDesc = () => ({
  1: t('U obchodníka můžete zaplatit na jeho platebním terminálu', { ns: 'my' }),
  2: t('Nakupte na webu obchodníka a zaplaťte přes platební bránu vaší kartou\u00a0Up', { ns: 'my' }),
  3: t('Domluvte se s obchodníkem na platbě kartou\u00a0Up, vyžádá si po vás číslo vaší karty, následně budete vyzváni k odsouhlasení platby', { ns: 'my' }),
});

export function getActivePairs() {
  return [
    { label: t('Vše'), value: '' },
    { label: t('Aktivní'), value: 1 },
    { label: t('Neaktivní'), value: 0 },
  ];
}


export const allProductCodes = ['GK', 'GF', 'RP', 'GD', 'BN', 'BS', 'BZ', 'BK', 'BV', 'BW', 'BR', 'BP'];

// bank_card_assigned - Třístavový příznak; 0 = ne, 1 = čekající na dokončení, 2 = potvrzeno OK
export const BANK_CARD_NOT_ASSIGNED = 0;
export const BANK_CARD_ASSIGN_PENDING = 1;
export const BANK_CARD_ASSIGNED_OK = 2;

// Pohlaví (0 = nezjištěno, 1 = muž, 2 = žena)
export const getSexPairs = () => [
  { label: t('Nezjištěno', { ns: 'firm' }), value: 0 },
  { label: t('Muž', { ns: 'firm' }), value: 1 },
  { label: t('Žena', { ns: 'firm' }), value: 2 },
];

export const getGroupTypePairs = () => [
  { label: t('Vyjmenovaná', { ns: 'firm' }), value: 1 },
  { label: t('Dynamická', { ns: 'firm' }), value: 2 },
];


// Oslovení (1 = pan, 2 = paní, 3 = slečna)
export const getSalutationPairs = () => [
  { label: t('Neuvedeno', { ns: 'firm' }), value: '' },
  { label: t('Pan', { ns: 'firm' }), value: 1 },
  { label: t('Paní', { ns: 'firm' }), value: 2 },
  { label: t('Slečna', { ns: 'firm' }), value: 3 },
];

export const freeCardsSupported = false;
export const bankAccountsSupported = false;
