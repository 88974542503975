import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../component1/Card';
import Fieldset from '../../../../component1/Form/Fieldset';
import TextField from '../../../../component1/TextField/TextField';
import * as validation from '../../../../service/validation';
import FlatButton from '../../../../component1/FlatButton/FlatButton';
import { dateTimeApiToGUI } from '../../../../service/date';
import { moneyFormat } from '../../../../service/number';
import styles from './MyPayGateConfirmWidget.scss';


const getInitialFormElements = (confirmed) => ({
  isValid: false,
  elements: {
    password: {
      errorText: '',
      rules: confirmed ? [validation.required] : [],
    },
  },
});

export class MyPayGateConfirmWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      form: getInitialFormElements(),
    };
  }

  validateForm(elements) {
    const updatedElements = validation.findErrors(this.state, elements);

    if (updatedElements) {
      this.setState({
        form: {
          elements: updatedElements,
          isValid: false,
        },
      });
      return false;
    }

    this.setState({
      form: {
        elements,
        isValid: true,
      },
    });

    return true;
  }

  handleSubmit(confirmed) {
    if (!this.validateForm(getInitialFormElements(confirmed).elements)) {
      return;
    }

    this.props.widgetMyPayGateConfirm({
      n55_gate_transaction_id: this.props.payment.n55_gate_transaction_id,
      confirmed: confirmed ? 1 : 0,
      password: this.state.password,
    }).then(() => {
      this.props.updateWidget();
    });
  }

  render() {
    const { t, payment } = this.props;

    return (
      <Card
        layout="fluid"
        title={t('Požadavek na platbu')}
        actions={[
          <FlatButton
            key={1}
            label={t('Zamítnout')}
            onClick={() => this.handleSubmit(false)}
          />,
          <FlatButton
            key={2}
            label={t('Potvrdit')}
            onClick={() => this.handleSubmit(true)}
          />,
        ]}
      >
        <Fieldset>
          <p className={styles.details}>
            {t('Přijali jsme požadavek na platbu od:')}
            <br />
            <strong>{payment.n50_name}</strong>
            <br />
            {t('Objednávka:')}
            {' '}
            <strong>{payment.n55_ext_id}</strong>
            <br />
            {t('Popis:')}
            {' '}
            <strong>{payment.n55_description}</strong>
            <br />
            {t('Datum objednávky:')}
            {' '}
            <strong>{dateTimeApiToGUI(payment.n55_date_created)}</strong>
            <br />
          </p>
          <p className={styles.amount} style={{ textAlign: 'center', marginBottom: 0 }}>
            {t('K platbě celkem:')}
            <br />
            <strong>{moneyFormat(payment.n55_value, t)}</strong>
          </p>
          <TextField
            fieldId={`password-${payment.n55_gate_transaction_id}`}
            label={t('Heslo do portálu UpGusto')}
            onChange={(e, value) => this.setState({ password: value })}
            value={this.state.password}
            type="password"
            errorText={this.state.form.elements.password.errorText}
            autoComplete
            isThin
          />
        </Fieldset>
      </Card>
    );
  }
}

MyPayGateConfirmWidget.propTypes = {
  t: PropTypes.func.isRequired,
  widgetMyPayGateConfirm: PropTypes.func.isRequired,
  updateWidget: PropTypes.func.isRequired,
  payment: PropTypes.shape({
    n50_name: PropTypes.string.isRequired,
    n55_date_created: PropTypes.string.isRequired,
    n55_description: PropTypes.string.isRequired,
    n55_ext_id: PropTypes.string.isRequired,
    n55_gate_transaction_id: PropTypes.number.isRequired,
    n55_value: PropTypes.number.isRequired,
  }).isRequired,
};
