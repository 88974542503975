import { ActionWithPayload, ApiActions } from '../../../data/storeModel';

export const FETCH_ORDERS_REQUEST = 'firm/benefia-orders/FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'firm/benefia-orders/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'firm/benefia-orders/FETCH_ORDERS_FAILURE';

export const DOWNLOAD_ORDERS_REPORT_REQUEST = 'firm/benefia-orders/DOWNLOAD_ORDERS_REPORT_REQUEST';
export const DOWNLOAD_ORDERS_REPORT_SUCCESS = 'firm/benefia-orders/DOWNLOAD_ORDERS_REPORT_SUCCESS';
export const DOWNLOAD_ORDERS_REPORT_FAILURE = 'firm/benefia-orders/DOWNLOAD_ORDERS_REPORT_FAILURE';


export const UPDATE_ORDERS_FILTER_DATA = 'firm/benefia-orders/UPDATE_ORDERS_FILTER_DATA';

export type Actions =
  ActionWithPayload<typeof UPDATE_ORDERS_FILTER_DATA> |
  ApiActions<typeof FETCH_ORDERS_REQUEST, typeof FETCH_ORDERS_SUCCESS, typeof FETCH_ORDERS_FAILURE, any[], any>;
