import { RSAA } from 'redux-api-middleware';
import { API_URL } from '../../../config/envspecific';
import * as types from './actionTypes';


export const fetchPayments = (queryString, payload, callback) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/partners_gate_payments?${queryString}`,
    method: 'GET',
    types: [
      { type: types.FETCH_GATE_PAYMENTS_REQUEST, payload },
      types.FETCH_GATE_PAYMENTS_SUCCESS,
      types.FETCH_GATE_PAYMENTS_FAILURE,
    ],
  },
}).then(() => callback());

export const updatePaymentsFilterData = (filterData, orderColumnData) => ({
  type: types.UPDATE_GATE_PAYMENTS_FILTER_DATA,
  payload: {
    filter: filterData,
    orderBy: orderColumnData,
  },
});
