import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RaisedButton from '../../../../component1/RaisedButton/RaisedButton';
import styles from './ContributionOrderWidget.scss';
import { PropTypeWidgetContribution } from '../../../global/proptypes';
import { moneyFormat3, formatInputNumber, roundAmount } from '../../../../service/number';
import TextField from '../../../../component1/TextField/TextField';
import * as validation from '../../../../service/validation';
import CheckboxField from '../../../../component1/CheckboxField/CheckboxField';

export const OrderPageContent = (props) => {
  const {
    t, onOrderAmount, data, setOrderAmount, orderAmount, emissionTransfer, setEmissionTransfer,
  } = props;
  const [orderedAmountError, setOrderedAmountError] = useState(null);
  const orderAmountNumber = formatInputNumber(orderAmount);
  const useOldEmission = data.old_emission_amount !== null && emissionTransfer;

  const participations = data.participations.map((part) => {
    const amount1 = (Math.min(orderAmountNumber || 0, data.m30_rest_amount) * part.m17_ratio) / 100;
    const amount = useOldEmission && part.m16_particip_role === 'EMPL'
      ? Math.max(amount1 - data.old_emission_amount, 0)
      : amount1;

    return {
      label: part.m17_label,
      amount,
    };
  });

  return (
    <div className={styles.content}>
      <table>
        <tbody>
          <tr>
            <td>
              {t('Letos mohu požádat ještě o:')}
            </td>
            <td className={styles.amount}>{moneyFormat3(data.m30_rest_amount, t)}</td>
          </tr>
          <tr>
            <td colSpan="2">
              <TextField
                label={t('Žádám:')}
                fieldId="m20_ordered_amount"
                formatValue="number"
                onChange={(e, _value) => {
                  const value = formatInputNumber(_value);
                  setOrderAmount(typeof value === 'number' ? roundAmount(value) : value);
                }}
                value={orderAmount || ''}
                maxLength={8}
                errorText={orderedAmountError}
              />
            </td>
          </tr>
          {data.old_emission_amount !== null && (
            <tr>
              <td>{t('Úhrada z loňské emise:')}</td>
              <td className={styles.amount}>{moneyFormat3(data.old_emission_amount, t)}</td>
            </tr>
          )}
          {participations.map(({ amount, label }, idx) => (
            <tr key={idx}>
              <td>
                <span
                    // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: label }}
                />
                  :
              </td>
              <td className={styles.amount}>{moneyFormat3(amount, t)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {data.old_emission_amount !== null && (
      <CheckboxField
        checked={emissionTransfer}
        fieldId="emission_transfer"
        label={t('Použít na úhradu loňský zůstatek')}
        onClick={() => setEmissionTransfer(!emissionTransfer)}
      />
      )}
      <RaisedButton
        label={t('Dobít kartu sumou')}
        onClick={() => {
          const emplRatio = data.participations.find((p) => p.m16_particip_role === 'EMPL').m17_ratio;
          const min = useOldEmission ? roundAmount((data.old_emission_amount * 100) / emplRatio) : 1;
          const rangeFrom = !useOldEmission && data.m30_rest_amount < min ? data.m30_rest_amount : min;
          const error = validation.required(orderAmount) || validation.amountBetween(rangeFrom, data.m30_rest_amount)(orderAmount);

          setOrderedAmountError(error);
          if (!error) {
            onOrderAmount();
          }
        }}
        priority="primary"
      />
    </div>
  );
};

OrderPageContent.propTypes = {
  t: PropTypes.func.isRequired,
  onOrderAmount: PropTypes.func.isRequired,
  setOrderAmount: PropTypes.func.isRequired,
  emissionTransfer: PropTypes.bool,
  setEmissionTransfer: PropTypes.func.isRequired,
  orderAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypeWidgetContribution.isRequired,
};
