import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Card } from '../../../../component1/Card';
import FlatButton from '../../../../component1/FlatButton/FlatButton';
import RaisedButton from '../../../../component1/RaisedButton/RaisedButton';
import CheckboxField from '../../../../component1/CheckboxField/CheckboxField';
import Dialog from '../../../../component1/Dialog/Dialog';
import styles from './ContributionTermsWidget.scss';
import { PropTypeWidgetContribution } from '../../../global/proptypes';
import { widgetMinHeight } from '../../../global/constants';

export class ContributionTermsWidget extends React.Component {
  state = {
    showConfirmation: false,
    termsConfirmed: false,
    showTerms: null,
  };

  handleCancel = () => {
    this.setState({ showConfirmation: false, termsConfirmed: false });
  }

  handleOpenTermsModal = () => {
    this.setState({ showTerms: 1 });
  }

  handleCloseTermsModal = () => {
    this.setState({ showTerms: null });
  }

  handleOpenTerms2Modal = () => {
    this.setState({ showTerms: 2 });
  }

  render() {
    const { t, data } = this.props;
    const { showConfirmation } = this.state;

    const actions = showConfirmation ? [
      <FlatButton key={2} label={t('Nesouhlasím')} onClick={this.handleCancel} />,
      <FlatButton key={1} label={t('Souhlasím')} onClick={() => this.props.onConfirm(data.m01_contribution_id, data.c30_company_user_id)} disabled={!this.state.termsConfirmed} primary />,
    ] : null;
    const imgSrc = '/images/logo-RP-dark.svg';

    return (
      <Card
        layout="fluid"
        title={data.m10_title}
        actions={actions}
        minHeight={widgetMinHeight}
        bodyClassName={styles.cardBody}
      >
        <img className={styles.img} src={imgSrc} alt={data.m10_title} />
        {!showConfirmation && (
        <div className={styles.contentFlex}>
          {data.labels.map((txt, idx) => (
            <p
              key={idx}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: txt }}
            />
          ))}
          <RaisedButton label={t('Požádat zaměstnavatele')} onClick={() => this.setState({ showConfirmation: true })} priority="primary" />
        </div>
        )}
        {showConfirmation && (
        <div className={styles.content}>
          <h3>{t('Odsouhlasení podmínek')}</h3>
          {/* <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: data.terms_text }}
          > */}
          <p>
            <Trans i18nKey={data.terms_code === 'SLSP' ? 'confirmGeneralConditionToOrderContributionSLSP' : 'confirmGeneralConditionToOrderContribution'} t={t}>
              Žiadam o poskytnutie príspevku na rekreáciu v zmysle §152a Zákonníka práce prostredníctvom rekreačného poukazu a beriem na vedomie obmedzenia plynúce z
              {' '}
              <a onClick={this.handleOpenTermsModal} role="presentation">Prehlásenia zamestnanca o použití rekreačného poukazu</a>
              {' a '}
              <a onClick={this.handleOpenTerms2Modal} role="presentation">súhlas so zrážkou zo mzdy</a>
              .
            </Trans>
          </p>
          <CheckboxField
            fieldId="confirm_terms"
            label={data.terms_button_label}
            checked={this.state.termsConfirmed}
            onClick={() => this.setState({ termsConfirmed: !this.state.termsConfirmed })}
          />
          {this.state.showTerms && (
          <Dialog
            onHandleClose={this.handleCloseTermsModal}
            title={this.state.showTerms === 2 ? t('Súhlas so zrážkou zo mzdy') : t('Prehlásenie zamestnanca o použití rekreačného poukazu')}
            actions={[{
              label: t('Zavřít'),
              onClick: () => ({}),
            }]}
            className={styles.termsModal}
            hasCross
            forceOpen
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: this.state.showTerms === 2 ? t('termsEmployeeStatementRP2') : t('termsEmployeeStatementRP') }}
            />
          </Dialog>
          )}
        </div>
        )}
      </Card>
    );
  }
}

ContributionTermsWidget.propTypes = {
  t: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  data: PropTypeWidgetContribution.isRequired,
};
