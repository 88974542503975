import { combineReducers } from 'redux-immutable';
import * as types from './actionTypes';
import { reducer as partnerAffiliateReducer } from './affiliate';
import { reducer as partnerContractReducer } from './contract';
import { reducer as partnerInvoiceReducer } from './invoice';
import { reducer as partnerTransactionReducer } from './transaction';
import { reducer as partnerGatePaymentReducer } from './gatePayment';
import { reducer as partnerUserReducer } from './user';
import { reducer as partnerPartnerReducer } from './partner';


const reducerFactory = (initialState) => (
  combineReducers({
    partner: partnerPartnerReducer(initialState),
    affiliate: partnerAffiliateReducer(initialState),
    contract: partnerContractReducer(initialState),
    invoice: partnerInvoiceReducer(initialState),
    transaction: partnerTransactionReducer(initialState),
    gatePayment: partnerGatePaymentReducer(initialState),
    user: partnerUserReducer(initialState),
    activeContractId: (state, action) => {
      const { type, payload } = action;

      if (typeof state === 'undefined') {
        return initialState.getIn(['partner', 'activeContractId']);
      }

      if (type === types.UPDATE_ACTIVE_CONTRACT_ID) {
        return payload.contractId;
      }

      return state;
    },
    activePartnerId: (state, action) => {
      const { type, payload } = action;

      if (typeof state === 'undefined') {
        return initialState.getIn(['partner', 'activePartnerId']);
      }

      if (type === types.UPDATE_ACTIVE_PARTNER_ID) {
        return payload.partnerId;
      }

      return state;
    },
  })
);

export default reducerFactory;
