import moment from 'moment-timezone';

// locales
import 'moment/locale/sk';
import 'moment/locale/cs';
import { capitalizeFirstLetter } from './string';

const TIMEZONE = 'Europe/Prague';

moment.tz.setDefault(TIMEZONE);

export const initMomentLocale = (locale) => {
  moment.locale(locale);
  // console.log('initMomentLocale', locale, moment.locale(), moment.locales());
};

export const dateFromString = (date, fmt) => {
  if (!date) {
    return null;
  }
  const momentDate = moment.tz(date, 'YYYY-MM-DD', TIMEZONE);

  if (momentDate.isValid()) {
    return momentDate.format(fmt || 'DD. MM. YYYY');
  }

  return 'invalid date';
};

export const dateTimeFromString = (date, fmt) => {
  if (date === null || date === undefined) {
    return null;
  }

  const momentDate = moment.tz(date, moment.ISO_8601, TIMEZONE);

  if (momentDate.isValid()) {
    return momentDate.format(fmt || 'DD. MM. YYYY HH:mm');
  }

  return 'invalid date';
};

export const monthFromString = (date, inputFmt) => {
  const momentDate = moment.tz(date, inputFmt || 'YYYY-MM-DD', TIMEZONE);

  if (momentDate.isValid()) {
    return `${capitalizeFirstLetter(momentDate.format('MMMM'))} ${momentDate.format('YY')}`;
  }

  return 'invalid date';
};

export const momentFromString = (strVal, fmt) => moment.tz(strVal, fmt || moment.ISO_8601, TIMEZONE);

export const dateTimeApiToGUI = (apiVal) => dateTimeFromString(apiVal);
export const dateApiToGUI = (apiVal) => dateFromString(apiVal);
export const dateApiToDate = (apiVal) => (apiVal ? momentFromString(apiVal).toDate() : null);
export const dateGUItoApi = (dateVal) => (dateVal ? moment(dateVal).format('YYYY-MM-DD') : null);
export const dateTimeToApi = (dateVal) => (dateVal ? moment(dateVal).format('YYYY-MM-DD HH:mm:ss') : null);

export const toMoment = (value) => {
  const momentDate = moment.tz(new Date(value), TIMEZONE);

  if (momentDate.isValid()) {
    return momentDate;
  }

  return 'invalid date';
};

export const toMomentDateOnly = (value) => {
  const momentDate = moment.tz(new Date(value), TIMEZONE);

  if (momentDate.isValid()) {
    momentDate.startOf('day');
    return momentDate;
  }

  return 'invalid date';
};

export const momentNow = () => moment.tz(TIMEZONE);

export const minutesToHours = (total) => {
  if (total === null || total === undefined) {
    return null;
  }
  const hours = Math.floor(total / 60);
  const mins = (total - (hours * 60));
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};

export const getMonthsPairs = () => moment.months().map((mon, idx) => ({ label: capitalizeFirstLetter(mon), value: idx + 1 }));
export const getYearsPairs = () => Array.apply(0, Array(3)).map((_, i) => moment().subtract(i, 'year').year()).map((year) => ({ label: `${year}`, value: year }));
