import { createSelector } from 'reselect';

const getOrderList = (state) => state.getIn(['my', 'order', 'orderList']);
const getOrder = (state) => state.getIn(['my', 'order', 'order']);
const getOnlineConfirm = (state) => state.getIn(['my', 'order', 'onlineConfirm']);
const getIsInProgress = (state) => state.getIn(['my', 'order', 'isInProgress']);
const getProducts = (state) => state.getIn(['my', 'order', 'products']);

export const selectOrderListMeta = createSelector(
  [getOrderList],
  (orderList) => ({
    filter: orderList.get('filter').toJS(),
    orderBy: orderList.get('orderBy').toJS(),
    limit: orderList.get('limit'),
    lastOffset: orderList.get('lastOffset'),
    lastCount: orderList.get('lastCount'),
    fetchCount: orderList.get('fetchCount'),
    isFetching: orderList.get('isFetching'),
    isLoadingMore: orderList.get('isLoadingMore'),
    offset: orderList.get('offset'),
  })
);

export const selectOrders = createSelector(
  [getOrderList],
  (orderList) => orderList.get('orders').toJS()
);

export const selectOrder = createSelector(
  [getOrder],
  (order) => order
);

export const selectOnlineConfirm = createSelector(
  [getOnlineConfirm],
  (onlineConfirm) => onlineConfirm
);

export const selectIsInProgress = createSelector(
  [getIsInProgress],
  (isInProgress) => isInProgress
);

export const selectProducts = createSelector(
  [getProducts],
  (products) => products.get('data').toJS()
);

export const selectProductMap = createSelector(
  [getProducts],
  (products) => {
    const data = products.get('data').toJS();
    if (!data || data.length === 0) {
      return null;
    }

    return data.reduce((acc, curr) => {
      // eslint-disable-next-line
      acc[curr.s20_code] = curr;
      return acc;
    }, {});
  }
);
