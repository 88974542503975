import React, { lazy } from 'react';
import { SignUpCenteredLayout } from '../layout/main1';

const RegistrationContainer = lazy(() => import(/* webpackChunkName: "registration" */ './firm/RegistrationContainer'));
const FirmRegistrationContainer = lazy(() => import(/* webpackChunkName: "registration" */ './firm/FirmRegistrationContainer'));
const FirmRegistrationActivationContainer = lazy(() => import(/* webpackChunkName: "registration" */ './firm/FirmRegistrationActivationContainer'));
const FirmRegistrationSetPasswordContainer = lazy(() => import(/* webpackChunkName: "registration" */ './firm/FirmRegistrationSetPasswordContainer'));
const RegistrationSetPasswordContainer = lazy(() => import(/* webpackChunkName: "registration" */ './RegistrationSetPasswordContainer'));
const MyRegistrationContainer = lazy(() => import(/* webpackChunkName: "registration" */ './my/MyRegistrationContainer'));
const MyActivationContainer = lazy(() => import(/* webpackChunkName: "registration" */ './my/MyActivationContainer'));
const PartnerRegistrationSetPasswordContainer = lazy(() => import(/* webpackChunkName: "registration" */ './PartnerRegistrationSetPasswordContainer'));

export default {
  name: 'registration',
  path: 'registrace(-:brand)',
  component: (props) => <SignUpCenteredLayout {...props} />,
  indexRoute: { component: RegistrationContainer },
  requireLogout: true,
  childRoutes: [
    // Firm registration
    {
      name: 'firm_registration',
      path: 'firma',
      indexRoute: { component: FirmRegistrationContainer },
      childRoutes: [
        {
          name: 'firm_registration_activation',
          path: 'aktivace',
          component: FirmRegistrationActivationContainer,
          requireLogout: true,
        },
        {
          name: 'firm_registration_set_password',
          path: 'nastaveni-hesla',
          component: FirmRegistrationSetPasswordContainer,
        },
      ],
    },

    // B2C registration
    {
      name: 'my_registration',
      path: 'moje',
      indexRoute: { component: MyRegistrationContainer },
      childRoutes: [
        {
          name: 'my_registration_activation',
          path: 'aktivace',
          component: MyActivationContainer,
          requireLogout: true,
        },
        // not used
        // {
        //   name: 'my_registration_set_password',
        //   path: 'nastaveni-hesla',
        //   component: MyRegistrationSetPasswordContainer,
        // },
      ],
    },
    {
      name: 'registration_set_password',
      path: 'nastaveni-hesla',
      component: RegistrationSetPasswordContainer,
    },

    // B2B Partner activation
    {
      name: 'partner_registration',
      path: 'provozovny',
      // indexRoute: { component: },
      childRoutes: [
        {
          name: 'partner_registration_set_password',
          path: 'nastaveni-hesla',
          component: PartnerRegistrationSetPasswordContainer,
        },
      ],
    },
  ],
};
