import { lazy } from 'react';

const OrderBaseContainer = lazy(() => import(/* webpackChunkName: "firm" */ './containers/OrderBaseContainer'));
const OrderPageContractSelection = lazy(() => import(/* webpackChunkName: "firm" */ './pages/contractSelection/OrderPageContractSelectionContainer'));
const OrderPageCompletion = lazy(() => import(/* webpackChunkName: "firm" */ './pages/completion/OrderPageCompletionContainer'));
const OrderPageOrderReceived = lazy(() => import(/* webpackChunkName: "firm" */ './pages/completion/OrderPageOrderReceivedContainer'));
const OrderPageBankPayment = lazy(() => import(/* webpackChunkName: "firm" */ './pages/completion/OrderPageBankPaymentContainer'));
const OrderPageInitialization = lazy(() => import(/* webpackChunkName: "firm" */ './pages/initialization/OrderPageInitializationContainer'));
const OrderConfirmValidation = lazy(() => import(/* webpackChunkName: "firm" */ './pages/validation/confirmValidationContainer'));
const OrderPageInitializationErrors = lazy(() => import(/* webpackChunkName: "firm" */ './pages/initialization/OrderPageInitializationErrorsContainer'));
const OrderNameValidation = lazy(() => import(/* webpackChunkName: "firm" */ './pages/validation/nameValidationContainer'));
const OrderAddressValidation = lazy(() => import(/* webpackChunkName: "firm" */ './pages/validation/addressValidationContainer'));
const OrderPageAdjustmentOfValues = lazy(() => import(/* webpackChunkName: "firm" */ './pages/adjustmentOfValues/OrderPageAdjustmentOfValuesContainer'));
const OrderPageFinalInspection = lazy(() => import(/* webpackChunkName: "firm" */ './pages/finalInspection/OrderPageFinalInspectionContainer'));


export default {
  path: 'objednavka',
  childRoutes: [
    {
      name: 'firm_order',
      key: 'C3',
      indexRoute: { component: OrderBaseContainer },
    },
    {
      name: 'firm_order_contract_selection',
      path: 'vyber-firmy',
      key: 'C20',
      component: OrderPageContractSelection,
    },
    {
      name: 'firm_order_received',
      path: 'prijata',
      key: 'C31',
      component: OrderPageOrderReceived,
    },
    {
      name: 'firm_order_completion',
      path: 'dokoncena',
      key: 'C28',
      component: OrderPageCompletion,
    },
    {
      name: 'firm_order_payment',
      path: 'platba',
      key: 'C29',
      component: OrderPageBankPayment,
    },
    {
      component: OrderBaseContainer,
      childRoutes: [
        {
          name: 'firm_order_initialization',
          path: 'import',
          key: 'C21',
          indexRoute: { component: OrderPageInitialization },
          childRoutes: [
            {
              name: 'firm_order_initialization_errors',
              path: 'chyby',
              key: 'C22',
              component: OrderPageInitializationErrors,
            },
          ],
        },
        {
          path: 'kontrola-importu',
          childRoutes: [
            {
              name: 'firm_order_address_validation',
              path: 'adresy',
              key: 'C24',
              component: OrderAddressValidation,
            },
            {
              name: 'firm_order_name_validation',
              path: 'jmena-na-karte',
              key: 'C23',
              component: OrderNameValidation,
            },
            {
              name: 'firm_order_confirm_validation',
              path: 'potvrzeni-zmen',
              key: 'C25',
              component: OrderConfirmValidation,
            },
          ],
        },
        {
          name: 'firm_order_adjustment',
          path: 'upravy',
          key: 'C26',
          component: OrderPageAdjustmentOfValues,
        },
        {
          name: 'firm_order_final_inspection',
          path: 'rekapitulace',
          key: 'C27',
          component: OrderPageFinalInspection,
        },
      ],
    },
  ],
};
