import { browserHistory } from 'react-router';
import generatePath from './generatePath';


export default (routingTable, name, parameters, authorizedPageKeys) => {
  const generatedPath = generatePath(routingTable, name, parameters, authorizedPageKeys);

  if (generatedPath) {
    browserHistory.push(generatedPath);
    return;
  }

  throw new Error('Route does not match to any of defined routes.');
};
