import { ApiMyUser } from '../../model';
import { ApiActions } from '../../../../data/storeModel';

export const FETCH_USER_REQUEST = 'my/user/FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'my/user/FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'my/user/FETCH_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'my/user/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'my/user/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'my/user/UPDATE_USER_FAILURE';

export const FETCH_USER_PAIRS_REQUEST = 'my/user/FETCH_USER_PAIRS_REQUEST';
export const FETCH_USER_PAIRS_SUCCESS = 'my/user/FETCH_USER_PAIRS_SUCCESS';
export const FETCH_USER_PAIRS_FAILURE = 'my/user/FETCH_USER_PAIRS_FAILURE';

export type Actions =
  ApiActions<typeof FETCH_USER_REQUEST, typeof FETCH_USER_SUCCESS, typeof FETCH_USER_FAILURE, ApiMyUser>
  | ApiActions<typeof UPDATE_USER_REQUEST, typeof UPDATE_USER_SUCCESS, typeof UPDATE_USER_FAILURE>
  | ApiActions<typeof FETCH_USER_PAIRS_REQUEST, typeof FETCH_USER_PAIRS_SUCCESS, typeof FETCH_USER_PAIRS_FAILURE>;
