import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['partner', 'invoice']);
  }

  if (type === types.FETCH_INVOICES_REQUEST) {
    return state
      .setIn(['invoiceList', 'isFetchingFailure'], false)
      .setIn(['invoiceList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['invoiceList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['invoiceList', 'fetchCount'], Immutable.fromJS(payload.limit))
      .setIn(['invoiceList', 'limit'], Immutable.fromJS(payload.limit))
      .setIn(['invoiceList', 'lastOffset'], Immutable.fromJS(payload.lastOffset))
      .setIn(['invoiceList', 'orderBy'], Immutable.fromJS(payload.orderBy))
      .setIn(['invoiceList', 'filter'], Immutable.fromJS(payload.filter));
  }

  if (type === types.FETCH_INVOICES_SUCCESS) {
    const invoices = payload.map((item) => ({
      data: Object.assign(item, { id: item.i01_invoice_id }),
      actions: { download: true },
    }));
    const invoiceList = state.get('invoiceList');
    const fetchCount = invoiceList.get('fetchCount');
    const lastOffset = invoiceList.get('lastOffset');
    const stateInvoices = invoiceList.get('invoices');
    const lastCount = invoices.length;

    return state
      .setIn(['invoiceList', 'invoices'], getEntitiesFromResponse(invoices, stateInvoices, lastOffset, fetchCount))
      .setIn(['invoiceList', 'lastCount'], lastCount)
      .setIn(['invoiceList', 'isFetching'], false)
      .setIn(['invoiceList', 'isLoadingMore'], false)
      .setIn(['invoiceList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_INVOICES_FAILURE) {
    return state
      .setIn(['invoiceList', 'isFetching'], false)
      .setIn(['invoiceList', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_INVOICES_FILTER_DATA) {
    return state
      .setIn(['invoiceList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['invoiceList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  return state;
};


export default reducerFactory;
