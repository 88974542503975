import { apiMiddleware } from 'redux-api-middleware';
import { browserHistory } from 'react-router';
// import createLogger from 'redux-logger';
// import { ENVIRONMENT } from '../config/envspecific';
import thunkMiddleware from 'redux-thunk';
import {
  applyMiddleware, createStore, compose, Middleware,
} from 'redux';
import apiUserTokenInjectorFactory from './middleware/apiUserTokenInjector';
import rootReducer from './rootReducer';
import { t, getLang } from './i18n/init';
import sessionTimeout from './middleware/sessionTimeout';
import apiAuthorization from './middleware/apiAuthorization';
import apiError500HandlerFactory from './middleware/apiError500Handler';
import apiError502Handler from './middleware/apiError502Handler';
import { apiRequestErrorHandler } from './middleware/apiRequestErrorHandler';
import { apiRSAAInjectorFactory } from './middleware/apiRSAAInjectorFactory';

const middlewares: Middleware[] = [thunkMiddleware];

// if (ENVIRONMENT === 'dev') {
//   middlewares.push(createLogger({
//     stateTransformer: state => state.toJS(),
//     diff: true,
//   }));
// }

middlewares.push(apiRSAAInjectorFactory(getLang));
middlewares.push(apiUserTokenInjectorFactory(window.localStorage));
middlewares.push(apiMiddleware);
middlewares.push(sessionTimeout(window.localStorage, window.location, browserHistory));
middlewares.push(apiAuthorization(window.localStorage, browserHistory, t));
middlewares.push(apiError502Handler);
middlewares.push(apiError500HandlerFactory(t));
middlewares.push(apiRequestErrorHandler);

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

export type StoreRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
