import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import colors from './colors1';

const defaultTheme = createMuiTheme();

export const styles: ThemeOptions = {
  typography: {
    fontFamily: 'Palanquin, Helvetica, Roboto, Arial, sans-serif',
  },
  palette: {
    primary: { main: colors.brandMain },
    // action: { active: colors.black },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 9999,
      },
    },
    MuiInputLabel: {
      root: {
        color: colors.text,
        '&.Mui-focused': {
          color: colors.text,
        },
      },
    },
    MuiTextField: {
      root: {
        width: '100%', // due to timepicker
      },
    },
    MuiInput: {
      input: {
        '&::placeholder': {
          color: colors.pLaceholder,
          opacity: 1,
        },
      },
    },
    MuiIcon: {
      root: {
        fontSize: 24,
      },
    },
    MuiRadio: {
      root: {
        padding: '0 3px',
      },
    },
    MuiTabs: {
      root: {
        background: colors.white,
      },
      indicator: {
        background: colors.brandMain,
      },
    },
    MuiTab: {
      root: {
        [defaultTheme.breakpoints.up('sm')]: {
          fontSize: 14,
          minWidth: 160,
        },
        fontWeight: 500,
        '&$selected': {
          color: colors.brandMain,
        },
      },
    },
    MuiDialog: {
      scrollBody: {
        '&:after': {
          height: 0,
        },
      },
    },
    MuiDialogContent: {
      root: {
        fontSize: 16,
        padding: '20px 16px',
        overflowX: 'hidden', // fix for iphone. when only overflow-y is set (and x is default or scroll), dialog content cant be dragged to scroll with dialog in vertical direction
      },
    },
    MuiDialogActions: {
      root: {
        padding: 16,
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
      },
    },
    MuiPickersModal: {
      withAdditionalAction: {
        justifyContent: 'space-between',
        '& > *:first-child': {
          marginRight: 0,
        },
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: colors.white,
      },
      toolbarBtnSelected: {
        color: colors.white,
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: colors.white,
      },
    },
  },
};

// export const themeV0 = {
//   fontFamily: 'Palanquin, Helvetica, Roboto, Arial, sans-serif',
//   palette: {
//     primary1Color: colors.brandBlue,
//     primary2Color: colors.brandBlue,
//     accent1Color: colors.brandGreen,
//     textColor: colors.black,
//   },
//   // backdropColor: colors.beige2Transparent,
//   inkBar: {
//     backgroundColor: colors.brandRed,
//   },
//   tabs: {
//     backgroundColor: colors.white,
//     textColor: colors.black,
//     selectedTextColor: colors.brandRed,
//   },
//   menuItem: {
//     hoverColor: colors.gray1,
//   },
//   zIndexList: {
//     main: 1,
//     header: 2,
//     flashMessage: 6,
//   },
// };
