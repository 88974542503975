import Immutable from 'immutable';
import * as types from './actionTypes';

export const paymentGateReducerFactory = (initialState) => (state, action) => {
  if (typeof state === 'undefined') {
    return initialState.get('paymentGate');
  }

  if (action.type === types.FETCH_TRANSACTION_REQUEST) {
    return state
      .set('isFetching', true)
      .set('isFetchingFailure', false);
  }

  if (action.type === types.FETCH_TRANSACTION_SUCCESS) {
    return state
      .set('result', null)
      .set('transaction', Immutable.fromJS(action.payload))
      .set('isFetching', false)
      .set('isFetchingFailure', false);
  }

  if (action.type === types.FETCH_TRANSACTION_FAILURE) {
    return state
      .set('result', null)
      .set('isFetching', false)
      .set('isFetchingFailure', true);
  }

  if (action.type === types.CONFIRM_TRANSACTION_REQUEST) {
    return state
      .set('result', null)
      .set('isFetching', true)
      .set('isFetchingFailure', false);
  }

  if (action.type === types.CONFIRM_TRANSACTION_SUCCESS) {
    return state
      .set('result', Immutable.fromJS(action.payload))
      .set('isFetching', false)
      .set('isFetchingFailure', false)
      // update transaction state
      .setIn(['transaction', 'n58_open_state'], action.payload.n58_open_state)
      .setIn(['transaction', 'message'], action.payload.message);
  }

  if (action.type === types.CONFIRM_TRANSACTION_FAILURE) {
    let newState = state
      .set('result', Immutable.fromJS(action.payload.response))
      .set('isFetching', false)
      .set('isFetchingFailure', true);

    // update transaction state
    if (action.payload.response && action.payload.response.n58_open_state !== undefined) {
      newState = newState
        .setIn(['transaction', 'n58_open_state'], action.payload.response.n58_open_state)
        .setIn(['transaction', 'message'], action.payload.response.message);
    }

    return newState;
  }

  return state;
};
