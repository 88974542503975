export const FETCH_DELIVERIES_REQUEST = 'firm/delivery/FETCH_DELIVERIES_REQUEST';
export const FETCH_DELIVERIES_SUCCESS = 'firm/delivery/FETCH_DELIVERIES_SUCCESS';
export const FETCH_DELIVERIES_FAILURE = 'firm/delivery/FETCH_DELIVERIES_FAILURE';

export const RECEIVE_DELIVERY_REQUEST = 'firm/delivery/RECEIVE_DELIVERY_REQUEST';
export const RECEIVE_DELIVERY_SUCCESS = 'firm/delivery/RECEIVE_DELIVERY_SUCCESS';
export const RECEIVE_DELIVERY_FAILURE = 'firm/delivery/RECEIVE_DELIVERY_FAILURE';

export const UPDATE_DELIVERIES_FILTER_DATA = 'firm/delivery/UPDATE_DELIVERIES_FILTER_DATA';
export const UPDATE_DELIVERY = 'firm/delivery/UPDATE_DELIVERY';
