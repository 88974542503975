import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../TextField/TextField';
import { normalizePhone } from '../../service/number';
import { INSTANCE_SK } from '../../config/envspecific';

const PhoneField = (props) => {
  const { value, onChange, ...rest } = props;

  return (
    <TextField
      type="text"
      value={value}
      onChange={(e, val) => onChange(e, val)}
      onBlur={(e) => onChange(e, normalizePhone(value, INSTANCE_SK ? '+421' : '+420'))}
      fieldId="tf-phone-field"
      {...rest}
    />
  );
};

PhoneField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  fieldId: PropTypes.string,
  bigText: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default PhoneField;
