import { RSAA } from 'redux-api-middleware';
import { browserHistory } from 'react-router';
import * as types from './actionTypes';
import { API_URL } from '../../../config/envspecific';
import { t, tRoute } from '../../../i18n';
import { ADD_FLASH_MESSAGE } from '../../flashMessages/actionTypes';


export const fetchPartnerPairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/partners?view=pairs`,
    method: 'GET',
    types: [
      types.FETCH_PARTNER_PAIRS_REQUEST,
      types.FETCH_PARTNER_PAIRS_SUCCESS,
      types.FETCH_PARTNER_PAIRS_FAILURE,
    ],
  },
});

export const fetchBankAccounts = (partnerId) => ({
  [RSAA]: {
    endpoint: `${API_URL}/partners_bank_accounts?filter=c01_company_id:${partnerId}`,
    method: 'GET',
    types: [
      types.FETCH_BANK_ACCOUNTS_REQUEST,
      types.FETCH_BANK_ACCOUNTS_SUCCESS,
      types.FETCH_BANK_ACCOUNTS_FAILURE,
    ],
  },
});

export const addBankAccount = (bankAccount) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/partners_bank_accounts`,
    method: 'POST',
    body: JSON.stringify(bankAccount),
    types: [
      types.CREATE_BANK_ACCOUNT_REQUEST,
      types.CREATE_BANK_ACCOUNT_SUCCESS,
      types.CREATE_BANK_ACCOUNT_FAILURE,
    ],
  },
});

export const fetchPartner = (partnerId) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/partners/${partnerId}`,
    method: 'GET',
    types: [
      types.FETCH_DETAIL_REQUEST,
      types.FETCH_DETAIL_SUCCESS,
      types.FETCH_DETAIL_FAILURE,
    ],
  },
});

const updatePartnerCallApi = (partnerId, data) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/partners/${partnerId}`,
    method: 'PUT',
    body: JSON.stringify(data),
    types: [
      types.UPDATE_PARTNER_REQUEST,
      types.UPDATE_PARTNER_SUCCESS,
      types.UPDATE_PARTNER_FAILURE,
    ],
  },
});

const updatePartnerShowFlashSuccess = () => ({
  type: ADD_FLASH_MESSAGE,
  payload: {
    type: 'success',
    text: t('Editace proběhla úspěšně.', { ns: 'partner' }),
  },
});

const updatePartnerShowFlashFailure = () => ({
  type: ADD_FLASH_MESSAGE,
  payload: {
    type: 'error',
    text: t('Omlouváme se, editace selhala.', { ns: 'partner' }),
  },
});

export const updatePartner = (partnerId, data) => (dispatch) => dispatch(
  updatePartnerCallApi(partnerId, data)
).then((response) => {
  if (response.type === types.UPDATE_PARTNER_SUCCESS) {
    dispatch(updatePartnerShowFlashSuccess());
  } else if (response.type === types.UPDATE_PARTNER_FAILURE) {
    dispatch(updatePartnerShowFlashFailure());
  }
  browserHistory.push(tRoute(`/provozovny/partner/${partnerId}`));
});
