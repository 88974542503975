import { BenefitAdminItemType } from '../../global/model';
import { ActionWithPayload, ApiActions } from '../../../data/storeModel';

export const FETCH_BENEFITS_REQUEST = 'firm/benefits/FETCH_BENEFITS_REQUEST';
export const FETCH_BENEFITS_SUCCESS = 'firm/benefits/FETCH_BENEFITS_SUCCESS';
export const FETCH_BENEFITS_FAILURE = 'firm/benefits/FETCH_BENEFITS_FAILURE';

export const FETCH_BENEFIT_ITEM_REQUEST = 'firm/benefits/FETCH_BENEFIT_ITEM_REQUEST';
export const FETCH_BENEFIT_ITEM_SUCCESS = 'firm/benefits/FETCH_BENEFIT_ITEM_SUCCESS';
export const FETCH_BENEFIT_ITEM_FAILURE = 'firm/benefits/FETCH_BENEFIT_ITEM_FAILURE';

export const SAVE_BENEFIT_ITEM_REQUEST = 'firm/benefits/SAVE_BENEFIT_ITEM_REQUEST';
export const SAVE_BENEFIT_ITEM_SUCCESS = 'firm/benefits/SAVE_BENEFIT_ITEM_SUCCESS';
export const SAVE_BENEFIT_ITEM_FAILURE = 'firm/benefits/SAVE_BENEFIT_ITEM_FAILURE';

export const DELETE_BENEFIT_REQUEST = 'firm/benefits/DELETE_BENEFIT_REQUEST';
export const DELETE_BENEFIT_SUCCESS = 'firm/benefits/DELETE_BENEFIT_SUCCESS';
export const DELETE_BENEFIT_FAILURE = 'firm/benefits/DELETE_BENEFIT_FAILURE';


export const UPDATE_BENEFITS_FILTER_DATA = 'firm/UPDATE_BENEFITS_FILTER_DATA';

export type Actions =
  ActionWithPayload<typeof UPDATE_BENEFITS_FILTER_DATA> |
  ApiActions<typeof FETCH_BENEFITS_REQUEST, typeof FETCH_BENEFITS_SUCCESS, typeof FETCH_BENEFITS_FAILURE, BenefitAdminItemType[], any> |
  ApiActions<typeof FETCH_BENEFIT_ITEM_REQUEST, typeof FETCH_BENEFIT_ITEM_SUCCESS, typeof FETCH_BENEFIT_ITEM_FAILURE, BenefitAdminItemType, any> |
  ApiActions<typeof SAVE_BENEFIT_ITEM_REQUEST, typeof SAVE_BENEFIT_ITEM_SUCCESS, typeof SAVE_BENEFIT_ITEM_FAILURE, BenefitAdminItemType, BenefitAdminItemType>;
