/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../component1/Card';
import { widgetMinHeight } from '../../../global/constants';
import styles from './PromoStackedWidget.scss';
import RaisedButton from '../../../../component1/RaisedButton/RaisedButton';
import Dialog from '../../../../component1/Dialog/Dialog';

export const PromoStackedWidget = ({
  data,
  t,
}) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <Card layout="vcentered" minHeight={widgetMinHeight} bodyClassName={styles.cardBody} title={data.title}>
      {data.top_text && (
        <div>
          <p className={styles.text}>
            <span>
              {data.top_text}
              {' '}
              {data.top_more_content && <a role="presentation" onClick={() => setShowMore(true)}>{t('ZISTIŤ VIAC')}</a>}
            </span>
          </p>
        </div>
      )}
      <div
        className={styles.fullImg}
        style={{
          backgroundImage: `url(${data.background})`,
        }}
      >
        {data.html_text && <p className={styles.text} dangerouslySetInnerHTML={{ __html: data.html_text }} />}
        {data.logo && <a href={data.href} className={styles.imgContainer} target="_blank" rel="noopener noreferrer"><img className={styles.img} src={data.logo} alt={data.title} /></a>}
        {(!data.logo && data.href) && (
          <RaisedButton
            style={{
              position: 'absolute',
              bottom: 37,
            }}
            priority="primary"
            size="large"
            type="submit"
            label={t('Zjistit více')}
            externalHref={data.href}
            targetBlank
          />
        )}
        {data.comment && <p>{data.comment}</p>}
      </div>
      {showMore && (
        <Dialog
          onHandleClose={() => setShowMore(false)}
          title={t('Využite stovky výhod v Klube Up')}
          actions={[{
            label: t('Zavřít'),
            onClick: () => ({}),
          }]}
          hasCross
          forceOpen
        >
          <span
              // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: data.top_more_content }}
          />
        </Dialog>
      )}
    </Card>
  );
};

PromoStackedWidget.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    html_text: PropTypes.string,
    top_text: PropTypes.string,
    top_more_content: PropTypes.string,
    logo: PropTypes.string,
    background: PropTypes.string,
    comment: PropTypes.string,
    href: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};
