import { RSAA } from 'redux-api-middleware';
import { addFlashMessage } from '../../../flashMessages';
import { t } from '../../../../i18n';
import { API_URL } from '../../../../config/envspecific';
import * as types from './actionTypes';

export const fetchMyPlaceAffiliateTypePairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/my_affiliates/branches`,
    method: 'GET',
    types: [
      types.FETCH_AFFILIATE_TYPE_PAIRS_REQUEST,
      types.FETCH_AFFILIATE_TYPE_PAIRS_SUCCESS,
      types.FETCH_AFFILIATE_TYPE_PAIRS_FAILURE,
    ],
  },
});

export const fetchMyPlaceProductDescPairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/my_affiliates/products`,
    method: 'GET',
    types: [
      types.FETCH_PRODUCT_DESC_PAIRS_REQUEST,
      types.FETCH_PRODUCT_DESC_PAIRS_SUCCESS,
      types.FETCH_PRODUCT_DESC_PAIRS_FAILURE,
    ],
  },
});


export const fetchAffiliates = (queryString, payload, callback) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/my_affiliates?${queryString}`,
    method: 'GET',
    types: [
      { type: types.FETCH_AFFILIATES_REQUEST, payload },
      types.FETCH_AFFILIATES_SUCCESS,
      types.FETCH_AFFILIATES_FAILURE,
    ],
  },
}).then(() => callback());

export const fetchAffiliateMarkers = (queryString = '', payload = {}) => ({
  [RSAA]: {
    endpoint: `${API_URL}/my_affiliates?${queryString}`,
    method: 'GET',
    types: [
      { type: types.FETCH_AFFILIATES_MARKERS_REQUEST, payload },
      types.FETCH_AFFILIATES_MARKERS_SUCCESS,
      types.FETCH_AFFILIATES_MARKERS_FAILURE,
    ],
  },
});

export const fetchAffiliate = (contractAddressId) => ({
  [RSAA]: {
    endpoint: `${API_URL}/my_affiliates/${contractAddressId}`,
    method: 'GET',
    types: [
      types.FETCH_AFFILIATE_REQUEST,
      types.FETCH_AFFILIATE_SUCCESS,
      types.FETCH_AFFILIATE_FAILURE,
    ],
  },
});

export const updateAffiliatesFilterData = (filterData, orderColumnData) => ({
  type: types.UPDATE_AFFILIATES_FILTER_DATA,
  payload: {
    filter: filterData,
    orderBy: orderColumnData,
  },
});

export const saveWishlistAffiliate = (data) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: 'my_affiliates',
    method: 'PUT',
    body: JSON.stringify(data),
    types: [
      types.SAVE_WISHLIST_AFFILIATE_REQUEST,
      types.SAVE_WISHLIST_AFFILIATE_SUCCESS,
      types.SAVE_WISHLIST_AFFILIATE_FAILURE,
    ],
  },
}).then((action) => {
  if (action.error) {
    const message = action.payload.response && action.payload.response.message;
    dispatch(addFlashMessage('error', message || t('Chyba odeslání požadavku na nové místo.', { ns: 'my' })));
  } else {
    dispatch(addFlashMessage('success', t('Požadavek na nové místo úspěšně odeslán.', { ns: 'my' })));
  }
  return action;
});
