import { createSelector } from 'reselect';

const getWidgets = (state) => state.getIn(['home', 'widgets']).toJS();

export const selectWidgetsList = createSelector([getWidgets], (data) => data.widgets);
export const selectWidgetData = createSelector([getWidgets], (data) => data.widgetsData);
export const selectActiveProducts = createSelector([selectWidgetsList], (widgets) => {
  const ph20 = widgets.find((w) => w.widget === 'ph20');
  return ph20 && ph20.products && ph20.products.map((p) => ({ s20_code: p.s20_code }));
});

export const selectMainCompanyUserId = createSelector([selectWidgetsList], (widgets) => {
  const ph20 = widgets.find((w) => w.widget === 'ph20');
  return (ph20 && ph20.c30_company_user_id) || null;
});


export const selectContributionRP = createSelector([selectWidgetsList], (widgets) => {
  const widget = widgets.find((w) => w.widget === 'ph40' && w.enabled && w.show);
  return {
    fetched: widgets.length > 0,
    data: widget && widget.contributions && widget.contributions.length && widget.contributions[0],
  };
});

export const selectWidgetBenefitsEnabled = createSelector([getWidgets], (data) => !!(data.widgets.find((w) => w.widget === 'ph73')?.enabled));
