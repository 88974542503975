import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SelectField from '../../../../../component1/SelectField/SelectField';
import { moneyFormat3 } from '../../../../../service/number';
import RaisedButton from '../../../../../component1/RaisedButton/RaisedButton';
import styles from './CreditCardLink.scss';
import { PropTypeCard } from '../../../../global/proptypes';
import { INSTANCE } from '../../../../../config/envspecific';
import { tRoute } from '../../../../../i18n';

const limitsConfig = {
  cz: {
    GK: ['', 100, 200, 300, 500, 1000],
    GF: ['', 200, 500, 800, 1000, 1500],
    RP: [''],
    GD: ['', 200, 500, 800, 1000, 1500],
    default: ['', 200, 500, 800, 1000, 1500],
    creditAmount: ['', 200, 500, 1000, 1500, 2000],
  },
  sk: {
    GK: ['', 5, 10, 15, 20, 40],
    GF: [''],
    RP: [''],
    GD: [''],
    default: ['', 5, 10, 15, 20, 40],
    creditAmount: ['', 10, 20, 30, 50, 100],
  },
};

export const limitsListFactory = (limitType, t) => {
  if (!limitType) {
    return [{ label: t('Neaktivní'), value: '' }];
  }
  const config = limitsConfig[INSTANCE][limitType] || limitsConfig[INSTANCE].default;
  return config.map((l) => ({ label: l ? moneyFormat3(l, t) : t('Neaktivní'), value: l }));
};

const initialStateFactory = (card, deactivate) => () => card.auto_credit.settings.reduce((acc, curr) => {
  acc[curr.s20_code] = {
    minBalance: (deactivate ? null : curr.p65_min_balance) || '',
    creditAmount: (deactivate ? null : curr.p65_credit_amount) || '',
  };
  return acc;
}, { changed: deactivate });

export const CreditCardLinkDetail = ({
  t, card, onSave, showAssignedCard, onBack, readonly,
}) => {
  const [state, setState] = useState(initialStateFactory(card, false));
  const isActive = card.auto_credit.settings.some((s) => state[s.s20_code].minBalance && state[s.s20_code].creditAmount);
  return (
    <div className={styles.container}>
      <div>
        <span>{t('Stav napojení na platební kartu:')}</span>
        {' '}
        <strong>{isActive ? t('Aktivní') : t('Neaktivní')}</strong>
      </div>
      <table className={styles.detailTable}>
        <tbody>
          <tr className={styles.header}>
            <th>{t('Služba:')}</th>
            {card.auto_credit.settings.map((p) => <th key={p.s20_code}>{p.s20_name}</th>)}
          </tr>
          <tr>
            <th>{t('Minimální zůstatek:')}</th>
            {card.auto_credit.settings.map((s) => (
              <td key={s.s20_code}>
                <SelectField
                  value={state[s.s20_code].minBalance || ''}
                  options={limitsListFactory(s.s20_code, t)}
                  onChange={(value) => {
                    setState({
                      ...state,
                      changed: true,
                      [s.s20_code]: {
                        ...state[s.s20_code],
                        minBalance: value,
                      },
                    });
                  }}
                  errorText={null}
                  disabled={readonly}
                  rightAligned
                  isThin
                />
              </td>
            ))}
          </tr>
          <tr>
            <th>{t('Automatické dobití:')}</th>
            {card.auto_credit.settings.map((s) => (
              <td key={s.s20_code}>
                <SelectField
                  value={state[s.s20_code].creditAmount || ''}
                  options={limitsListFactory('creditAmount', t)}
                  onChange={(value) => {
                    setState({
                      ...state,
                      changed: true,
                      [s.s20_code]: {
                        ...state[s.s20_code],
                        creditAmount: value,
                      },
                    });
                  }}
                  errorText={null}
                  disabled={readonly}
                  rightAligned
                  isThin
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <div style={{
        display: 'flex', justifyContent: 'space-around', paddingTop: 10, height: 32,
      }}
      >
        {state.changed ? (
          <>
            <RaisedButton label={t('Uložit změny')} priority="primary" onClick={() => { onSave(state, card); setState({ ...state, changed: false }); }} disabled={readonly} />
            <RaisedButton label={t('Zrušit změny')} onClick={() => setState(initialStateFactory(card))} disabled={readonly} />
          </>
        ) : (
          <>
            {isActive && !readonly && <RaisedButton label={t('Deaktivovat')} onClick={() => setState(initialStateFactory(card, true))} />}
            {showAssignedCard && !readonly && <RaisedButton label={t('Detail nastavení')} internalHref={tRoute('/moje/propojena-karta', t)} />}
            {onBack && <RaisedButton label={t('Zpět')} onClick={onBack} />}
          </>
        )}
      </div>
    </div>
  );
};

CreditCardLinkDetail.propTypes = {
  card: PropTypeCard,
  onSave: PropTypes.func.isRequired,
  showAssignedCard: PropTypes.bool.isRequired,
  onBack: PropTypes.func,
  readonly: PropTypes.bool,
  t: PropTypes.func.isRequired,
};
