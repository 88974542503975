import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import RaisedButton from '../../../../../component1/RaisedButton/RaisedButton';
import styles from './CreditCardLink.scss';
import CheckboxField from '../../../../../component1/CheckboxField/CheckboxField';

export const CreditCardLinkGopayTerms = ({
  conditionsUrl, t, onConfirm,
}) => {
  const [conditionsConfirmed, setConditionsConfirmed] = useState(false);
  return (
    <div className={`${styles.container}`}>
      <h4>{t('Jak to funguje?')}</h4>
      <p>
        {t('Nastavíte si minimální zůstatek na své kartě\u00a0Up, při kterém se automaticky dobije o částku, kterou si také sami zvolíte. Platba za navýšení zůstatku se automaticky strhne z vaší platební karty. Službu je možné kdykoliv změnit nebo vypnout.')}
      </p>
      <div style={{ display: 'flex' }}>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: t('creditCardLinkGopayInfo') }}
        />
        <img className={styles.logoGoPay} src="/images/gopay-logos.png" alt="" />
      </div>
      <div className={styles.paragraph}>
        <span>
          <Trans i18nKey="creditCardLinkReadConditons" t={t}>
            Přečtěte si detailní
            {' '}
            <a href={conditionsUrl} target="_blank" rel="noreferrer noopener">podmínky pro použití služby propojení karet.</a>
          </Trans>
        </span>
        <CheckboxField
          fieldId="confirm_terms"
          label={t('Souhlasím s podmínkami')}
          checked={conditionsConfirmed}
          onClick={() => setConditionsConfirmed(!conditionsConfirmed)}
        />
      </div>
      <div className={styles.buttons}>
        <RaisedButton label={t('Pokračovat')} onClick={() => onConfirm(conditionsConfirmed)} disabled={!conditionsConfirmed} priority="primary" />
      </div>
    </div>
  );
};

CreditCardLinkGopayTerms.propTypes = {
  conditionsUrl: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
