import { RSAA, getJSON, ApiError } from 'redux-api-middleware';
import { browserHistory } from 'react-router';
import { API_URL } from '../../../config/envspecific';
import { t, tRoute } from '../../../i18n';
import * as types from './actionTypes';
import { ADD_FLASH_MESSAGE } from '../../flashMessages/actionTypes';
import { addFlashMessage } from '../../flashMessages/actions';


export const markVisibleCallApi = (affiliateId) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/affiliates/set_visibility`,
    method: 'PUT',
    body: JSON.stringify({
      action: 'mark_visible',
      c25_contract_address_id: affiliateId,
    }),
    types: [
      {
        type: types.MARK_AFFILIATE_VISIBLE_REQUEST,
        payload: () => ({ affiliateId }),
      },
      {
        type: types.MARK_AFFILIATE_VISIBLE_SUCCESS,
        payload: (action, state, res) => ({
          response: getJSON(res),
          affiliateId,
        }),
      },
      {
        type: types.MARK_AFFILIATE_VISIBLE_FAILURE,
        payload: (action, state, res) => {
          getJSON(res).then((json) => new ApiError(res.status, res.statusText, json));
          return { affiliateId, status: res.status };
        },
      },
    ],
  },
});

export const markVisibleFlashSuccess = () => ({
  type: ADD_FLASH_MESSAGE,
  payload: {
    type: 'success',
    text: t('Provozovna je nyní viditelná.', { ns: 'partner' }),
  },
});

export const markVisibleFlashFailure = () => ({
  type: ADD_FLASH_MESSAGE,
  payload: {
    type: 'error',
    text: t('Při zviditelnění provozovny se vyskytla chyba, zkuste to prosím znovu.', { ns: 'partner' }),
  },
});

export const markVisible = (affiliateId) => (dispatch) => dispatch(markVisibleCallApi(affiliateId))
  .then((response) => {
    if (response.type === types.MARK_AFFILIATE_VISIBLE_SUCCESS) {
      dispatch(markVisibleFlashSuccess());
    } else if (response.type === types.MARK_AFFILIATE_VISIBLE_FAILURE) {
      dispatch(markVisibleFlashFailure());
    }
  });

export const markHiddenCallApi = (affiliateId) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/affiliates/set_visibility`,
    method: 'PUT',
    body: JSON.stringify({
      action: 'mark_hidden',
      c25_contract_address_id: affiliateId,
    }),
    types: [
      {
        type: types.MARK_AFFILIATE_HIDDEN_REQUEST,
        payload: () => ({ affiliateId }),
      },
      {
        type: types.MARK_AFFILIATE_HIDDEN_SUCCESS,
        payload: (action, state, res) => ({
          response: getJSON(res),
          affiliateId,
        }),
      },
      {
        type: types.MARK_AFFILIATE_HIDDEN_FAILURE,
        payload: (action, state, res) => {
          getJSON(res).then((json) => new ApiError(res.status, res.statusText, json));
          return { affiliateId, status: res.status };
        },
      },
    ],
  },
});

export const markHiddenFlashSuccess = () => ({
  type: ADD_FLASH_MESSAGE,
  payload: {
    type: 'success',
    text: t('Provozovna je nyní skrytá.', { ns: 'partner' }),
  },
});

export const markHiddenFlashFailure = () => ({
  type: ADD_FLASH_MESSAGE,
  payload: {
    type: 'error',
    text: t('Při skrývání provozovny se vyskytla chyba, zkuste to prosím znovu.', { ns: 'partner' }),
  },
});

export const markHidden = (affiliateId) => (dispatch) => dispatch(markHiddenCallApi(affiliateId))
  .then((response) => {
    if (response.type === types.MARK_AFFILIATE_HIDDEN_SUCCESS) {
      dispatch(markHiddenFlashSuccess());
    } else if (response.type === types.MARK_AFFILIATE_HIDDEN_FAILURE) {
      dispatch(markHiddenFlashFailure());
    }
  });

export const fetchAffiliates = (queryString, payload, callback) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/affiliates?${queryString}`,
    method: 'GET',
    types: [
      { type: types.FETCH_AFFILIATES_REQUEST, payload },
      types.FETCH_AFFILIATES_SUCCESS,
      types.FETCH_AFFILIATES_FAILURE,
    ],
  },
}).then(() => callback());

export const updateAffiliatesFilterData = (filterData, orderColumnData) => ({
  type: types.UPDATE_AFFILIATES_FILTER_DATA,
  payload: {
    filter: filterData,
    orderBy: orderColumnData,
  },
});


export const fetchAffiliate = (affiliateId) => ({
  [RSAA]: {
    endpoint: `${API_URL}/affiliates/${affiliateId}`,
    method: 'GET',
    types: [
      types.FETCH_AFFILIATE_REQUEST,
      types.FETCH_AFFILIATE_SUCCESS,
      types.FETCH_AFFILIATE_FAILURE,
    ],
  },
});

export const createAffiliateCallApi = (data) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/affiliates`,
    method: 'POST',
    body: JSON.stringify(data),
    types: [
      types.CREATE_AFFILIATE_REQUEST,
      types.CREATE_AFFILIATE_SUCCESS,
      types.CREATE_AFFILIATE_FAILURE,
    ],
  },
});

export const createAffiliateShowFlashSuccess = () => ({
  type: ADD_FLASH_MESSAGE,
  payload: {
    type: 'success',
    text: t('Vytvoření provozovny bylo úspěšné.', { ns: 'partner' }),
  },
});

export const createAffiliateShowFlashFailure = () => ({
  type: ADD_FLASH_MESSAGE,
  payload: {
    type: 'error',
    text: t('Omlouváme se, ale nepodařilo se vytvořit novou provozovnu.', { ns: 'partner' }),
  },
});


export const redirectToAffiliatesList = () => new Promise((resolve) => {
  browserHistory.push(tRoute('/provozovny/provozovny'));
  resolve();
});

export const createAffiliate = (data) => (dispatch) => dispatch(createAffiliateCallApi(data))
  .then((response) => {
    if (response.type === types.CREATE_AFFILIATE_SUCCESS) {
      dispatch(createAffiliateShowFlashSuccess());
    } else if (response.type === types.CREATE_AFFILIATE_FAILURE) {
      dispatch(createAffiliateShowFlashFailure());
    }

    redirectToAffiliatesList();
  });

export const updateAffiliateCallApi = (affiliateId, data) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/affiliates/${affiliateId}`,
    method: 'PUT',
    body: JSON.stringify(data),
    types: [
      types.UPDATE_AFFILIATE_REQUEST,
      types.UPDATE_AFFILIATE_SUCCESS,
      types.UPDATE_AFFILIATE_FAILURE,
    ],
  },
});

export const updateAffiliateShowFlashSuccess = () => ({
  type: ADD_FLASH_MESSAGE,
  payload: {
    type: 'success',
    text: t('Editace proběhla úspěšně.', { ns: 'partner' }),
  },
});

export const updateAffiliateShowFlashFailure = () => ({
  type: ADD_FLASH_MESSAGE,
  payload: {
    type: 'error',
    text: t('Omlouváme se, editace selhala.', { ns: 'partner' }),
  },
});

export const updateAffiliate = (affiliateId, data) => (dispatch) => dispatch(
  updateAffiliateCallApi(affiliateId, data)
).then((response) => {
  if (response.type === types.UPDATE_AFFILIATE_SUCCESS) {
    dispatch(updateAffiliateShowFlashSuccess());
  } else if (response.type === types.UPDATE_AFFILIATE_FAILURE) {
    dispatch(updateAffiliateShowFlashFailure());
  }
  redirectToAffiliatesList();
});

export const fetchAffiliatePairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/affiliates?view=pairs`,
    method: 'GET',
    types: [
      types.FETCH_AFFILIATE_PAIRS_REQUEST,
      types.FETCH_AFFILIATE_PAIRS_SUCCESS,
      types.FETCH_AFFILIATE_PAIRS_FAILURE,
    ],
  },
});

export const fetchAffiliateMPOSAvailable = (affiliateId) => ({
  [RSAA]: {
    endpoint: `${API_URL}/affiliates/${affiliateId}/mpos_avail`,
    method: 'GET',
    types: [
      types.FETCH_AFFILIATE_MPOS_AVAIL_REQUEST,
      types.FETCH_AFFILIATE_MPOS_AVAIL_SUCCESS,
      types.FETCH_AFFILIATE_MPOS_AVAIL_FAILURE,
    ],
  },
});

export const fetchAffiliateMPOSActivated = (affiliateId) => ({
  [RSAA]: {
    endpoint: `${API_URL}/affiliates/${affiliateId}/mpos_activated`,
    method: 'GET',
    types: [
      types.FETCH_AFFILIATE_MPOS_ACTIVE_REQUEST,
      types.FETCH_AFFILIATE_MPOS_ACTIVE_SUCCESS,
      types.FETCH_AFFILIATE_MPOS_ACTIVE_FAILURE,
    ],
  },
});

export const storeMPOSActivation = (affiliateId, mPOSId) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/affiliates/mpos_activate`,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({
      c25_contract_address_id: affiliateId,
      n01_pos_id: mPOSId,
    }),
    types: [
      types.FETCH_AFFILIATE_ACTIVATE_MPOS_REQUEST,
      types.FETCH_AFFILIATE_ACTIVATE_MPOS_SUCCESS,
      types.FETCH_AFFILIATE_ACTIVATE_MPOS_FAILURE,
    ],
  },
}).then((response) => {
  if (response.type === types.FETCH_AFFILIATE_ACTIVATE_MPOS_SUCCESS) {
    dispatch(addFlashMessage('success', t('Aktivace terminálu proběhla úspěšně', { ns: 'partner' })));
  } else if (response.type === types.FETCH_AFFILIATE_ACTIVATE_MPOS_FAILURE) {
    dispatch(addFlashMessage('error', t('Chyba aktivace terminálu', { ns: 'partner' })));
  }

  return response;
});

export const fetchAffiliateProductTypes = (contractId) => ({
  [RSAA]: {
    endpoint: `${API_URL}/partners_contracts/${contractId}/affiliate_types`,
    method: 'GET',
    types: [
      types.FETCH_AFFILIATE_PRODUCT_TYPES_REQUEST,
      types.FETCH_AFFILIATE_PRODUCT_TYPES_SUCCESS,
      types.FETCH_AFFILIATE_PRODUCT_TYPES_FAILURE,
    ],
  },
});

export const flashWarningMessage = (text) => addFlashMessage('warning', text);
