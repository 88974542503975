import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { MOBILE_APP } from '../../config/envspecific';
import RaisedButton from '../RaisedButton/RaisedButton';
import styles from './Dialog.scss';
import Overlay from '../Progress/Overlay/Overlay';
import { DialogTitle } from './DialogTitle';
import { ReactChildrenLike } from '../../modules/global/model';

const baseStyles = {
  paper: (props: Props) => ({
    maxWidth: props.maxWidth || (props.isNarrow ? 400 : 768),
  }),
};

interface Props extends WithStyles<typeof baseStyles> {
  actions?: Array<{
      label: string,
      onClick: () => boolean|Promise<boolean>|void,
      primary?: boolean,
      disabled?: boolean,
    }|null|undefined>,
  children: ReactChildrenLike,
  maxWidth?: number|string,
  openElement?: JSX.Element,
  beforeBodyElement?: JSX.Element,
  forceOpen?: boolean,
  isInline?: boolean,
  isNarrow?: boolean,
  isModal?: boolean,
  isWarning?: boolean,
  isSuccess?: boolean,
  hasCross?: boolean,
  fluid?: boolean,
  timeout?: number,
  title?: string | JSX.Element,
  className?: string,
  centerContent?: boolean,
  onHandleOpen?: () => void,
  onHandleClose?: () => void,
}

interface State {
  open: boolean;
  loading: boolean;
  openElement: JSX.Element|null;
}

class BaseDialog extends React.Component<Props, State> {
  timeout: NodeJS.Timeout|null = null;

  constructor(props: Props) {
    super(props);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      open: false,
      loading: false,
      openElement:
        this.props.openElement
          ? React.cloneElement(this.props.openElement, { onClick: this.handleOpen })
          : null,
    };
  }

  componentDidMount() {
    if (!this.props.openElement && this.props.forceOpen === undefined) {
      this.handleOpen();
    }

    setTimeout(() => this.patchDOM());
  }

  componentDidUpdate() {
    this.patchDOM();
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    if (this.props.openElement) {
      this.setState({
        openElement: props.openElement ? React.cloneElement(props.openElement, { onClick: this.handleOpen }) : null,
      });
      return;
    }

    this.setState({ openElement: null });
    this.patchDOM();
  }

  setTimeout() {
    if (this.props.timeout) {
      this.clearTimeout();
      this.timeout = setTimeout(() => {
        this.handleClose();
      }, this.props.timeout);
    }
  }

  clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = null;
  }

  handleOpen() {
    this.setTimeout();
    this.setState({ open: true });

    if (this.props.onHandleOpen) {
      this.props.onHandleOpen();
    }
  }

  handleClose() {
    this.clearTimeout();
    this.setState({ open: false, loading: false });

    if (this.props.onHandleClose) {
      this.props.onHandleClose();
    }
  }

  patchDOM() {
  }

  render() {
    const { classes, centerContent } = this.props;
    const scrollBody = MOBILE_APP;

    const actionElements = this.props.actions && this.props.actions.filter((action) => !!action).map((action, i) => (
      <div key={i} className={styles.action}>
        <RaisedButton
          label={action!.label}
          priority={action!.primary ? 'primary' : null}
          onClick={() => {
            const ret = action!.onClick();

            if (ret && ret !== true && typeof ret !== 'boolean' && ret.then && typeof ret.then === 'function') {
              this.setState({ loading: true });
              ret.then((r) => {
                if (r !== false) {
                  this.handleClose();
                } else {
                  this.setState({ loading: false });
                }
              });
            } else if (ret !== false) {
              this.handleClose();
            }
          }}
          disabled={action!.disabled}
        />
      </div>
    ));

    const open = this.props.forceOpen || this.state.open;

    return (
      <div className={this.props.isInline ? styles.isInline : undefined}>
        {this.state.openElement}
        <Dialog
          open={open}
          disableBackdropClick={this.props.isModal}
          disableEscapeKeyDown={this.props.isModal}
          onClose={this.handleClose}
          maxWidth={false}
          classes={{
            paper: `${classes.paper} ${this.props.fluid ? styles.fluidContent : styles.content}`,
          }}
          scroll={scrollBody ? 'body' : 'paper'}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Overlay show={this.state.loading} />
          {this.props.title && (
          <DialogTitle
            showCross={!!this.props.hasCross}
            isWarning={!!this.props.isWarning}
            isSuccess={!!this.props.isSuccess}
            onClose={this.handleClose}
          >
            {this.props.title}
          </DialogTitle>
          )}
          {this.props.beforeBodyElement}
          <DialogContent className={centerContent ? styles.centerContent : undefined}>
            {this.props.children}
          </DialogContent>
          {actionElements && (
          <DialogActions>
            {actionElements}
          </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(baseStyles)(BaseDialog);
