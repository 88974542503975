export const FETCH_CONTRACT_REQUEST = 'firm/contract/FETCH_CONTRACT_REQUEST';
export const FETCH_CONTRACT_SUCCESS = 'firm/contract/FETCH_CONTRACT_SUCCESS';
export const FETCH_CONTRACT_FAILURE = 'firm/contract/FETCH_CONTRACT_FAILURE';

export const FETCH_CONTRACT_PAIRS_REQUEST = 'firm/contract/FETCH_CONTRACT_PAIRS_REQUEST';
export const FETCH_CONTRACT_PAIRS_SUCCESS = 'firm/contract/FETCH_CONTRACT_PAIRS_SUCCESS';
export const FETCH_CONTRACT_PAIRS_FAILURE = 'firm/contract/FETCH_CONTRACT_PAIRS_FAILURE';

export const DOWNLOAD_CONTRACT_PDF_REQUEST = 'firm/contract/DOWNLOAD_CONTRACT_PDF_REQUEST';
export const DOWNLOAD_CONTRACT_PDF_SUCCESS = 'firm/contract/DOWNLOAD_CONTRACT_PDF_SUCCESS';
export const DOWNLOAD_CONTRACT_PDF_FAILURE = 'firm/contract/DOWNLOAD_CONTRACT_PDF_FAILURE';
