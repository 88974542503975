import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CreditCardLinkGopayTerms } from './CreditCardLinkGopayTerms';
import { CreditCardLinkContractTerms } from './CreditCardLinkContractTerms';

export const CreditCardLinkTerms = ({
  gopayConditions, t, onConfirm, needContract,
}) => {
  const [confirmed, setConfirmed] = useState({
    gopay: false,
    b2c: !needContract,
  });

  if (!confirmed.gopay) {
    return (
      <CreditCardLinkGopayTerms
        conditionsUrl={gopayConditions}
        t={t}
        onConfirm={(val) => {
          const newConfirmed = {
            ...confirmed,
            gopay: val,
          };
          setConfirmed(newConfirmed);

          if (newConfirmed.gopay && newConfirmed.b2c) {
            onConfirm(newConfirmed.gopay && newConfirmed.b2c);
          }
        }}
      />
    );
  }

  if (!confirmed.b2c) {
    return (
      <CreditCardLinkContractTerms
        t={t}
        onConfirm={(val) => {
          const newConfirmed = {
            ...confirmed,
            b2c: val,
          };
          setConfirmed(newConfirmed);

          if (newConfirmed.gopay && newConfirmed.b2c) {
            onConfirm(newConfirmed.gopay && newConfirmed.b2c);
          }
        }}
      />
    );
  }

  return null;
};

CreditCardLinkTerms.propTypes = {
  gopayConditions: PropTypes.string.isRequired,
  needContract: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
