import Immutable from 'immutable';
import * as types from './actionTypes';
import { tRoute } from '../../../i18n';
import { getEntitiesFromResponse } from '../../../service/entityList';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['partner', 'affiliate']);
  }

  if (type === types.FETCH_AFFILIATES_REQUEST) {
    return state
      .setIn(['affiliateList', 'isFetchingFailure'], false)
      .setIn(['affiliateList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['affiliateList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['affiliateList', 'fetchCount'], Immutable.fromJS(payload.limit))
      .setIn(['affiliateList', 'limit'], Immutable.fromJS(payload.limit))
      .setIn(['affiliateList', 'lastOffset'], Immutable.fromJS(payload.lastOffset))
      .setIn(['affiliateList', 'orderBy'], Immutable.fromJS(payload.orderBy))
      .setIn(['affiliateList', 'filter'], Immutable.fromJS(payload.filter));
  }

  if (type === types.FETCH_AFFILIATES_SUCCESS) {
    const affiliates = payload.map((item) => ({
      data: {
        id: item.c25_contract_address_id,
        c01_company_id: item.c01_company_id,
        c25_code: item.c25_code,
        is_active: !!item.is_active,
        c20_label: {
          value: item.c20_label,
          href: tRoute(`/provozovny/provozovna/${item.c25_contract_address_id}`), // REF: move to render
        },
        full_address: item.full_address,
        c10_number: item.c10_number,
        monthly_visits: item.monthly_visits,
      },
      actions: {
        markVisible: !!+item.mark_visible,
        markHidden: !!+item.mark_hidden,
        showTransactions: !!+item.show_earnings,
      },
      flags: {
        isMarkingVisible: false,
        isMarkingVisibleFailure: false,
        isMarkingHidden: false,
        isMarkingHiddenFailure: false,
      },
    }));

    const affiliateList = state.get('affiliateList');
    const fetchCount = affiliateList.get('fetchCount');
    const lastOffset = affiliateList.get('lastOffset');
    const stateAffiliates = affiliateList.get('affiliates');
    const lastCount = affiliates.length;

    return state
      .setIn(['affiliateList', 'affiliates'], getEntitiesFromResponse(affiliates, stateAffiliates, lastOffset, fetchCount))
      .setIn(['affiliateList', 'lastCount'], lastCount)
      .setIn(['affiliateList', 'isFetching'], false)
      .setIn(['affiliateList', 'isLoadingMore'], false)
      .setIn(['affiliateList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_AFFILIATES_FAILURE) {
    return state
      .setIn(['affiliateList', 'isFetching'], false)
      .setIn(['affiliateList', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_AFFILIATES_FILTER_DATA) {
    return state
      .setIn(['affiliateList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['affiliateList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  if (type === types.FETCH_AFFILIATE_REQUEST) {
    return state
      .setIn(['affiliate', 'isFetching'], true)
      .setIn(['affiliate', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_AFFILIATE_SUCCESS) {
    const data = {
      ...payload,
      c20_pos: !!payload.c20_pos,
    };

    return state
      .setIn(['affiliate', 'isFetching'], false)
      .setIn(['affiliate', 'data'], Immutable.fromJS(data));
  }

  if (type === types.FETCH_AFFILIATE_FAILURE) {
    return state
      .setIn(['affiliate', 'isFetching'], false)
      .setIn(['affiliate', 'isFetchingFailure'], true);
  }

  if (type === types.CREATE_AFFILIATE_REQUEST) {
    return state
      .setIn(['newAffiliate', 'isPostingFailure'], false)
      .setIn(['newAffiliate', 'isPosting'], true);
  }

  if (type === types.CREATE_AFFILIATE_SUCCESS) {
    return state.setIn(['newAffiliate', 'isPosting'], false);
  }

  if (type === types.CREATE_AFFILIATE_FAILURE) {
    return state
      .setIn(['newAffiliate', 'isPostingFailure'], true)
      .setIn(['newAffiliate', 'isPosting'], false);
  }

  if (type === types.UPDATE_AFFILIATE_REQUEST) {
    return state
      .setIn(['affiliate', 'isPostingFailure'], false)
      .setIn(['affiliate', 'isPosting'], true);
  }

  if (type === types.UPDATE_AFFILIATE_SUCCESS) {
    return state.setIn(['affiliate', 'isPosting'], false);
  }

  if (type === types.UPDATE_AFFILIATE_FAILURE) {
    return state
      .setIn(['affiliate', 'isPostingFailure'], true)
      .setIn(['affiliate', 'isPosting'], false);
  }

  if (type === types.FETCH_AFFILIATE_PAIRS_REQUEST) {
    return state
      .setIn(['affiliatePairsList', 'isFetchingFailure'], true)
      .setIn(['affiliatePairsList', 'isFetching'], true);
  }

  if (type === types.FETCH_AFFILIATE_PAIRS_SUCCESS) {
    const affiliates = payload.map((item) => ({
      label: item.c20_label,
      value: item.c25_contract_address_id,
    }));

    return state
      .setIn(['affiliatePairsList', 'affiliates'], Immutable.fromJS(affiliates))
      .setIn(['affiliatePairsList', 'isFetching'], false);
  }

  if (type === types.FETCH_AFFILIATE_PAIRS_FAILURE) {
    return state
      .setIn(['affiliatePairsList', 'isFetching'], false)
      .setIn(['affiliatePairsList', 'isFetchingFailure'], true);
  }

  if (action.type === types.MARK_AFFILIATE_VISIBLE_REQUEST) {
    const affiliates = state.getIn(['affiliateList', 'affiliates']).map((affiliate) => {
      if (affiliate.get('data').get('id') === payload.affiliateId) {
        return affiliate.set('flags', affiliate.get('flags')
          .set('isMarkingVisible', true)
          .set('isMarkingFailure', false));
      }

      return affiliate;
    });

    return state.setIn(['affiliateList', 'affiliates'], Immutable.fromJS(affiliates));
  }

  if (action.type === types.MARK_AFFILIATE_VISIBLE_SUCCESS) {
    const affiliates = state.getIn(['affiliateList', 'affiliates']).map((affiliate) => {
      if (affiliate.get('data').get('id') === payload.affiliateId) {
        return affiliate.set('flags', affiliate.get('flags')
          .set('isMarkingVisible', false)
          .set('isMarkingFailure', false))
          .set('actions', affiliate.get('actions')
            .set('markVisible', false)
            .set('markHidden', true));
      }

      return affiliate;
    });

    return state.setIn(['affiliateList', 'affiliates'], Immutable.fromJS(affiliates));
  }

  if (action.type === types.MARK_AFFILIATE_VISIBLE_FAILURE) {
    const affiliates = state.getIn(['affiliateList', 'affiliates']).map((affiliate) => {
      if (affiliate.get('data').get('id') === payload.affiliateId) {
        return affiliate.set('flags', affiliate.get('flags')
          .set('isMarkingVisible', false)
          .set('isMarkingVisibleFailure', true));
      }

      return affiliate;
    });

    return state.setIn(['affiliateList', 'affiliates'], Immutable.fromJS(affiliates));
  }

  if (action.type === types.MARK_AFFILIATE_HIDDEN_REQUEST) {
    const affiliates = state.getIn(['affiliateList', 'affiliates']).map((affiliate) => {
      if (affiliate.get('data').get('id') === payload.affiliateId) {
        return affiliate.set('flags', affiliate.get('flags')
          .set('isMarkingHidden', true)
          .set('isMarkingHiddenFailure', false));
      }

      return affiliate;
    });

    return state.setIn(['affiliateList', 'affiliates'], Immutable.fromJS(affiliates));
  }

  if (action.type === types.MARK_AFFILIATE_HIDDEN_SUCCESS) {
    const affiliates = state.getIn(['affiliateList', 'affiliates']).map((affiliate) => {
      if (affiliate.get('data').get('id') === payload.affiliateId) {
        return affiliate
          .set('flags', affiliate.get('flags')
            .set('isMarkingVisible', false)
            .set('isMarkingFailure', false))
          .set('actions', affiliate.get('actions')
            .set('markVisible', true)
            .set('markHidden', false));
      }

      return affiliate;
    });

    return state.setIn(['affiliateList', 'affiliates'], Immutable.fromJS(affiliates));
  }

  if (action.type === types.MARK_AFFILIATE_HIDDEN_FAILURE) {
    const affiliates = state.getIn(['affiliateList', 'affiliates']).map((affiliate) => {
      if (affiliate.get('data').get('id') === payload.affiliateId) {
        return affiliate.set('flags', affiliate.get('flags')
          .set('isMarkingHidden', false)
          .set('isMarkingHiddenFailure', true));
      }

      return affiliate;
    });

    return state.setIn(['affiliateList', 'affiliates'], Immutable.fromJS(affiliates));
  }

  if (type === types.FETCH_AFFILIATE_MPOS_AVAIL_REQUEST) {
    return state
      .setIn(['mPOSAvailable', 'isFetchingFailure'], false)
      .setIn(['mPOSAvailable', 'isFetching'], true);
  }

  if (type === types.FETCH_AFFILIATE_MPOS_AVAIL_SUCCESS) {
    return state
      .setIn(['mPOSAvailable', 'data'], Immutable.fromJS(payload))
      .setIn(['mPOSAvailable', 'isFetching'], false);
  }

  if (type === types.FETCH_AFFILIATE_MPOS_AVAIL_FAILURE) {
    return state
      .setIn(['mPOSAvailable', 'isFetching'], false)
      .setIn(['mPOSAvailable', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_AFFILIATE_MPOS_ACTIVE_REQUEST) {
    return state
      .setIn(['mPOSActive', 'isFetchingFailure'], false)
      .setIn(['mPOSActive', 'isFetching'], true);
  }

  if (type === types.FETCH_AFFILIATE_MPOS_ACTIVE_SUCCESS || type === types.FETCH_AFFILIATE_ACTIVATE_MPOS_SUCCESS) {
    return state
      .setIn(['mPOSActive', 'data'], Immutable.fromJS(payload))
      .setIn(['mPOSActive', 'isFetching'], false);
  }

  if (type === types.FETCH_AFFILIATE_MPOS_ACTIVE_FAILURE) {
    return state
      .setIn(['mPOSActive', 'isFetching'], false)
      .setIn(['mPOSActive', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_AFFILIATE_PRODUCT_TYPES_SUCCESS) {
    return state.setIn(['productAffiliateTypes'], Immutable.fromJS(payload));
  }

  return state;
};


export default reducerFactory;
