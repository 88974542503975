import { ActionWithPayload, ApiActions } from '../../../data/storeModel';
import { NewsAdminDetailType, NewsAdminListItemType } from '../../global/model';

export const FETCH_NEWS_REQUEST = 'firm/news/FETCH_NEWS_REQUEST';
export const FETCH_NEWS_SUCCESS = 'firm/news/FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAILURE = 'firm/news/FETCH_NEWS_FAILURE';

export const FETCH_NEWS_ITEM_REQUEST = 'firm/news/FETCH_NEWS_ITEM_REQUEST';
export const FETCH_NEWS_ITEM_SUCCESS = 'firm/news/FETCH_NEWS_ITEM_SUCCESS';
export const FETCH_NEWS_ITEM_FAILURE = 'firm/news/FETCH_NEWS_ITEM_FAILURE';

export const SAVE_NEWS_ITEM_REQUEST = 'firm/news/SAVE_NEWS_ITEM_REQUEST';
export const SAVE_NEWS_ITEM_SUCCESS = 'firm/news/SAVE_NEWS_ITEM_SUCCESS';
export const SAVE_NEWS_ITEM_FAILURE = 'firm/news/SAVE_NEWS_ITEM_FAILURE';

export const DELETE_NEWS_REQUEST = 'firm/news/DELETE_NEWS_REQUEST';
export const DELETE_NEWS_SUCCESS = 'firm/news/DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAILURE = 'firm/news/DELETE_NEWS_FAILURE';

export const UPDATE_NEWS_FILTER_DATA = 'firm/firm/UPDATE_NEWS_FILTER_DATA';

export type Actions =
  ActionWithPayload<typeof UPDATE_NEWS_FILTER_DATA> |
  ApiActions<typeof FETCH_NEWS_REQUEST, typeof FETCH_NEWS_SUCCESS, typeof FETCH_NEWS_FAILURE, NewsAdminListItemType[], any> |
  ApiActions<typeof FETCH_NEWS_ITEM_REQUEST, typeof FETCH_NEWS_ITEM_SUCCESS, typeof FETCH_NEWS_ITEM_FAILURE, NewsAdminDetailType, any> |
  ApiActions<typeof SAVE_NEWS_ITEM_REQUEST, typeof SAVE_NEWS_ITEM_SUCCESS, typeof SAVE_NEWS_ITEM_FAILURE, NewsAdminDetailType, NewsAdminDetailType>;
