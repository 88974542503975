import { combineReducers } from 'redux-immutable';
import * as types from './actionTypes';
import firmContractReducer from './contract/reducer';
import cardReducer from './card/reducer';
import branchAndCostCenterReducer from './branchAndCostCenter/reducer';
import branchReducer from './branch/reducer';
import costCenterReducer from './costCenter/reducer';
import firmDeliveryReducer from './delivery/reducer';
import firmTransactionReducer from './transaction/reducer';
import firmAttendanceReducer from './attendance/reducer';
import firmInvoiceReducer from './invoice/reducer';
import firmOrdersReducer from './orders/reducer';
import firmFirmReducer from './firm/reducer';
import firmUserReducer from './user/reducer';
import { firmBenefiaOrdersReducerFactory } from './benefiaOrder/reducer';
import { positionReducerFactory } from './position/reducer';
import { departmentReducerFactory } from './department/reducer';
import { firmUserGroupsReducerFactory } from './userGroup/reducer';
import { firmBenefitsReducerFactory } from './benefits/reducer';
import { firmNewsReducerFactory } from './news/reducer';


const reducerFactory = (initialState) => (
  combineReducers({
    branch: branchReducer(initialState),
    branchAndCostCenter: branchAndCostCenterReducer(initialState),
    card: cardReducer(initialState),
    contract: firmContractReducer(initialState),
    costCenter: costCenterReducer(initialState),
    position: positionReducerFactory(initialState),
    department: departmentReducerFactory(initialState),
    firm: firmFirmReducer(initialState),
    invoice: firmInvoiceReducer(initialState),
    orders: firmOrdersReducer(initialState),
    transaction: firmTransactionReducer(initialState),
    benefiaOrder: firmBenefiaOrdersReducerFactory(initialState),
    attendance: firmAttendanceReducer(initialState),
    userGroup: firmUserGroupsReducerFactory(initialState),
    user: firmUserReducer(initialState),
    benefits: firmBenefitsReducerFactory(initialState),
    news: firmNewsReducerFactory(initialState),
    delivery: firmDeliveryReducer(initialState),
    activeFirmId: (state, action) => {
      const { type, payload } = action;

      if (typeof state === 'undefined') {
        return initialState.getIn(['firm', 'activeFirmId']);
      }

      if (type === types.UPDATE_ACTIVE_FIRM_ID) {
        return payload.firmId ? +payload.firmId : null;
      }

      return state;
    },
    activeContractId: (state, action) => {
      const { type, payload } = action;

      if (typeof state === 'undefined') {
        return initialState.getIn(['firm', 'activeContractId']);
      }

      if (type === types.UPDATE_ACTIVE_CONTRACT_ID) {
        return payload.contractId ? +payload.contractId : null;
      }

      return state;
    },
  })
);

export default reducerFactory;
