import { RSAA } from 'redux-api-middleware';
import { API_URL } from '../../config/envspecific';
import * as types from './actionTypes';
import { setLanguage, getLocale } from '../../i18n';

export const fetchBankPairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/k10`,
    method: 'GET',
    types: [
      types.FETCH_BANK_PAIRS_REQUEST,
      types.FETCH_BANK_PAIRS_SUCCESS,
      types.FETCH_BANK_PAIRS_FAILURE,
    ],
  },
});

const reslistT04Codes = {
  my: 'u',
  firm: 'k',
  partner: 'p',
};

export const fetchTransactionTypePairs = (mod) => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/t04_${reslistT04Codes[mod]}`,
    method: 'GET',
    types: [
      types.FETCH_TRANSACTION_TYPE_PAIRS_REQUEST,
      types.FETCH_TRANSACTION_TYPE_PAIRS_SUCCESS,
      types.FETCH_TRANSACTION_TYPE_PAIRS_FAILURE,
    ],
  },
});

export const fetchDeliveryStatusPairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/o11`,
    method: 'GET',
    types: [
      types.FETCH_DELIVERY_STATUS_PAIRS_REQUEST,
      types.FETCH_DELIVERY_STATUS_PAIRS_SUCCESS,
      types.FETCH_DELIVERY_STATUS_PAIRS_FAILURE,
    ],
  },
});

export const fetchCashSystemPairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/n80`,
    method: 'GET',
    types: [
      types.FETCH_CASH_SYSTEM_PAIRS_REQUEST,
      types.FETCH_CASH_SYSTEM_PAIRS_SUCCESS,
      types.FETCH_CASH_SYSTEM_PAIRS_FAILURE,
    ],
  },
});

export const fetchAffiliateTypePairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/c23`,
    method: 'GET',
    types: [
      types.FETCH_AFFILIATE_TYPE_PAIRS_REQUEST,
      types.FETCH_AFFILIATE_TYPE_PAIRS_SUCCESS,
      types.FETCH_AFFILIATE_TYPE_PAIRS_FAILURE,
    ],
  },
});

export const fetchNameOnCardPairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/p17`,
    method: 'GET',
    types: [
      types.FETCH_NAME_ON_CARD_PAIRS_REQUEST,
      types.FETCH_NAME_ON_CARD_PAIRS_SUCCESS,
      types.FETCH_NAME_ON_CARD_PAIRS_FAILURE,
    ],
  },
});

export const fetchDeliveryCardOrderPairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/p43`,
    method: 'GET',
    types: [
      types.FETCH_DELIVERY_CARD_ORDER_PAIRS_REQUEST,
      types.FETCH_DELIVERY_CARD_ORDER_PAIRS_SUCCESS,
      types.FETCH_DELIVERY_CARD_ORDER_PAIRS_FAILURE,
    ],
  },
});

export const setBrand = (brand) => (dispatch) => {
  let code = null;

  if (!brand) {
    code = null;
  } else if (['o2'].includes(brand)) {
    code = String(brand).toUpperCase();
  } else {
    throw new Error(`Unknown brand token: ${brand}`);
  }

  dispatch({
    type: types.SET_BRAND,
    payload: { code },
  });
};

export const fetchProductPairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/s20`,
    method: 'GET',
    types: [
      types.FETCH_PRODUCT_PAIRS_REQUEST,
      types.FETCH_PRODUCT_PAIRS_SUCCESS,
      types.FETCH_PRODUCT_PAIRS_FAILURE,
    ],
  },
});

export const fetchProductDescPairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/s20_desc`,
    method: 'GET',
    types: [
      types.FETCH_PRODUCT_DESC_PAIRS_REQUEST,
      types.FETCH_PRODUCT_DESC_PAIRS_SUCCESS,
      types.FETCH_PRODUCT_DESC_PAIRS_FAILURE,
    ],
  },
});

export const fetchProductExPairs = () => (dispatch) => Promise.all([
  dispatch(fetchProductPairs()),
  dispatch(fetchProductDescPairs()),
]);

export const setOffline = (offline) => ({
  type: types.SET_OFFLINE,
  payload: { offline },
});

export const sendLogMessage = (message) => ({
  [RSAA]: {
    endpoint: `${API_URL}/log`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify([{ message }]),
    types: [
      types.SEND_LOG_MESSAGE_REQUEST,
      types.SEND_LOG_MESSAGE_SUCCESS,
      types.SEND_LOG_MESSAGE_FAILURE,
    ],
  },
});

export const fetchCardKindsPairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/p24`,
    method: 'GET',
    types: [
      types.FETCH_CARD_KINDS_PAIRS_REQUEST,
      types.FETCH_CARD_KINDS_PAIRS_SUCCESS,
      types.FETCH_CARD_KINDS_PAIRS_FAILURE,
    ],
  },
});

export const fetchCardTypePairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/p23`,
    method: 'GET',
    types: [
      types.FETCH_CARD_TYPE_PAIRS_REQUEST,
      types.FETCH_CARD_TYPE_PAIRS_SUCCESS,
      types.FETCH_CARD_TYPE_PAIRS_FAILURE,
    ],
  },
});

export const fetchCardStatusPairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/p21`,
    method: 'GET',
    types: [
      types.FETCH_CARD_STATUS_PAIRS_REQUEST,
      types.FETCH_CARD_STATUS_PAIRS_SUCCESS,
      types.FETCH_CARD_STATUS_PAIRS_FAILURE,
    ],
  },
});

export const fetchPaymentGateTransactionStatusPairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/n58`,
    method: 'GET',
    types: [
      types.FETCH_GATE_TRANS_STATUS_PAIRS_REQUEST,
      types.FETCH_GATE_TRANS_STATUS_PAIRS_SUCCESS,
      types.FETCH_GATE_TRANS_STATUS_PAIRS_FAILURE,
    ],
  },
});

export const changeLanguage = (lang) => () => {
  if (lang !== getLocale()) {
    setLanguage(lang);
  }
};

export const fetchPaymentTypePairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/i04`,
    method: 'GET',
    types: [
      types.FETCH_PAYMENT_TYPE_PAIRS_REQUEST,
      types.FETCH_PAYMENT_TYPE_PAIRS_SUCCESS,
      types.FETCH_PAYMENT_TYPE_PAIRS_FAILURE,
    ],
  },
});

export const fetchAttendanceTypePairs = () => ({
  [RSAA]: {
    endpoint: `${API_URL}/reslists/d11`,
    method: 'GET',
    types: [
      types.FETCH_ATTENDANCE_TYPE_PAIRS_REQUEST,
      types.FETCH_ATTENDANCE_TYPE_PAIRS_SUCCESS,
      types.FETCH_ATTENDANCE_TYPE_PAIRS_FAILURE,
    ],
  },
});
