import PropTypes from 'prop-types';
import React from 'react';
import { Card } from '../../../../component1/Card';
import RaisedButton from '../../../../component1/RaisedButton/RaisedButton';
import { widgetMinHeight } from '../../../global/constants';
import { tRoute } from '../../../../i18n';
import styles from './BenefitsWidget.scss';
import { ProductIcon } from '../../../../component1/ProductButton/ProductButton';

export const BenefitsWidget = ({ t }) => (
  <Card layout="centered" title={t('Interné benefity')} minHeight={widgetMinHeight}>
    <div className={styles.cont}>
      <p className={styles.smallText}>{t('Vyberte si benefity podľa vlastnej preferencie.')}</p>
      <p className={styles.text}>{t('Benefity pre lepšie pracovné prostredie')}</p>
      <div className={styles.products}>
        <div>
          <ProductIcon code="BW" />
          {t('Wellness')}
        </div>
        <div>
          <ProductIcon code="BN" />
          {t('Darčeky')}
        </div>
        <div>
          <ProductIcon code="BS" />
          {t('Šport')}
        </div>
      </div>
    </div>
    <div className={styles.btnCont}>
      <RaisedButton
        priority="primary"
        size="large"
        type="submit"
        label={t('Zobraziť benefity')}
        internalHref={tRoute('/moje/benefity', t)}
      />
    </div>
  </Card>
);

BenefitsWidget.propTypes = {
  t: PropTypes.func.isRequired,
};
