import PropTypes from 'prop-types';
import React from 'react';
import styles from './ViewField.scss';

const ViewField = ({
  label, value, href, children, className, valueSize,
}) => {
  if ((value === null || value === undefined) && !children) {
    return null;
  }

  const valueSizeClassName = valueSize ? styles[`${valueSize}Value`] : '';

  return (
    <div className={`${styles.viewField} ${valueSizeClassName} ${className || ''}`}>
      <span>{label}</span>
      <br />
      {href ? <a href={href}>{value}</a> : value}
      {children}
    </div>
  );
};

ViewField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  href: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  valueSize: PropTypes.oneOf(['lg']),
};

export default ViewField;
