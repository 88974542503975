import Immutable from 'immutable';
import * as types from './actionTypes';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['partner', 'partner']);
  }

  if (type === types.FETCH_BANK_ACCOUNTS_REQUEST) {
    return state
      .setIn(['bankAccountsList', 'isFetching'], true)
      .setIn(['bankAccountsList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_BANK_ACCOUNTS_SUCCESS) {
    const bankAccounts = payload.map((bankAccount) => ({
      data: bankAccount,
    }));

    return state
      .setIn(['bankAccountsList', 'isFetching'], false)
      .setIn(['bankAccountsList', 'isFetchingFailure'], false)
      .setIn(['bankAccountsList', 'bankAccounts'], Immutable.fromJS(bankAccounts));
  }

  if (type === types.FETCH_BANK_ACCOUNTS_FAILURE) {
    return state
      .setIn(['bankAccountsList', 'isFetching'], false)
      .setIn(['bankAccountsList', 'isFetchingFailure'], true);
  }

  if (type === types.CREATE_BANK_ACCOUNT_SUCCESS) {
    const bankAccounts = state.getIn(['bankAccountsList', 'bankAccounts']).push({ data: payload });
    return state.setIn(['bankAccountsList', 'bankAccounts'], bankAccounts);
  }

  if (type === types.FETCH_DETAIL_REQUEST) {
    return state
      .setIn(['partnerDetail', 'isFetching'], true)
      .setIn(['partnerDetail', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_DETAIL_SUCCESS) {
    payload.c01_ico = `${payload.c01_ico}`;

    return state
      .setIn(['partnerDetail', 'isFetching'], false)
      .setIn(['partnerDetail', 'isFetchingFailure'], false)
      .setIn(['partnerDetail', 'data'], Immutable.fromJS(payload));
  }

  if (type === types.FETCH_DETAIL_FAILURE) {
    return state
      .setIn(['partnerDetail', 'isFetching'], false)
      .setIn(['partnerDetail', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_PARTNER_PAIRS_REQUEST) {
    return state
      .setIn(['partnerPairsList', 'isFetchingFailure'], false)
      .setIn(['partnerPairsList', 'isFetching'], true);
  }

  if (action.type === types.FETCH_PARTNER_PAIRS_SUCCESS) {
    const partnerPairs = action.payload.map((item) => ({
      label: item.c01_name,
      value: item.c01_company_id,
    }));

    return state
      .setIn(['partnerPairsList', 'partnerPairs'], Immutable.fromJS(partnerPairs))
      .setIn(['partnerPairsList', 'isFetching'], false);
  }

  if (action.type === types.FETCH_PARTNER_PAIRS_FAILURE) {
    return state
      .setIn(['partnerPairsList', 'isFetching'], false)
      .setIn(['partnerPairsList', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_PARTNER_REQUEST) {
    return state
      .setIn(['partnerDetail', 'isPostingFailure'], false)
      .setIn(['partnerDetail', 'isPosting'], true);
  }

  if (type === types.UPDATE_PARTNER_SUCCESS) {
    return state
      .setIn(['partnerDetail', 'isPosting'], false)
      .setIn(['partnerDetail', 'data'], Immutable.fromJS(payload));
  }

  if (type === types.UPDATE_PARTNER_FAILURE) {
    return state
      .setIn(['partnerDetail', 'isPostingFailure'], true)
      .setIn(['partnerDetail', 'isPosting'], false);
  }

  return state;
};


export default reducerFactory;
