import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../component1/Card';
import FlatButton from '../../../../component1/FlatButton/FlatButton';
import styles from './PartnerPayGateActivationWidget.scss';
import Icon from '../../../../component1/Icon/Icon';
import Dialog from '../../../../component1/Dialog/Dialog';
import Fieldset from '../../../../component1/Form/Fieldset';
import SelectField from '../../../../component1/SelectField/SelectField';


export class PartnerPayGateActivationWidget extends React.Component {
  state = {
    dialogShow: false,
    finished: false,
    c25_contract_address_id: '',
  };

  handleActivate = () => {
    this.setState({ dialogShow: true });
  }

  handleCancel = () => {
    if (this.state.finished) {
      this.props.updateWidget(); // reload widgets if something happened
    }

    this.setState({ dialogShow: false, finished: false, c25_contract_address_id: '' });
  }

  handleSelect = async () => {
    const ret = await this.props.widgetPartnerActivatePayGate(this.state.c25_contract_address_id);
    const ok = ret && !ret.error;
    this.setState({ finished: ok });
    return !ok; // close on error
  }

  handleClose = () => {
    this.handleCancel();
  }

  render() {
    const { t, data } = this.props;
    const { finished } = this.state;

    const actions = finished ? [
      {
        label: t('Zavřít'),
        primary: true,
        onClick: this.handleClose,
      },
    ] : [
      {
        label: t('Vybrat'),
        primary: true,
        onClick: this.handleSelect,
        disabled: !this.state.c25_contract_address_id,
      },
      {
        label: t('Zrušit'),
        onClick: this.handleCancel,
      },
    ];

    const activatedAddress = finished && data.affiliates.find((itm) => itm.c25_contract_address_id === this.state.c25_contract_address_id);

    return (
      <Card
        layout="centered"
        title={t('Platební brána')}
        actions={[
          <FlatButton
            key={4}
            label={t('Aktivovat')}
            onClick={this.handleActivate}
          />,
        ]}
      >
        <Dialog
          title={finished ? t('Registrace dokončena') : t('Vyberte provozovnu')}
          forceOpen={this.state.dialogShow}
          onHandleClose={this.handleClose}
          actions={actions}
          isNarrow
        >
          {!finished && (
            <>
              <p
                className={styles.gateWarning}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: t('Gusto platební brána je určena pro místa, kde není potřeba extrémně rychlé odbavené. Nehodí se proto pro restaurace, lékárny, bistra, obchody apod. Před první transakcí si projděte <a href="{{manualHref}}" target="_blank">manuál</a> platby a seznamte s ním také personál.', { manualHref: data.manual }) }}
              />
              <Fieldset>
                <p>{t('Místo, pro které chcete bránu používat:')}</p>
                <SelectField
                  label={t('Adresa provozovny')}
                  fieldId="c25_contract_address"
                  options={[{ label: t('Nezvoleno'), value: '' }].concat(data.affiliates.map((itm) => ({
                    label: itm.full_address,
                    value: itm.c25_contract_address_id,
                  })))}
                  onChange={(value) => this.setState({ c25_contract_address_id: value ? +value : value })}
                  value={this.state.c25_contract_address_id}
                />
              </Fieldset>
            </>
          )}
          {finished && (
          <p className={styles.text}>
            {t('Vytvořili jste si novou platební bránu pro provozovnu:')}
            <br />
            <br />
            {activatedAddress && activatedAddress.full_address}
          </p>
          )}
        </Dialog>
        <Icon icon="credit_card" size="xxlarge" />
        <p className={styles.text}>{t('Aktivujte si možnost akceptovat platby kartou\u00a0Up prostřednictvím portálu Gusto.')}</p>
        <p className={styles.text}>{t('Pro zaplacení potřebujete jen část čísla karty. Držitel karty odsouhlasí platbu a samotná platba je zrealizovaná.')}</p>
      </Card>
    );
  }
}

PartnerPayGateActivationWidget.propTypes = {
  t: PropTypes.func.isRequired,
  widgetPartnerActivatePayGate: PropTypes.func.isRequired,
  updateWidget: PropTypes.func.isRequired,
  data: PropTypes.shape({
    affiliates: PropTypes.arrayOf(PropTypes.shape({
      c25_contract_address_id: PropTypes.number.isRequired,
      full_address: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    manual: PropTypes.string,
    show: PropTypes.number.isRequired,
    w15_enabled: PropTypes.number.isRequired,
    widget: PropTypes.string.isRequired,
  }).isRequired,
};
