import download from 'downloadjs-next';

export function getFilenameFromResponse(res) {
  const cdHeader = res.headers.get('content-disposition');

  if (!cdHeader || !cdHeader.includes('filename=')) {
    return undefined;
  }

  const arr = /.*filename=(.+)/.exec(cdHeader);

  if (!arr || !arr[1]) {
    return undefined;
  }

  const filename = arr[1].replace(/"/g, ' ').trim();
  return filename;
}

export default (blob, fileName) => download(blob, fileName);

export const downloadFileAttachment = async (res) => download(await res.blob(), getFilenameFromResponse(res));

// export default (blob, fileName) => {
//   if (window.navigator.msSaveBlob) {
//     window.navigator.msSaveBlob(blob, fileName);
//     return;
//   }

//   const url = window.URL.createObjectURL(blob);
//   const a = document.createElement('a');
//   const event = new MouseEvent('click', {
//     view: window,
//     bubbles: true,
//     cancelable: true,
//   });

//   a.target = '_blank';
//   a.href = url;
//   a.download = fileName;
//   a.dispatchEvent(event);
//   window.URL.revokeObjectURL(url);
// };
