import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';


const getDelivery = (deliveryRaw) => ({
  data: {
    id: deliveryRaw.o10_delivery_way_id,
    o10_delivery_way_id: deliveryRaw.o10_delivery_way_id,
    o01_order_id: deliveryRaw.o01_order_id,
    c01_company_id: deliveryRaw.c01_company_id,
    c10_contract_id: deliveryRaw.c10_contract_id,
    o10_package_number: deliveryRaw.o10_package_number,
    full_address: deliveryRaw.full_address,
    o11_package_status: deliveryRaw.o11_package_status,
    o10_date_confirmation: deliveryRaw.o10_date_confirmation,
    o01_date_order: deliveryRaw.o01_date_order,
    o01_number: deliveryRaw.o01_number,
    has_code: !!deliveryRaw.has_code,
  },
  actions: {
    receive: !!deliveryRaw.can_take,
    activate: !!deliveryRaw.can_activate,
  },
});


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'delivery']);
  }

  if (type === types.UPDATE_DELIVERIES_FILTER_DATA) {
    return state
      .setIn(['deliveryList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['deliveryList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  if (type === types.FETCH_DELIVERIES_REQUEST) {
    return state
      .setIn(['deliveryList', 'isFetchingFailure'], false)
      .setIn(['deliveryList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['deliveryList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['deliveryList', 'fetchCount'], payload.limit)
      .setIn(['deliveryList', 'limit'], payload.limit)
      .setIn(['deliveryList', 'lastOffset'], payload.lastOffset)
      .setIn(['deliveryList', 'orderBy'], Immutable.fromJS(payload.orderBy))
      .setIn(['deliveryList', 'filter'], Immutable.fromJS(payload.filter));
  }

  if (type === types.FETCH_DELIVERIES_SUCCESS) {
    const deliveries = payload.map((deliveryRaw) => getDelivery(deliveryRaw));
    const deliveryList = state.get('deliveryList');
    const fetchCount = deliveryList.get('fetchCount');
    const lastOffset = deliveryList.get('lastOffset');
    const stateDeliveries = deliveryList.get('deliveries');
    const lastCount = deliveries.length;

    return state
      .setIn(['deliveryList', 'deliveries'], getEntitiesFromResponse(deliveries, stateDeliveries, lastOffset, fetchCount))
      .setIn(['deliveryList', 'lastCount'], lastCount)
      .setIn(['deliveryList', 'isFetching'], false)
      .setIn(['deliveryList', 'isLoadingMore'], false)
      .setIn(['deliveryList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_DELIVERIES_FAILURE) {
    return state
      .setIn(['deliveryList', 'isFetching'], false)
      .setIn(['deliveryList', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_DELIVERY) {
    return state.setIn(
      ['deliveryList', 'deliveries'],
      state.getIn(['deliveryList', 'deliveries']).map((delivery) => {
        if (delivery.getIn(['data', 'id']) === payload.o10_delivery_way_id) {
          return Immutable.fromJS(getDelivery(payload));
        }
        return delivery;
      })
    );
  }

  return state;
};


export default reducerFactory;
