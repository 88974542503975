import PropTypes from 'prop-types';
import React from 'react';
import styles from './Main.scss';


const Main = (props) => (
  <main className={`${styles.root} ${props.className || ''}`} onScroll={props.onScroll}>
    {props.children}
  </main>
);

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  onScroll: PropTypes.func,
  className: PropTypes.string,
};

export default Main;
