import Immutable from 'immutable';
import * as types from './actionTypes';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['partner', 'user']);
  }

  if (type === types.FETCH_USER_PAIRS_REQUEST) {
    return state
      .setIn(['userPairsList', 'isFetchingFailure'], false)
      .setIn(['userPairsList', 'isFetching'], true);
  }

  if (type === types.FETCH_USER_PAIRS_SUCCESS) {
    const users = payload.map((item) => ({
      label: item.fullname,
      value: item.c30_company_user_id,
    }));

    return state
      .setIn(['userPairsList', 'users'], Immutable.fromJS(users))
      .setIn(['userPairsList', 'isFetching'], false);
  }

  if (type === types.FETCH_USER_PAIRS_FAILURE) {
    return state
      .setIn(['userPairsList', 'isFetching'], false)
      .setIn(['userPairsList', 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
