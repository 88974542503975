
import { BenefitItemType } from '../../../global/model';
import { ActionWithPayload, ApiActions } from '../../../../data/storeModel';

export const FETCH_MY_BENEFITS_REQUEST = 'my/FETCH_MY_BENEFITS_REQUEST';
export const FETCH_MY_BENEFITS_SUCCESS = 'my/FETCH_MY_BENEFITS_SUCCESS';
export const FETCH_MY_BENEFITS_FAILURE = 'my/FETCH_MY_BENEFITS_FAILURE';

export const FETCH_MY_BENEFIT_ITEM_REQUEST = 'my/FETCH_MY_BENEFIT_ITEM_REQUEST';
export const FETCH_MY_BENEFIT_ITEM_SUCCESS = 'my/FETCH_MY_BENEFIT_ITEM_SUCCESS';
export const FETCH_MY_BENEFIT_ITEM_FAILURE = 'my/FETCH_MY_BENEFIT_ITEM_FAILURE';

export const UPDATE_BENEFITS_FILTER_DATA = 'my/UPDATE_BENEFITS_FILTER_DATA';

export type Actions =
  ActionWithPayload<typeof UPDATE_BENEFITS_FILTER_DATA> |
  ApiActions<typeof FETCH_MY_BENEFITS_REQUEST, typeof FETCH_MY_BENEFITS_SUCCESS, typeof FETCH_MY_BENEFITS_FAILURE, BenefitItemType[], any> |
  ApiActions<typeof FETCH_MY_BENEFIT_ITEM_REQUEST, typeof FETCH_MY_BENEFIT_ITEM_SUCCESS, typeof FETCH_MY_BENEFIT_ITEM_FAILURE, BenefitItemType, any>;
