import { lazy } from 'react';

const AffiliateDetailContainer = lazy(() => import(/* webpackChunkName: "partner" */ './affiliate/AffiliateDetailContainer'));
const AffiliateListContainer = lazy(() => import(/* webpackChunkName: "partner" */ './affiliate/AffiliateListContainer'));
const AffiliateInvoiceListContainer = lazy(() => import(/* webpackChunkName: "partner" */ './invoice/InvoiceListContainer'));
const AffiliateTransactionListContainer = lazy(() => import(/* webpackChunkName: "partner" */ './transaction/TransactionListContainer'));
const PartnerOverviewContainer = lazy(() => import(/* webpackChunkName: "partner" */ './partner/PartnerOverviewContainer'));
const PartnerUpdateContainer = lazy(() => import(/* webpackChunkName: "partner" */ './partner/PartnerUpdateContainer'));
const PaymentsListContainer = lazy(() => import(/* webpackChunkName: "partner" */ './gatePayment/PaymentsListContainer'));

export default {
  path: 'provozovny',
  childRoutes: [
    {
      name: 'affiliates',
      path: 'provozovny',
      key: 'P1',
      component: AffiliateListContainer,
    },
    {
      name: 'affiliate',
      path: 'provozovna/:affiliateId',
      key: 'P5',
      component: AffiliateDetailContainer,
    },
    {
      name: 'add_affiliate',
      path: 'nova-provozovna',
      key: 'P4',
      component: AffiliateDetailContainer,
    },
    {
      name: 'affiliate_invoices',
      path: 'faktury',
      key: 'P3',
      component: AffiliateInvoiceListContainer,
    },
    {
      name: 'affiliate_transactions',
      path: 'trzby',
      key: 'P2',
      component: AffiliateTransactionListContainer,
    },
    {
      name: 'affiliate_gate_payments',
      path: 'platby-branou',
      key: 'P2',
      component: PaymentsListContainer,
    },
    {
      name: 'affiliate_partner',
      path: 'partner(/:partnerId)',
      key: 'P8',
      component: PartnerOverviewContainer,
    },
    {
      name: 'edit_affiliate_partner',
      path: 'uprava-partnera/:partnerId',
      key: 'P9',
      component: PartnerUpdateContainer,
    },
  ],
};
