import * as types from './actionTypes';
import {
  ReducerFactory, IImmutableMap, IRootState,
} from '../../../../data/storeModel';

export type MySettlementState = IImmutableMap<IRootState['my']['settlement']>;
type Actions = types.Actions;

const reducerFactory: ReducerFactory<MySettlementState, Actions> = (initialState) => (state, action) => {
  if (typeof state === 'undefined') {
    return initialState.getIn(['my', 'settlement']);
  }

  if (action.type === types.SAVE_SETTLEMENT_REQUEST) {
    return state
      .set('isPosting', true);
  }

  if (action.type === types.SAVE_SETTLEMENT_SUCCESS) {
    return state
      .set('isPosting', false)
      .set('isSent', true);
  }

  if (action.type === types.SAVE_SETTLEMENT_FAILURE) {
    return state
      .set('isPosting', false);
  }

  if (action.type === types.GET_SETTLEMENT_REQUEST) {
    return state
      .set('isLoading', true);
  }

  if (action.type === types.GET_SETTLEMENT_SUCCESS) {
    return state
      .set('isLoading', false)
      .set('isChecked', true);
  }

  if (action.type === types.GET_SETTLEMENT_FAILURE) {
    return state
      .set('isLoading', false);
  }

  return state;
};


export default reducerFactory;
