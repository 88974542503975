import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { CreditCardNotLinked } from './CreditCardNotLinked';
import { CreditCardLinkTerms } from './CreditCardLinkTerms';
import Dialog from '../../../../../component1/Dialog/Dialog';
import { CreditCardLinkNew } from './CreditCardLinkNew';
import { CreditCardLinkDetail } from './CreditCardLinkDetail';
import { CreditCardLinkAnotherCards } from './CreditCardLinkAnotherCards';
import { CreditCardLinkPending } from './CreditCardLinkPending';
import { PropTypeCard } from '../../../../global/proptypes';
import Overlay from '../../../../../component1/Progress/Overlay/Overlay';
import { BANK_CARD_NOT_ASSIGNED, BANK_CARD_ASSIGN_PENDING, BANK_CARD_ASSIGNED_OK } from '../../../../../data/constants';
import { MOBILE_APP } from '../../../../../config/envspecific';

class BaseCreditCardLinkContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      conditionsConfirmed: !!props.data.bank_card_assigned,
      gopayRedirect: null,
      showAnotherCards: false,
      selectedCardId: this.props.p20_card_id || null,
    };

    if (this.props.offerAnotherCards && !props.readonly) {
      // this.state.showModal = true;
      this.state.showAnotherCards = true;
      this.state.selectedCardId = null;
    }
  }

  handleCloseModal = () => {
    this.setState({ showModal: false, showAnotherCards: false, conditionsConfirmed: !!this.props.data.bank_card_assigned });
    if (this.props.onModalClose) {
      this.props.onModalClose();
    }
  }

  handleMoreInfo = () => {
    this.setState({ showModal: true });
  }

  handleConditionConfirm = async (conditionsConfirmed) => {
    if (this.props.fetchCards) {
      await this.props.fetchCards();
    }
    this.setState({ conditionsConfirmed });
  }

  handleNewLink = async (linkData) => {
    const assignedOk = this.props.data.bank_card_assigned === BANK_CARD_ASSIGNED_OK;
    let redirectUrl = null;

    if (this.props.data.need_contract && this.state.conditionsConfirmed && this.props.contractConfirmation) {
      const conditionsRet = await this.props.contractConfirmation();
      if (conditionsRet.error) {
        this.handleCloseModal();
        return;
      }
    }

    if (!assignedOk) {
      const assignRet = await this.props.createBankCardAssign(this.state.conditionsConfirmed);
      if (assignRet.error) {
        this.handleCloseModal();
        return;
      }
      redirectUrl = assignRet.payload.redirect;
    }

    await this.props.saveCardAutoCredit(linkData.cardId, [linkData], assignedOk);

    if (!assignedOk && redirectUrl) {
      this.setState({ gopayRedirect: redirectUrl });
      window.location.href = redirectUrl;
      if (this.state.showModal && MOBILE_APP) {
        this.handleCloseModal();
      }
    }
  }

  handleCardAssign = async () => {
    const assignRet = await this.props.createBankCardAssign(this.state.conditionsConfirmed);
    const redirectUrl = assignRet.payload.redirect;

    if (redirectUrl) {
      this.setState({ gopayRedirect: redirectUrl });
      window.location.href = redirectUrl;
      if (this.state.showModal && MOBILE_APP) {
        this.handleCloseModal();
      }
    }
  }

  handleLinkSave = async (linkData, card) => {
    const allSettings = card.auto_credit.settings.map((p) => ({
      productCode: p.s20_code,
      ...linkData[p.s20_code],
    }));

    const ret = await this.props.saveCardAutoCredit(card.p20_card_id, allSettings, true);

    if (!ret.error) {
      this.setState({ showModal: false, selectedCardId: this.props.p20_card_id || null, showAnotherCards: !this.props.p20_card_id });
    }
  }

  handleSelectCard = (card) => {
    this.setState({ showAnotherCards: false, selectedCardId: card.p20_card_id });
  }

  handleBack = () => {
    this.setState({ showAnotherCards: true, selectedCardId: null });
  }

  renderContent() {
    const {
      t, cards, isFetching, data, readonly,
    } = this.props;
    const card = cards && cards.find((c) => c.p20_card_id === this.state.selectedCardId);

    if (isFetching) {
      return (<Overlay />);
    }

    if (this.state.gopayRedirect) {
      return (
        <>
          <CreditCardLinkPending gopayRedirect={this.state.gopayRedirect} t={t} readonly={readonly} />
          <Overlay show={!MOBILE_APP} />
        </>
      );
    }

    if (data.bank_card_assigned === BANK_CARD_ASSIGN_PENDING) {
      return (<CreditCardLinkPending gopayRedirect={data.redirect} t={t} readonly={readonly} />);
    }

    if (this.state.showAnotherCards) {
      return (<CreditCardLinkAnotherCards cards={cards} onClose={this.handleCloseModal} onSelect={this.handleSelectCard} t={t} />);
    }

    if (this.state.selectedCardId && !card) {
      return null;
    }

    if (!this.state.selectedCardId && data.bank_card_assigned === BANK_CARD_ASSIGNED_OK && !this.props.p20_card_id) {
      return (<div>&nbsp;</div>);
    }

    const isSet = data.bank_card_assigned === BANK_CARD_ASSIGNED_OK || readonly;
    return (isSet
      ? (
        <CreditCardLinkDetail
          card={card}
          onSave={this.handleLinkSave}
          onBack={this.props.offerAnotherCards && this.handleBack}
          showAssignedCard={!this.props.offerAnotherCards}
          readonly={readonly}
          t={t}
        />
      )
      : <CreditCardLinkNew cards={cards} onContinue={this.handleNewLink} showCard={!this.props.p20_card_id} t={t} />);
  }

  render() {
    const { t, data, readonly } = this.props;
    const { showModal, conditionsConfirmed } = this.state;

    const notConfirmed = data.bank_card_assigned === BANK_CARD_NOT_ASSIGNED && !conditionsConfirmed;
    const confirmModalShown = data.bank_card_assigned === BANK_CARD_NOT_ASSIGNED && showModal;
    const content = !notConfirmed && this.renderContent();

    return (
      <>
        {(notConfirmed || confirmModalShown) && <CreditCardNotLinked onClick={this.handleMoreInfo} t={t} readonly={readonly} />}
        {showModal ? (
          <Dialog
            onHandleClose={this.handleCloseModal}
            title={t('Připojit platební kartu')}
            isNarrow={!notConfirmed}
            hasCross
            forceOpen
          >
            {conditionsConfirmed
              ? content
              : (
                <CreditCardLinkTerms
                  gopayConditions={data.gopay_conditions}
                  needContract={data.need_contract}
                  onConfirm={this.handleConditionConfirm}
                  t={t}
                />
              )}
          </Dialog>
        ) : content}
      </>
    );
  }
}

export const PropTypeCreditCardLinkData = PropTypes.shape({
  gopay_conditions: PropTypes.string.isRequired,
  need_contract: PropTypes.number,
  bank_card_assigned: PropTypes.number.isRequired,
  redirect: PropTypes.string,
});

BaseCreditCardLinkContainer.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypeCreditCardLinkData.isRequired,
  cards: PropTypes.arrayOf(PropTypeCard).isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchCards: PropTypes.func,
  p20_card_id: PropTypes.number, // current card (if in card detail), falsy when in multi card mode
  offerAnotherCards: PropTypes.bool,
  readonly: PropTypes.bool,
  createBankCardAssign: PropTypes.func.isRequired,
  contractConfirmation: PropTypes.func,
  saveCardAutoCredit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func,
};

export const CreditCardLinkContainer = withTranslation('my')(BaseCreditCardLinkContainer);
