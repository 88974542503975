import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CardGridView from '../../component1/CardGridView/CardGridView';
import HelpWidget from './component/HelpWidget/HelpWidget';
import CardActivationWidget from './component/CardActivationWidget/CardActivationWidget';
import {
  fetchWidgets, widgetActivateCardFetch, setWidgetAndSave, widgetPartnerActivatePayGate, widgetPartnerSavePayGatePayment,
  widgetPartnerConfirmPayGatePayment, widgetMyPayGateConfirm, widgetPartnerFetchPayGatePayments, confirmContributionTerms,
  createContributionOrder, saveContributionSetPayment, saveContributionOnlineConfirm, downloadContributionInvoice,
  smsVerificationSetPhone, smsVerificationCheckCode, setPopupBannerShown,
} from './actions';
import { selectBrandCode, selectPaymentTypePairs } from '../global/selectors';
import MyCardWidget from './component/MyCardWidget/MyCardWidget';
import { selectWidgetsList } from './selectors';
import {
  PropTypeRouter, PropTypePayGateTransactions, PropTypePairsList, PropTypeCard,
} from '../global/proptypes';
import { selectAuthorizedPages } from '../auth';
import { tRoute } from '../../i18n';
import { isRouteAuthorized } from '../../router';
import { PartnerPayGateActivationWidget } from './component/PartnerPayGateActivationWidget/PartnerPayGateActivationWidget';
import { PartnerPayGatePaymentWidget } from './component/PartnerPayGatePaymentWidget/PartnerPayGatePaymentWidget';
import { PartnerPayGateOverviewWidget } from './component/PartnerPayGateOverviewWidget/PartnerPayGateOverviewWidget';
import { MyPayGateConfirmWidget } from './component/MyPayGateConfirmWidget/MyPayGateConfirmWidget';
import { ContributionOrderWidget } from './component/ContributionOrderWidget/ContributionOrderWidget';
import { ContributionTermsWidget } from './component/ContributionTermsWidget/ContributionTermsWidget';
import { fetchPaymentTypePairs } from '../global/actions';
import { ActiveAppUpdater } from '../../component1/ActiveAppUpdater/ActiveAppUpdater';
import { MyPlacesWidget } from './component/MyPlacesWidget/MyPlacesWidget';
import { OnlineShopsWidget } from './component/OnlineShopsWidget/OnlineShopsWidget';
import { PromoTemplateWidget } from './component/PromoTemplateWidget/PromoTemplateWidget';
import SmsVerificationWidget from './component/SmsVerificationWidget/SmsVerificationWidget';
import SmsVerificationConfirmWidget from './component/SmsVerificationConfirmWidget/SmsVerificationConfirmWidget';
import { CreditCardLinkWidget } from './component/CreditCardLinkWidget/CreditCardLinkWidget';
import { selectCards, fetchCards } from '../my/resources/card';
import { selectCardsFetching } from '../my/resources/card/selectors';
import { saveCardAutoCredit, createBankCardAssign, contractConfirmation } from '../my/resources/card/actions';
import ContributionOldEmissionWidget from './component/ContributionOldEmissionWidget/ContributionOldEmissionWidget';
import { PromoStackedWidget } from './component/PromoStackedWidget/PromoStackedWidget';
import { PromoFullWidget } from './component/PromoFullWidget/PromoFullWidget';
import { BenefiaOrderWidget } from './component/BenefiaOrderWidget/BenefiaOrderWidget';
import { saveBenefiaOrder } from '../benefia/actions';
import { NewsDialog } from './component/NewsDialog/NewsDialog';
import { NewsWidget } from './component/NewsWidget/NewsWidget';
import { BenefitsWidget } from './component/BenefitsWidget/BenefitsWidget';

class CardGridViewContainer extends Component {
  state = {
    isAutoUpdating: false,
    verificationReset: false,
    showNewsModal: false,
  };

  widgetsOrder = {
    ph10: 1,
    ph22: 1,
    ph30: 1,
    ph33: 1,
    ph20: 2,
    ph100: 2.3,
    ph50: 2.5,
    ph71: 2.6,
    ph40: 3,
    help: 4,
    ph72: 5,
    ph73: 5,
    ph41: 6,
    BW01: undefined,
    ph60: undefined,
    ph55: undefined,
    ph54: undefined,
    ph52: undefined,
    ph51: undefined,
    ph31: undefined,
    ph32: undefined,
  };

  constructor(props) {
    super(props);
    this.props.fetchWidgets();
  }

  handlePendingBalanceClick = () => {
    if (isRouteAuthorized('my_transactions', {}, this.props.authorizedPages)) {
      this.props.router.push(`${tRoute('/moje/transakce', this.props.t)}?t03_transaction_status_id=[1,2]`);
    }
  };

  handlePopupClose = () => {
    this.props.setPopupBannerShown(true);
  };

  handleShowNewsModal = () => {
    this.setState({ showNewsModal: true });
  };

  handleCloseNewsModal = () => {
    this.setState({ showNewsModal: false });
  };

  renderWidget(widget, allWidgets) {
    const { t } = this.props;
    const key = `${widget.widget}`;
    switch (widget.widget) {
      // case 'welcome':
      //   return (<WelcomeWidget key={key} isPromoted brandCode={this.props.brandCode} t={t} />);
      case 'help':
        return (<HelpWidget key={key} t={t} />);
      case 'ph10':
        return (
          <CardActivationWidget
            key={key}
            onCardActivate={this.props.activateCardFetch}
            isLoading={this.props.widgetsData.ph10.isLoading}
            t={t}
          />
        );
      case 'ph20':
        return (
          <MyCardWidget
            key={key}
            data={widget}
            cardEditAllowed={isRouteAuthorized('edit_my_card', { userId: widget.c30_company_user_id }, this.props.authorizedPages)}
            t={t}
            onPendingBalanceClick={this.handlePendingBalanceClick}
          />
        );
      case 'ph22':
        return (
          <CreditCardLinkWidget
            key={key}
            data={widget}
            cards={this.props.myCards}
            isFetching={this.props.myCardsFetching}
            fetchCards={this.props.fetchMyCards}
            createBankCardAssign={this.props.createBankCardAssign}
            saveCardAutoCredit={this.props.saveCardAutoCredit}
            contractConfirmation={this.props.contractConfirmation}
            t={t}
          />
        );
      case 'ph30':
        return (
          <PartnerPayGateActivationWidget
            key={key}
            t={t}
            data={widget}
            widgetPartnerActivatePayGate={this.props.widgetPartnerActivatePayGate}
            updateWidget={this.props.fetchWidgets}
          />
        );
      case 'ph31':
        return (
          <PartnerPayGatePaymentWidget
            key={key}
            t={t}
            data={widget}
            widgetPartnerSavePayGatePayment={this.props.widgetPartnerSavePayGatePayment}
            updateWidget={() => {
              this.props.fetchWidgets();
              this.props.widgetPartnerFetchPayGatePayments();
            }}
          />
        );
      case 'ph32':
        return (
          <PartnerPayGateOverviewWidget
            key={key}
            t={t}
            data={widget}
            payments={this.props.widgetsData.ph32.payments}
            isLoading={this.props.widgetsData.ph32.isLoading}
            affiliateId={this.props.widgetsData.ph32.affiliateId}
            widgetPartnerFetchPayGatePayments={this.props.widgetPartnerFetchPayGatePayments}
            widgetPartnerConfirmPayGatePayment={this.props.widgetPartnerConfirmPayGatePayment}
            updateWidget={() => {
              this.props.fetchWidgets();
            }}
            isPromoted
          />
        );
      case 'ph33':
        return widget.payments.map((payment) => (
          <MyPayGateConfirmWidget
            key={key}
            t={t}
            payment={payment}
            widgetMyPayGateConfirm={this.props.widgetMyPayGateConfirm}
            updateWidget={() => {
              this.props.fetchWidgets();
              if (allWidgets.some((w) => w.widget === 'ph32')) {
                this.props.widgetPartnerFetchPayGatePayments();
              }
            }}
          />
        ));
      case 'ph40':
        return widget.contributions.map((contr) => (contr.m30_terms_confirmed
          ? (
            <ContributionOrderWidget
              key={key}
              t={t}
              data={contr}
                // data={{ ...contr, m30_rest_amount: 500 }}
              paymentTypePairs={this.props.paymentTypePairs}
              isLoading={this.props.contributionWidgetLoading}
              onOrder={this.props.createContributionOrder}
              onSetPayment={this.props.saveContributionSetPayment}
              onOnlineConfirm={this.props.saveContributionOnlineConfirm}
              onInvoiceDownload={this.props.downloadContributionInvoice}
              fetchPaymentTypePairs={this.props.fetchPaymentTypePairs}
              updateWidget={() => {
                this.props.fetchWidgets();
              }}
            />
          )
          : (
            <ContributionTermsWidget
              key={key}
              t={t}
              data={contr}
              onConfirm={async (id, userId) => {
                await this.props.confirmContributionTerms(id, userId);
                this.props.fetchWidgets();
              }}
            />
          )
        ));
      case 'ph41':
        return (
          <ContributionOldEmissionWidget
            key={key}
            t={t}
            data={widget}
            onClose={this.props.hideOldEmissionWidget}
          />
        );
      case 'ph50':
        return <MyPlacesWidget key={key} t={t} />;
      case 'ph51':
        return <OnlineShopsWidget key={key} t={t} />;
      case 'ph52':
        // return (INSTANCE_SK ? [
        //   // <NewsWidget
        //   //   key="promo-gotal"
        //   //   data={{
        //   //     html_text: `<span style="color: white">${t('Nerušte svoj termín, ale zmeňte dátum, pripravujeme pre vás lepší Gothal')}</span>`,
        //   //     logo: '/images/logo-gothal.png',
        //   //     background: '/images/bg-gothal.jpg',
        //   //     href: t('https://gothal.sk/akciove-balicky/rodinny-pobyt-na-rekreacny-poukaz'),
        //   //   }}
        //   //   t={t}
        //   // />,
        // ] : []).concat();
        return widget.promo.map((d, i) => <PromoTemplateWidget key={i} data={d} t={t} />);
      case 'ph54':
        return (
          <PromoStackedWidget
            key="promo-club-up"
            t={t}
            data={{
              title: 'Využite stovky výhod',
              top_text: t('Ako používateľ karty\u00a0Up, ktorú prevádzkuje Up Slovensko, s. r. o., máte prístup do Klubu Up, ktorý Vám prináša extra finančné výhody.'),
              top_more_content: t('promoClubUpMoreContent'),
              background: '/images/bg-club-up.jpg',
            }}
          />
        );
      case 'ph55':
        return (
          <PromoFullWidget
            key="promo-tabory-2021"
            data={{
              html_text: `<span style="color: white">${t('Denné a pobytové tábory 2021 na Slovensku')}</span>`,
              background: '/images/bg-tabory-sk.jpg',
              href: t('https://www.gustokarta.sk/sk/tabory'),
            }}
            t={t}
          />
        );
      case 'ph60':
        return !widget.pending || this.state.verificationReset ? (
          <SmsVerificationWidget
            key={key}
            instructionUrl={widget.instruction}
            onBack={this.state.verificationReset ? (() => this.setState({ verificationReset: false })) : null}
            onVerify={async (phone) => {
              const ret = await this.props.smsVerificationSetPhone(phone);
              this.setState({ verificationReset: false });
              if (!ret.error) {
                this.props.fetchWidgets();
              }
            }}
            t={t}
          />
        ) : (
          <SmsVerificationConfirmWidget
            key={key}
            phone={widget.pending}
            onConfirm={async (pin) => {
              const ret = await this.props.smsVerificationCheckCode(pin);
              if (!ret.error) {
                this.props.fetchWidgets();
              }
            }}
            onReset={() => this.setState({ verificationReset: true })}
            t={t}
          />
        );
      case 'ph71':
        return (
          <Fragment key={key}>
            <PromoFullWidget
              key="ph71"
              data={{
                title: t('Vitajte v UpBenefia!'),
                background: '/images/bg-news.jpg',
              }}
              onClick={this.handleShowNewsModal}
              t={t}
            />
            {this.state.showNewsModal && <NewsDialog onClose={this.handleCloseNewsModal} t={t} />}
          </Fragment>
        );
      case 'ph72':
        return (
          <PromoFullWidget
            key="ph72"
            data={{
              title: t('Interné benefity'),
              button: t('Zobraziť benefity (PDF)'),
              html_text: t('Benefity pre lepšie pracovné prostredie'),
              background: '/images/bg-benefits.jpg',
              href: widget.link,
              whiteText: true,
            }}
            t={t}
          />
        );
      case 'ph73':
        return (
          <BenefitsWidget
            key="ph73"
            t={t}
          />
        );
      case 'ph100':
        return widget.news?.length ? (<NewsWidget key={widget.widget} news={widget.news} t={t} />) : null;
      case 'BW01':
        return <BenefiaOrderWidget key="BW01" companyUserId={widget.c30_company_user_id} saveBenefiaOrder={this.props.saveBenefiaOrder} t={t} />;
      default:
        return null;
    }

    // if (widget.widget === 'firm_first_order') {
    //   return (<FirmFirstOrderWidget key={i} />);
    // } else if (widget.type === 'firm_delivery') {
    //   return (<FirmDeliveryWidget key={i} />);
    // } else if (widget.type === 'my_card') {
    //   return (<MyCardWidget key={i} balance={widget.props.balance} />);
    // } else if (widget.type === 'my_affiliates') {
    //   return (<MyAffiliatesWidget key={i} />);
    // }
  }

  sortWidgets(widgets) {
    // const contribIdx = widgets.findIndex((w) => w.widget === 'ph40');
    // const helpIdx = contribIdx !== -1 ? contribIdx + 1 : 4; // #297
    // const localWidgets = INSTANCE_SK ? widgets.slice(0, helpIdx)
    //   .concat([helpWidget], widgets.slice(helpIdx)) : widgets;
    return widgets.map((w, idx) => ({ ...w, order: w.order || (this.widgetsOrder[w.widget] === undefined ? idx : this.widgetsOrder[w.widget]) })).sort((a, b) => a.order - b.order);
  }

  render() {
    const {
      widgets,
    } = this.props;

    // const showPopup = INSTANCE_CZ && !popupShown; // && isRouteAuthorized('my_cards', { }, this.props.authorizedPages);
    // const promoWidget = widgets.find((w) => w.widget === 'ph52');
    // const hasCustomHelpWidget = promoWidget && promoWidget.promo.some((p) => p.template === 'how-to');
    const helpWidget = {
      widget: 'help',
      show: true,
      enabled: true,
    };

    const localWidgets = this.sortWidgets(widgets.concat(helpWidget));

    return (
      <>
        <CardGridView isFetching={this.props.isFetching && !this.state.isAutoUpdating} transitionEnabled>
          {localWidgets.filter((w) => w.show && w.enabled)
            .map((widget) => this.renderWidget(widget, localWidgets))}
          <ActiveAppUpdater onUpdate={async () => {
            this.setState({ isAutoUpdating: true });
            await this.props.fetchWidgets();
            this.setState({ isAutoUpdating: false });
          }}
          />
        </CardGridView>
      </>
    );
    // {showPopup && <PopupBanner show onClose={this.handlePopupClose} t={t} content={} />}
  }
}

CardGridViewContainer.propTypes = {
  authorizedPages: PropTypes.arrayOf(PropTypes.string).isRequired,
  router: PropTypeRouter,
  fetchWidgets: PropTypes.func.isRequired,
  activateCardFetch: PropTypes.func.isRequired,
  hideOldEmissionWidget: PropTypes.func.isRequired,
  widgetPartnerActivatePayGate: PropTypes.func.isRequired,
  widgetPartnerSavePayGatePayment: PropTypes.func.isRequired,
  widgetPartnerConfirmPayGatePayment: PropTypes.func.isRequired,
  widgetPartnerFetchPayGatePayments: PropTypes.func.isRequired,
  widgetMyPayGateConfirm: PropTypes.func.isRequired,
  createContributionOrder: PropTypes.func.isRequired,
  confirmContributionTerms: PropTypes.func.isRequired,
  saveContributionSetPayment: PropTypes.func.isRequired,
  saveContributionOnlineConfirm: PropTypes.func.isRequired,
  downloadContributionInvoice: PropTypes.func.isRequired,
  fetchPaymentTypePairs: PropTypes.func.isRequired,
  smsVerificationSetPhone: PropTypes.func.isRequired,
  smsVerificationCheckCode: PropTypes.func.isRequired,
  widgets: PropTypes.arrayOf(PropTypes.shape({
    widget: PropTypes.string.isRequired,
    props: PropTypes.object,
  })).isRequired,
  widgetsData: PropTypes.shape({
    ph10: PropTypes.shape({
      isLoading: PropTypes.bool,
    }),
    ph32: PropTypes.shape({
      isLoading: PropTypes.bool,
      affiliateId: PropTypes.number,
      payments: PropTypePayGateTransactions,
    }),
  }),
  paymentTypePairs: PropTypePairsList,
  contributionWidgetLoading: PropTypes.bool,
  fetchMyCards: PropTypes.func.isRequired,
  myCardsFetching: PropTypes.bool.isRequired,
  myCards: PropTypes.arrayOf(PropTypeCard),
  createBankCardAssign: PropTypes.func.isRequired,
  contractConfirmation: PropTypes.func.isRequired,
  saveCardAutoCredit: PropTypes.func.isRequired,
  setPopupBannerShown: PropTypes.func.isRequired,
  saveBenefiaOrder: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  // popupShown: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => {
  const home = state.get('home')
    .toJS();
  const dashWidgets = home.widgets;
  return ({
    authorizedPages: selectAuthorizedPages(state),
    widgets: selectWidgetsList(state),
    widgetsData: dashWidgets.widgetsData,
    isFetching: dashWidgets.isFetching,
    brandCode: selectBrandCode(state),
    paymentTypePairs: selectPaymentTypePairs(state),
    contributionWidgetLoading: dashWidgets.widgetsData.ph40.isLoading,
    myCards: selectCards(state),
    myCardsFetching: selectCardsFetching(state),
    // popupShown: home.popupShown,
  });
};

const mapDispatchToProps = (dispatch) => ({
  fetchWidgets: () => dispatch(fetchWidgets()),
  fetchPaymentTypePairs: () => dispatch(fetchPaymentTypePairs()),
  fetchMyCards: () => dispatch(fetchCards()),
  saveCardAutoCredit: (...args) => dispatch(saveCardAutoCredit(...args)),
  createBankCardAssign: (...args) => dispatch(createBankCardAssign(...args)),
  contractConfirmation: () => dispatch(contractConfirmation()),
  activateCardFetch: (cardNo) => dispatch(widgetActivateCardFetch(cardNo)),
  hideOldEmissionWidget: () => dispatch(setWidgetAndSave('ph41', false, false)),
  widgetPartnerActivatePayGate: (addressId) => dispatch(widgetPartnerActivatePayGate(addressId)),
  widgetPartnerSavePayGatePayment: (data) => dispatch(widgetPartnerSavePayGatePayment(data)),
  widgetPartnerConfirmPayGatePayment: (transactionId, confirmed, pin) => dispatch(widgetPartnerConfirmPayGatePayment(transactionId, confirmed, pin)),
  widgetMyPayGateConfirm: (data) => dispatch(widgetMyPayGateConfirm(data)),
  widgetPartnerFetchPayGatePayments: (affiliateId) => dispatch(widgetPartnerFetchPayGatePayments(affiliateId)),
  confirmContributionTerms: (contributionId, userId) => dispatch(confirmContributionTerms(contributionId, userId)),
  createContributionOrder: (contributionId, userId, amount, emissionTransfer) => dispatch(createContributionOrder(contributionId, userId, amount, emissionTransfer)),
  saveContributionSetPayment: (contributionId, userId, paymentTypeId) => dispatch(saveContributionSetPayment(contributionId, userId, paymentTypeId)),
  saveContributionOnlineConfirm: (goPayId) => dispatch(saveContributionOnlineConfirm(goPayId)),
  downloadContributionInvoice: (invoiceId, number) => dispatch(downloadContributionInvoice(invoiceId, number)),
  smsVerificationSetPhone: (phone) => dispatch(smsVerificationSetPhone(phone)),
  smsVerificationCheckCode: (code) => dispatch(smsVerificationCheckCode(code)),
  setPopupBannerShown: (show) => dispatch(setPopupBannerShown(show)),
  saveBenefiaOrder: (data) => dispatch(saveBenefiaOrder(data)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('home')(CardGridViewContainer));
