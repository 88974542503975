export const SAVE_USER_LANGUAGE_REQUEST = 'user/settings/SAVE_USER_LANGUAGE_REQUEST';
export const SAVE_USER_LANGUAGE_SUCCESS = 'user/settings/SAVE_USER_LANGUAGE_SUCCESS';
export const SAVE_USER_LANGUAGE_FAILURE = 'user/settings/SAVE_USER_LANGUAGE_FAILURE';

export const FETCH_USER_COMMUNICATION_REQUEST = 'user/settings/FETCH_USER_COMMUNICATION_REQUEST';
export const FETCH_USER_COMMUNICATION_SUCCESS = 'user/settings/FETCH_USER_COMMUNICATION_SUCCESS';
export const FETCH_USER_COMMUNICATION_FAILURE = 'user/settings/FETCH_USER_COMMUNICATION_FAILURE';

export const SAVE_USER_COMMUNICATION_REQUEST = 'user/settings/SAVE_USER_COMMUNICATION_REQUEST';
export const SAVE_USER_COMMUNICATION_SUCCESS = 'user/settings/SAVE_USER_COMMUNICATION_SUCCESS';
export const SAVE_USER_COMMUNICATION_FAILURE = 'user/settings/SAVE_USER_COMMUNICATION_FAILURE';
