import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import RaisedButton from '../../../../../component1/RaisedButton/RaisedButton';
import styles from './CreditCardLink.scss';
import CheckboxField from '../../../../../component1/CheckboxField/CheckboxField';

export const CreditCardLinkContractTerms = ({
  t, onConfirm,
}) => {
  const [conditionsConfirmed, setConditionsConfirmed] = useState(false);
  return (
    <div className={`${styles.container}`}>
      <div className={styles.paragraph}>
        <span>
          <Trans i18nKey="creditCardLinkReadContractConditions" t={t}>
          Abyste mohli dále pokračovat, je potřeba odsouhlasit
            {' '}
            <a href={t('//vsp-uzivatel.gustokarta.cz')} target="_blank" rel="noreferrer noopener">Všeobecné smluvní podmínky.</a>
          </Trans>
        </span>
        <CheckboxField
          fieldId="confirm_terms"
          label={t('Souhlasím s podmínkami')}
          checked={conditionsConfirmed}
          onClick={() => setConditionsConfirmed(!conditionsConfirmed)}
        />
      </div>
      <div className={styles.buttons}>
        <RaisedButton label={t('Pokračovat')} onClick={() => onConfirm(conditionsConfirmed)} disabled={!conditionsConfirmed} priority="primary" />
      </div>
    </div>
  );
};

CreditCardLinkContractTerms.propTypes = {
  t: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
