export const ACTIVATE_CARD_REQUEST = 'my/card/ACTIVATE_CARD_REQUEST';
export const ACTIVATE_CARD_SUCCESS = 'my/card/ACTIVATE_CARD_SUCCESS';
export const ACTIVATE_CARD_FAILURE = 'my/card/ACTIVATE_CARD_FAILURE';

export const BLOCK_CARD_REQUEST = 'my/user/BLOCK_CARD_REQUEST';
export const BLOCK_CARD_SUCCESS = 'my/user/BLOCK_CARD_SUCCESS';
export const BLOCK_CARD_FAILURE = 'my/user/BLOCK_CARD_FAILURE';

export const RENEW_CARD_REQUEST = 'my/user/RENEW_CARD_REQUEST';
export const RENEW_CARD_SUCCESS = 'my/user/RENEW_CARD_SUCCESS';
export const RENEW_CARD_FAILURE = 'my/user/RENEW_CARD_FAILURE';

export const FETCH_BASKET_REQUEST = 'my/card/FETCH_BASKET_REQUEST';
export const FETCH_BASKET_SUCCESS = 'my/card/FETCH_BASKET_SUCCESS';
export const FETCH_BASKET_FAILURE = 'my/card/FETCH_BASKET_FAILURE';

export const CONFIRM_BASKET_REQUEST = 'my/card/CONFIRM_BASKET_REQUEST';
export const CONFIRM_BASKET_SUCCESS = 'my/card/CONFIRM_BASKET_SUCCESS';
export const CONFIRM_BASKET_FAILURE = 'my/card/CONFIRM_BASKET_FAILURE';

export const FETCH_CREDITS_REQUEST = 'my/card/FETCH_CREDITS_REQUEST';
export const FETCH_CREDITS_SUCCESS = 'my/card/FETCH_CREDITS_SUCCESS';
export const FETCH_CREDITS_FAILURE = 'my/card/FETCH_CREDITS_FAILURE';

export const CONFIRM_CREDITS_REQUEST = 'my/card/CONFIRM_CREDITS_REQUEST';
export const CONFIRM_CREDITS_SUCCESS = 'my/card/CONFIRM_CREDITS_SUCCESS';
export const CONFIRM_CREDITS_FAILURE = 'my/card/CONFIRM_CREDITS_FAILURE';

export const FETCH_BASKET_OVERVIEW_REQUEST = 'my/card/FETCH_BASKET_OVERVIEW_REQUEST';
export const FETCH_BASKET_OVERVIEW_SUCCESS = 'my/card/FETCH_BASKET_OVERVIEW_SUCCESS';
export const FETCH_BASKET_OVERVIEW_FAILURE = 'my/card/FETCH_BASKET_OVERVIEW_FAILURE';

export const UPDATE_BASKET_PARAMETERS_REQUEST = 'my/card/UPDATE_BASKET_PARAMETERS_REQUEST';
export const UPDATE_BASKET_PARAMETERS_SUCCESS = 'my/card/UPDATE_BASKET_PARAMETERS_SUCCESS';
export const UPDATE_BASKET_PARAMETERS_FAILURE = 'my/card/UPDATE_BASKET_PARAMETERS_FAILURE';

export const SAVE_BASKET_ADDRESS_REQUEST = 'my/card/SAVE_BASKET_ADDRESS_REQUEST';
export const SAVE_BASKET_ADDRESS_SUCCESS = 'my/card/SAVE_BASKET_ADDRESS_SUCCESS';
export const SAVE_BASKET_ADDRESS_FAILURE = 'my/card/SAVE_BASKET_ADDRESS_FAILURE';

export const CONFIRM_ORDER_REQUEST = 'my/card/CONFIRM_ORDER_REQUEST';
export const CONFIRM_ORDER_SUCCESS = 'my/card/CONFIRM_ORDER_SUCCESS';
export const CONFIRM_ORDER_FAILURE = 'my/card/CONFIRM_ORDER_FAILURE';

export const DELETE_ORDER_REQUEST = 'my/card/DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'my/card/DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'my/card/DELETE_ORDER_FAILURE';

export const FETCH_CARDS_REQUEST = 'my/card/FETCH_CARDS_REQUEST';
export const FETCH_CARDS_SUCCESS = 'my/card/FETCH_CARDS_SUCCESS';
export const FETCH_CARDS_FAILURE = 'my/card/FETCH_CARDS_FAILURE';

export const CONFIRM_CONTRACT_REQUEST = 'my/card/CONFIRM_CONTRACT_REQUEST';
export const CONFIRM_CONTRACT_SUCCESS = 'my/card/CONFIRM_CONTRACT_SUCCESS';
export const CONFIRM_CONTRACT_FAILURE = 'my/card/CONFIRM_CONTRACT_FAILURE';

export const SAVE_CARD_LIMIT_REQUEST = 'my/card/SAVE_CARD_LIMIT_REQUEST';
export const SAVE_CARD_LIMIT_SUCCESS = 'my/card/SAVE_CARD_LIMIT_SUCCESS';
export const SAVE_CARD_LIMIT_FAILURE = 'my/card/SAVE_CARD_LIMIT_FAILURE';

export const CHECK_CREDIT_TRANSFER_REQUEST = 'my/card/CHECK_CREDIT_TRANSFER_REQUEST';
export const CHECK_CREDIT_TRANSFER_SUCCESS = 'my/card/CHECK_CREDIT_TRANSFER_SUCCESS';
export const CHECK_CREDIT_TRANSFER_FAILURE = 'my/card/CHECK_CREDIT_TRANSFER_FAILURE';

export const SAVE_CREDIT_TRANSFER_REQUEST = 'my/card/SAVE_CREDIT_TRANSFER_REQUEST';
export const SAVE_CREDIT_TRANSFER_SUCCESS = 'my/card/SAVE_CREDIT_TRANSFER_SUCCESS';
export const SAVE_CREDIT_TRANSFER_FAILURE = 'my/card/SAVE_CREDIT_TRANSFER_FAILURE';

export const SAVE_CARD_AUTO_CREDIT_REQUEST = 'my/card/SAVE_CARD_AUTO_CREDIT_REQUEST';
export const SAVE_CARD_AUTO_CREDIT_SUCCESS = 'my/card/SAVE_CARD_AUTO_CREDIT_SUCCESS';
export const SAVE_CARD_AUTO_CREDIT_FAILURE = 'my/card/SAVE_CARD_AUTO_CREDIT_FAILURE';

export const BANK_CARD_ASSIGN_REQUEST = 'my/card/BANK_CARD_ASSIGN_REQUEST';
export const BANK_CARD_ASSIGN_SUCCESS = 'my/card/BANK_CARD_ASSIGN_SUCCESS';
export const BANK_CARD_ASSIGN_FAILURE = 'my/card/BANK_CARD_ASSIGN_FAILURE';

export const FETCH_ASSIGNED_CARDS_REQUEST = 'my/card/FETCH_ASSIGNED_CARDS_REQUEST';
export const FETCH_ASSIGNED_CARDS_SUCCESS = 'my/card/FETCH_ASSIGNED_CARDS_SUCCESS';
export const FETCH_ASSIGNED_CARDS_FAILURE = 'my/card/FETCH_ASSIGNED_CARDS_FAILURE';

export const CARD_ASSIGN_ONLINE_CONFIRM_REQUEST = 'my/card/CARD_ASSIGN_ONLINE_CONFIRM_REQUEST';
export const CARD_ASSIGN_ONLINE_CONFIRM_SUCCESS = 'my/card/CARD_ASSIGN_ONLINE_CONFIRM_SUCCESS';
export const CARD_ASSIGN_ONLINE_CONFIRM_FAILURE = 'my/card/CARD_ASSIGN_ONLINE_CONFIRM_FAILURE';

export const FETCH_CARD_PIN_REQUEST = 'my/card/FETCH_CARD_PIN_REQUEST';
export const FETCH_CARD_PIN_SUCCESS = 'my/card/FETCH_CARD_PIN_SUCCESS';
export const FETCH_CARD_PIN_FAILURE = 'my/card/FETCH_CARD_PIN_FAILURE';

export const REQUEST_UPDATE_CARD_PIN_REQUEST = 'my/card/REQUEST_UPDATE_CARD_PIN_REQUEST';
export const REQUEST_UPDATE_CARD_PIN_SUCCESS = 'my/card/REQUEST_UPDATE_CARD_PIN_SUCCESS';
export const REQUEST_UPDATE_CARD_PIN_FAILURE = 'my/card/REQUEST_UPDATE_CARD_PIN_FAILURE';

export const SAVE_UPDATE_CARD_PIN_REQUEST = 'my/card/SAVE_UPDATE_CARD_PIN_REQUEST';
export const SAVE_UPDATE_CARD_PIN_SUCCESS = 'my/card/SAVE_UPDATE_CARD_PIN_SUCCESS';
export const SAVE_UPDATE_CARD_PIN_FAILURE = 'my/card/SAVE_UPDATE_CARD_PIN_FAILURE';


export const UNSET_CREDITS = 'my/card/UNSET_CREDITS';
export const SET_OPEN_ADDCARD = 'my/card/SET_OPEN_ADDCARD';
