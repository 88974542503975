export const DOWNLOAD_INVOICES_PDF_REQUEST = 'my/order/DOWNLOAD_INVOICES_PDF_REQUEST';
export const DOWNLOAD_INVOICES_PDF_SUCCESS = 'my/order/DOWNLOAD_INVOICES_PDF_SUCCESS';
export const DOWNLOAD_INVOICES_PDF_FAILURE = 'my/order/DOWNLOAD_INVOICES_PDF_FAILURE';

export const FETCH_ORDER_REQUEST = 'my/order/FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'my/order/FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'my/order/FETCH_ORDER_FAILURE';

export const FETCH_ORDERS_REQUEST = 'my/order/FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'my/order/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'my/order/FETCH_ORDERS_FAILURE';

export const FETCH_PRODUCTS_REQUEST = 'my/order/FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'my/order/FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'my/order/FETCH_PRODUCTS_FAILURE';

export const ONLINE_CONFIRM_REQUEST = 'my/order/ONLINE_CONFIRM_REQUEST';
export const ONLINE_CONFIRM_SUCCESS = 'my/order/ONLINE_CONFIRM_SUCCESS';
export const ONLINE_CONFIRM_FAILURE = 'my/order/ONLINE_CONFIRM_FAILURE';

export const ONLINE_PAY_REQUEST = 'my/order/ONLINE_PAY_REQUEST';
export const ONLINE_PAY_SUCCESS = 'my/order/ONLINE_PAY_SUCCESS';
export const ONLINE_PAY_FAILURE = 'my/order/ONLINE_PAY_FAILURE';

export const UPDATE_ORDERS_FILTER_DATA = 'my/order/UPDATE_ORDERS_FILTER_DATA';

export const SET_MY_ORDER_IN_PROGRESS = 'my/order/SET_MY_ORDER_IN_PROGRESS';
