import { createSelector } from 'reselect';

const getTransactionList = (state) => state.getIn(['my', 'transaction', 'transactionList']);


export const selectTransactionListMeta = createSelector(
  [getTransactionList],
  (transactionList) => ({
    filter: transactionList.get('filter').toJS(),
    orderBy: transactionList.get('orderBy').toJS(),
    limit: transactionList.get('limit'),
    lastOffset: transactionList.get('lastOffset'),
    lastCount: transactionList.get('lastCount'),
    fetchCount: transactionList.get('fetchCount'),
    isFetching: transactionList.get('isFetching'),
    isLoadingMore: transactionList.get('isLoadingMore'),
    offset: transactionList.get('offset'),
  })
);

export const selectTransactions = createSelector(
  [getTransactionList],
  (transactionList) => transactionList.get('transactions').toJS()
);

export const selectHasMultipleCardsTransactions = createSelector(
  [selectTransactions],
  (list) => list.length > 0 && list.some((i) => i.data.c30_company_user_id !== list[0].data.c30_company_user_id)
);
