import Immutable from 'immutable';
import * as types from './actionTypes';


export const homeWidgetsReducerFactory = (initialState) => (state, action) => {
  if (typeof state === 'undefined') {
    return initialState.getIn(['home', 'widgets']);
  }

  if (action.type === types.FETCH_WIDGETS_REQUEST) {
    return state.setIn(['isFetching'], true);
  }

  if (action.type === types.FETCH_WIDGETS_SUCCESS) {
    return state
      .setIn(['widgets'], Immutable.fromJS(action.payload.map((w) => ({ ...w, enabled: w.w15_enabled === undefined ? true : !!w.w15_enabled }))))
      .setIn(['isFetching'], false);
  }

  if (action.type === types.FETCH_WIDGET_PH10_REQUEST) {
    return state.setIn(['widgetsData', 'ph10', 'isLoading'], true);
  }

  if (action.type === types.FETCH_WIDGET_PH10_SUCCESS) {
    return state
      .updateIn(['widgets'], (widgets) => widgets.filter((w) => w.get('widget') !== 'ph10'))
      .setIn(['widgetsData', 'ph10', 'isLoading'], false);
  }

  if (action.type === types.FETCH_WIDGET_PH10_FAILURE) {
    return state.setIn(['widgetsData', 'ph10', 'isLoading'], false);
  }

  if (action.type === types.SET_WIDGET) {
    const idx = state.getIn(['widgets']).findIndex((w) => w.get('widget') === action.payload.widget);
    if (idx === -1) {
      return state;
    }
    return state.setIn(['widgets', idx, 'enabled'], action.payload.enabled);
  }

  if (action.type === types.SET_WIDGET_PH32_AFFILIATE_ID) {
    return state.setIn(['widgetsData', 'ph32', 'affiliateId'], action.payload.affiliateId);
  }

  if (action.type === types.FETCH_WIDGET_PH32_PAYMENTS_REQUEST) {
    return state.setIn(['widgetsData', 'ph32', 'isLoading'], true);
  }

  if (action.type === types.FETCH_WIDGET_PH32_PAYMENTS_SUCCESS) {
    return state
      .setIn(['widgetsData', 'ph32', 'isLoading'], false)
      .setIn(['widgetsData', 'ph32', 'payments'], action.payload);
  }

  if (action.type === types.FETCH_WIDGET_PH32_PAYMENTS_FAILURE) {
    return state.setIn(['widgetsData', 'ph32', 'isLoading'], false);
  }

  if ([types.SAVE_WIDGET_PH40_CREATE_ORDER_REQUEST, types.SAVE_WIDGET_PH40_SET_PAYMENT_REQUEST].includes(action.type)) {
    return state.setIn(['widgetsData', 'ph40', 'isLoading'], true);
  }

  if (// action.type === types.SAVE_WIDGET_PH40_CONFIRM_TERMS_SUCCESS ||
    action.type === types.SAVE_WIDGET_PH40_CREATE_ORDER_SUCCESS
      || action.type === types.SAVE_WIDGET_PH40_SET_PAYMENT_SUCCESS) {
    const contribution = action.payload.contribution;

    const newState = state.setIn(['widgetsData', 'ph40', 'isLoading'], false);

    if (!contribution) {
      return newState; // remove contribution completely or reload all widgets => components responsibility
    }
    const widgetIdx = newState.getIn(['widgets']).findIndex((w) => w.get('widget') === 'ph40');

    if (widgetIdx === -1) {
      throw new Error();
    }
    const contributionIdx = newState.getIn(['widgets', widgetIdx, 'contributions']).findIndex((c) => c.get('m01_contribution_id') === contribution.m01_contribution_id);
    if (contributionIdx === -1) {
      throw new Error();
    }
    return newState
      .setIn(['widgets', widgetIdx, 'contributions', contributionIdx], Immutable.fromJS(contribution));
  }

  if ([types.SAVE_WIDGET_PH40_CREATE_ORDER_FAILURE, types.SAVE_WIDGET_PH40_SET_PAYMENT_FAILURE].includes(action.type)) {
    return state.setIn(['widgetsData', 'ph40', 'isLoading'], false);
  }

  if (action.type === types.SET_POPUP_BANNER_SHOWN) {
    return state
      .setIn(['popupShown'], action.payload.shown);
  }

  return state;
};
