import PropTypes from 'prop-types';
import React from 'react';
import styles from './Section.scss';

const Section = (props) => (
  <div className={props.hasTabs ? styles.hasRootTabs : styles.root} style={props.style}>
    {props.children}
  </div>
);

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  hasTabs: PropTypes.bool,
  style: PropTypes.shape(),
};

export default Section;
