import { MOBILE_APP } from '../config/envspecific';

const apiError502HandlerMiddleware = () => (next) => (action) => {
  if (typeof action.payload !== 'undefined' && action.payload.status === 502) {
    if (!MOBILE_APP) {
      window.location.reload();
    }

    return false;
  }

  return next(action);
};

export default apiError502HandlerMiddleware;
