export const SELECT_CONTRACT = 'order/SELECT_CONTRACT';
export const DELETE_CONTRACT = 'order/DELETE_CONTRACT';

export const ADD_NAME_BEING_VALIDATED = 'order/ADD_NAME_BEING_VALIDATED';
export const REMOVE_NAME_BEING_VALIDATED = 'order/REMOVE_NAME_BEING_VALIDATED';

export const SET_INITIALIZATION_ERRORS = 'order/SET_INITIALIZATION_ERRORS';
export const SET_ORDER_FILE_ID = 'order/SET_ORDER_FILE_ID';
export const DELETE_ORDER_FILE_ID = 'order/DELETE_ORDER_FILE_ID';
export const SET_VALIDATION_NAMES_ERRORS = 'order/SET_VALIDATION_NAMES_ERRORS';
export const SET_VALIDATION_ADDRESSES_ERRORS = 'order/SET_VALIDATION_ADDRESSES_ERRORS';
export const SET_VALIDATION_CHANGES = 'order/SET_VALIDATION_CHANGES';
export const SET_ORDER_BASKET = 'order/SET_ORDER_BASKET';
export const SET_ORDER_FILTER = 'order/SET_ORDER_FILTER';
export const SET_ORDER_OVERVIEW = 'order/SET_ORDER_OVERVIEW';
export const SET_ORDER_IN_PROGRESS = 'order/SET_ORDER_IN_PROGRESS';
export const SET_ORDER_PRODUCTCODE = 'order/SET_ORDER_PRODUCTCODE';

export const FILE_DOWNLOAD_REQUEST = 'order/FILE_DOWNLOAD_REQUEST';
export const FILE_DOWNLOAD_SUCCESS = 'order/FILE_DOWNLOAD_SUCCESS';
export const FILE_DOWNLOAD_FAILURE = 'order/FILE_DOWNLOAD_FAILURE';

export const IMPORT_FILE_REQUEST = 'order/IMPORT_FILE_REQUEST';
export const IMPORT_FILE_SUCCESS = 'order/IMPORT_FILE_SUCCESS';
export const IMPORT_FILE_FAILURE = 'order/IMPORT_FILE_FAILURE';

export const DELETE_ORDER_REQUEST = 'order/DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'order/DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'order/DELETE_ORDER_FAILURE';

export const VALIDATION_ADDRESSES_REQUEST = 'order/VALIDATION_ADDRESSES_REQUEST';
export const VALIDATION_ADDRESSES_SUCCESS = 'order/VALIDATION_ADDRESSES_SUCCESS';
export const VALIDATION_ADDRESSES_FAILURE = 'order/VALIDATION_ADDRESSES_FAILURE';

export const VALIDATION_NAMES_REQUEST = 'order/VALIDATION_NAMES_REQUEST';
export const VALIDATION_NAMES_SUCCESS = 'order/VALIDATION_NAMES_SUCCESS';
export const VALIDATION_NAMES_FAILURE = 'order/VALIDATION_NAMES_FAILURE';

export const CHECK_CHANGES_REQUEST = 'order/CHECK_CHANGES_REQUEST';
export const CHECK_CHANGES_SUCCESS = 'order/CHECK_CHANGES_SUCCESS';
export const CHECK_CHANGES_FAILURE = 'order/CHECK_CHANGES_FAILURE';

export const LOAD_ORDER_IN_PROGRESS_REQUEST = 'order/LOAD_ORDER_IN_PROGRESS_REQUEST';
export const LOAD_ORDER_IN_PROGRESS_SUCCESS = 'order/LOAD_ORDER_IN_PROGRESS_SUCCESS';
export const LOAD_ORDER_IN_PROGRESS_FAILURE = 'order/LOAD_ORDER_IN_PROGRESS_FAILURE';

export const DELETE_CARD_REQUEST = 'order/DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'order/DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = 'order/DELETE_CARD_FAILURE';

export const CREATE_FREE_CARD_REQUEST = 'order/CREATE_FREE_CARD_REQUEST';
export const CREATE_FREE_CARD_SUCCESS = 'order/CREATE_FREE_CARD_SUCCESS';
export const CREATE_FREE_CARD_FAILURE = 'order/CREATE_FREE_CARD_FAILURE';

export const EDIT_ORDER_CARD_REQUEST = 'order/EDIT_ORDER_CARD_REQUEST';
export const EDIT_ORDER_CARD_SUCCESS = 'order/EDIT_ORDER_CARD_SUCCESS';
export const EDIT_ORDER_CARD_FAILURE = 'order/EDIT_ORDER_CARD_FAILURE';

export const EDIT_ORDER_CREDIT_REQUEST = 'order/EDIT_ORDER_CREDIT_REQUEST';
export const EDIT_ORDER_CREDIT_SUCCESS = 'order/EDIT_ORDER_CREDIT_SUCCESS';
export const EDIT_ORDER_CREDIT_FAILURE = 'order/EDIT_ORDER_CREDIT_FAILURE';


export const EDIT_ORDER_REQUEST = 'order/EDIT_ORDER_REQUEST';
export const EDIT_ORDER_SUCCESS = 'order/EDIT_ORDER_SUCCESS';
export const EDIT_ORDER_FAILURE = 'order/EDIT_ORDER_FAILURE';

export const ORDER_OVERVIEW_REQUEST = 'order/ORDER_OVERVIEW_REQUEST';
export const ORDER_OVERVIEW_SUCCESS = 'order/ORDER_OVERVIEW_SUCCESS';
export const ORDER_OVERVIEW_FAILURE = 'order/ORDER_OVERVIEW_FAILURE';

export const SET_CARD_ADDRESS_CHANGE_SUCCESS = 'order/SET_CARD_ADDRESS_CHANGE_SUCCESS';
export const SET_CARD_ADDRESS_CHANGE_FAILURE = 'order/SET_CARD_ADDRESS_CHANGE_FAILURE';

export const CARD_ADDRESS_CHANGE_REQUEST = 'order/CARD_ADDRESS_CHANGE_REQUEST';
export const CARD_ADDRESS_CHANGE_SUCCESS = 'order/CARD_ADDRESS_CHANGE_SUCCESS';
export const CARD_ADDRESS_CHANGE_FAILURE = 'order/CARD_ADDRESS_CHANGE_FAILURE';

export const COMPLETE_ORDER_REQUEST = 'order/COMPLETE_ORDER_REQUEST';
export const COMPLETE_ORDER_SUCCESS = 'order/COMPLETE_ORDER_SUCCESS';
export const COMPLETE_ORDER_FAILURE = 'order/COMPLETE_ORDER_FAILURE';

export const ONLINE_PAY_REQUEST = 'order/ONLINE_PAY_REQUEST';
export const ONLINE_PAY_SUCCESS = 'order/ONLINE_PAY_SUCCESS';
export const ONLINE_PAY_FAILURE = 'order/ONLINE_PAY_FAILURE';

export const ONLINE_CONFIRM_REQUEST = 'order/ONLINE_CONFIRM_REQUEST';
export const ONLINE_CONFIRM_SUCCESS = 'order/ONLINE_CONFIRM_SUCCESS';
export const ONLINE_CONFIRM_FAILURE = 'order/ONLINE_CONFIRM_FAILURE';

export const LOAD_DELIVERY_ADDRESSES_REQUEST = 'order/LOAD_DELIVERY_ADDRESSES_REQUEST';
export const LOAD_DELIVERY_ADDRESSES_SUCCESS = 'order/LOAD_DELIVERY_ADDRESSES_SUCCESS';
export const LOAD_DELIVERY_ADDRESSES_FAILURE = 'order/LOAD_DELIVERY_ADDRESSES_FAILURE';

export const FETCH_CARD_IMAGE_REQUEST = 'order/FETCH_CARD_IMAGE_REQUEST';
export const FETCH_CARD_IMAGE_SUCCESS = 'order/FETCH_CARD_IMAGE_SUCCESS';
export const FETCH_CARD_IMAGE_FAILURE = 'order/FETCH_CARD_IMAGE_FAILURE';

export const FETCH_CARD_PERSONALIZATION_REQUEST = 'order/FETCH_CARD_PERSONALIZATION_REQUEST';
export const FETCH_CARD_PERSONALIZATION_SUCCESS = 'order/FETCH_CARD_PERSONALIZATION_SUCCESS';
export const FETCH_CARD_PERSONALIZATION_FAILURE = 'order/FETCH_CARD_PERSONALIZATION_FAILURE';

export const REDIRECT_TO_VALIDATION = 'order/REDIRECT_TO_VALIDATION';
export const RESET_REDIRECT_TO_VALIDATION = 'order/RESET_REDIRECT_TO_VALIDATION';

export const REDIRECT_TO_CONTRACT_SELECTION = 'order/REDIRECT_TO_CONTRACT_SELECTION';
export const RESET_REDIRECT_TO_CONTRACT_SELECTION = 'order/RESET_REDIRECT_TO_CONTRACT_SELECTION';

export const REDIRECT_TO_ADJUSTMENT = 'order/REDIRECT_TO_ADJUSTMENT';
export const RESET_REDIRECT_TO_ADJUSTMENT = 'order/RESET_REDIRECT_TO_ADJUSTMENT';

export const FETCH_PRODUCTS_REQUEST = 'order/FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'order/FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'order/FETCH_PRODUCTS_FAILURE';
