export const FETCH_AFFILIATES_REQUEST = 'my/place/FETCH_AFFILIATES_REQUEST';
export const FETCH_AFFILIATES_SUCCESS = 'my/place/FETCH_AFFILIATES_SUCCESS';
export const FETCH_AFFILIATES_FAILURE = 'my/place/FETCH_AFFILIATES_FAILURE';

export const FETCH_AFFILIATES_MARKERS_REQUEST = 'my/place/FETCH_AFFILIATES_MARKERS_REQUEST';
export const FETCH_AFFILIATES_MARKERS_SUCCESS = 'my/place/FETCH_AFFILIATES_MARKERS_SUCCESS';
export const FETCH_AFFILIATES_MARKERS_FAILURE = 'my/place/FETCH_AFFILIATES_MARKERS_FAILURE';

export const FETCH_AFFILIATE_REQUEST = 'my/place/FETCH_AFFILIATE_REQUEST';
export const FETCH_AFFILIATE_SUCCESS = 'my/place/FETCH_AFFILIATE_SUCCESS';
export const FETCH_AFFILIATE_FAILURE = 'my/place/FETCH_AFFILIATE_FAILURE';

export const SAVE_WISHLIST_AFFILIATE_REQUEST = 'my/place/SAVE_WISHLIST_AFFILIATE_REQUEST';
export const SAVE_WISHLIST_AFFILIATE_SUCCESS = 'my/place/SAVE_WISHLIST_AFFILIATE_SUCCESS';
export const SAVE_WISHLIST_AFFILIATE_FAILURE = 'my/place/SAVE_WISHLIST_AFFILIATE_FAILURE';

export const FETCH_AFFILIATE_TYPE_PAIRS_REQUEST = 'my/place/FETCH_AFFILIATE_TYPE_PAIRS_REQUEST';
export const FETCH_AFFILIATE_TYPE_PAIRS_SUCCESS = 'my/place/FETCH_AFFILIATE_TYPE_PAIRS_SUCCESS';
export const FETCH_AFFILIATE_TYPE_PAIRS_FAILURE = 'my/place/FETCH_AFFILIATE_TYPE_PAIRS_FAILURE';

export const FETCH_PRODUCT_DESC_PAIRS_REQUEST = 'my/place/FETCH_PRODUCT_DESC_PAIRS_REQUEST';
export const FETCH_PRODUCT_DESC_PAIRS_SUCCESS = 'my/place/FETCH_PRODUCT_DESC_PAIRS_SUCCESS';
export const FETCH_PRODUCT_DESC_PAIRS_FAILURE = 'my/place/FETCH_PRODUCT_DESC_PAIRS_FAILURE';

export const UPDATE_AFFILIATES_FILTER_DATA = 'my/place/UPDATE_AFFILIATES_FILTER_DATA';
