export const FETCH_FIRM_POSITION_PAIRS_REQUEST = 'firm/position/FETCH_FIRM_POSITION_PAIRS_REQUEST';
export const FETCH_FIRM_POSITION_PAIRS_SUCCESS = 'firm/position/FETCH_FIRM_POSITION_PAIRS_SUCCESS';
export const FETCH_FIRM_POSITION_PAIRS_FAILURE = 'firm/position/FETCH_FIRM_POSITION_PAIRS_FAILURE';

export const ADD_POSITION_REQUEST = 'firm/position/ADD_POSITION_REQUEST';
export const ADD_POSITION_SUCCESS = 'firm/position/ADD_POSITION_SUCCESS';
export const ADD_POSITION_FAILURE = 'firm/position/ADD_POSITION_FAILURE';

export const UPDATE_POSITION_REQUEST = 'firm/position/UPDATE_POSITION_REQUEST';
export const UPDATE_POSITION_SUCCESS = 'firm/position/UPDATE_POSITION_SUCCESS';
export const UPDATE_POSITION_FAILURE = 'firm/position/UPDATE_POSITION_FAILURE';

export const DELETE_POSITION_REQUEST = 'firm/position/DELETE_POSITION_REQUEST';
export const DELETE_POSITION_SUCCESS = 'firm/position/DELETE_POSITION_SUCCESS';
export const DELETE_POSITION_FAILURE = 'firm/position/DELETE_POSITION_FAILURE';

export const FETCH_FIRM_POSITIONS_REQUEST = 'firm/position/FETCH_FIRM_POSITIONS_REQUEST';
export const FETCH_FIRM_POSITIONS_SUCCESS = 'firm/position/FETCH_FIRM_POSITIONS_SUCCESS';
export const FETCH_FIRM_POSITIONS_FAILURE = 'firm/position/FETCH_FIRM_POSITIONS_FAILURE';

export const UPDATE_FIRM_POSITIONS_FILTER_DATA = 'firm/position/UPDATE_FIRM_POSITIONS_FILTER_DATA';
