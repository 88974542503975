import * as openpgp from 'openpgp';
import { NON_PROD_ENV } from '../config/envspecific';

const publicKeyArmored = `-----BEGIN PGP PUBLIC KEY BLOCK-----

mQENBGCgEC0BCAC9Ko1f7WVmf5jToLtjNDqsWchSqjAyY5BSfylob0R3neaKvZfa
AI3HtEIqW2j0WiL/ndtDtjF5TgVcehZGZv6nnOYa41EUEDwJ+lriM8dK75c4jcwg
mXZ3/IleRewBJJ0a102mDZKrA/sH/6EFb4ayLIXYVswSjPEHFWAnxlcOT0U8t466
l+Kg4CS3ODUYS66kQQ6cS71E5hcePDCf2C2DS69cShVQTb5xd+uKmoEpNgNKwxuQ
fXcfezcceJHFd2+V2E1izMc0Ns0azxsMSgX4dmYCyWvqj17Ji6Hno1r7GN61jSAs
zrfx5pB1XYnQb6blTxyb/XEC0006IhcbSbGvABEBAAG0JWNtc3Rlc3RAcHJvcGF5
LnNrIDxjbXN0ZXN0QHByb3BheS5zaz6JAU4EEwEKADgWIQT67pZ6vaQsmERiiF1T
3TbXO4flzQUCYKAQLQIbAwULCQgHAgYVCgkICwIEFgIDAQIeAQIXgAAKCRBT3TbX
O4flzStmB/9xrxgQbSjneOYQFNmN/EUN9tG8znoHqo6PJTnULs+YIBYvEQHjjOnV
qfrv2D1FJ2q+8i/9dlpGGy7xhCdI8ooojZo0Blv91Z80zSOR2K2mZARd/ZwwYT9s
GEFTH/IQdNAmt1C4qPId0ZtGSS5g+XbFoWuBUYGnbPLLLU5GLgV9yth+ilC3GDK0
IW+r+tbyTAIRzJPn751fsdURwXQt+S5N8O2AOjJkKdv/De/uQn6veB0HhAiIXG3s
in1R8unn0Tz9d+MaXvqGlcalvv75nAkFxTJlEQd+Wa2sqW7eiTrBM2fPpb3t6R/u
Pf+7Ch0n+8Jphz7+W0nSTEuidc3697ajuQENBGCgEC0BCADKw1c9GsAM6zULm7Eq
uiKnhZtwAxOUC2k0dIdN2bWdRcBsYDk3xI9jhda9WmdxM9aiJCZUEDwKNM2seNk9
4bmmfrOZhuB1/k5hxVZzvQkJv+g0ohn6z6I7fyTx1ttMjqyPYfIQ8w87bBqlE14c
woVkKjiKi3LHU4vw0XM2ogX6d+2I2MJ1esmyUtbFw+Or9XoJJf8+JLj6tl24obch
rRpGCyhIzBqIklqoa8JMS3zE4iKodZlWNgsuavN/xfJE+oqIgz5leUPQn+Z5xEQU
pcXyiiZvolmcNsebxS51Owpej5etIpVUGzWRCANfyPF65L5vtfItT4V6Ok3joEnK
AxUdABEBAAGJATYEGAEKACAWIQT67pZ6vaQsmERiiF1T3TbXO4flzQUCYKAQLQIb
DAAKCRBT3TbXO4flzYvKB/9w5gKnCzk6ZUQiqUxIFc9wxeVqPw52CDpHBEr3c+NH
9IJ1tpAbGoT9uxGcS4R7EoG9/JxxtS4hhwzBmgt0uwp1NIO9kFZuDbCctCzNevvm
RUBJOilhz7GjdEl0EpNIexGi5m4TdvykIqHC810ZDiprliVu+GyxeJc40BCel1bV
0+CdEyGF4M3v/Ma6UqbhZ8Ji81t6koGH6Vj0ULKsUQTHeNYAYcgck4k6C+oFZ99s
VKtXU5NrtxXw6aKFzSIXNdy4DFVJazfgHpWGTbmMqWmv8USQUBC+mOKO8VwPjaC5
+wjVzpq/zqP6Z1L7xhFYFIyQb9vc8RcQ5jT3feCA67YS
=il51
-----END PGP PUBLIC KEY BLOCK-----`;

const publicKeyArmoredProd = `-----BEGIN PGP PUBLIC KEY BLOCK-----

mQGNBGDIvKQBDADj5xwBQGKaYcBcxBnHtNvPLGUH85viXVesvd58wJ0AA2k3d9Ex
+ORt7GIfvIdKOSsMh0jxxBSVm8TdeVjzX0YqCtqeiYelp6lmW2r7o/6HLud2L5LP
4vGYWxpWeExY8RC4PXwCq09F1bjpV0Oy+zADJ4OHXKgQ7LmLqAD3CbmPZcD8k0dK
JVtyqzLgpOeVbQCCidrcjn47IyN8BHN8NhPc3vW4IENMQT2lyBZRy2IlKuA2NB0k
Vc7SLriLmBCfykWU4LApD7eXI8I5Pn79m7+k/yK88Iej+udmI84THX/BA1MHPp7F
ZEArGfblEPo50xFhhtvGKykySrl1+yfoq1As0BuNEwKubkBWeTQvAh53VloBKRGu
BC3WlHoA6Of5Ch5CYPCBpqUBSgW4dhe25e+kI1ZoSTp2EG6y2IomA+OTpoNsqjM5
nS0RYLb/wcacrq0OeSxv8BCOgUdTozuUvNya9Rk94rUedokLYONe2EtOtI3BiAhn
+uJnhe/3At+ZCssAEQEAAbQdY21zQHByb3BheS5zayA8Y21zQHByb3BheS5zaz6J
Ac4EEwEKADgWIQRKJzMA1Y3l1ohZ8eq9w68zpgP7LAUCYMi8pAIbAwULCQgHAgYV
CgkICwIEFgIDAQIeAQIXgAAKCRC9w68zpgP7LKolC/sHvr4IY7EIRTV+VwgBEsz+
6O16zpt5LwfpGa5Fj5QAog/aUWgwZB1Y/vk/v9SjRCkQ3UYjVY6sZkc2TS1x/nYO
ynnGbLlTiDDYd+iVbMt1fIh4ydtZg6JJLYGeHJ+Y2reRNwl6BIHG1pGpLfZSgz8J
x19Qo9VzFt3k0BTM24NTFV3Ou4PZC15IwpbyjrLxb6eqmphSs7rs26euevInYmtK
u2QrK42NDy6iYfseJh9+rYHoIKXUkC5B/EqCa4GrEFWFuSIIOymsJXQLyJ0Bm4Jg
8FQPX1k+NPZ/K15gH3GcksLxwbaM45SNN7FDmvVpo8wy8U55zzch+1AZZTL5Aj6d
A7wX6uvE8TY/ml8mIpM6RCZ4yEbMxYiw0J1DwHcBHPr2EnEKVhTxAtDHIccxO+Cx
Qwa7Cv2utWancG1nt5yW3oKGPq7W8sif2VW5PMhYh8oMNC2moFbiK7BKP3YUi2ZB
Oo+1IxXuOt6rtuYAFZDfuPoNL+PZCmOvrfN4WzIus/q5AY0EYMi8pAEMAMqLv+Tl
KSB4taFnWXmZOBAHuIx45whvaDqHrWlvXHJ1EFNjs5GfKlg+tWseVNvTOC9bd1Yo
kS5RVS0IdK3/FS34X/bWKVq6cMemuFYazDH+BsLD4WX2AXHQXfaROixxlrPmQ+Rm
jlZes4JtmYK9rXGv273k8x/wi5YcrILgxhvcP/jwX7AimL0PI2dB210y3WgmPgAs
xiPE2NlWdnSBkxCwE7+HISCkcWESVeBJjVIlhd57r2PCLQj9cDsnSxnmJONM10FW
ELwG5v4HGMv8o3WbSDB58RTjFH5NjvXzsGIIwar5upjsXsKSXqvIRQWG2is41E51
AlH8KpHeT5LM79eeD5MQgCVChaEPXGPVrieSOKVfkP+xqF5UzYvmAEBctAIzXqWD
7RVmSiJ5hVOy/5u+SDWkK+K/rZ7v5ZSdJEc4xdS2J51aBQZBWrUBamw12MQEc6y4
BmnBgSts37w1XcUJruflgZi5lStUBH7WyAFvWNT7m0SBYm5l2FDgHZMJJQARAQAB
iQG2BBgBCgAgFiEESiczANWN5daIWfHqvcOvM6YD+ywFAmDIvKQCGwwACgkQvcOv
M6YD+yzASQv9HjZ4Q/DOsTGLBXsoQuQkIrLw7hDnFMBhujW89HAam7znDjvBJ2Jl
EZsigs9Vs/vJV9sSGVua/TyPcjAdyhx5hE46R4jursD2Q5+KBru8UILlTAklD8PO
x2MK+01zq4f3BJYNufYz+b9ZpZ5157Q1bypu6JAdHDGXNb/K9JPk16iELn6DW7BH
028V3nT17GfMYtoETaktTn2ViNb7GzyMN4a72Xot8AEm/xglGwyTBT7aABgXxXId
QA9sXrUW5ZoiWpjyBNr82i1JGN+Lz3TjsnpLJaXVie3EREP+r0c/ihwwuA3iUthl
BtD0+52GBRrjLkNWLsUtov00kN9dryxebcEmxixZ8dwfWsyLazmaMGcou8zsEMig
6OILS6qGLiqdf45AsQiINm+dwCN2zmRHC1/Oxzuki+ETIJxISlnDvoVibXpPqFJu
EWgAlbl+wz8qQ/nMdFlf86t3YM8chM3TUsBZ3Q4FyZ4p6932zcgFoMlrbmjWOCGA
p7N7FCJ8lkcx
=U5VQ
-----END PGP PUBLIC KEY BLOCK-----`;

export async function encryptPin(pin: string, uuid: string): Promise<string> {
  const armoredKey = NON_PROD_ENV ? publicKeyArmored : publicKeyArmoredProd;
  const publicKey = await openpgp.readKey({ armoredKey });
  const encrypted = await openpgp.encrypt({
    message: await openpgp.createMessage({
      text: JSON.stringify({
        uuid,
        pin,
        url: window.location.href,
        time: new Date(),
      }),
    }),
    encryptionKeys: publicKey,
  });
  // console.log({
  //   armoredKey, NON_PROD_ENV, publicKeyArmored, publicKeyArmoredProd, encrypted, pin, uuid,
  // });
  return encrypted;
}
