import Immutable from 'immutable';
import { dateTimeFromString } from '../../../service/date';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';


const reducerFactory = (initialState) => (state, action) => {
  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'transaction']);
  }

  if (action.type === types.FETCH_TRANSACTIONS_REQUEST) {
    return state
      .setIn(['transactionList', 'isFetchingFailure'], false)
      .setIn(['transactionList', 'isFetching'], !action.payload.isLoadingMore)
      .setIn(['transactionList', 'isLoadingMore'], !!action.payload.isLoadingMore)
      .setIn(['transactionList', 'fetchCount'], Immutable.fromJS(action.payload.limit))
      .setIn(['transactionList', 'limit'], Immutable.fromJS(action.payload.limit))
      .setIn(['transactionList', 'lastOffset'], Immutable.fromJS(action.payload.lastOffset))
      .setIn(['transactionList', 'orderBy'], Immutable.fromJS(action.payload.orderBy))
      .setIn(['transactionList', 'filter'], Immutable.fromJS(action.payload.filter));
  }

  if (action.type === types.FETCH_TRANSACTIONS_SUCCESS) {
    const transactions = action.payload.map((item) => ({
      data: {
        ...item,
        id: item.t01_transaction_id,
        t01_date_transaction: dateTimeFromString(item.t01_date_transaction),
      },
    }));

    const transactionList = state.get('transactionList');
    const fetchCount = transactionList.get('fetchCount');
    const lastOffset = transactionList.get('lastOffset');
    const stateTransactions = transactionList.get('transactions');
    const lastCount = transactions.length;

    return state
      .setIn(['transactionList', 'transactions'], getEntitiesFromResponse(transactions, stateTransactions, lastOffset, fetchCount))
      .setIn(['transactionList', 'lastCount'], lastCount)
      .setIn(['transactionList', 'isFetching'], false)
      .setIn(['transactionList', 'isLoadingMore'], false)
      .setIn(['transactionList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_TRANSACTIONS_FAILURE) {
    return state
      .setIn(['transactionList', 'isFetching'], false)
      .setIn(['transactionList', 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
