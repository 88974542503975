import PropTypes from 'prop-types';
import React from 'react';
import styles from './List.scss';


const List = (props) => (
  <ul className={props.isCompact ? styles.isRootCompact : styles.root}>
    {!!props.children && React.Children.toArray(props.children).map((item, i) => (
      <li key={i} className={styles.item}>{item}</li>
    ))}
  </ul>
);

List.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isCompact: PropTypes.bool,
};

export default List;
