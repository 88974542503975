export const FETCH_LOGIN_DATA_REQUEST = 'FETCH_LOGIN_DATA_REQUEST';
export const FETCH_LOGIN_DATA_SUCCESS = 'FETCH_LOGIN_DATA_SUCCESS';
export const FETCH_LOGIN_DATA_FAILURE = 'FETCH_LOGIN_DATA_FAILURE';

export const SET_AUTHORIZED_PAGES = 'SET_AUTHORIZED_PAGES';
export const SET_LOGIN_INFO = 'SET_LOGIN_INFO';
export const SET_CONTRACT_RIGHTS = 'SET_CONTRACT_RIGHTS';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGIN_AS_USER_REQUEST = 'LOGIN_AS_USER_REQUEST';
export const LOGIN_AS_USER_SUCCESS = 'LOGIN_AS_USER_SUCCESS';
export const LOGIN_AS_USER_FAILURE = 'LOGIN_AS_USER_FAILURE';

export const REMIND_PASSWORD_REQUEST = 'REMIND_PASSWORD_REQUEST';
export const REMIND_PASSWORD_SUCCESS = 'REMIND_PASSWORD_SUCCESS';
export const REMIND_PASSWORD_FAILURE = 'REMIND_PASSWORD_FAILURE';
export const CLEAR_REMIND_PASSWORD = 'CLEAR_REMIND_PASSWORD';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const HASH_CHECK_REQUEST = 'auth/HASH_CHECK_REQUEST';
export const HASH_CHECK_SUCCESS = 'auth/HASH_CHECK_SUCCESS';
export const HASH_CHECK_FAILURE = 'auth/HASH_CHECK_FAILURE';
