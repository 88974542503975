import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import styles from './Overlay.scss';


const Overlay: React.FC<{ show?: boolean, absolute?: boolean }> = ({ show, absolute }) => {
  if (show === false) {
    return null;
  }

  return (
    <div className={absolute ? styles.rootAbsolute : styles.root}>
      <CircularProgress size={45} thickness={5} />
    </div>
  );
};

Overlay.propTypes = {
  show: PropTypes.bool,
  absolute: PropTypes.bool,
};

export default Overlay;
