import { addFlashMessage } from '../modules/flashMessages';
import { t } from '../i18n';

export const apiErrorMessageHandler = (dispatch: any, ns?: string, defaultMessage?: string, excludeCodes?: string[]) => (action: any) => {
  if (action.error && (!excludeCodes || !excludeCodes.includes(action.payload.response.code))) {
    const message = action.payload.response && action.payload.response.message;
    dispatch(addFlashMessage('error', message || (defaultMessage || t('Chyba komunikace')), { ns: ns || 'common' }));
  }

  return action;
};

export const apiResultMessageHandler = (dispatch: any, successMessage?: string, ns?: string) => (action: any) => {
  if (action.error) {
    apiErrorMessageHandler(dispatch, ns)(action);
  } else if (successMessage) {
    dispatch(addFlashMessage('success', successMessage));
  }
  return action;
};
