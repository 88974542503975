import { createSelector } from 'reselect';
import { selectProductMap } from '../order/selectors';
import { t } from '../../../../i18n';
import { CURRENCY } from '../../../global/constants';
import { selectAuthUserInfo } from '../../../auth/selectors';

export const getBasket = (state) => state.getIn(['my', 'card', 'basket']).toJS();
export const getCredits = (state) => state.getIn(['my', 'card', 'credits']).toJS();
export const getBasketData = (state) => state.getIn(['my', 'card', 'basket', 'data']).toJS();
export const getCreditsData = (state) => state.getIn(['my', 'card', 'credits', 'data']).toJS();
export const getOverview = (state) => state.getIn(['my', 'card', 'basketOverview']).toJS();
export const getOverviewData = (state) => state.getIn(['my', 'card', 'basketOverview', 'data']).toJS();
export const getCards = (state) => state.getIn(['my', 'card', 'cardList', 'cards']).toJS();
export const getAssignedCards = (state) => state.getIn(['my', 'card', 'creditCardOverview']).toJS();
export const getCardsFetching = (state) => state.getIn(['my', 'card', 'cardList', 'isFetching']);
export const getIsDeletingOrder = (state) => state.getIn(['my', 'card', 'isDeletingOrder']);
export const getContractNotConfirmed = (state) => state.getIn(['my', 'card', 'contractNotConfirmed']);
export const getOpenAddCard = (state) => state.getIn(['my', 'card', 'basket', 'openAddCard']);

export const selectCards = createSelector([getCards], (cards) => cards);
export const selectCardsFetching = createSelector([getCardsFetching], (isFetching) => isFetching);
export const selectAssignedCards = createSelector([getAssignedCards], (slice) => slice.cards);
export const selectAssignedCardWidget = createSelector([getAssignedCards], (slice) => slice.widget);
export const selectAssignedCardsFetching = createSelector([getAssignedCards], (slice) => slice.isFetching);

export const selectMainCardDetails = createSelector([selectAuthUserInfo], (user) => user && {
  u01_name: user.u01_name,
  u01_surname: user.u01_surname,
  u01_mail_work: user.email,
});

export const selectTermsURL = createSelector(
  [getOverviewData],
  (overview) => (overview && overview.vop ? overview.vop : '')
);

export const selectBasketCards = createSelector(
  [getBasketData],
  (basket) => {
    if (!basket || !basket.cards) {
      return [];
    }
    return basket.cards;
  }
);

export const selectBasketHasCards = createSelector(
  [getBasketData],
  (basket) => {
    if (!basket || !basket.cards) {
      return false;
    }
    return basket.cards.length > 0;
  }
);

export const selectBasketDeliveryAddress = createSelector(
  [getBasketData],
  (basket) => {
    if (!basket || !basket.delivery_address) {
      return null;
    }
    return basket.delivery_address;
  }
);

export const selectBasketInvoiceAddress = createSelector(
  [getBasketData],
  (basket) => {
    if (!basket || !basket.invoice_address) {
      return null;
    }
    return basket.invoice_address;
  }
);

export const selectCredits = createSelector(
  [getCreditsData],
  (credits) => {
    if (!credits) {
      return [];
    }

    const rows = [];

    credits.forEach((data) => {
      let row = rows.find((r) => r.o90_order_line_temp_id === data.o90_order_line_temp_id);

      // new row
      if (!row) {
        row = {
          ...data,
          o93_credit_temp_ids: {},
        };

        delete row.order_value;
        delete row.o93_credit_temp_id;

        rows.push(row);
      }

      // set value for specified code
      row[`order_value_${data.s20_code}`] = data.order_value;
      row.o93_credit_temp_ids[data.s20_code] = data.o93_credit_temp_id;
    });

    return rows.map((data) => ({
      data,
    }));
  }
);

export const selectCreditsIsFetching = createSelector(
  [getCredits],
  (credits) => {
    if (!credits) {
      return null;
    }
    return credits.isFetching;
  }
);

export const selectCreditsProducts = createSelector(
  [getCreditsData, selectProductMap],
  (credits, productMap) => {
    const productCodes = [];
    credits.forEach((c) => {
      if (!productCodes.includes(c.s20_code)) {
        productCodes.push(c.s20_code);
      }
    });

    if (productMap === null) {
      // sort only by codes
      return productCodes.sort();
    }

    // regular sort
    return productCodes.sort((code1, code2) => productMap[code1].s20_product_id - productMap[code2].s20_product_id);
  }
);

export const selectOverviewInvoiceLines = createSelector(
  [getOverviewData],
  (overview) => {
    if (!overview || !overview.invoice_lines) {
      return [];
    }

    const invoiceLines = overview.invoice_lines.map((data) => ({
      data: {
        name: data.name,
        i10_value: data.i10_value,
        i10_count: data.i10_count,
        i10_value_total: data.i10_value_total,
        i10_value_vat: data.i10_value_vat,
        v01_vat_perc: data.v01_vat_perc,
        total: data.total,
        unit: data.unit,
      },
    }));

    const vatRates = [];

    invoiceLines.forEach(({ data }) => {
      if (!vatRates.includes(data.v01_vat_perc)) {
        vatRates.push(data.v01_vat_perc);
      }
    });

    vatRates.filter((vatPerc) => vatPerc !== 0).forEach((vatPerc) => {
      invoiceLines.push({
        data: {
          name: t('Základ DPH {{vatPerc}} %', { vatPerc }),
          total: invoiceLines.filter((item) => item.data.v01_vat_perc === vatPerc).reduce(
            (carry, item) => carry + +item.data.i10_value_total,
            0
          ),
          unit: t('currency', { context: CURRENCY }),
        },
      },
      {
        data: {
          name: t('DPH {{vatPerc}} %', { vatPerc }),
          total: invoiceLines.filter((item) => item.data.v01_vat_perc === vatPerc).reduce(
            (carry, item) => carry + +item.data.i10_value_vat,
            0
          ),
          unit: t('currency', { context: CURRENCY }),
        },
      });
    });

    invoiceLines.push(
      {
        data: {
          name: t('Celkem k úhradě'),
          total: overview.invoice_lines.reduce(
            (carry, data) => carry + +data.total,
            0
          ),
          unit: t('currency', { context: CURRENCY }),
        },
      }
    );

    return invoiceLines;
  }
);

export const selectOverviewPaymentInformation = createSelector(
  [getOverviewData],
  (overview) => {
    if (!overview || !overview.parameters) {
      return null;
    }

    return {
      value: overview.parameters.i04_payment_type_id,
      options: overview.parameters.payments.map((item) => ({
        value: item.i04_payment_type_id,
        label: item.name,
      })),
    };
  }
);

export const selectOverviewInfo = createSelector(
  [getOverviewData],
  (overview) => {
    if (!overview || !overview.invoice_head) {
      return null;
    }

    return {
      invoice_head: {
        fullname: overview.invoice_head.c20_label,
        address: overview.invoice_head.full_address,
      },
      delivery: {
        fullname: overview.delivery.c20_label,
        address: overview.delivery.full_address,
      },
      invoice_address: overview.invoice_head,
      delivery_address: overview.delivery,
    };
  }
);

export const selectIsDeletingOrder = createSelector([getIsDeletingOrder], (isDeletingOrder) => isDeletingOrder);

export const selectContractNotConfirmed = createSelector([getContractNotConfirmed], (val) => val);

export const selectOpenAddCard = createSelector([getOpenAddCard], (val) => val);

export const selectBasketPaymentInfo = createSelector(
  [getBasketData],
  (basket) => {
    if (!basket || !basket.paymentInfo) {
      return null;
    }
    return basket.paymentInfo;
  }
);

export const selectBasketIsFetching = createSelector(
  [getBasket],
  (basket) => {
    if (!basket) {
      return null;
    }
    return basket.isFetching;
  }
);

export const selectBasketIsConfirmingOrder = createSelector(
  [getOverview],
  (overview) => {
    if (!overview) {
      return null;
    }
    return overview.isConfirmingOrder;
  }
);
