const persistKeys = ['portalLang'];

class Auth {
  constructor(storage) {
    this.storage = storage;
  }

  login(token) {
    if (this.storage.auth_token) {
      return;
    }

    this.storage.auth_token = token;
  }

  logout() {
    const persistValues = persistKeys.map((key) => this.storage.getItem(key));
    this.storage.clear();
    persistValues.forEach((val, i) => {
      if (val !== null) {
        this.storage.setItem(persistKeys[i], val);
      }
    });
  }

  isLoggedIn() {
    return !!this.storage.auth_token;
  }
}

export function authFactory() {
  return new Auth(window.localStorage);
}

export function clearLoginSession() {
  const auth = authFactory();
  auth.logout();
}

export default Auth;
