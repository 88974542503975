import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';


const reducerFactory = (initialState) => (state, action) => {
  const {
    type,
    payload,
  } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'invoice']);
  }

  if (action.type === types.FETCH_INVOICES_REQUEST) {
    return state
      .setIn(['invoiceList', 'isFetchingFailure'], false)
      .setIn(['invoiceList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['invoiceList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['invoiceList', 'fetchCount'], Immutable.fromJS(action.payload.limit))
      .setIn(['invoiceList', 'limit'], Immutable.fromJS(action.payload.limit))
      .setIn(['invoiceList', 'lastOffset'], Immutable.fromJS(action.payload.lastOffset))
      .setIn(['invoiceList', 'orderBy'], Immutable.fromJS(action.payload.orderBy))
      .setIn(['invoiceList', 'filter'], Immutable.fromJS(action.payload.filter));
  }

  if (action.type === types.FETCH_INVOICES_SUCCESS) {
    const invoices = action.payload.map((item) => ({
      data: {
        id: item.i01_invoice_id,
        number: item.number,
        i02_invoice_type: item.i02_invoice_type,
        i03_invoice_status: item.i03_invoice_status,
        total: item.total,
        date: item.date,
        i01_date_pay: item.i01_date_pay,
        i01_date_recieve_pay: item.i01_date_recieve_pay,
        employees: item.employees,
        orders: item.orders,
        i01_customer_code: item.i01_customer_code,
      },
      actions: {
        downloadPdf: !!item.can_download,
        downloadCsv: !!item.can_download,
        payOnline: !!item.can_pay_online,
        openReports: !!item.has_reports,
      },
    }));

    const invoiceList = state.get('invoiceList');
    const fetchCount = invoiceList.get('fetchCount');
    const lastOffset = invoiceList.get('lastOffset');
    const stateInvoices = invoiceList.get('invoices');
    const lastCount = invoices.length;

    return state
      .setIn(['invoiceList', 'invoices'], getEntitiesFromResponse(invoices, stateInvoices, lastOffset, fetchCount))
      .setIn(['invoiceList', 'lastCount'], lastCount)
      .setIn(['invoiceList', 'isFetching'], false)
      .setIn(['invoiceList', 'isLoadingMore'], false)
      .setIn(['invoiceList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_INVOICES_FAILURE) {
    return state
      .setIn(['invoiceList', 'isFetching'], false)
      .setIn(['invoiceList', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_FIRM_INVOICES_FILTER_DATA) {
    return state
      .setIn(['invoiceList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['invoiceList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  if (action.type === types.FETCH_INVOICE_REPORTS_REQUEST) {
    return state
      .setIn(['reportsList', 'isFetching'], true)
      .setIn(['reportsList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_INVOICE_REPORTS_SUCCESS) {
    return state
      .setIn(['reportsList', 'reports'], action.payload)
      .setIn(['reportsList', 'isFetching'], false)
      .setIn(['reportsList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_INVOICE_REPORTS_FAILURE) {
    return state
      .setIn(['reportsList', 'isFetching'], false)
      .setIn(['reportsList', 'isFetchingFailure'], true);
  }

  return state;
};


export default reducerFactory;
