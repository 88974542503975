import pathToRegexp from 'path-to-regexp';
// import transformOptionalPathParameters from './transformOptionalPathParameters';

const pathToRegexpCustom = (path, keys) => pathToRegexp(path === '/*' ? '/(.*)' : path, keys);

export default (path, url) => {
  const keys = [];
  const re = pathToRegexpCustom(path, keys);
  const parts = re.exec(url);

  const params = keys.reduce((acc, curr, idx) => {
    acc[curr.name] = parts[idx + 1] || null;
    return acc;
  }, {});

  return {
    params,
  };
};
