import PropTypes from 'prop-types';
import React from 'react';
import { DatePicker as MUIDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import TextField from '../TextField/TextField';

const localeData = moment.localeData();
const format = localeData.longDateFormat('L');

const DatePicker = (props) => {
  const t = props.t;
  const textFieldStyle = props.textFieldStyle ? props.textFieldStyle : {};
  textFieldStyle.fontSize = '15px';
  textFieldStyle.fontWeight = '500';
  // textFieldStyle.height = '100%';
  // textFieldStyle.padding = '8px';

  // const hasValue = !!props.value;

  return (
    <MUIDatePicker
      clearLabel={t('Vymazat')}
      cancelLabel={t('Zrušit')}
      okLabel={t('Vybrat')}
      onChange={(date) => props.onChange(date)}
      value={props.value || null}
      disabled={!!props.disabled}
      label={props.label}
      format={format}
      TextFieldComponent={(p) => <TextField {...p} icon="pending_actions" hasNoErrors={props.hasNoErrors} errorText={props.errorText} isThin={props.isThin} />}
      disableFuture={props.disableFuture}
      disablePast={props.disablePast}
      autoOk
      clearable
    />
  );
};

DatePicker.propTypes = {
  // name: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isThin: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date),
  textFieldStyle: PropTypes.shape({}),
  errorText: PropTypes.string,
  hasNoErrors: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default DatePicker;
