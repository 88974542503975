import React from 'react';
import { Section, Main } from '../../component1/Layout';
import VersionLabel from '../../component1/VersionLabel/VersionLabel';
import CardGridViewContainer from './CardGridViewContainer';
import { PropTypeRouter } from '../global/proptypes';


const HomePage = ({ router }) => (
  <Main>
    <Section>
      <CardGridViewContainer router={router} />
      <VersionLabel />
    </Section>
  </Main>
);

HomePage.propTypes = {
  router: PropTypeRouter,
};

export default HomePage;
