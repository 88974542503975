import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';

const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'branchAndCostCenter']);
  }

  if (type === types.FETCH_BRANCHES_COST_CENTERS_REQUEST) {
    return state
      .setIn(['branchesCostCentersList', 'isFetchingFailure'], false)
      .setIn(['branchesCostCentersList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['branchesCostCentersList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['branchesCostCentersList', 'fetchCount'], Immutable.fromJS(payload.limit))
      .setIn(['branchesCostCentersList', 'limit'], Immutable.fromJS(payload.limit))
      .setIn(['branchesCostCentersList', 'lastOffset'], Immutable.fromJS(payload.lastOffset))
      .setIn(['branchesCostCentersList', 'orderBy'], Immutable.fromJS(payload.orderBy))
      .setIn(['branchesCostCentersList', 'filter'], Immutable.fromJS(payload.filter));
  }

  if (type === types.FETCH_BRANCHES_COST_CENTERS_SUCCESS) {
    const branchesCostCenters = payload.map((item) => ({
      data: {
        ...item,
        is_active: !!+item.is_active,
      },
      actions: {
        edit: true,
        delete: true,
      },
    }));

    const branchesCostCentersList = state.get('branchesCostCentersList');
    const fetchCount = branchesCostCentersList.get('fetchCount');
    const lastOffset = branchesCostCentersList.get('lastOffset');
    const stateBranchesCostCenters = branchesCostCentersList.get('branchesCostCenters');
    const lastCount = branchesCostCenters.length;

    return state
      .setIn(['branchesCostCentersList', 'branchesCostCenters'], getEntitiesFromResponse(branchesCostCenters, stateBranchesCostCenters, lastOffset, fetchCount))
      .setIn(['branchesCostCentersList', 'lastCount'], lastCount)
      .setIn(['branchesCostCentersList', 'isFetching'], false)
      .setIn(['branchesCostCentersList', 'isLoadingMore'], false)
      .setIn(['branchesCostCentersList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_BRANCHES_COST_CENTERS_FAILURE) {
    return state
      .setIn(['branchesCostCentersList', 'isFetching'], false)
      .setIn(['branchesCostCentersList', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_BRANCHES_COST_CENTERS_FILTER_DATA) {
    return state
      .setIn(['branchesCostCentersList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['branchesCostCentersList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }


  return state;
};


export default reducerFactory;
