import { lazy } from 'react';

const MyAccountPage = lazy(() => import(/* webpackChunkName: "user" */ './pages/myAccount/MyAccountPage'));

export default {
  name: 'my_account',
  path: 'moje-nastaveni',
  key: 'H1',
  component: MyAccountPage,
};
