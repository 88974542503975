import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';
import {
  IImmutableMap, IRootState, ReducerFactory,
} from '../../../data/storeModel';

export type FirmUserGroupState = IImmutableMap<IRootState['firm']['userGroup']>;
type Actions = types.Actions;


export const firmUserGroupsReducerFactory: ReducerFactory<FirmUserGroupState, Actions> = (initialState) => (state, action) => {
  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'userGroup']);
  }

  if (action.type === types.FETCH_USER_GROUP_PAIRS_REQUEST) {
    return state
      .setIn(['groupsPairs', 'isFetchingFailure'], false)
      .setIn(['groupsPairs', 'isFetching'], true);
  }

  if (action.type === types.FETCH_USER_GROUP_PAIRS_SUCCESS) {
    const positions = action.payload.map((item) => ({
      label: item.z01_name,
      value: item.z01_group_id,
    }));

    return state
      .setIn(['groupsPairs', 'items'], Immutable.fromJS(positions))
      .setIn(['groupsPairs', 'isFetching'], false);
  }

  if (action.type === types.FETCH_USER_GROUP_PAIRS_FAILURE) {
    return state
      .setIn(['groupsPairs', 'isFetching'], false)
      .setIn(['groupsPairs', 'isFetchingFailure'], true);
  }


  if (action.type === types.FETCH_USER_GROUPS_REQUEST) {
    return state
      .setIn(['groupsList', 'isFetchingFailure'], false)
      .setIn(['groupsList', 'isFetching'], !action.payload.isLoadingMore)
      .setIn(['groupsList', 'isLoadingMore'], !!action.payload.isLoadingMore)
      .setIn(['groupsList', 'fetchCount'], Immutable.fromJS(action.payload.limit))
      .setIn(['groupsList', 'limit'], Immutable.fromJS(action.payload.limit))
      .setIn(['groupsList', 'lastOffset'], Immutable.fromJS(action.payload.lastOffset))
      .setIn(['groupsList', 'orderBy'], Immutable.fromJS(action.payload.orderBy))
      .setIn(['groupsList', 'filter'], Immutable.fromJS(action.payload.filter));
  }

  if (action.type === types.FETCH_USER_GROUPS_SUCCESS) {
    const groups = action.payload.map((item) => ({
      data: {
        ...item,
        id: item.z01_group_id,
      },
      actions: {
        edit: true,
        delete: true,
      },
    }));

    const groupsList = state.get('groupsList');
    const fetchCount = groupsList.get('fetchCount');
    const lastOffset = groupsList.get('lastOffset');
    const stateTransactions = groupsList.get('rows');
    const lastCount = groups.length;

    return state
      .setIn(['groupsList', 'rows'], getEntitiesFromResponse(groups, stateTransactions, lastOffset, fetchCount))
      .setIn(['groupsList', 'lastCount'], lastCount)
      .setIn(['groupsList', 'isFetching'], false)
      .setIn(['groupsList', 'isLoadingMore'], false)
      .setIn(['groupsList', 'isFetchingFailure'], false);
  }

  if (action.type === types.FETCH_USER_GROUPS_FAILURE) {
    return state
      .setIn(['groupsList', 'isFetching'], false)
      .setIn(['groupsList', 'isFetchingFailure'], true);
  }

  if (action.type === types.UPDATE_USER_GROUPS_FILTER_DATA) {
    return state
      .setIn(['groupsList', 'filter'], Immutable.fromJS(action.payload.filter))
      .setIn(['groupsList', 'orderBy'], Immutable.fromJS(action.payload.orderBy));
  }

  if (action.type === types.FETCH_GROUP_USERS_REQUEST) {
    return state
      .setIn(['groupUsersList', 'isFetchingFailure'], false)
      .setIn(['groupUsersList', 'isFetching'], !action.payload.isLoadingMore)
      .setIn(['groupUsersList', 'isLoadingMore'], !!action.payload.isLoadingMore)
      .setIn(['groupUsersList', 'fetchCount'], Immutable.fromJS(action.payload.limit))
      .setIn(['groupUsersList', 'limit'], Immutable.fromJS(action.payload.limit))
      .setIn(['groupUsersList', 'lastOffset'], Immutable.fromJS(action.payload.lastOffset))
      .setIn(['groupUsersList', 'orderBy'], Immutable.fromJS(action.payload.orderBy))
      .setIn(['groupUsersList', 'filter'], Immutable.fromJS(action.payload.filter));
  }

  if (action.type === types.FETCH_GROUP_USERS_SUCCESS) {
    const { users, ...group } = action.payload;

    const rows = users.map((item) => ({
      data: {
        ...item,
        id: item.c30_company_user_id,
      },
      actions: {
        edit: group.z02_group_type_id === 1,
        delete: group.z02_group_type_id === 1,
      },
    }));

    const groupUsersList = state.get('groupUsersList');
    const fetchCount = groupUsersList.get('fetchCount');
    const lastOffset = groupUsersList.get('lastOffset');
    const stateTransactions = groupUsersList.get('rows');
    const lastCount = users.length;

    return state
      .setIn(['groupUsersList', 'rows'], getEntitiesFromResponse(rows, stateTransactions, lastOffset, fetchCount))
      .setIn(['groupUsersList', 'lastCount'], lastCount)
      .setIn(['groupUsersList', 'isFetching'], false)
      .setIn(['groupUsersList', 'isLoadingMore'], false)
      .setIn(['groupUsersList', 'isFetchingFailure'], false)
      .setIn(['groupUsersList', 'group'], group);
  }

  if (action.type === types.FETCH_GROUP_USERS_FAILURE) {
    return state
      .setIn(['groupUsersList', 'isFetching'], false)
      .setIn(['groupUsersList', 'isFetchingFailure'], true);
  }

  return state;
};
