import { RSAA } from 'redux-api-middleware';
import { browserHistory } from 'react-router';
import { API_URL } from '../../../../config/envspecific';
import { tRoute } from '../../../../i18n';
import { addFlashMessage } from '../../../flashMessages';
import * as types from './actionTypes';


const redirectToFirstLogin = () => new Promise((resolve) => {
  browserHistory.push(tRoute('/prvni-prihlaseni'));
  resolve();
});

const redirectToFirstLoginActivation = () => new Promise((resolve) => {
  browserHistory.push(tRoute('/prvni-prihlaseni/aktivace'));
  resolve();
});

const redirectToFirstLoginAuthorization = (cardNumber) => new Promise((resolve) => {
  browserHistory.push(tRoute(`/prvni-prihlaseni/autorizace?card=${cardNumber}`));
  resolve();
});

const registerCardApiCall = (cardNumber) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/login/card_reg`,
    method: 'POST',
    body: JSON.stringify({ card_no: cardNumber }),
    types: [
      types.REGISTER_CARD_REQUEST,
      types.REGISTER_CARD_SUCCESS,
      types.REGISTER_CARD_FAILURE,
    ],
  },
});

export const registerCard = (cardNumber) => (dispatch) => dispatch(registerCardApiCall(cardNumber))
  .then((response) => {
    if (response.type === types.REGISTER_CARD_SUCCESS) {
      redirectToFirstLoginActivation();
    } else if (response.type === types.REGISTER_CARD_FAILURE) {
      if (parseInt(response.payload.response.code, 10) === 131) {
        redirectToFirstLoginAuthorization(cardNumber);
      } else {
        dispatch(addFlashMessage('error', response.payload.response.message));
      }
    }
  });

const registerCardWithEmailApiCall = (cardNumber, mailWork, securityCode) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/login/card_reg_email`,
    method: 'POST',
    body: JSON.stringify({
      card_no: cardNumber,
      u01_mail_work: mailWork,
      u01_security_code: securityCode,
    }),
    types: [
      types.REGISTER_CARD_WITH_EMAIL_REQUEST,
      types.REGISTER_CARD_WITH_EMAIL_SUCCESS,
      types.REGISTER_CARD_WITH_EMAIL_FAILURE,
    ],
  },
});

export const registerCardWithEmail = (cardNumber, mailWork, securityCode) => (dispatch) => dispatch(registerCardWithEmailApiCall(cardNumber, mailWork, securityCode))
  .then((response) => {
    if (response.type === types.REGISTER_CARD_WITH_EMAIL_SUCCESS) {
      redirectToFirstLoginActivation();
    } else if (response.type === types.REGISTER_CARD_WITH_EMAIL_FAILURE) {
      dispatch(addFlashMessage('error', response.payload.response.message));

      if (parseInt(response.payload.response.code, 10) === 133) {
        redirectToFirstLogin();
      }
    }
  });
