import Immutable from 'immutable';
import { getLang } from '../i18n';


export const getLoad = (fetchEntities,
  getFilterFromUrl,
  saveFilter = undefined,
  filters = undefined) => (stateFilter, stateOrderBy, stateLimit) => {
  const filterFromUrl = getFilterFromUrl(window.location.search, filters);
  const orderBy = filterFromUrl.orderBy || stateOrderBy;
  const filter = Object.keys(filterFromUrl.filter).length ? { ...filterFromUrl.filter, ...stateFilter } : stateFilter;
  const limit = filterFromUrl.limit || stateLimit;

  if (saveFilter) {
    saveFilter(filter, orderBy);
  }
  fetchEntities(filter, orderBy, limit);
};

export const getLoadMore = (fetchEntities, saveFilter) => (filter, orderBy, limit, lastOffset, lastCount, fetchCount) => (
  fetchCount < lastCount ? () => {
    const newOffset = lastOffset + limit;
    saveFilter(filter, orderBy);
    fetchEntities(filter, orderBy, limit, newOffset, true);
  } : null);

export const getReload = (fetchEntities, saveFilter) => (filter, orderBy, limit, offset = 0) => {
  const newLimit = offset + limit;
  saveFilter(filter, orderBy);
  fetchEntities(filter, orderBy, newLimit);
};

export function getEntitiesFetchQueryString(filters, filter, orderBy, limit = undefined, offset = 0) {
  let orderString = 'order=';
  const limitString = limit ? `limit=${parseInt(limit, 10) + parseInt(1, 10)}` : '';
  const offsetString = `offset=${offset}`;
  const filterString = `filter=${filters
    .filter((f) => (filter[f.name] || filter[f.name] === 0) && !f.clientOnly) // Number(0) as valid filter value
    .map((f) => {
      if (f.getString) {
        return f.getString(filter[f.name], f.name);
      }

      return `${f.name}:${filter[f.name]}`;
    })
    .join(';')}`;

  if (orderBy && orderBy.column) {
    orderString += `${orderBy.column} ${orderBy.order === 'ASC' ? 'a' : 'd'}`;
  }

  return `${offsetString}&${limitString}&${orderString}&${filterString}`;
}

export const getFetchApiUrl = (url, filters, filter, orderBy) => {
  const queryString = getEntitiesFetchQueryString(filters, filter, orderBy, 100000, 0);
  const lang = getLang();
  const urlStr = typeof url === 'function' ? url(filter) : url;
  return `/${lang}/${urlStr}?${queryString}`;
};

export const getExportList = (downloadFn, apiUrl, colsOrCode, filters) => (filter, orderBy) => downloadFn(getFetchApiUrl(apiUrl, filters || [], filter, orderBy), colsOrCode);

export const getFetchEntities = (fetch, _filters, browserHistory, path, setUrlFromFilter) => (filter, orderBy, limit, offset = 0, isLoadingMore = false) => {
  const filters = typeof _filters === 'function' ? _filters() : _filters;
  const queryString = getEntitiesFetchQueryString(filters, filter, orderBy, limit, offset);

  fetch(
    queryString,
    {
      filter, orderBy, limit, lastOffset: offset, isLoadingMore,
    },
    () => {
      const urlLimit = !isLoadingMore ? limit : offset + limit;
      setUrlFromFilter(filter, orderBy, urlLimit, browserHistory, path, filters);
    }
  );
};

export const getEntitiesFromResponse = (resEntities, stateEntities, lastOffset, fetchCount) => {
  const lastCount = resEntities.length;
  if (lastCount > fetchCount) {
    resEntities.pop();
  }

  const resEntitiesI = Immutable.fromJS(resEntities);
  if (lastOffset > 0) {
    return stateEntities.concat(resEntitiesI);
  }

  return resEntitiesI;
};

export const getFilterQueryFromMap = (filter, allowed) => {
  const filters = Object.keys(filter || []).filter((name) => !allowed || allowed.includes(name));

  const filterString = `filter=${filters
    .filter((name) => (filter[name] || filter[name] === 0))
    .map((name) => `${name}:${filter[name]}`)
    .join(';')}`;

  return filterString;
};

export const getDownloadReport = (downloadCallback, _filters) => (filter, orderBy) => {
  const filters = typeof _filters === 'function' ? _filters() : _filters;
  const queryString = getEntitiesFetchQueryString(filters, filter, orderBy);
  return downloadCallback(queryString, { filter, orderBy });
};
