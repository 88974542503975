import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../../component1/Card';
import List from '../../../../component1/List/List';
import RaisedButton from '../../../../component1/RaisedButton/RaisedButton';
import { widgetMinHeight } from '../../../global/constants';


//   Do widgetu dať info:
//   pracovné dni: 08:00 - 16:30 (working days: 08:00 - 16:30)
// (aby vedeli, kedy môžu volať a písať na Podporu, respektíve kedy sa dočkajú odpovede).

const HelpWidget = ({ t }) => (
  <Card title={t('Podpora')} layout="centered" minHeight={widgetMinHeight}>
    <List>
      <RaisedButton
        size="large"
        label={t('02 / 325 535 67')}
        externalHref={`tel:${t('02 / 325 535 67').replace(/ /g, '')}`}
        icon="phone"
        isBlock
      />
      <RaisedButton
        size="large"
        label={t('info@up-gusto.sk')}
        externalHref={`mailto:${t('info@up-gusto.sk')}`}
        icon="email"
        isBlock
      />
      <p>{t('Pracovné dni: 08:00 - 16:30')}</p>
    </List>
  </Card>
);

HelpWidget.propTypes = {
  t: PropTypes.func.isRequired,
};

export default HelpWidget;
