import { setOffline } from '../modules/global/actions';
import { MOBILE_APP } from '../config/envspecific';
import { SET_APP_ACTIVE } from '../modules/global/actionTypes';

// private
const setAppActive = (active) => ({
  type: SET_APP_ACTIVE,
  payload: { active },
});

export const initOfflineState = () => setOffline(navigator.onLine === false);
export const appActiveChange = (active) => (dispatch) => dispatch(setAppActive(active));

export const initOfflineHooks = () => (dispatch) => {
  if (navigator.onLine !== undefined) {
    dispatch(initOfflineState());
    const target = MOBILE_APP ? document : window;
    target.addEventListener('offline', () => dispatch(setOffline(true)), false);
    target.addEventListener('online', () => dispatch(setOffline(false)), false);
  }
};

export const initPageVisibleHooks = () => (dispatch) => {
  dispatch(appActiveChange(!document.hidden));
  const target = MOBILE_APP ? document : window;
  target.addEventListener('visibilitychange', () => dispatch(appActiveChange(!document.hidden)), false);
};
