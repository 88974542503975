import { ActionWithPayload, ApiActions } from '../../../data/storeModel';
import { NewsItemType } from '../../global/model';

export const FETCH_MY_NEWS_REQUEST = 'firm/home/FETCH_MY_NEWS_REQUEST';
export const FETCH_MY_NEWS_SUCCESS = 'firm/home/FETCH_MY_NEWS_SUCCESS';
export const FETCH_MY_NEWS_FAILURE = 'firm/home/FETCH_MY_NEWS_FAILURE';

export const FETCH_MY_NEWS_ITEM_REQUEST = 'firm/home/FETCH_MY_NEWS_ITEM_REQUEST';
export const FETCH_MY_NEWS_ITEM_SUCCESS = 'firm/home/FETCH_MY_NEWS_ITEM_SUCCESS';
export const FETCH_MY_NEWS_ITEM_FAILURE = 'firm/home/FETCH_MY_NEWS_ITEM_FAILURE';

export const UPDATE_NEWS_FILTER_DATA = 'firm/home/UPDATE_NEWS_FILTER_DATA';

export type Actions =
  ActionWithPayload<typeof UPDATE_NEWS_FILTER_DATA> |
  ApiActions<typeof FETCH_MY_NEWS_REQUEST, typeof FETCH_MY_NEWS_SUCCESS, typeof FETCH_MY_NEWS_FAILURE, NewsItemType[], any> |
  ApiActions<typeof FETCH_MY_NEWS_ITEM_REQUEST, typeof FETCH_MY_NEWS_ITEM_SUCCESS, typeof FETCH_MY_NEWS_ITEM_FAILURE, NewsItemType, any>;
