import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory, Router } from 'react-router';
import { withTranslation } from 'react-i18next';
import { tRoute, languagesSupported } from './i18n/init';
import { addFlashMessage } from './modules/flashMessages';
import {
  requestLogUserInAsUser,
  requestLoginInfo,
} from './modules/auth';
import {
  setOrderFileId,
  setOrderInProgress,
  selectContract,
} from './modules/order/actions';
import {
  setMyOrderInProgress,
} from './modules/my/resources/order';
import { authFactory } from './modules/auth/Auth';
import matchRoutePure from './router/matchRoute';
import {
  getRoutingTable,
  updateRoutingTableLocale,
  getLocalizedRoutingTable,
  matchRoute,
  redirectRoute,
  isPathAuthorized,
  modifyRoutes,
  generatePath,
} from './router';
import { MOBILE_APP } from './config/envspecific';
import CordovaExtension from './cordova/CordovaExtension';
import parsePath from './router/parsePath';

export class BaseRootRouter extends Component {
  static propTypes = {
    // eslint-disable-next-line
    store: PropTypes.object.isRequired,
    // eslint-disable-next-line
    i18n: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
  };

  lastRouteUrl = null;

  generation = 0;

  constructor(props) {
    super(props);
    this.state = this.getRouterState();
    props.i18n.on('languageChanged', this.onLangChanged);
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.onLangChanged);
  }

  getRouterState() {
    this.generation += 1;
    updateRoutingTableLocale((key, options) => this.props.t(key, options));

    const lang = this.props.i18n.language || this.props.i18n.options.fallbackLng[0];

    const routingTable = modifyRoutes(getRoutingTable(), (r) => ({
      onEnter: r.name === 'error_404' && r.path === '*' ? this.onEnterHookCheckLocalizedURL : (r.onEnter || this.onEnterDefaultHook),
      onLeave: r.onLeave, // || this.onLeaveDefaultHook,
    }));

    return {
      routerLang: lang,
      router: (<Router
        key={`router${this.generation}`}
        routes={routingTable}
        history={browserHistory}
        onUpdate={this.handleRouteUpdate}
      />),
    };
  }

  /**
   * Updates routing table with new translations and do the redirect if url changed.
   * !! replaces whole Router component tree
   */
  onLangChanged = (lang) => {
    // console.log('onLangChanged', this.state.routerLang, '=>', lang);
    if (this.state.routerLang === lang) {
      // no lang change
      return;
    }
    // update router only
    // route is updated by onEnterHookCheckLocalizedURL after new router initialziation
    this.setState(this.getRouterState());
  }

  onEnterHookCheckLocalizedURL = () => {
    const pathname = window.location.pathname;
    // console.log('onEnterHookCheckLocalizedURL', pathname);

    let matchedRoute = null;

    languagesSupported.some((lng) => {
      const tbl = getLocalizedRoutingTable((key, options) => this.props.t(key, options), { lng });
      const route = matchRoutePure(tbl, pathname);

      if (route.name !== 'error_404') {
        matchedRoute = route;
        return true;
      }
      return false;
    });

    const parsed = matchedRoute && parsePath(matchedRoute.fullPath, pathname);

    // found non-404 page -> redirect to it
    if (matchedRoute && parsed) {
      const url = generatePath(matchedRoute.name, parsed.params, 'allow-all');
      // console.log('redirecting localized URL planned', matchedRoute, parsed, window.location, '=>', url);

      if (url) {
        // be sure to call redirect after new router is in place
        this.setState(() => {
          // console.log('redirecting localized URL started', matchedRoute, parsed, window.location, '=>', url);
          browserHistory.push(`${url}${window.location.search}`);
        });
      }
    }
  }

  onEnterDefaultHook = (nextState, replace) => {
    const t = this.props.t;
    const authorizedPageKeys = this.props.store.getState().getIn(['auth', 'authorizedPages', 'pages']).toJS();
    // console.log('onEnterDefaultHook', window.location.pathname, nextState, authorizedPageKeys);

    if (!authorizedPageKeys || authorizedPageKeys.length === 0) {
      // leave it to onUpdate handler with more complex logic
      return;
    }

    const userIsAuthorized = isPathAuthorized(window.location.pathname, authorizedPageKeys);

    if (userIsAuthorized) {
      return;
    }

    let url = null;

    // better ux -> stay on the same page with error message
    if (MOBILE_APP) {
      this.props.store.dispatch(addFlashMessage('error', t('Přístup zamítnut')));

      // previous url
      url = this.lastRouteUrl || '/'; // fallback
    }

    replace(url || generatePath('error_404', {}, authorizedPageKeys));
  }

  // UNSAFE_componentWillMount() {
  //   const localizedRoutes = getLocalizedRoute(routingTable);
  //   console.log(JSON.stringify(routesParts.filter((value, index, self) => self.indexOf(value) === index)
  //     .reduce((acc, curr) => ({ ...acc, [curr]: curr }), {})));
  //   this.setState({ routingTable: localizedRoutes });
  // }

  getAuth = () => authFactory();

  redirectToHomepageIfLoggedIn = (authorizedPageKeys) => {
    const auth = this.getAuth();

    if (auth.isLoggedIn()) {
      redirectRoute('homepage', {}, authorizedPageKeys);
    }
  };

  handlePageNotAuthorized(authorizedPageKeys) {
    const t = this.props.t;
    // fallback when onEnterDefaultHook wasn't called
    // do not show 404 in MOBILE APP
    if (MOBILE_APP) {
      browserHistory.goBack();
      this.props.store.dispatch(addFlashMessage('error', t('Přístup zamítnut')));
    } else {
      redirectRoute('error_404', {}, authorizedPageKeys);
    }
  }

  fetchLoginInfo = () => {
    const { store } = this.props;
    store.dispatch(requestLoginInfo()).then((response) => {
      const matchedRoute = matchRoute(window.location.pathname);

      // login info fetch failed (eg. offline?)
      if (!response && !matchedRoute.loginOptional) {
        redirectRoute('login', { r: 'r' }, []);
        return false;
      }

      const authorizedPageKeys = store.getState().getIn(['auth', 'authorizedPages', 'pages']).toJS();
      const userIsAuthorized = isPathAuthorized(window.location.pathname, authorizedPageKeys);
      const userIsLoggedIn = this.getAuth().isLoggedIn();

      if (matchedRoute.requireLogout) {
        this.redirectToHomepageIfLoggedIn(authorizedPageKeys);
      }

      if (!userIsAuthorized && !userIsLoggedIn) {
        redirectRoute('login', { r: 'r' }, authorizedPageKeys);
        return false;
      }

      if (!userIsAuthorized && userIsLoggedIn && matchedRoute.name !== 'error_404') {
        this.handlePageNotAuthorized(authorizedPageKeys);
        return false;
      }

      if (response && response.data.pending_my_order.has_pending_order) {
        store.dispatch(setMyOrderInProgress(true));
      }

      if (response && response.data.pending_order.has_pending_order) {
        store.dispatch(setOrderFileId(parseInt(response.data.pending_order.o50_order_file, 10)));
        store.dispatch(setOrderInProgress(response.data.pending_order.has_pending_order));
        store.dispatch(selectContract({
          c01_name: response.data.pending_order.c01_name,
          c10_contract_id: response.data.pending_order.c10_contract_id,
          c01_company_id: response.data.pending_order.c01_company_id,
        }));
      }
      return true;
    });

    return true;
  };

  getUrlParameterByName = (location, name) => {
    const match = new RegExp(`[?&]${name}=([^&]*)`).exec(location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  };

  handleRouteUpdate = () => {
    const { store } = this.props;
    // console.log('handleRouteUpdate', window.location.pathname);
    const loginAsUserHash = this.getUrlParameterByName(window.location, 'u90_hash');
    if (loginAsUserHash && window.location.pathname.startsWith(tRoute('/prihlaseni'))) {
      store.dispatch(requestLogUserInAsUser(loginAsUserHash));
      return false;
    }

    const userIsLoggedIn = this.getAuth().isLoggedIn();
    const authorizedPageKeys = store.getState().getIn(['auth', 'authorizedPages', 'pages']).toJS();

    // auto login (session restore)
    if (userIsLoggedIn && (!authorizedPageKeys || !authorizedPageKeys.length)) {
      const isSessionInFailure = store.getState().getIn(['auth', 'isSessionInFailure']);
      if (!isSessionInFailure) {
        this.fetchLoginInfo();
      }
      return false;
    }

    const matchedRoute = matchRoute(window.location.pathname);
    const userIsAuthorized = isPathAuthorized(window.location.pathname, authorizedPageKeys);

    if (matchedRoute.requireLogout) {
      this.redirectToHomepageIfLoggedIn(authorizedPageKeys);
    }

    if (!userIsAuthorized && !userIsLoggedIn) {
      redirectRoute('login', { r: 'r' }, authorizedPageKeys);
      return false;
    }

    if (!userIsAuthorized && userIsLoggedIn && matchedRoute.name !== 'error_404') {
      this.handlePageNotAuthorized(authorizedPageKeys);
      return false;
    }

    window.scrollTo(0, 0);
    this.lastRouteUrl = browserHistory.getCurrentLocation().pathname;
    return true;
  }

  render() {
    return (
      [
        this.state.router,
        MOBILE_APP && <CordovaExtension key="cordova" router={browserHistory} />,
      ]);
  }
}

export const RootRouter = withTranslation()(BaseRootRouter);
