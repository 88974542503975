import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import styles from './ContributionOrderWidget.scss';
import { moneyFormat3 } from '../../../../service/number';
// import { PropTypeWidgetContribution } from '../../../global/proptypes';

export const ConfirmPageContent = ({ t, orderAmount }) => (
  <div className={styles.content}>
    <h3>{t('Potvrzení objednávky')}</h3>
    <p>
      <Trans i18nKey="contributionOrderConfirm" t={t}>
        Chcete potvrdit objednávku ve výši
        {' '}
        <strong>{{ amount: moneyFormat3(orderAmount, t) }}</strong>
        ?
      </Trans>

    </p>
  </div>
);

ConfirmPageContent.propTypes = {
  t: PropTypes.func.isRequired,
  orderAmount: PropTypes.number,
  // data: PropTypeWidgetContribution.isRequired,
};
