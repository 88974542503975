import { RSAA } from 'redux-api-middleware';
import { BENEFIA_API_URL } from '../../config/envspecific';
import * as types from './actionTypes';
import { ThunkApiActionCreator } from '../../data/storeModel';
import { apiErrorMessageHandler } from '../../service/helpers';

export const requestBenefiaLoginInfo: ThunkApiActionCreator<any, any, any> = () => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${BENEFIA_API_URL}/login`,
    method: 'GET',
    types: [
      types.FETCH_LOGIN_DATA_REQUEST,
      types.FETCH_LOGIN_DATA_SUCCESS,
      types.FETCH_LOGIN_DATA_FAILURE,
    ],
  },
}).then((response) => (response && !response.error && response.payload) || null);

export const fetchBenefiaWidgets: ThunkApiActionCreator = () => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${BENEFIA_API_URL}/widgets`,
    method: 'GET',
    types: [
      types.FETCH_WIDGETS_REQUEST,
      types.FETCH_WIDGETS_SUCCESS,
      types.FETCH_WIDGETS_FAILURE,
    ],
  },
});

export const saveBenefiaOrder: ThunkApiActionCreator = (data: { company_user_id: number; price: number; quantity: number }) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${BENEFIA_API_URL}/order/cadhoc/create`,
    method: 'POST',
    body: JSON.stringify(data),
    types: [
      types.SAVE_BENEFIA_ORDER_REQUEST,
      types.SAVE_BENEFIA_ORDER_SUCCESS,
      types.SAVE_BENEFIA_ORDER_FAILURE,
    ],
  },
}).then(apiErrorMessageHandler(dispatch));
