import Immutable from 'immutable';
import * as types from './actionTypes';
import { getEntitiesFromResponse } from '../../../service/entityList';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.getIn(['partner', 'gatePayment']);
  }

  if (type === types.FETCH_GATE_PAYMENTS_REQUEST) {
    return state
      .setIn(['paymentsList', 'isFetchingFailure'], false)
      .setIn(['paymentsList', 'isFetching'], !payload.isLoadingMore)
      .setIn(['paymentsList', 'isLoadingMore'], !!payload.isLoadingMore)
      .setIn(['paymentsList', 'fetchCount'], Immutable.fromJS(payload.limit))
      .setIn(['paymentsList', 'limit'], Immutable.fromJS(payload.limit))
      .setIn(['paymentsList', 'lastOffset'], Immutable.fromJS(payload.lastOffset))
      .setIn(['paymentsList', 'orderBy'], Immutable.fromJS(payload.orderBy))
      .setIn(['paymentsList', 'filter'], Immutable.fromJS(payload.filter));
  }

  if (type === types.FETCH_GATE_PAYMENTS_SUCCESS) {
    const payments = payload.map((item) => ({ data: item }));
    const paymentsList = state.get('paymentsList');
    const fetchCount = paymentsList.get('fetchCount');
    const lastOffset = paymentsList.get('lastOffset');
    const stateTransactions = paymentsList.get('payments');
    const lastCount = payments.length;

    return state
      .setIn(['paymentsList', 'payments'], getEntitiesFromResponse(payments, stateTransactions, lastOffset, fetchCount))
      .setIn(['paymentsList', 'lastCount'], lastCount)
      .setIn(['paymentsList', 'isFetching'], false)
      .setIn(['paymentsList', 'isLoadingMore'], false)
      .setIn(['paymentsList', 'isFetchingFailure'], false);
  }

  if (type === types.FETCH_GATE_PAYMENTS_FAILURE) {
    return state
      .setIn(['paymentsList', 'isFetching'], false)
      .setIn(['paymentsList', 'isFetchingFailure'], true);
  }

  if (type === types.UPDATE_GATE_PAYMENTS_FILTER_DATA) {
    return state
      .setIn(['paymentsList', 'filter'], Immutable.fromJS(payload.filter))
      .setIn(['paymentsList', 'orderBy'], Immutable.fromJS(payload.orderBy));
  }

  return state;
};


export default reducerFactory;
