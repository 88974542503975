import autodetectEnv from './autodetect';

const env = autodetectEnv();

// Must be 'production' or 'dev'
export const ENVIRONMENT = env.envType;

// Including protocol and must not end with /
export const API_URL = env.apiUrl;

export const BENEFIA_API_URL = env.benefiaApiUrl;

// Google map API key
export const GOOGLE_MAP_API_KEY = env.googleMapKey;

// Default timeout length [ms]
export const DEFAULT_TIMEOUT_LENGTH = 5000;

export const ENV_NAME = env.envName; // gcdev, gustokarta etc..

// app instance cz | sk
export const INSTANCE = env.instance;
export const INSTANCE_CZ = env.instance === 'cz';
export const INSTANCE_SK = env.instance === 'sk';

// app lang (locale identifier: cs, sk)
export const LANG = env.defaultLang;

export const NON_PROD_ENV = env.envName !== 'gustokarta';

export const MOBILE_APP = !!window.cordova;
