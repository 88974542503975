export const FETCH_ATTENDANCE_TODAY_REQUEST = 'my/attendance/FETCH_ATTENDANCE_TODAY_REQUEST';
export const FETCH_ATTENDANCE_TODAY_SUCCESS = 'my/attendance/FETCH_ATTENDANCE_TODAY_SUCCESS';
export const FETCH_ATTENDANCE_TODAY_FAILURE = 'my/attendance/FETCH_ATTENDANCE_TODAY_FAILURE';

export const UPDATE_ATTENDANCE_TODAY_FILTER_DATA = 'my/attendance/UPDATE_ATTENDANCE_TODAY_FILTER_DATA';

export const FETCH_ATTENDANCE_EMPLOYEE_REQUEST = 'my/attendance/FETCH_ATTENDANCE_EMPLOYEE_REQUEST';
export const FETCH_ATTENDANCE_EMPLOYEE_SUCCESS = 'my/attendance/FETCH_ATTENDANCE_EMPLOYEE_SUCCESS';
export const FETCH_ATTENDANCE_EMPLOYEE_FAILURE = 'my/attendance/FETCH_ATTENDANCE_EMPLOYEE_FAILURE';

export const UPDATE_ATTENDANCE_EMPLOYEE_FILTER_DATA = 'my/attendance/UPDATE_ATTENDANCE_EMPLOYEE_FILTER_DATA';
