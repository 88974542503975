
const mobilePages = [
  'H', // home
  'H1', // my account
  'U1', 'U7', // my / cards
  'U10', 'U9', 'U6', // my / places
  'U4', // my / transactions
  'U17', // my / bank_card_overview
  'U18', // my / attendance
];

export function updateAuthorizedPages(pages) {
  return pages && pages.filter((page) => mobilePages.includes(page));
}
