import React, { lazy } from 'react';
import { SignUpCenteredLayout } from '../layout/main1';

const FirstLoginContainer = lazy(() => import(/* webpackChunkName: "registration" */ './pages/firstLogin/FirstLoginContainer'));
const FirstLoginAuthorizationContainer = lazy(() => import(/* webpackChunkName: "registration" */ './pages/firstLoginAuthorization/FirstLoginAuthorizationContainer'));
const FirstLoginActivationContainer = lazy(() => import(/* webpackChunkName: "registration" */ './pages/firstLoginActivation/FirstLoginActivationContainer'));

export default {
  name: 'first_login',
  path: 'prvni-prihlaseni',
  component: (props) => <SignUpCenteredLayout {...props} />,
  indexRoute: { component: FirstLoginContainer },
  requireLogout: true,
  childRoutes: [
    {
      name: 'first_login_authorization',
      path: 'autorizace',
      component: FirstLoginAuthorizationContainer,
    },
    {
      name: 'firm_registration_activation',
      path: 'aktivace',
      component: FirstLoginActivationContainer,
    },
    // not used
    // {
    //   name: 'firm_registration_set_password',
    //   path: 'nastaveni-hesla',
    //   component: FirstLoginSetPasswordContainer,
    // },
  ],
};
