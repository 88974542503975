export const FETCH_CARD_REQUEST = 'firm/card/FETCH_CARD_REQUEST';
export const FETCH_CARD_SUCCESS = 'firm/card/FETCH_CARD_SUCCESS';
export const FETCH_CARD_FAILURE = 'firm/card/FETCH_CARD_FAILURE';

export const ASSIGN_USER_REQUEST = 'firm/card/ASSIGN_USER_REQUEST';
export const ASSIGN_USER_SUCCESS = 'firm/card/ASSIGN_USER_SUCCESS';
export const ASSIGN_USER_FAILURE = 'firm/card/ASSIGN_USER_FAILURE';

export const FETCH_CARDS_REQUEST = 'firm/card/FETCH_CARDS_REQUEST';
export const FETCH_CARDS_SUCCESS = 'firm/card/FETCH_CARDS_SUCCESS';
export const FETCH_CARDS_FAILURE = 'firm/card/FETCH_CARDS_FAILURE';

export const UPDATE_FIRM_CARDS_FILTER_DATA = 'firm/card/UPDATE_FIRM_CARDS_FILTER_DATA';

export const SET_CARD_STATUS_REQUEST = 'firm/card/SET_CARD_STATUS_REQUEST';
export const SET_CARD_STATUS_SUCCESS = 'firm/card/SET_CARD_STATUS_SUCCESS';
export const SET_CARD_STATUS_FAILURE = 'firm/card/SET_CARD_STATUS_FAILURE';

export const FETCH_FIRM_CARD_STATUS_PAIRS_REQUEST = 'firm/card/FETCH_FIRM_CARD_STATUS_PAIRS_REQUEST';
export const FETCH_FIRM_CARD_STATUS_PAIRS_SUCCESS = 'firm/card/FETCH_FIRM_CARD_STATUS_PAIRS_SUCCESS';
export const FETCH_FIRM_CARD_STATUS_PAIRS_FAILURE = 'firm/card/FETCH_FIRM_CARD_STATUS_PAIRS_FAILURE';

export const FETCH_CARD_DISTR_ADDRESS_PAIRS_REQUEST = 'firm/card/FETCH_CARD_DISTR_ADDRESS_PAIRS_REQUEST';
export const FETCH_CARD_DISTR_ADDRESSES_PAIRS_SUCCESS = 'firm/card/FETCH_CARD_DISTR_ADDRESSES_PAIRS_SUCCESS';
export const FETCH_CARD_DISTR_ADDRESSES_PAIRS_FAILURE = 'firm/card/FETCH_CARD_DISTR_ADDRESSES_PAIRS_FAILURE';
