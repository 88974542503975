import originalRoutingTable from './routingTable';
import generatePathPure from './generatePath';
import isPathAuthorizedPure from './isPathAuthorized';
import isRouteAuthorizedPure from './isRouteAuthorized';
import isRouteWithContractAuthorizedPure from './isRouteWithContractAuthorized';
import matchRoutePure from './matchRoute';
import redirectRoutePure from './redirectRoute';
import { tRoute } from '../i18n';

export const modifyRoutes = (route, modifier) => ({
  ...route,
  ...modifier(route),
  childRoutes: route.childRoutes && route.childRoutes.map((r) => modifyRoutes(r, modifier)),
});

const getLocalizedRoute = (route, t, options) => modifyRoutes(route, (r) => ({ path: r.path !== undefined && t !== null ? tRoute(r.path, t, options) : r.path }));
export const getLocalizedRoutingTable = (t, options) => getLocalizedRoute(originalRoutingTable, t, options);

let routingTable = originalRoutingTable;
export const getRoutingTable = () => routingTable;
export const updateRoutingTableLocale = (t) => { routingTable = getLocalizedRoutingTable(t); };


/**
 * Generate path from the route.
 * @param name Name of the route.
 * @param parameters Parameters object.
 * @param authorizedPageKeys Authorized page keys array.
 * @return Returns path of null if route is not found.
 */
export const generatePath = (name, parameters, authorizedPageKeys) => generatePathPure(routingTable, name, parameters, authorizedPageKeys);

/**
 * Get active route.
 * @throws Throws error when the route is not found.
 * @return Returns active route object (with name, key and requireLogout flag).
 */
export const getActiveRoute = () => matchRoutePure(routingTable, window.location.pathname);

/**
 * Check whether the user is authorized to access the path.
 * @param path The path to check.
 * @param authorizedPageKeys Authorized page keys array.
 * @throws Throws error when the route is not found.
 * @return Returns true if authorized, otherwise false.
 */
export const isPathAuthorized = (path, authorizedPageKeys) => isPathAuthorizedPure(routingTable, path, authorizedPageKeys);

/**
 * Check whether the user is authorized to access the route.
 * @param name Name of the route.
 * @param parameters Parameters object.
 * @param authorizedPageKeys Authorized page keys array.
 * @return Returns true if authorized, otherwise false.
 */
export const isRouteAuthorized = (name, parameters, authorizedPageKeys) => isRouteAuthorizedPure(routingTable, name, parameters, authorizedPageKeys);

/**
 * Check whether the user is authorized to access the route with contact right.
 * Route without key is automatically resolved as unauthorized.
 * @param name Name of the route.
 * @param parameters Parameters object.
 * @param contractId Currect contract id.
 * @param contractRights Contract rights object.
 * @return Returns true if route has key and it is authorized, otherwise false.
 */
export const isRouteWithContractAuthorized = (name, parameters, contractId, contractRights) => isRouteWithContractAuthorizedPure(routingTable, name, parameters, contractId, contractRights);

/**
 * Matches the route which is defined in the routing table.
 * @param path The path to match.
 * @return Returns route object (with name, key and requireLogout flag)
 *         or null if the route is not found.
 */
export const matchRoute = (path) => matchRoutePure(routingTable, path);

/**
 * Redirect to route.
 * @param name Name of the route.
 * @param parameters Parameters of the route.
 * @param  authorizedPageKeys Authorized page keys array.
 * @throws Throws error when the route is not found.
 */
export const redirectRoute = (name, parameters, authorizedPageKeys) => redirectRoutePure(routingTable, name, parameters, authorizedPageKeys);
