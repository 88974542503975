export const FETCH_WIDGETS_REQUEST = 'FETCH_WIDGETS_REQUEST';
export const FETCH_WIDGETS_SUCCESS = 'FETCH_WIDGETS_SUCCESS';
export const FETCH_WIDGETS_FAILURE = 'FETCH_WIDGETS_FAILURE';

export const FETCH_PORTAL_WIDGETS_REQUEST = 'FETCH_PORTAL_WIDGETS_REQUEST';
export const FETCH_PORTAL_WIDGETS_SUCCESS = 'FETCH_PORTAL_WIDGETS_SUCCESS';
export const FETCH_PORTAL_WIDGETS_FAILURE = 'FETCH_PORTAL_WIDGETS_FAILURE';

export const FETCH_WIDGET_PH10_REQUEST = 'FETCH_WIDGET_PH10_REQUEST';
export const FETCH_WIDGET_PH10_SUCCESS = 'FETCH_WIDGET_PH10_SUCCESS';
export const FETCH_WIDGET_PH10_FAILURE = 'FETCH_WIDGET_PH10_FAILURE';

export const SAVE_WIDGETS_REQUEST = 'SAVE_WIDGETS_REQUEST';
export const SAVE_WIDGETS_SUCCESS = 'SAVE_WIDGETS_SUCCESS';
export const SAVE_WIDGETS_FAILURE = 'SAVE_WIDGETS_FAILURE';

export const FETCH_WIDGET_PH30_REQUEST = 'FETCH_WIDGET_PH30_REQUEST';
export const FETCH_WIDGET_PH30_SUCCESS = 'FETCH_WIDGET_PH30_SUCCESS';
export const FETCH_WIDGET_PH30_FAILURE = 'FETCH_WIDGET_PH30_FAILURE';

export const SAVE_WIDGET_PH31_REQUEST = 'SAVE_WIDGET_PH31_REQUEST';
export const SAVE_WIDGET_PH31_SUCCESS = 'SAVE_WIDGET_PH31_SUCCESS';
export const SAVE_WIDGET_PH31_FAILURE = 'SAVE_WIDGET_PH31_FAILURE';

export const SAVE_WIDGET_PH32_REQUEST = 'SAVE_WIDGET_PH32_REQUEST';
export const SAVE_WIDGET_PH32_SUCCESS = 'SAVE_WIDGET_PH32_SUCCESS';
export const SAVE_WIDGET_PH32_FAILURE = 'SAVE_WIDGET_PH32_FAILURE';

export const SET_WIDGET_PH32_AFFILIATE_ID = 'SET_WIDGET_PH32_AFFILIATE_ID';
export const FETCH_WIDGET_PH32_PAYMENTS_REQUEST = 'FETCH_WIDGET_PH32_PAYMENTS_REQUEST';
export const FETCH_WIDGET_PH32_PAYMENTS_SUCCESS = 'FETCH_WIDGET_PH32_PAYMENTS_SUCCESS';
export const FETCH_WIDGET_PH32_PAYMENTS_FAILURE = 'FETCH_WIDGET_PH32_PAYMENTS_FAILURE';

export const SAVE_WIDGET_PH33_REQUEST = 'SAVE_WIDGET_PH33_REQUEST';
export const SAVE_WIDGET_PH33_SUCCESS = 'SAVE_WIDGET_PH33_SUCCESS';
export const SAVE_WIDGET_PH33_FAILURE = 'SAVE_WIDGET_PH33_FAILURE';

export const SAVE_WIDGET_PH40_CONFIRM_TERMS_REQUEST = 'SAVE_WIDGET_PH40_CONFIRM_TERMS_REQUEST';
export const SAVE_WIDGET_PH40_CONFIRM_TERMS_SUCCESS = 'SAVE_WIDGET_PH40_CONFIRM_TERMS_SUCCESS';
export const SAVE_WIDGET_PH40_CONFIRM_TERMS_FAILURE = 'SAVE_WIDGET_PH40_CONFIRM_TERMS_FAILURE';

export const SAVE_WIDGET_PH40_CREATE_ORDER_REQUEST = 'SAVE_WIDGET_PH40_CREATE_ORDER_REQUEST';
export const SAVE_WIDGET_PH40_CREATE_ORDER_SUCCESS = 'SAVE_WIDGET_PH40_CREATE_ORDER_SUCCESS';
export const SAVE_WIDGET_PH40_CREATE_ORDER_FAILURE = 'SAVE_WIDGET_PH40_CREATE_ORDER_FAILURE';

export const SAVE_WIDGET_PH40_SET_PAYMENT_REQUEST = 'SAVE_WIDGET_PH40_SET_PAYMENT_REQUEST';
export const SAVE_WIDGET_PH40_SET_PAYMENT_SUCCESS = 'SAVE_WIDGET_PH40_SET_PAYMENT_SUCCESS';
export const SAVE_WIDGET_PH40_SET_PAYMENT_FAILURE = 'SAVE_WIDGET_PH40_SET_PAYMENT_FAILURE';

export const SAVE_WIDGET_PH40_ONLINE_CONFIRM_REQUEST = 'SAVE_WIDGET_PH40_ONLINE_CONFIRM_REQUEST';
export const SAVE_WIDGET_PH40_ONLINE_CONFIRM_SUCCESS = 'SAVE_WIDGET_PH40_ONLINE_CONFIRM_SUCCESS';
export const SAVE_WIDGET_PH40_ONLINE_CONFIRM_FAILURE = 'SAVE_WIDGET_PH40_ONLINE_CONFIRM_FAILURE';

export const CONFIRM_WIDGET_PH70_REQUEST = 'CONFIRM_WIDGET_PH70_REQUEST';
export const CONFIRM_WIDGET_PH70_SUCCESS = 'CONFIRM_WIDGET_PH70_SUCCESS';
export const CONFIRM_WIDGET_PH70_FAILURE = 'CONFIRM_WIDGET_PH70_FAILURE';

export const DOWNLOAD_INVOICES_PDF_REQUEST = 'home/order/DOWNLOAD_INVOICES_PDF_REQUEST';
export const DOWNLOAD_INVOICES_PDF_SUCCESS = 'home/order/DOWNLOAD_INVOICES_PDF_SUCCESS';
export const DOWNLOAD_INVOICES_PDF_FAILURE = 'home/order/DOWNLOAD_INVOICES_PDF_FAILURE';

export const SET_WIDGET = 'SET_WIDGET';
export const SET_POPUP_BANNER_SHOWN = 'SET_POPUP_BANNER_SHOWN';
