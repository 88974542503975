import PropTypes from 'prop-types';
import React from 'react';
import styles from './Grid.scss';


const colSpans = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const colOffsets = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

class Col extends React.Component {
  getClassNames() {
    const colClasses = [];

    if (this.props.className) {
      colClasses.push(this.props.className);
    }

    if (this.props.flex) {
      colClasses.push(styles.col_flex);
    }

    if (this.props.xs) {
      const className = styles[Number.isInteger(this.props.xs) ? `col_xs_${this.props.xs}` : 'col_xs'];
      colClasses.push(className);
    }

    if (this.props.sm) {
      const className = styles[Number.isInteger(this.props.sm) ? `col_sm_${this.props.sm}` : 'col_sm'];
      colClasses.push(className);
    }

    if (this.props.md) {
      const className = styles[Number.isInteger(this.props.md) ? `col_md_${this.props.md}` : 'col_md'];
      colClasses.push(className);
    }

    if (this.props.lg) {
      const className = styles[Number.isInteger(this.props.lg) ? `col_lg_${this.props.lg}` : 'col_lg'];
      colClasses.push(className);
    }

    if (this.props.xl) {
      const className = styles[Number.isInteger(this.props.xl) ? `col_xl_${this.props.xl}` : 'col_xl'];
      colClasses.push(className);
    }

    if (this.props.xsOffset) {
      const className = styles[`col_xs_offset_${this.props.xsOffset}`];
      colClasses.push(className);
    }

    if (this.props.smOffset) {
      const className = styles[`col_sm_offset_${this.props.smOffset}`];
      colClasses.push(className);
    }

    if (this.props.mdOffset) {
      const className = styles[`col_md_offset_${this.props.mdOffset}`];
      colClasses.push(className);
    }

    if (this.props.lgOffset) {
      const className = styles[`col_lg_offset_${this.props.lgOffset}`];
      colClasses.push(className);
    }

    if (this.props.xlOffset) {
      const className = styles[`col_xl_offset_${this.props.xlOffset}`];
      colClasses.push(className);
    }

    if (this.props.justify) {
      colClasses.push(styles[`col_justify_${this.props.justify}`]);
    }

    return colClasses.join(' ');
  }

  render() {
    const classNames = this.getClassNames();

    return (
      <div className={classNames} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

Col.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  style: PropTypes.shape({}),
  flex: PropTypes.bool,
  xs: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(colSpans)]),
  sm: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(colSpans)]),
  md: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(colSpans)]),
  lg: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(colSpans)]),
  xl: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(colSpans)]),
  xsOffset: PropTypes.oneOf(colOffsets),
  smOffset: PropTypes.oneOf(colOffsets),
  mdOffset: PropTypes.oneOf(colOffsets),
  lgOffset: PropTypes.oneOf(colOffsets),
  xlOffset: PropTypes.oneOf(colOffsets),
  justify: PropTypes.oneOf(['flex-end', 'center']),
};

export default Col;
