import React, { useState } from 'react';
import { Card } from '../../../../component1/Card';
import { widgetMinHeight } from '../../../global/constants';
import styles from './NewsWidget.scss';
import { NewsItemType, TranslateFn } from '../../../global/model';
import FlatButton from '../../../../component1/FlatButton/FlatButton';
import { tRoute } from '../../../../i18n';

interface Props {
  news: NewsItemType[];
  t: TranslateFn;
}

export const NewsWidget: React.FC<Props> = ({ news, t }) => {
  const [newsIdx] = useState(0);
  const newsItem = news[newsIdx];

  const actions = [<FlatButton key={1} label={t('Zobraziť novinky')} internalHref={tRoute('/novinky')} simple />];

  return (
    <Card layout="vcentered" minHeight={widgetMinHeight} bodyClassName={styles.cardBody} title={t('Novinky')} actions={actions}>
      <div className={styles.fullImg} style={{ backgroundImage: newsItem.preview_file ? `url(${newsItem.preview_file})` : undefined }} />
      <div className={styles.bottomRoot}>
        <h3>{newsItem.z32_name}</h3>
        <p>{newsItem.z32_widget_text}</p>
      </div>
      {newsItem.z30_category && <span className={styles.category}>{newsItem.z30_category}</span>}
    </Card>
  );
};
