export const FETCH_FIRM_BRANCH_PAIRS_REQUEST = 'firm/branch/FETCH_FIRM_BRANCH_PAIRS_REQUEST';
export const FETCH_FIRM_BRANCH_PAIRS_SUCCESS = 'firm/branch/FETCH_FIRM_BRANCH_PAIRS_SUCCESS';
export const FETCH_FIRM_BRANCH_PAIRS_FAILURE = 'firm/branch/FETCH_FIRM_BRANCH_PAIRS_FAILURE';

export const ADD_BRANCH_REQUEST = 'firm/branch/ADD_BRANCH_REQUEST';
export const ADD_BRANCH_SUCCESS = 'firm/branch/ADD_BRANCH_SUCCESS';
export const ADD_BRANCH_FAILURE = 'firm/branch/ADD_BRANCH_FAILURE';

export const UPDATE_BRANCH_REQUEST = 'firm/branch/UPDATE_BRANCH_REQUEST';
export const UPDATE_BRANCH_SUCCESS = 'firm/branch/UPDATE_BRANCH_SUCCESS';
export const UPDATE_BRANCH_FAILURE = 'firm/branch/UPDATE_BRANCH_FAILURE';

export const DELETE_BRANCH_REQUEST = 'firm/branch/DELETE_BRANCH_REQUEST';
export const DELETE_BRANCH_SUCCESS = 'firm/branch/DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAILURE = 'firm/branch/DELETE_BRANCH_FAILURE';
