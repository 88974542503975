import Immutable from 'immutable';
import * as types from './actionTypes';
import { COMPANY_PRODUCTS_UPDATED } from '../firm/firm/actionTypes';


const reducerFactory = (initialState) => (state, action) => {
  const { type, payload } = action;

  if (typeof state === 'undefined') {
    return initialState.get('order');
  }

  if (type === types.COMPLETE_ORDER_REQUEST) {
    return state.set('isLoading', true);
  }

  if (type === types.COMPLETE_ORDER_SUCCESS) {
    return state.setIn(['steps', 'paymentInfo'], Immutable.fromJS(payload))
      .set('isLoading', false);
  }

  if (type === types.SELECT_CONTRACT) {
    return state.set('contract', Immutable.fromJS(payload))
      .setIn(['steps', 'contractSelection', 'passed'], true);
    // Removed - selecting contract doesnot mean creating the order (it is created after import)
    // .set('isInProgress', true)
  }

  if (type === types.DELETE_CONTRACT) {
    return state.set('contract', null)
      .setIn(['steps', 'contractSelection', 'passed'], false)
      .set('isInProgress', false);
  }

  if (type === types.SET_ORDER_PRODUCTCODE) {
    return state.set('productCode', payload);
  }

  if (type === types.FETCH_PRODUCTS_SUCCESS) {
    // temporary added is_enabled
    return state.setIn(['products', 'data'], Immutable.fromJS(payload.map((p) => ({ ...p, is_enabled: p.is_enabled === undefined ? true : p.is_enabled }))))
      .setIn(['products', 'isLoading'], false);
  }

  if (type === types.FETCH_PRODUCTS_REQUEST) {
    return state.setIn(['products', 'isLoading'], true)
      .setIn(['products', 'isError'], false);
  }

  if (type === types.FETCH_PRODUCTS_FAILURE) {
    return state.setIn(['products', 'isLoading'], false)
      .setIn(['products', 'isError'], true);
  }

  if (type === COMPANY_PRODUCTS_UPDATED) {
    // update is_enabled flag
    const firmId = state.getIn(['contract', 'c01_company_id'], null);
    const originalData = state.getIn(['products', 'data']);

    if (firmId !== action.payload.companyId || !originalData) {
      return state;
    }

    const originalDataJS = originalData.toJS();

    const data = originalDataJS.map((product) => {
      const prod = action.payload.products.find((p) => p.s20_code === product.s20_code);
      return prod ? ({ ...product, is_enabled: prod.is_enabled }) : product;
    });
    return state.setIn(['products', 'data'], Immutable.fromJS(data));
  }

  if (type === types.SET_ORDER_IN_PROGRESS) {
    return state.set('isInProgress', payload);
  }

  if (type === types.SET_INITIALIZATION_ERRORS) {
    return state.setIn(['steps', 'initialization', 'errors'], Immutable.fromJS(payload));
  }

  if (type === types.SET_ORDER_FILE_ID) {
    return state.setIn(['steps', 'initialization', 'fileId'], parseInt(payload, 10));
  }

  if (type === types.DELETE_ORDER_FILE_ID) {
    return state.setIn(['steps', 'initialization', 'fileId'], null);
  }

  if (type === types.SET_VALIDATION_CHANGES) {
    return state.setIn(['steps', 'validation', 'changes'], Immutable.fromJS(payload));
  }

  if (type === types.SET_VALIDATION_ADDRESSES_ERRORS) {
    return state.setIn(['steps', 'validation', 'addresses'], Immutable.fromJS(payload));
  }

  if (type === types.CARD_ADDRESS_CHANGE_SUCCESS) {
    const addresses = state.getIn(['steps', 'validation', 'addresses']).toJS();

    if (!addresses || addresses.length === 0) {
      return state.setIn(['steps', 'validation', 'addresses'], Immutable.fromJS([]));
    }

    const validatedAddresses = addresses.map((address) => {
      if (address.o92_order_address_temp_id === payload.payload.o92_order_address_temp_id) {
        const validatedAddress = address;
        validatedAddress.is_valid = true;
        return validatedAddress;
      }

      return address;
    });

    let allAddressValid = true;
    validatedAddresses.forEach((address) => {
      if (!address.is_valid) {
        allAddressValid = false;
      }
    });

    return state.setIn(['steps', 'validation', 'addresses'], Immutable.fromJS(validatedAddresses))
      .setIn(['steps', 'validation', 'addressChangesValid'], Immutable.fromJS(allAddressValid));
  }

  if (type === types.CARD_ADDRESS_CHANGE_FAILURE) {
    const addresses = state.getIn(['steps', 'validation', 'addresses']).toJS();

    if (!addresses || addresses.length === 0) {
      return state.setIn(['steps', 'validation', 'addresses'], Immutable.fromJS([]));
    }

    const validatedAddresses = addresses.map((address) => {
      if (address.o92_order_address_temp_id === payload.payload.o92_order_address_temp_id) {
        const validatedAddress = address;
        validatedAddress.is_valid = false;
        return validatedAddress;
      }

      return address;
    });

    let allAddressValid = true;
    validatedAddresses.forEach((address) => {
      if (!address.is_valid) {
        allAddressValid = false;
      }
    });

    return state.setIn(['steps', 'validation', 'addresses'], Immutable.fromJS(validatedAddresses))
      .setIn(['steps', 'validation', 'addressChangesValid'], Immutable.fromJS(allAddressValid));
  }

  if (type === types.SET_VALIDATION_NAMES_ERRORS) {
    return state.setIn(['steps', 'validation', 'names'], Immutable.fromJS(payload));
  }

  if (type === types.DELETE_ORDER_REQUEST) {
    return state
      .set('isDeletingOrder', false)
      .set('isDeletingOrderFailure', false);
  }

  if (type === types.DELETE_ORDER_SUCCESS) {
    return initialState.get('order')
      .set('products', state.get('products'));
  }

  if (type === types.DELETE_ORDER_FAILURE) {
    return state
      .set('isDeletingOrder', false)
      .set('isDeletingOrderFailure', true);
  }

  if (type === types.FILE_DOWNLOAD_REQUEST) {
    return state
      .setIn(['steps', 'initialization', 'isDownloading'], true)
      .setIn(['steps', 'initialization', 'isDownloadingFailure'], false);
  }

  if (type === types.FILE_DOWNLOAD_SUCCESS) {
    return state
      .setIn(['steps', 'initialization', 'isDownloading'], false)
      .setIn(['steps', 'initialization', 'isDownloadingFailure'], true);
  }

  if (type === types.FILE_DOWNLOAD_FAILURE) {
    return state
      .setIn(['steps', 'initialization', 'isDownloading'], false)
      .setIn(['steps', 'initialization', 'isDownloadingFailure'], false);
  }

  if (type === types.IMPORT_FILE_REQUEST) {
    return state
      .setIn(['steps', 'initialization', 'isImporting'], true)
      .setIn(['steps', 'initialization', 'isImportingFailure'], false)
      .setIn(['steps', 'initialization', 'errors'], Immutable.fromJS([]));
  }

  if (type === types.IMPORT_FILE_SUCCESS) {
    return state
      .setIn(['steps', 'initialization', 'isImporting'], false)
      .setIn(['steps', 'initialization', 'isImportingFailure'], false);
  }

  if (type === types.IMPORT_FILE_FAILURE) {
    return state
      .setIn(['steps', 'initialization', 'isImporting'], false)
      .setIn(['steps', 'initialization', 'isImportingFailure'], true);
  }

  if (type === types.LOAD_ORDER_IN_PROGRESS_REQUEST) {
    return state
      .setIn(['steps', 'adjustmentOfValues', 'isLoadingBasket'], true)
      .setIn(['steps', 'adjustmentOfValues', 'isLoadingBasketFailure'], false);
  }

  if (type === types.LOAD_ORDER_IN_PROGRESS_SUCCESS) {
    return state
      .setIn(['steps', 'adjustmentOfValues', 'isLoadingBasket'], false)
      .setIn(['steps', 'adjustmentOfValues', 'isLoadingBasketFailure'], false);
  }

  if (type === types.LOAD_ORDER_IN_PROGRESS_FAILURE) {
    return state
      .setIn(['steps', 'adjustmentOfValues', 'isLoadingBasket'], false)
      .setIn(['steps', 'adjustmentOfValues', 'isLoadingBasketFailure'], true);
  }

  if (type === types.DELETE_CARD_SUCCESS) {
    let newState = state;

    payload.types.forEach((cardType) => {
      const items = state.getIn(['steps', 'adjustmentOfValues', 'basket', cardType]);
      newState = newState.setIn(
        ['steps', 'adjustmentOfValues', 'basket', cardType],
        items.filter((item) => item.get('o90_order_line_temp_id') !== payload.lineId)
      );
    });
    return newState;
  }

  if (type === types.CREATE_FREE_CARD_SUCCESS) {
    const data = {
      c85_name: 'Volná karta',
      o90_cards_count: payload.o90_cards_count,
      address: payload.address,
    };
    return state.updateIn(
      ['steps', 'adjustmentOfValues', 'basket', 'free_cards'],
      (array) => array.push(data)
    );
  }

  if (type === types.SET_ORDER_BASKET) {
    payload.new_cards.forEach((card, index) => {
      const normalizedCard = { o90_mandatory_card: !!card.o90_mandatory_card };
      payload.new_cards[index] = { ...card, ...normalizedCard };
    });
    return state
      .setIn(['steps', 'adjustmentOfValues', 'basket'], Immutable.fromJS(payload));
  }

  if (type === types.SET_ORDER_OVERVIEW) {
    return state
      .setIn(['steps', 'finalInspection', 'overview'], Immutable.fromJS(payload));
  }

  if (type === types.ADD_NAME_BEING_VALIDATED) {
    const namesBeingValidated = state.getIn(['steps', 'validation', 'namesBeingValidated']).push(payload.id);
    return state
      .setIn(['steps', 'validation', 'namesBeingValidated'], Immutable.fromJS(namesBeingValidated));
  }

  if (type === types.REMOVE_NAME_BEING_VALIDATED) {
    const namesBeingValidated = state
      .getIn(['steps', 'validation', 'namesBeingValidated'])
      .filter((item) => item !== payload.id);

    return state
      .setIn(['steps', 'validation', 'namesBeingValidated'], Immutable.fromJS(namesBeingValidated));
  }

  if (type === types.EDIT_ORDER_CREDIT_SUCCESS) {
    if (!state.getIn(['steps', 'adjustmentOfValues', 'basket', 'credits'])) {
      return state;
    }

    const credits = state.getIn(['steps', 'adjustmentOfValues', 'basket', 'credits']).toJS();
    return state
      .setIn(
        ['steps', 'adjustmentOfValues', 'basket', 'credits'],
        Immutable.fromJS(credits.map((item) => {
          const updatedItem = item;
          if (item.o93_credit_temp_id === payload.id) {
            updatedItem.o93_count = payload.data.o93_count
              ? payload.data.o93_count : item.o93_count;
            updatedItem.o93_value = payload.data.o93_value
              ? payload.data.o93_value : item.o93_value;
            updatedItem.c30_name_on_card = payload.data.c30_name_on_card
              ? payload.data.c30_name_on_card : item.c30_name_on_card;
            updatedItem.total = updatedItem.o93_count * updatedItem.o93_value;
          }
          return updatedItem;
        }))
      );
  }

  if (type === types.EDIT_ORDER_SUCCESS) {
    const basket = state.getIn(['steps', 'adjustmentOfValues', 'basket']).toJS();
    const updatedBasket = {
      ...basket,
      i04_payment_type_id: payload.i04_payment_type_id
        ? payload.i04_payment_type_id : basket.i04_payment_type_id,
      p43_package_order_id: payload.p43_package_order_id
        ? payload.p43_package_order_id : basket.p43_package_order_id,
      p17_card_perso_id: payload.p17_card_perso_id
        ? payload.p17_card_perso_id : basket.p17_card_perso_id,
      i01_customer_code: 'i01_customer_code' in payload
        ? payload.i01_customer_code : basket.i01_customer_code,
      p30_line2_perso: payload.p30_line2_perso
        ? payload.p30_line2_perso : null,
    };
    const parametersIm = state.getIn(['steps', 'finalInspection', 'overview', 'parameters']);
    const parameters = parametersIm ? parametersIm.toJS() : {};

    let updatedPaymentTypeId;
    let updatedPackageOrderId;
    let updateCardPersosId;

    if (payload.i04_payment_type_id) {
      updatedPaymentTypeId = payload.i04_payment_type_id;
    } else if (parameters.i04_payment_type_id) {
      updatedPaymentTypeId = parameters.i04_payment_type_id;
    } else {
      updatedPaymentTypeId = 1;
    }

    if (payload.p43_package_order_id) {
      updatedPackageOrderId = payload.p43_package_order_id;
    } else if (parameters.p43_package_order_id) {
      updatedPackageOrderId = parameters.p43_package_order_id;
    } else {
      updatedPackageOrderId = 1;
    }

    if (payload.p17_card_perso_id) {
      updateCardPersosId = payload.p17_card_perso_id;
    } else if (parameters.p17_card_perso_id) {
      updateCardPersosId = parameters.p17_card_perso_id;
    } else {
      updateCardPersosId = 1;
    }

    const updatedParameters = {
      ...parameters,
      i04_payment_type_id: updatedPaymentTypeId,
      p43_package_order_id: updatedPackageOrderId,
      p17_card_perso_id: updateCardPersosId,
    };

    return state
      .setIn(['steps', 'adjustmentOfValues', 'basket'], Immutable.fromJS(updatedBasket))
      .setIn(['steps', 'finalInspection', 'overview', 'parameters'], Immutable.fromJS(updatedParameters));
  }

  if (type === types.SET_ORDER_FILTER) {
    const initialFilter = initialState.getIn(['order', 'filter']).toJS();
    const updatedFilter = state.get('filter').toJS();
    const filterFields = Object.keys(initialFilter);

    filterFields.forEach((filterField) => {
      if (payload[filterField] === undefined) {
        updatedFilter[filterField] = initialFilter[filterField];
      } else {
        updatedFilter[filterField] = payload[filterField];
      }
    });

    return state.set('filter', Immutable.fromJS(updatedFilter));
  }

  if (type === types.LOAD_DELIVERY_ADDRESSES_REQUEST) {
    return state
      .setIn(['steps', 'adjustmentOfValues', 'deliveryAddressPairs', 'isFetching'], true)
      .setIn(['steps', 'adjustmentOfValues', 'deliveryAddressPairs', 'isFetchingFailure'], false);
  }

  if (type === types.LOAD_DELIVERY_ADDRESSES_SUCCESS) {
    const firmsAddresses = payload.map((item) => ({
      c25_contract_address_id: item.id,
      contract_address: item.val,
    }));
    return state
      .setIn(['steps', 'adjustmentOfValues', 'deliveryAddressPairs', 'deliveryAddresses'], Immutable.fromJS(firmsAddresses))
      .setIn(['steps', 'adjustmentOfValues', 'deliveryAddressPairs', 'isFetching'], false)
      .setIn(['steps', 'adjustmentOfValues', 'deliveryAddressPairs', 'isFetchingFailure'], false);
  }

  if (type === types.LOAD_DELIVERY_ADDRESSES_FAILURE) {
    return state
      .setIn(['steps', 'adjustmentOfValues', 'deliveryAddressPairs', 'isFetching'], false)
      .setIn(['steps', 'adjustmentOfValues', 'deliveryAddressPairs', 'isFetchingFailure'], true);
  }

  if (type === types.ONLINE_CONFIRM_REQUEST) {
    return state
      .setIn(['steps', 'onlineConfirm', 'isFetching'], true)
      .setIn(['steps', 'onlineConfirm', 'isFetchingFailure'], false);
  }

  if (type === types.ONLINE_CONFIRM_SUCCESS) {
    return state
      .setIn(['steps', 'onlineConfirm', 'data'], Immutable.fromJS(payload))
      .setIn(['steps', 'onlineConfirm', 'isFetching'], false)
      .setIn(['steps', 'onlineConfirm', 'isFetchingFailure'], false);
  }

  if (type === types.ONLINE_CONFIRM_FAILURE) {
    return state
      .setIn(['steps', 'onlineConfirm', 'data'], Immutable.fromJS(payload.response))
      .setIn(['steps', 'onlineConfirm', 'isFetching'], false)
      .setIn(['steps', 'onlineConfirm', 'isFetchingFailure'], true);
  }

  if (type === types.FETCH_CARD_IMAGE_SUCCESS) {
    return state
      .setIn(['steps', 'finalInspection', 'cardImage'], payload);
  }

  if (type === types.FETCH_CARD_PERSONALIZATION_SUCCESS) {
    return state
      .setIn(['steps', 'finalInspection', 'cardPersonalization'], Immutable.fromJS(payload));
  }

  if (type === types.REDIRECT_TO_VALIDATION) {
    return state.setIn(['isRedirectedToValidation'], true);
  }

  if (type === types.RESET_REDIRECT_TO_VALIDATION) {
    return state.set('isRedirectedToValidation', false);
  }

  if (type === types.REDIRECT_TO_CONTRACT_SELECTION) {
    return state.setIn(['isRedirectedToContractSelection'], true);
  }

  if (type === types.RESET_REDIRECT_TO_CONTRACT_SELECTION) {
    return state.set('isRedirectedToContractSelection', false);
  }

  if (type === types.REDIRECT_TO_ADJUSTMENT) {
    return state.setIn(['isRedirectedToAdjustment'], true);
  }

  if (type === types.RESET_REDIRECT_TO_ADJUSTMENT) {
    return state.set('isRedirectedToAdjustment', false);
  }

  return state;
};


export default reducerFactory;
