import { RSAA } from 'redux-api-middleware';
import { API_URL } from '../../../../config/envspecific';
import * as types from './actionTypes';

export const fetchTransactions = (queryString, payload, callback) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/my_transactions?${queryString}`,
    method: 'GET',
    types: [
      { type: types.FETCH_TRANSACTIONS_REQUEST, payload },
      types.FETCH_TRANSACTIONS_SUCCESS,
      types.FETCH_TRANSACTIONS_FAILURE,
    ],
  },
}).then(() => callback());

export const updateTransactionsFilterData = (filter, orderBy) => ({
  type: types.UPDATE_TRANSACTIONS_FILTER_DATA,
  payload: { filter, orderBy },
});
