import { createSelector } from 'reselect';

const getCardRegistration = (state) => state.getIn(['registration', 'firstLogin', 'registerCard']);
const getCardRegistrationWithEmail = (state) => state.getIn(['registration', 'firstLogin', 'registerCardWithEmail']);


export const selectCardRegistrationMeta = createSelector(
  [getCardRegistration],
  (cardRegistration) => ({
    isPosting: cardRegistration.get('isPosting'),
  })
);
export const selectCardRegistrationWithEmailMeta = createSelector(
  [getCardRegistrationWithEmail],
  (cardRegistrationWithEmail) => ({
    isPosting: cardRegistrationWithEmail.get('isPosting'),
  })
);
