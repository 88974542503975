import React, { lazy } from 'react';
import { browserHistory } from 'react-router';
import { MainLayout1 } from '../modules/layout/main1';
import MyRoutingTable from '../modules/my/routingTable';
import FirmRoutingTable from '../modules/firm/routingTable';
import PartnerRoutingTable from '../modules/partner/routingTable';
import UserRoutingTable from '../modules/user/routingTable';
import HelpRoutingTable from '../modules/help/routingTable';
import AuthRoutingTable from '../modules/auth/routingTable';
import RegistrationRoutingTable from '../modules/registration/registrationRoutingTable';
import FirstLoginRoutingTable from '../modules/registration/firstLoginRoutingTable';
import PaymentGateRoutingTable from '../modules/paymentGate/routingTable';
import { onEnterHookLogged } from './routerHooks';
import HomePage1 from '../modules/home1/HomePage';

const NotFoundPage = lazy(() => import(/* webpackChunkName: "home" */ './pages/NotFoundPage/NotFoundPage'));
const OnlinePaymentLandingContainer = lazy(() => import(/* webpackChunkName: "home1" */ '../modules/home1/OnlinePaymentLandingContainer'));
const OnlineShopsPage = lazy(() => import(/* webpackChunkName: "offers1" */ '../modules/home1/pages/onlineShops/OnlineShopsPage'));
const MyNewsListContainer = lazy(() => import(/* webpackChunkName: "home1" */ '../modules/home1/pages/news/NewsListContainer'));
const MyNewsDetailContainer = lazy(() => import(/* webpackChunkName: "home1" */ '../modules/home1/pages/news/NewsDetailContainer'));

export default {
  name: 'homepage',
  path: '/',
  key: 'H',
  childRoutes: [
    { // rebranded routes
      component: (props) => <MainLayout1 {...props} browserHistory={browserHistory} />,
      indexRoute: { component: HomePage1 },
      // component: (props) => <div>{props.children}</div>, // new layout
      // indexRoute: { component: () => <div>homepage signpost</div> },
      childRoutes: [
        MyRoutingTable,
        UserRoutingTable,
        HelpRoutingTable,
        {
          name: 'news',
          path: 'novinky',
          key: 'U19',
          indexRoute: { component: MyNewsListContainer },
          childRoutes: [{
            name: 'news',
            path: ':newsId',
            key: 'U19',
            component: MyNewsDetailContainer,
          }],
        },
      ],
      onEnter: onEnterHookLogged,
    },
    { // legacy brand routes
      component: (props) => <MainLayout1 {...props} browserHistory={browserHistory} />,
      childRoutes: [
        {
          name: 'online-payment-landing',
          path: '/online-payment-confirm',
          key: null,
          component: (props) => <OnlinePaymentLandingContainer {...props} type="contribution" />,
        },
        {
          name: 'card-assign-landing',
          path: '/card-assign-confirm',
          key: null,
          component: (props) => <OnlinePaymentLandingContainer {...props} type="card-assign" />,
        },
        {
          name: 'online-shops',
          path: '/nakupujte-online',
          key: null,
          component: OnlineShopsPage,
        },
        FirmRoutingTable,
        PartnerRoutingTable,
      ],
      onEnter: onEnterHookLogged,
    },
    AuthRoutingTable,
    RegistrationRoutingTable,
    FirstLoginRoutingTable,
    PaymentGateRoutingTable,
    {
      name: 'error_404',
      path: '404',
      component: NotFoundPage,
    },
    {
      name: 'ios_app_index',
      path: 'ios-app(-v:version).html',
      onEnter: (nextState, replace) => replace('/'),
    },
    {
      name: 'android_app_index',
      path: 'android-app(-v:version).html',
      onEnter: (nextState, replace) => replace('/'),
    },
    {
      name: 'index_html',
      path: 'index.html',
      onEnter: (nextState, replace) => replace('/'),
    },
    {
      name: 'error_404',
      path: '*',
      component: NotFoundPage,
    },
  ],
};
