import Immutable from 'immutable';
import * as types from './actionTypes';


const reducerFactory = (initialState) => (state, action) => {
  if (typeof state === 'undefined') {
    return initialState.getIn(['firm', 'contract']);
  }

  if (action.type === types.FETCH_CONTRACT_PAIRS_REQUEST) {
    return state
      .setIn(['contractPairs', 'isFetchingFailure'], false)
      .setIn(['contractPairs', 'isFetching'], true);
  }

  if (action.type === types.FETCH_CONTRACT_PAIRS_SUCCESS) {
    const contracts = action.payload.map((item) => ({
      ...item,
      label: `${item.c01_name} / ${item.c10_number}`,
      value: item.c10_contract_id,
      branch_strict_mode: (+item.branch_strict_mode) === 1,
    }));

    return state
      .setIn(['contractPairs', 'contractPairs'], Immutable.fromJS(contracts))
      .setIn(['contractPairs', 'isFetching'], false);
  }

  if (action.type === types.FETCH_CONTRACT_PAIRS_FAILURE) {
    return state
      .setIn(['contractPairs', 'isFetching'], false)
      .setIn(['contractPairs', 'isFetchingFailure'], true);
  }

  if (action.type === types.FETCH_CONTRACT_REQUEST) {
    return state
      .setIn(['contractList', 'isFetchingFailure'], false)
      .setIn(['contractList', 'isFetching'], true);
  }

  if (action.type === types.FETCH_CONTRACT_SUCCESS) {
    const contracts = action.payload.map((item) => ({
      data: item,
    }));

    return state
      .setIn(['contractList', 'contracts'], Immutable.fromJS(contracts))
      .setIn(['contractList', 'isFetching'], false);
  }

  if (action.type === types.FETCH_CONTRACT_FAILURE) {
    return state
      .setIn(['contractList', 'isFetching'], false)
      .setIn(['contractList', 'isFetchingFailure'], true);
  }


  return state;
};


export default reducerFactory;
