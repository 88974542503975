import { RSAA } from 'redux-api-middleware';
import { API_URL } from '../../../../config/envspecific';
import { t } from '../../../../i18n';
import { addFlashMessage } from '../../../flashMessages/actions';
import * as types from './actionTypes';
import {
  ThunkApiActionCreator,
} from '../../../../data/storeModel';
import { ApiMyUser } from '../../model';

export const fetchUser: ThunkApiActionCreator<ApiMyUser> = (userId: number) => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/my_users/${userId}`,
    method: 'GET',
    types: [
      types.FETCH_USER_REQUEST,
      types.FETCH_USER_SUCCESS,
      types.FETCH_USER_FAILURE,
    ],
  },
});

export const updateUser: ThunkApiActionCreator = (userId: number, data: any) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/my_users/${userId}`,
    method: 'PUT',
    body: JSON.stringify(data),
    types: [
      types.UPDATE_USER_REQUEST,
      {
        type: types.UPDATE_USER_SUCCESS,
        payload: async (action, state, res) => {
          const json = await res.json();
          dispatch(addFlashMessage('success', t('Uložení bylo úspěšné.', { ns: 'my' })));
          if (json.pending_phone && json.is_logged_user) {
            dispatch(addFlashMessage('success', t('Na nové telefonní číslo {{phone}} jsme odeslali ověřovací kód. Pro dokončení ověření zadejte kód na domovské stránce.', { ns: 'my', phone: json.pending_phone })));
          } else if (json.pending_phone && !json.is_logged_user) {
            dispatch(addFlashMessage('success', t('Na nové telefonní číslo {{phone}} jsme odeslali ověřovací kód. Přihlaste se do účtu Karty {{fullname}} a zadejte na domovské stránce potvrzovací kód.', { ns: 'my', fullname: `${data.u01_name} ${data.u01_surname}`, phone: json.pending_phone })));
          }
          return json;
        },
      },
      {
        type: types.UPDATE_USER_FAILURE,
        payload: (action, state, res) => {
          res.json().then((response) => {
            dispatch(addFlashMessage('error', response.message));
          });
          return res.json().then((json) => json);
        },
      },
    ],
  },
});

export const fetchUserPairs: ThunkApiActionCreator = () => (dispatch) => dispatch({
  [RSAA]: {
    endpoint: `${API_URL}/my_users`,
    method: 'GET',
    types: [
      types.FETCH_USER_PAIRS_REQUEST,
      types.FETCH_USER_PAIRS_SUCCESS,
      types.FETCH_USER_PAIRS_FAILURE,
    ],
  },
});
