export const FETCH_LOGIN_DATA_REQUEST = 'benefia/FETCH_LOGIN_DATA_REQUEST';
export const FETCH_LOGIN_DATA_SUCCESS = 'benefia/FETCH_LOGIN_DATA_SUCCESS';
export const FETCH_LOGIN_DATA_FAILURE = 'benefia/FETCH_LOGIN_DATA_FAILURE';

export const FETCH_WIDGETS_REQUEST = 'benefia/FETCH_WIDGETS_REQUEST';
export const FETCH_WIDGETS_SUCCESS = 'benefia/FETCH_WIDGETS_SUCCESS';
export const FETCH_WIDGETS_FAILURE = 'benefia/FETCH_WIDGETS_FAILURE';

export const SAVE_BENEFIA_ORDER_REQUEST = 'benefia/order/SAVE_BENEFIA_ORDER_REQUEST';
export const SAVE_BENEFIA_ORDER_SUCCESS = 'benefia/order/SAVE_BENEFIA_ORDER_SUCCESS';
export const SAVE_BENEFIA_ORDER_FAILURE = 'benefia/order/SAVE_BENEFIA_ORDER_FAILURE';
