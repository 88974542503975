export default {
  white: '#ffffff',
  black: '#4f5d5b',
  // Primary palette
  brandMain: '#f59100',
  brandGreen: '#9cc435',
  // Secondary palette
  brandYellow: '#f5b510',
  brandBlue: '#5eadd7',
  brandOrange: '#f08100',
  brandGreenDark: '#5c710b',
  red: '#e83c49',
  text: '#4f5d5b',

  // Grays
  gray1: '#f5f5f5',
  gray2: '#eee',
  gray3: '#e4e4e4',
  gray4: '#b2b2b2',
  gray5: '#86939e',
  // Browns
  brown0: '#837e70',

  pLaceholder: '#a1a1aa',

  // gusto legacy palette
  // // Primary palette
  // brandRed: '#e83c49',
  // brandGreen: '#9cc435',
  // // Secondary palette
  // brandYellow: '#f5b510',
  // brandBlue: '#5eadd7',
  // brandOrange: '#f08100',
  // brandGreenDark: '#5c710b',
  // // Grays
  // gray1: '#f5f5f5',
  // gray2: '#eee',
  // gray3: '#e4e4e4',
  // gray4: '#b2b2b2',
  // gray5: '#86939e',
  // // Browns
  // brown0: '#837e70',
};
// NOTICE
// Please keep synced with `./_colors.scss` which is used to configure application components.
