import { createSelector } from 'reselect';

const getAffiliate = (state) => state.getIn(['my', 'place', 'affiliate'])
  .toJS();
const getAffiliateList = (state) => state.getIn(['my', 'place', 'affiliateList'])
  .toJS();
const getAffiliates = (state) => state.getIn(['my', 'place', 'affiliateList', 'affiliates'])
  .toJS();
const getWishlist = (state) => state.getIn(['my', 'place', 'wishlist'])
  .toJS();

const getAffiliateMarkers = (state) => state.getIn(
  ['my', 'place', 'affiliateMarkers']
)
  .toJS();

const getAffiliateMarkersItems = (state) => state.getIn(
  ['my', 'place', 'affiliateMarkers', 'markers']
)
  .toJS();

const getAffiliateTypes = (state) => state
  .getIn(['my', 'place', 'affiliateTypePairs', 'data'])
  .toJS();

const getProductsDesc = (state) => state
  .getIn(['my', 'place', 'productsDescPairs', 'data'])
  .toJS();


export const selectMyPlaceAffiliateTypePairs = createSelector([getAffiliateTypes], (pairs) => pairs);

export const selectMyPlaceProductDescPairs = createSelector([getProductsDesc],
  (pairs) => pairs.map((item) => ({
    label: item.label || `(${item.cond})`,
    value: item.cond,
  })));

export const selectAffiliateMeta = createSelector(
  [getAffiliate],
  (affiliate) => ({
    isFetching: affiliate.isFetching,
  })
);

export const selectNewPlaceMeta = createSelector(
  [getWishlist],
  (wishlist) => ({
    isFetching: wishlist.isFetching,
    isPosting: wishlist.isPosting,
  })
);

export const selectAffiliate = createSelector(
  [getAffiliate],
  (affiliate) => ({
    ...affiliate.data,
    items: [affiliate.data],
  })
);

export const selectAffiliateList = createSelector(
  [getAffiliateList],
  (affiliates) => affiliates
);

export const selectAffiliates = createSelector(
  [getAffiliates],
  (affiliates) => affiliates
);

export const selectAffiliateMarkers = createSelector(
  [getAffiliateMarkersItems],
  (markers) => markers
);


export const selectAffiliateMarkersList = createSelector(
  [getAffiliateMarkers],
  (markers) => markers
);
