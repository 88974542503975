import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SelectField from '../../../../../component1/SelectField/SelectField';
import RaisedButton from '../../../../../component1/RaisedButton/RaisedButton';
import styles from './CreditCardLink.scss';
import { PropTypeCard } from '../../../../global/proptypes';
import { limitsListFactory } from './CreditCardLinkDetail';
import { moneyFormat3 } from '../../../../../service/number';

const initialStateFactory = (cards) => () => ({
  cardId: cards.length > 0 ? cards[0].p20_card_id : null,
  productCode: cards.length > 0 ? cards[0].auto_credit.settings[0].s20_code : null,
  minBalance: '',
  creditAmount: '',
});

export const CreditCardLinkNew = ({
  t, cards, onContinue, showCard,
}) => {
  const [state, setState] = useState(initialStateFactory(cards));
  const cardsList = cards.map((c) => ({ label: `${c.fullname} **** ${c.p20_pan || '****'}`, value: c.p20_card_id }));
  const singleCard = cards.length === 1 ? cards[0] : null;
  const selectedCard = singleCard || cards.find((c) => c.p20_card_id === state.cardId);
  const products = selectedCard ? selectedCard.auto_credit.settings.map((s) => ({ label: s.s20_name, value: s.s20_code })) : [];
  return (
    <div className={styles.container}>
      <div>
        <span>{t('Z připojené karty vám budeme navyšovat zůstatek na kartě:')}</span>
        {showCard && singleCard && (
          <div className={styles.cardText}>
            <span>{singleCard.fullname}</span>
            <span>
              {'**** **** **** '}
              {singleCard.p20_pan || '****'}
            </span>
          </div>
        )}
        {showCard && !singleCard && (
          <div className={styles.cardSelect}>
            <SelectField
              value={state.cardId || ''}
              options={cardsList}
              onChange={(value) => {
                setState({
                  ...state,
                  changed: true,
                  cardId: value ? +value : null,
                });
              }}
              errorText={null}
            />
          </div>
        )}
      </div>
      <div>
        <span>{t('Pokud zůstatek')}</span>
      </div>
      <div className={styles.centered}>
        <table className={styles.detailTable}>
          <tbody>
            <tr className={styles.header}>
              <th>{t('u služby:')}</th>
              <td>
                <SelectField
                  value={state.productCode}
                  options={products}
                  onChange={(value) => {
                    setState({
                      ...state,
                      changed: true,
                      productCode: value,
                    });
                  }}
                  errorText={null}
                  disabled={!selectedCard}
                  isThin
                />
              </td>
            </tr>
            <tr>
              <th>{t('klesne pod:')}</th>
              <td>
                <SelectField
                  value={state.minBalance}
                  options={limitsListFactory(state.productCode, t)}
                  onChange={(value) => {
                    setState({
                      ...state,
                      minBalance: value,
                    });
                  }}
                  errorText={null}
                  disabled={!selectedCard}
                  rightAligned
                  isThin
                />
              </td>
            </tr>
            <tr>
              <th>{t('dobijeme:')}</th>
              <td>
                <SelectField
                  value={state.creditAmount}
                  options={limitsListFactory('creditAmount', t)}
                  onChange={(value) => {
                    setState({
                      ...state,
                      creditAmount: value,
                    });
                  }}
                  errorText={null}
                  disabled={!selectedCard}
                  rightAligned
                  isThin
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>{t('Nyní Vás přesměrujeme na zabezpečenou službu GoPay, kde provedeme zkušební transakci {{amountText}} pro ověření karty. My nebudeme nikdy znát žádné detaily o Vaší kartě.', { amountText: moneyFormat3(1, t) })}</p>
      <div className={styles.buttons}>
        <RaisedButton label={t('Pokračovat')} priority="primary" disabled={!(state.minBalance && state.creditAmount)} onClick={() => onContinue(state)} />
      </div>
    </div>
  );
};

CreditCardLinkNew.propTypes = {
  cards: PropTypes.arrayOf(PropTypeCard).isRequired,
  onContinue: PropTypes.func.isRequired,
  showCard: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};
