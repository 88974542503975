import { RSAA } from 'redux-api-middleware';
import { API_URL } from '../../../config/envspecific';
import { t } from '../../../i18n';
import * as types from './actionTypes';
import downloadFile from '../../../service/downloadFile';


export const fetchContractPairs = (partnerId) => {
  let filterString = '';
  if (partnerId) {
    filterString = `&filter=c01_company_id:${partnerId}`;
  }

  return {
    [RSAA]: {
      endpoint: `${API_URL}/partners_contracts?view=pairs${filterString}`,
      method: 'GET',
      types: [
        types.FETCH_CONTRACT_PAIRS_REQUEST,
        types.FETCH_CONTRACT_PAIRS_SUCCESS,
        types.FETCH_CONTRACT_PAIRS_FAILURE,
      ],
    },
  };
};

export const fetchContracts = (partnerId) => {
  // martin@visionapps.cz on 2017-05-18
  // Calling with view=enum is expensive for the API.
  // If this method is to be called ensure view=enum is needed and if not do refactor.
  const filter = partnerId ? `&filter=c01_company_id:${partnerId}` : '';

  return {
    [RSAA]: {
      endpoint: `${API_URL}/partners_contracts?view=enums${filter}`,
      method: 'GET',
      types: [
        types.FETCH_CONTRACTS_REQUEST,
        types.FETCH_CONTRACTS_SUCCESS,
        types.FETCH_CONTRACTS_FAILURE,
      ],
    },
  };
};

export const updatePayoutFreq = (freq) => (dispatch) => dispatch({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/partners_contracts/change_freq`,
    method: 'PUT',
    body: JSON.stringify(freq),
    types: [
      types.UPDATE_PAYOUT_FREQ_REQUEST,
      {
        type: types.UPDATE_PAYOUT_FREQ_SUCCESS,
        payload: (action, state, res) => res
          .json()
          .then((json) => ({
            c10_contract_id: freq.c10_contract_id,
            freq: json.freq,
            prov: json.prov,
            fee: json.fee,
          })),
      },
      types.UPDATE_PAYOUT_FREQ_FAILURE,
    ],
  },
});

export const downloadContractPdf = (contractId, number) => ({
  [RSAA]: {
    headers: { 'Content-Type': 'application/json' },
    endpoint: `${API_URL}/partners_contracts/${contractId}/pdf`,
    method: 'GET',
    types: [
      types.DOWNLOAD_CONTRACT_PDF_REQUEST,
      {
        type: types.DOWNLOAD_CONTRACT_PDF_SUCCESS,
        payload: (action, state, res) => res.blob().then(
          (blob) => downloadFile(blob, t('KartaUp_smlouva_{{number}}.pdf', { number, ns: 'partner' }))
        ),
      },
      types.DOWNLOAD_CONTRACT_PDF_FAILURE,
    ],
  },
});
