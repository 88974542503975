import PropTypes from 'prop-types';
import React from 'react';
import { Card } from '../../../../component1/Card';
import Fieldset from '../../../../component1/Form/Fieldset';
import PhoneField from '../../../../component1/PhoneField/PhoneField';
import { widgetMinHeight } from '../../../global/constants';
import FlatButton from '../../../../component1/FlatButton/FlatButton';
import Icon from '../../../../component1/Icon/Icon';
import styles from './SmsVerificationWidget.scss';
import { INSTANCE_SK } from '../../../../config/envspecific';
import { normalizePhone } from '../../../../service/number';

type Props = PropTypes.InferProps<typeof SmsVerificationWidget.propTypes>;

class SmsVerificationWidget extends React.Component<Props> {
  state = {
    phone: '',
  };

  validate() {
    const phone = normalizePhone(this.state.phone, INSTANCE_SK ? '+421' : '+420');

    if (phone && phone.length === 16) {
      return true;
    }

    return null;
  }

  render() {
    const { t, onBack } = this.props;
    const hasErrors = this.validate();

    return (
      <Card
        layout="fluid"
        title={t('SMS notifikace')}
        actions={[
          <FlatButton
            key={1}
            label={t('Ověřit')}
            onClick={() => this.props.onVerify(this.state.phone)}
            disabled={!hasErrors}
          />,
          onBack && (
          <FlatButton
            key={2}
            label={t('Zpět')}
            onClick={() => onBack()}
          />
          ),
        ]}
        minHeight={widgetMinHeight}
      >
        <Fieldset>
          <div className={styles.topBanner} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={styles.bannerIcon}><Icon icon="textsms" size="xlarge" /></div>
            <h3
            // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: t('Komunikujte s námi i prostřednictvím SMS.') }}
            />
          </div>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t('Můžeme vám posílat:<ul><li>Potvrzení pro platbu bránou</li></ul>') }}
          />
          <PhoneField
            fieldId="tf-activate-phone"
            label={t('Zadejte své telefonní číslo')}
            value={this.state.phone}
            onChange={(e, value) => this.setState({ phone: value })}
            bigText
          />
        </Fieldset>
      </Card>
    );
  }

  static propTypes = {
    onVerify: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    t: PropTypes.func.isRequired,
  };
}

export default SmsVerificationWidget;
